import { STATUS_COLORS } from '../helpers/constants';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import axios from 'axios';
import LoginState from '../components/LoginState';
import { TinyTooltip } from '../components/Tooltip';
import { debounce } from 'lodash';
import Modal from './Modal';
import { FILE_DETAILS_TABS as tabs } from '../helpers/constants';
import { withRouter } from 'react-router-dom';

// tabs: compliance, operations
class StatusBoard extends Component {
  constructor(props) {
    super(props);
    this.programsRef = React.createRef();

    let currentTab = 'Compliance'; // default value
    switch (this.props.match.params.tab) {
      case 'compliance':
        currentTab = 'Compliance';
        break;
      case 'operations':
        currentTab = 'Operations';
        break;
      default:
        return;
    }

    this.state = {
      activeTab: currentTab,
      statusData: [],
      statusDataCopy: [],
      showModal: false,
      modalType: '',
      modalTitle: '',
      modalFile: '',
      modalBy: '',
      modalDate: '',
      modalVerb: '',
      byIssue: false,
      dateIssue: false,
      isFileDataIDSortDesc: false,
      isBrokerSortDesc: false,
      isStatusSortDesc: false,
      isApplicationDateSortDesc: false,
      isIDDueDateSortDesc: false,
      isApprovedDateSortDesc: false,
      isRateLockedDateSortDesc: false,
      isRLEDueDateSortDesc: false,
      isAppraisalDateSortDesc: false,
      isClosingDateSortDesc: false,
      isICDDueDateSortDesc: false,
      isIDSentDateSortDesc: false,
      isIDReceivedDateSortDesc: false,
      isEoiStartDateSortDesc: false,
      isEstFundingDateSortDesc: false,
    };

    this.getStatusData();
  }

  componentDidMount() {
    if (!this.state.statusData) this.getPipelineFiles();
  }

  componentDidUpdate() {
    let currentTab = '';

    switch (this.props.match.params.tab) {
      case 'compliance':
        currentTab = 'Compliance';
        break;
      case 'operations':
        currentTab = 'Operations';
        break;
      default:
        return;
    }
    if (this.state.activeTab !== currentTab)
      this.setState({ activeTab: currentTab });
  }

  async getStatusData() {
    const response = axios.get('/getPipelineFiles', {
      params: {
        pUserID: LoginState.userId,
      },
    });

    try {
      var resultData = (await response).data;
      const arr = [];
      // filter out files based on sub
      resultData.forEach(pFile => {
        if (
          pFile.loanStatus === 'Registered' ||
          pFile.loanStatus === 'In Submission' ||
          pFile.loanStatus === 'Submitted' ||
          pFile.loanStatus === 'In Underwriting' ||
          pFile.loanStatus === 'Approved' ||
          pFile.loanStatus === 'Clear to Close' ||
          pFile.loanStatus === 'Doc Out' ||
          pFile.loanStatus === 'Doc In' ||
          pFile.loanStatus === 'In Review' ||
          pFile.loanStatus === 'Reviewed' ||
          pFile.loanStatus === 'Clear to Fund' ||
          pFile.loanStatus === 'Funded' ||
          pFile.loanStatus === 'On Hold'
        )
          arr.push(pFile);
      });
      this.setState({ statusData: arr, statusDataCopy: arr });
    } catch {
      this.setState({ statusData: [], statusDataCopy: [] });
    }
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  ShowModal() {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td width="130px">{this.state.modalVerb} By:</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  id="modalBy"
                  name="modalBy"
                  onChange={this.captureInput}
                  value={this.state.modalBy}
                />
              </td>
              <td width="50px">
                {this.state.byIssue && (
                  <i className="bi bi-exclamation-triangle text-danger" />
                )}
              </td>
            </tr>
            <tr>
              <td>{this.state.modalVerb} Date:</td>
              <td>
                <input
                  className="form-control"
                  type="date"
                  id="modalDate"
                  name="modalDate"
                  onChange={this.captureInput}
                  value={this.state.modalDate}
                />
              </td>
              <td>
                {this.state.dateIssue && (
                  <i className="bi bi-exclamation-triangle text-danger" />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  async ValidateAndSubmit() {
    this.setState({
      byIssue: this.state.modalBy ? false : true,
      dateIssue: this.state.modalDate ? false : true,
    });

    if (!this.state.modalBy || !this.state.modalDate) return;

    const response = axios.get('/updatestatusboard', {
      params: {
        pFileID: this.state.modalFile,
        pFieldName: this.state.modalTitle,
        pSentBy: this.state.modalBy,
        pSentDate: this.state.modalDate,
      },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if (resultData === 'Success') {
      this.setState({ showModal: false });
      this.getStatusData();
    }
  }

  toggleState = state => {
    this.setState(prevState => ({
      [state]: !prevState[state],
    }));
  };

  compareBy(key, order) {
    return (a, b) => {
      if (a[key] < b[key]) return this.state[order] ? -1 : 1;
      if (a[key] > b[key]) return this.state[order] ? 1 : -1;
      return 0;
    };
  }

  sortBy(key, order) {
    let arrayCopy = [];
    if (!this.state.hasFilters) this.toggleState('hasFilters');
    arrayCopy = [...this.state.statusData];
    arrayCopy.sort(this.compareBy(key, order));
    return this.setState({
      statusData: arrayCopy,
      [order]: !this.state[order],
    });
  }

  handleFilterClear() {
    this.setState({ statusData: this.state.statusDataCopy });
  }

  searchLoanNum(e) {
    const searchQuery = e.target.value;
    this.setState({ searchQuery });
    const removeDashes = str => str.replace(/-/g, '');

    if (this.state.statusDataCopy.length === 0) {
      this.setState({ statusDataCopy: this.state.statusData });
    }

    if (searchQuery === '') {
      this.handleFilterClear();
    } else {
      debounce(() => {
        this.setState({
          statusData: this.state.statusDataCopy.filter(item => {
            const noDashesId = removeDashes(item.encodedFileID);
            const noDashesSearchQuery = removeDashes(searchQuery);
            if (noDashesId.includes(noDashesSearchQuery)) {
              return item;
            }
            if (noDashesSearchQuery.indexOf(' ') >= 0) {
              const [a, b] = noDashesSearchQuery.split(' ');
              if (
                item.borrower.toLowerCase().includes(a.toLowerCase()) &&
                item.borrower.toLowerCase().includes(b.toLowerCase())
              ) {
                return item;
              }
            }
            if (item.borrower.toLowerCase().includes(noDashesSearchQuery)) {
              return item;
            }
            return null;
          }),
        });
      }, 500)();
    }
  }

  render() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 mb-2">
            <h1 className="h4 text-uppercase">status board</h1>
          </div>
          <div className="col-12 col-md-4 mb-4 mb-md-2">
            <input
              className="form-control"
              onChange={e => this.searchLoanNum(e)}
              value={this.state.searchQuery}
              placeholder="Search Borrower / Loan Number"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ul className="nav nav-tabs nav-fill">
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <span
                  aria-current="page"
                  className={`nav-link ${
                    this.state.activeTab === 'Compliance' ? 'active' : ''
                  }`}
                  onClick={() =>
                    this.props.history.push('/status-board/compliance')
                  }
                >
                  Compliance
                </span>
              </li>
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <span
                  className={`nav-link ${
                    this.state.activeTab === 'Operations' ? 'active' : ''
                  }`}
                  onClick={() =>
                    this.props.history.push('/status-board/operations')
                  }
                >
                  Operations
                </span>
              </li>
            </ul>
          </div>
          <div className="col-12">
            <div className="table-responsive border border-top-0 rounded-bottom bg-white">
              {this.state.activeTab === 'Compliance' ? (
                /* Compliance Tab Content*/
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th
                        key="LoanNo"
                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                      >
                        <button
                          className="btn px-1 text-nowrap"
                          onClick={() =>
                            this.sortBy('encodedFileID', 'isFileDataIDSortDesc')
                          }
                        >
                          Loan #
                        </button>
                      </th>
                      <th
                        key="+"
                        style={{
                          padding: '0px',
                          margin: '0px',
                          position: 'relative',
                          top: '-14px',
                        }}
                      >
                        +
                      </th>
                      <th
                        key="Borrower"
                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                      >
                        <button
                          className="btn px-1 text-nowrap"
                          onClick={() =>
                            this.sortBy('borrower', 'isBorrowerSortDesc')
                          }
                        >
                          Borrower
                        </button>
                      </th>
                      <th
                        key="Status"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <button
                          className="btn px-1 text-nowrap"
                          onClick={() =>
                            this.sortBy('loanStatus', 'isLoanStatusSortDesc')
                          }
                        >
                          Loan Status
                        </button>
                      </th>
                      <th
                        key="AppDate"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <TinyTooltip tip="Application Date">
                          <button
                            className="btn px-1 text-nowrap"
                            onClick={() =>
                              this.sortBy(
                                'applicationDate',
                                'isApplicationDateSortDesc'
                              )
                            }
                          >
                            App. Date
                          </button>
                        </TinyTooltip>
                      </th>
                      <th
                        key="IdDueDate"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <TinyTooltip tip="Initial Disclosure Due Date">
                          <button
                            className="btn px-1 text-nowrap"
                            onClick={() =>
                              this.sortBy(
                                'initialDisclosureDueDate',
                                'isIDDueDateSortDesc'
                              )
                            }
                          >
                            ID Due
                          </button>
                        </TinyTooltip>
                      </th>

                      <th
                        key="IleIssued"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                          lineHeight: '15px',
                          position: 'relative',
                          top: '-4px',
                          fontWeight: 'normal',
                        }}
                      >
                        <TinyTooltip tip="Initial Loan Estimate Issued">
                          ILE Issued
                        </TinyTooltip>
                      </th>

                      <th
                        key="IleSigned"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                          lineHeight: '15px',
                          position: 'relative',
                          top: '-4px',
                          fontWeight: 'normal',
                        }}
                      >
                        <TinyTooltip tip="Initial Loan Estimate Signed">
                          ILE Signed
                        </TinyTooltip>
                      </th>

                      <th
                        key="LleIssued"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                          lineHeight: '15px',
                          position: 'relative',
                          top: '-4px',
                          fontWeight: 'normal',
                        }}
                      >
                        <TinyTooltip tip="Locked Loan Estimate Issued">
                          LLE Issued
                        </TinyTooltip>
                      </th>

                      <th
                        key="LleSigned"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                          lineHeight: '15px',
                          position: 'relative',
                          top: '-4px',
                          fontWeight: 'normal',
                        }}
                      >
                        <TinyTooltip tip="Locked Loan Estimate Signed">
                          LLE Signed
                        </TinyTooltip>
                      </th>

                      <th
                        key="RleIssued"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                          lineHeight: '15px',
                          position: 'relative',
                          top: '-4px',
                          fontWeight: 'normal',
                        }}
                      >
                        <TinyTooltip tip="Revised Loan Estimate Issued">
                          RLE Issued
                        </TinyTooltip>
                      </th>

                      <th
                        key="RleSigned"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                          lineHeight: '15px',
                          position: 'relative',
                          top: '-4px',
                          fontWeight: 'normal',
                        }}
                      >
                        <TinyTooltip tip="Revised Loan Estimate Signed">
                          RLE Signed
                        </TinyTooltip>
                      </th>

                      <th
                        key="AppraisalDate"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <TinyTooltip tip="Appraisal Date">
                          <button
                            className="btn px-1 text-nowrap"
                            onClick={() =>
                              this.sortBy(
                                'appraisalDate',
                                'isAppraisalDateSortDesc'
                              )
                            }
                          >
                            Appraisal
                          </button>
                        </TinyTooltip>
                      </th>

                      <th
                        key="ClosingDate"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <TinyTooltip tip="Estimated Closing Date">
                          <button
                            className="btn px-1 text-nowrap"
                            onClick={() =>
                              this.sortBy(
                                'closingDate',
                                'isClosingDateSortDesc'
                              )
                            }
                          >
                            Est. Closing
                          </button>
                        </TinyTooltip>
                      </th>
                      <th
                        key="IcdDueDate"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <TinyTooltip tip="Initial Closing Disclosure Due Date">
                          <button
                            className="btn px-1 text-nowrap"
                            onClick={() =>
                              this.sortBy(
                                'initialClosingDisclosureDueDate',
                                'isICDDueDateSortDesc'
                              )
                            }
                          >
                            ICD Due
                          </button>
                        </TinyTooltip>
                      </th>

                      <th
                        key="IDSentDate"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <TinyTooltip tip="Initial Closing Disclosure Sent Date">
                          <button
                            className="btn px-1 text-nowrap"
                            onClick={() =>
                              this.sortBy('iDSentDate', 'isIDSentDateSortDesc')
                            }
                          >
                            ICD Issued
                          </button>
                        </TinyTooltip>
                      </th>
                      <th
                        key="IDReceivedDate"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <TinyTooltip tip="Initial Closing Disclosure Received Date">
                          <button
                            className="btn px-1 text-nowrap"
                            onClick={() =>
                              this.sortBy(
                                'iDReceivedDate',
                                'isIDReceivedDateSortDesc'
                              )
                            }
                          >
                            ICD Signed
                          </button>
                        </TinyTooltip>
                      </th>

                      <th
                        key="EoiStartDate"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <TinyTooltip tip="Evidence of Insurance Start Date">
                          <button
                            className="btn px-1 text-nowrap"
                            onClick={() =>
                              this.sortBy(
                                'eoiStartDate',
                                'isEoiStartDateSortDesc'
                              )
                            }
                          >
                            EOI Start
                          </button>
                        </TinyTooltip>
                      </th>
                      <th
                        key="EstFundingDate"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <TinyTooltip tip="Scheduled Funding Date">
                          <button
                            className="btn px-1 text-nowrap"
                            onClick={() =>
                              this.sortBy(
                                'schedFundingDate',
                                'isEstFundingDateSortDesc'
                              )
                            }
                          >
                            Est. Funding
                          </button>
                        </TinyTooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.statusData.map(file => (
                      <tr key={file.fileDataID}>
                        <td colSpan={3}>
                          <Link
                            color="primary500"
                            to={`../details/sub-${file.encodedFileID}`}
                          >
                            {file.encodedFileID}
                          </Link>
                          <br />
                          {file.borrower}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <span
                            className={`badge fw-normal rounded-pill`}
                            style={{
                              color: 'black',
                              backgroundColor:
                                STATUS_COLORS[file.loanStatus.toLowerCase()],
                            }}
                          >
                            {file.loanStatus}
                          </span>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {file.applicationDate.slice(0, 5)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {file.initialDisclosureSentDate !== '---' ? (
                            <TinyTooltip
                              tip={
                                'Sent: ' +
                                file.initialDisclosureSentDate.slice(0, 5) +
                                '\nBy: ' +
                                file.initialDisclosureSentBy
                              }
                            >
                              <i className="bi bi-check-circle text-success" />
                            </TinyTooltip>
                          ) : (
                            <>
                              {file.applicationDate === '---' ? (
                                <>---</>
                              ) : (
                                <>
                                  {file.initialDisclosureDueDate.endsWith(
                                    '*'
                                  ) ? (
                                    <button
                                      className="btn btn-light btn-sm"
                                      onClick={() =>
                                        this.setState({
                                          showModal: true,
                                          modalTitle: 'Initial Disclosure',
                                          modalType:
                                            'Initial Disclosure Sent (' +
                                            file.encodedFileID +
                                            ')',
                                          modalBy: LoginState.userName,
                                          modalFile: file.encodedFileID,
                                          modalVerb: 'Sent',
                                          modalDate: today,
                                          byIssue: false,
                                          dateIssue: false,
                                        })
                                      }
                                    >
                                      {file.initialDisclosureDueDate.slice(
                                        0,
                                        5
                                      )}
                                    </button>
                                  ) : (
                                    <>
                                      {file.initialDisclosureDueDate.endsWith(
                                        '+'
                                      ) ? (
                                        <button
                                          className="btn btn-light btn-sm"
                                          onClick={() =>
                                            this.setState({
                                              showModal: true,
                                              modalTitle: 'Initial Disclosure',
                                              modalType:
                                                'Initial Disclosure Sent (' +
                                                file.encodedFileID +
                                                ')',
                                              modalBy: LoginState.userName,
                                              modalFile: file.encodedFileID,
                                              modalVerb: 'Sent',
                                              modalDate: today,
                                              byIssue: false,
                                              dateIssue: false,
                                            })
                                          }
                                        >
                                          {file.initialDisclosureDueDate.slice(
                                            0,
                                            5
                                          )}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-warning btn-sm"
                                          onClick={() =>
                                            this.setState({
                                              showModal: true,
                                              modalTitle: 'Initial Disclosure',
                                              modalType:
                                                'Initial Disclosure Sent (' +
                                                file.encodedFileID +
                                                ')',
                                              modalBy: LoginState.userName,
                                              modalFile: file.encodedFileID,
                                              modalVerb: 'Sent',
                                              modalDate: today,
                                              byIssue: false,
                                              dateIssue: false,
                                            })
                                          }
                                        >
                                          {file.initialDisclosureDueDate.slice(
                                            0,
                                            5
                                          )}
                                        </button>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </td>

                        {/* ILE */}
                        <td style={{ textAlign: 'center' }}>
                          <div className="flex-grow-1 vstack gap-1">
                            {file.ileList.map(le => (
                              <span key={le.issuedDate}>
                                {le.issuedDate.slice(0, 5)}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <div className="flex-grow-1 vstack gap-1">
                            {file.ileList.map(le => (
                              <span key={le.signedDate}>
                                {le.signedDate.slice(0, 5)}
                              </span>
                            ))}
                          </div>
                        </td>

                        {/* LLE */}
                        <td style={{ textAlign: 'center' }}>
                          <div className="flex-grow-1 vstack gap-1">
                            {file.lleList.map(le => (
                              <span key={le.issuedDate}>
                                {le.issuedDate.slice(0, 5)}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <div className="flex-grow-1 vstack gap-1">
                            {file.lleList.map(le => (
                              <span key={le.signedDate}>
                                {le.signedDate.slice(0, 5)}
                              </span>
                            ))}
                          </div>
                        </td>

                        {/* RLE */}
                        <td style={{ textAlign: 'center' }}>
                          <div className="flex-grow-1 vstack gap-1">
                            {file.rleList.map(le => (
                              <span key={le.issuedDate}>
                                {le.issuedDate.slice(0, 5)}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <div className="flex-grow-1 vstack gap-1">
                            {file.rleList.map(le => (
                              <span key={le.signedDate}>
                                {le.signedDate.slice(0, 5)}
                              </span>
                            ))}
                          </div>
                        </td>

                        <td style={{ textAlign: 'center' }}>
                          {file.appraisalDate.slice(0, 5)}
                        </td>

                        <td style={{ textAlign: 'center' }}>
                          {file.closingDate.slice(0, 5)}
                        </td>

                        <td style={{ textAlign: 'center' }}>
                          {file.initialClosingDisclosureDueDate !== '---' ? (
                            <>
                              {file.initialClosingDisclosureSentDate !==
                              '---' ? (
                                <TinyTooltip
                                  tip={
                                    'Sent: ' +
                                    file.initialClosingDisclosureSentDate.slice(
                                      0,
                                      5
                                    ) +
                                    '\nBy: ' +
                                    file.initialClosingDisclosureSentBy
                                  }
                                >
                                  <i className="bi bi-check-circle text-success" />
                                </TinyTooltip>
                              ) : (
                                <>
                                  {file.initialClosingDisclosureDueDate.endsWith(
                                    '*'
                                  ) ? (
                                    <button
                                      className="btn btn-light btn-sm"
                                      onClick={() =>
                                        this.setState({
                                          showModal: true,
                                          modalTitle:
                                            'Initial Closing Disclosure',
                                          modalType:
                                            'Initial Closing Disclosure Sent (' +
                                            file.encodedFileID +
                                            ')',
                                          modalBy: LoginState.userName,
                                          modalFile: file.encodedFileID,
                                          modalVerb: 'Sent',
                                          modalDate: today,
                                          byIssue: false,
                                          dateIssue: false,
                                        })
                                      }
                                    >
                                      {file.initialClosingDisclosureDueDate.slice(
                                        0,
                                        5
                                      )}
                                    </button>
                                  ) : (
                                    <>
                                      {file.initialClosingDisclosureDueDate.endsWith(
                                        '+'
                                      ) ? (
                                        <button
                                          className="btn btn-light btn-sm"
                                          onClick={() =>
                                            this.setState({
                                              showModal: true,
                                              modalTitle:
                                                'Initial Closing Disclosure',
                                              modalType:
                                                'Initial Closing Disclosure Sent (' +
                                                file.encodedFileID +
                                                ')',
                                              modalBy: LoginState.userName,
                                              modalFile: file.encodedFileID,
                                              modalVerb: 'Sent',
                                              modalDate: today,
                                              byIssue: false,
                                              dateIssue: false,
                                            })
                                          }
                                        >
                                          {file.initialClosingDisclosureDueDate.slice(
                                            0,
                                            5
                                          )}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-warning btn-sm"
                                          onClick={() =>
                                            this.setState({
                                              showModal: true,
                                              modalTitle:
                                                'Initial Closing Disclosure',
                                              modalType:
                                                'Initial Closing Disclosure Sent (' +
                                                file.encodedFileID +
                                                ')',
                                              modalBy: LoginState.userName,
                                              modalFile: file.encodedFileID,
                                              modalVerb: 'Sent',
                                              modalDate: today,
                                              byIssue: false,
                                              dateIssue: false,
                                            })
                                          }
                                        >
                                          {file.initialClosingDisclosureDueDate.slice(
                                            0,
                                            5
                                          )}
                                        </button>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {file.idSentDate.slice(0, 5)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {file.idReceivedDate.slice(0, 5)}
                        </td>

                        <td style={{ textAlign: 'center' }}>
                          {file.eoiStartDate.slice(0, 5)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {file.schedFundingDate.slice(0, 5)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                /* Operations Tab Content*/
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        key="LoanNo"
                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                      >
                        <button
                          className="btn px-1 text-nowrap"
                          onClick={() =>
                            this.sortBy('encodedFileID', 'isFileDataIDSortDesc')
                          }
                        >
                          Loan #
                        </button>
                      </th>
                      <th
                        key="+"
                        style={{
                          padding: '0px',
                          margin: '0px',
                          position: 'relative',
                          top: '-14px',
                        }}
                      >
                        +
                      </th>
                      <th
                        key="Borrower"
                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                      >
                        <button
                          className="btn px-1 text-nowrap"
                          onClick={() =>
                            this.sortBy('borrower', 'isBorrowerSortDesc')
                          }
                        >
                          Borrower
                        </button>
                      </th>
                      <th
                        key="Status"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <button
                          className="btn px-1 text-nowrap"
                          onClick={() =>
                            this.sortBy('loanStatus', 'isLoanStatusSortDesc')
                          }
                        >
                          Loan Status
                        </button>
                      </th>

                      {tabs.map(tab => (
                        <React.Fragment key={tab.prefix}>
                          {/* skip the communications tab */}
                          {tab.prefix !== 'com' && (
                            <th
                              key={tab.prefix}
                              style={{
                                textAlign: 'center',
                                position: 'relative',
                                top: '-7px',
                                fontWeight: 'normal',
                              }}
                            >
                              <TinyTooltip tip={tab.label}>
                                {tab.prefix.toUpperCase()}
                              </TinyTooltip>
                            </th>
                          )}
                        </React.Fragment>
                      ))}

                      <th
                        key="MERS REG"
                        style={{
                          textAlign: 'center',
                          position: 'relative',
                          top: '-7px',
                          fontWeight: 'normal',
                        }}
                      >
                        MERS Reg.
                      </th>
                      <th
                        key="MERS TRANS"
                        style={{
                          textAlign: 'center',
                          position: 'relative',
                          top: '-7px',
                          fontWeight: 'normal',
                        }}
                      >
                        MERS Trans.
                      </th>

                      <th
                        key="Note Tracking"
                        style={{
                          textAlign: 'center',
                          position: 'relative',
                          top: '-7px',
                          fontWeight: 'normal',
                        }}
                      >
                        Note Tracking
                      </th>

                      <th
                        key="DOT Tracking"
                        style={{
                          textAlign: 'center',
                          position: 'relative',
                          top: '-7px',
                          fontWeight: 'normal',
                        }}
                      >
                        DOT Tracking
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.statusData.map(file => (
                      <tr key={file.fileDataID}>
                        <td colSpan={3}>
                          <Link
                            color="primary500"
                            to={`../details/sub-${file.encodedFileID}`}
                          >
                            {file.encodedFileID}
                          </Link>
                          <br />
                          {file.borrower}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <span
                            className={`badge fw-normal rounded-pill`}
                            style={{
                              color: 'black',
                              backgroundColor:
                                STATUS_COLORS[file.loanStatus.toLowerCase()],
                            }}
                          >
                            {file.loanStatus}
                          </span>
                        </td>

                        {/* check values to see if completed, or show percentage */}
                        {tabs.map(tab => (
                          <React.Fragment key={tab.prefix}>
                            {/* skip the communications tab */}
                            {tab.prefix !== 'com' && (
                              <td
                                key={tab.prefix}
                                style={{ textAlign: 'center' }}
                              >
                                <Link
                                  color="primary500"
                                  to={`../details/${tab.prefix}-${file.encodedFileID}`}
                                >
                                  {file.completedChecklists &&
                                  file.completedChecklists.includes(
                                    tab.prefix.toUpperCase().substring(0, 2)
                                  ) ? (
                                    <TinyTooltip
                                      tip={file.completedChecklists
                                        .split(
                                          tab.prefix
                                            .toUpperCase()
                                            .substring(0, 2)
                                        )[1]
                                        .split('-')[2]
                                        .split(',')[0]
                                        .slice(0, 5)}
                                    >
                                      <i className="bi fs-6 bi-check-circle text-success" />
                                    </TinyTooltip>
                                  ) : (
                                    <>
                                      {file.checklistPercent &&
                                      file.checklistPercent.includes(
                                        tab.prefix.toUpperCase().substring(0, 2)
                                      ) &&
                                      file.checklistPercent
                                        ?.split(
                                          tab.prefix
                                            .toUpperCase()
                                            .substring(0, 2)
                                        )[1]
                                        .split('(')[1]
                                        .split(')')[0] ? (
                                        <span
                                          style={{
                                            fontSize: '12px',
                                            color: 'primary500',
                                          }}
                                        >
                                          {
                                            file.checklistPercent
                                              ?.split(
                                                tab.prefix
                                                  .toUpperCase()
                                                  .substring(0, 2)
                                              )[1]
                                              .split('(')[1]
                                              .split(')')[0]
                                          }
                                          %
                                        </span>
                                      ) : (
                                        <i className="bi fs-6 bi-exclamation-triangle text-warning" />
                                      )}
                                    </>
                                  )}
                                </Link>
                              </td>
                            )}
                          </React.Fragment>
                        ))}

                        <td
                          style={{
                            textAlign: 'center',
                            minWidth: '120px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                          }}
                        >
                          {file.whTracking || '---'}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            minWidth: '120px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                          }}
                        >
                          {file.mersTransferTracking || '---'}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            minWidth: '120px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                          }}
                        >
                          {file.inTracking || '---'}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            minWidth: '120px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                          }}
                        >
                          {file.diTracking || '---'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <Modal
            title={this.state.modalType}
            show={this.state.showModal}
            onAccept={() => this.ValidateAndSubmit()}
            onDecline={() => this.setState({ showModal: false })}
            buttonMessage="Mark Done"
          >
            {this.ShowModal()}
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(StatusBoard);
