import React from 'react';
import { read } from 'xlsx';
import { saveRates } from '../helpers/utils';

const getExcelData = async e => {
  const reader = new FileReader();
  reader.onload = evt => {
    const bstr = evt.target.result;
    const wb = read(bstr, { type: 'binary' });
    const sheet = wb.Sheets[wb.SheetNames[3]];
    saveRates(sheet);
  };
  reader.readAsBinaryString(e.target.files[0]);
};

const Resources = () => {
  return <input onChange={getExcelData} type="file"></input>;
};

export default Resources;
