import React from 'react';
import styled from 'styled-components';

export default function Tooltip({ children, tip }) {
  return (
    <TooltipWrapper>
      {children}
      <Bubble>
        <p>{tip}</p>
      </Bubble>
    </TooltipWrapper>
  );
}

export function TinyTooltip({ children, tip }) {
  return (
    <TooltipWrapper>
      {children}
      {(tip && tip.trim() ) && (<SmallBubble>{tip}</SmallBubble>)}
    </TooltipWrapper>
  );
}

export function TooltipContent({ children, tip }) {
  return (
    <TooltipWrapper>
      {children}
      <SmallBubble>{tip}</SmallBubble>
    </TooltipWrapper>
  );
}

const Bubble = styled.div`
  display: none;
  background-color: ${p => p.theme.colors.gray400};
  color: white;
  padding: 1rem;
  margin-top: 0.5rem;
  border-radius: 3px;
  max-width: 750px;
  z-index: 9999;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${p => p.theme.colors.gray400};
    left: 8px;
    top: -7px;
  }
  & p {
    color: ${p => p.theme.colors.gray100};
  }
`;

const SmallBubble = styled.div`
  display: none;
  background-color: ${p => p.theme.colors.gray800};
  white-space: pre-line;
  color: white;
  padding: 5px;
  font-size: 11px;
  margin-top: 0.5rem;
  border-radius: 3px;
  min-width: 100px;
  max-width: 750px;
  text-align: center;
  z-index: 9999;
  & p {
    color: ${p => p.theme.colors.gray100};
  }
`;

const TooltipWrapper = styled.span`
  cursor: pointer;
  &:hover div {
    display: block;
    position: absolute;
    & p {
      margin: 0;
      overflow-x: hidden;
      white-space: pre-wrap;
      line-height: 1.5rem;
    }
  }
`;
