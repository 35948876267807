import React, { useState } from 'react';
import axios from 'axios';
import { VALID_GEOCODES } from '../helpers/constants';

const GeocodeFinder = () => {
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: 'CA',
    zipcode: '',
  });
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [loading, setLoading] = useState(false);

  const _handleInputChange = e => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const _handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setInfo('');
    setError('');
    if (!address.line1 || !address.zipcode || !address.city || !address.state) {
      setError('Please enter a valid address');
      setLoading(false);
      return;
    }
    try {
      const encodedAddress = encodeURIComponent(
        `${address.line1} ${address.city} ${address.state} ${address.zipcode}`
      );

      const response = await axios.get('/getGeoCodeFromAddress', {
        params: { address: encodedAddress },
      });
      if (response.data && response.data.result.addressMatches.length > 0) {
        const geoid =
          response.data.result.addressMatches[0].geographies[
            'Census Tracts'
          ][0]['GEOID'];
        if (VALID_GEOCODES.includes(geoid)) {
          setInfo('This address geoid is valid!');
          setLoading(false);
        } else {
          setError('This address geoid is not valid');
          setLoading(false);
        }
      } else {
        setError('This address was not found');
        setLoading(false);
      }
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const _clearForm = () => {
    setAddress({
      line1: '',
      city: '',
      state: '',
      zipcode: '',
    });
    setError('');
    setInfo('');
    setLoading(false);
  };

  return (
    <div className="container">
      <h1 className="h4 text-uppercase">geoid validator</h1>
      <div className="card p-4 border-secondary-light">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3">
            <form className="row g-3 mb-3" onSubmit={_handleSubmit}>
              <div className="col-12">
                <label htmlFor="inputAddress" className="form-label">
                  Address
                </label>
                <input
                  value={address.line1}
                  onChange={_handleInputChange}
                  type="text"
                  className="form-control"
                  name="line1"
                  placeholder="1234 Main St, apt1"
                />
              </div>
              <div className="col-md-5">
                <label htmlFor="inputCity" className="form-label">
                  City
                </label>
                <input
                  value={address.city}
                  onChange={_handleInputChange}
                  type="text"
                  className="form-control"
                  name="city"
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputState" className="form-label">
                  State
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  name="state"
                  onChange={_handleInputChange}
                  value={address.state}
                  readOnly
                >
                  <option>AZ</option>
                  <option>CA</option>
                  <option>CO</option>
                  <option>GA</option>
                  <option>TX</option>
                  <option>WA</option>
                </select>
              </div>
              <div className="col-md-3">
                <label htmlFor="inputZip" className="form-label">
                  Zip
                </label>
                <input
                  value={address.zipcode}
                  name="zipcode"
                  onChange={_handleInputChange}
                  type="text"
                  className="form-control"
                  id="inputZip"
                />
              </div>
              <div className="d-flex gap-2 pt-2 justify-content-center">
                {loading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      aria-hidden="true"
                    ></span>
                    <span role="status">Loading...</span>
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-outline-primary ms-2"
                  onClick={_clearForm}
                >
                  Clear Form
                </button>
              </div>
            </form>
            {error && (
              <div className="alert alert-danger" role="alert">
                <i className="bi bi-exclamation-triangle-fill me-2"></i> {error}
              </div>
            )}
            {info && (
              <div className="alert alert-success" role="alert">
                <i className="bi bi-check-lg me-2"></i> {info}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeocodeFinder;
