import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

class WizardSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registeredData: null,
    };

    this.GetWizardFileData();
  }

  // get data before page loads
  GetWizardFileData = async () => {
    axios
      .get('/getwizardfiledata', {
        params: {
          pFileID: this.props.match.params.id,
        },
      })
      .then(res => {
        if (res && res.data) {
          const array = res.data.split('$$$$$');
          const obj = {};
          for (let i = 0; i < array.length; i += 2) {
            if (!array[i].startsWith('[Contacts]')) {
              obj[array[i]] = array[i + 1];
            }
          }
          console.log(obj);
          this.setState({ registeredData: obj });
        }
      })
      .catch(e => console.log(e, 'Error fetching loan details'));
  };

  render() {
    const { registeredData } = this.state;
    const loanId = this.props.match.params.id;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 mb-4 text-uppercase">
              loan wizard 1003 submission id #: {loanId}
            </h1>
          </div>
          <div className="col-12 col-md-10">
            <div className="border rounded p-2 p-md-4 bg-white">
              {registeredData ? (
                <table className="w-100">
                  <tbody>
                    {Object.keys(registeredData).map(key => (
                      <tr key={key} className="border-bottom">
                        <td className="align-top py-2 fw-bold">{key} :</td>
                        <td className="align-top py-2 ">
                          {registeredData[key] || '--'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-primary my-4"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WizardSubmission);
