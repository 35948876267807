import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from './47-chat-outline-edited.json';

const CommentAnimated = () => {
  const container = useRef(null);

  useEffect(() => {
    // load animation
    const anim = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: animationData,
    });
    // add delay to animation between "loops"
    anim.onComplete = () => {
      setTimeout(() => {
        anim.goToAndPlay(0);
      }, 2000);
    };
    // clean up animations
    return () => {
      lottie.destroy();
    };
  }, []);

  return <div style={{ width: 24, height: 24 }} ref={container} />;
};

export default CommentAnimated;
