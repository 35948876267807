import React, { Component } from 'react';

import FNMImport from './Step1-FnmImport';
import ProgramSelect from './Step2-ProgramSelect';
import ContactInfo from './Step3-ContactInfo';
import LoanReview from './Step4-LoanReview';
import ConfirmRegistration from './Step5-ConfirmRegistration';
import WizardIndex from './WizardIndex';
import { withRouter } from 'react-router-dom';

class NewLoan extends Component {
  state = {
    currentStep: 'import',
    visited: ['import'],
    loan: [],
    program: {},
  };

  changeStep = (step, loan, fnmFile, program, contacts) => {
    this.setState(prevState => ({
      currentStep: step,
      visited: prevState.visited.includes(step)
        ? prevState.visited
        : [...prevState.visited, step],
      loan: loan || prevState.loan,
      fnmFile: fnmFile || prevState.fnmFile,
      program: program || prevState.program,
      contacts: contacts || prevState.contacts,
    }));
  };

  resetForm = () => {
    this.setState(prevState => ({
      currentStep: 'import',
      visited: ['import'],
      loan: [],
      program: {},
    }));
    this.render();
  };

  renderWizardStep = () => {
    switch (this.state.currentStep) {
      case 'import':
        return <FNMImport changeStep={this.changeStep} {...this.state} />;
      case 'select':
        return <ProgramSelect changeStep={this.changeStep} {...this.state} />;
      case 'contact':
        return <ContactInfo changeStep={this.changeStep} {...this.state} />;
      case 'review':
        return <LoanReview changeStep={this.changeStep} {...this.state} />;
      case 'confirm':
        return (
          <ConfirmRegistration history={this.props.history} {...this.state} />
        );
      default:
        return (
          <div>
            <h4>Something went wrong, try again</h4>
            <img
              alt="404 penguin"
              height="500"
              src="https://www.desicomments.com/wp-content/uploads/2017/02/Penguin-Image.jpg"
            />
          </div>
        );
    }
  };

  render() {
    return (
      <div className="container">
        <h4 className="text-uppercase">Register Loan</h4>
        {this.state.currentStep !== 'confirm' && (
          <WizardIndex
            changeStep={this.changeStep}
            resetForm={this.resetForm}
            activeStep={this.state.currentStep}
            visited={this.state.visited}
          />
        )}
        {this.renderWizardStep()}
      </div>
    );
  }
}

export default withRouter(NewLoan);
