import {
  Body,
  AutoCheck,
  Verify,
  Task,
  SignOff,
  FindLabelFromName,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS } from '../../helpers/constants';
import { getPropertyType, isAdmin } from '../../helpers/utils';
import Toast from '../Toast';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class OperationsChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      sendToInvestTr: false,
      sendToInvestWH: false,
      mersRegistrationDate: '',
      mersTransferDate: '',
      isLoading: true,
      errorMessage: '',
      checkList: {
        mersRegister: { checked: false },
        mersTransfer: { checked: false },
        warehouseNote: { checked: false },
        investorNote: { checked: false },
        finalSS: { checked: false },
        titlePolicy: { checked: false },
        recordedDOT: { checked: false },
        dotToInvest: { checked: false },
        complete: { checked: false },
      },
    };

    this.getOperationsData();
  }

  async getOperationsData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    response = axios.get('/getoperationsfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var operationsFields = (await response).data;

    var statelist = {
      fileData: resultData,

      mersTransferTracking: operationsFields.mersTransferTracking || '',
      originalMersTransferTracking: operationsFields.mersTransferTracking || '',
      whTracking: operationsFields.whTracking || '',
      originalWhTracking: operationsFields.whTracking || '',
      inTracking: operationsFields.inTracking || '',
      originalInTracking: operationsFields.inTracking || '',
      diTracking: operationsFields.diTracking || '',
      originalDiTracking: operationsFields.diTracking || '',

      mersRegistrationDate: operationsFields.mersRegistrationDate,
      mersTransferDate: operationsFields.mersTransferDate,
      sendToInvestTr: operationsFields.sendToInvestTr === '1',
      sendToInvestWH: operationsFields.sendToInvestWH === '1',
      sendToInvestIN: operationsFields.sendToInvestIN === '1',
      sendToInvestDI: operationsFields.sendToInvestDI === '1',
      isLoading: false,
    };

    // get checklist data
    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'OP' },
    });
    // the response.data will contain the values returned
    var checkData = (await response).data;
    var checklistData = this.state.checkList;
    for (var i = 0; i < checkData.length; i++) {
      checklistData[checkData[i].fieldName] = {
        checked: checkData[i].isChecked === 'True',
        timestamp: checkData[i].timestamp,
        userName: checkData[i].userName,
      };
    }

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    // check if PFD finalSS checked
    checklistData.finalSS.checked = operationsFields.finalSS === 'True';

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    statelist['checkList'] = checklistData;
    this.setState(statelist);
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'OP-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if (!checked) {
      checkList.complete.checked = false;
      this.setChecklistField('complete', false);
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'OP',
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2], // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][4].substring(1)]: this.state[fieldsToUpdate[i][4]],
          [fieldsToUpdate[i][4] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (prop === 'complete') continue;
      if (prop === 'mersRegister') {
        visibleCount++;
        if (this.state.mersRegistrationDate) checkedCount++;
        continue;
      }
      // skip mersTransfer if in-house set
      if (prop === 'mersTransfer') {
        visibleCount++;
        if (this.state.sendToInvestTr || this.state.mersTransferDate)
          checkedCount++;
        continue;
      }
      visibleCount++;
      if (
        Object.prototype.hasOwnProperty.call(checklistData, prop) &&
        checklistData[prop].checked
      ) {
        checkedCount++;
      }
    }
    var completePercent = this.state.fileData.completePercent
      ?.split('OP')[1]
      .split('(')[1]
      .split(')')[0];
    if (
      completePercent.toString() !==
      Math.round((checkedCount / visibleCount) * 100).toString()
    ) {
      this.state.fileData.completePercent =
        this.state.fileData.completePercent?.split('OP')[0] +
        'OP (' +
        Math.round((checkedCount / visibleCount) * 100).toString() +
        ')' +
        this.state.fileData.completePercent
          ?.split('OP')[1]
          .substring(
            this.state.fileData.completePercent?.split('OP')[1].indexOf(')') + 1
          );
      this.props.updatePercent(
        'OP',
        Math.round((checkedCount / visibleCount) * 100).toString()
      );
    }
    if (checkedCount < visibleCount) return false;
    return true;
  }

  markComplete() {
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('OP', 'complete');
  }

  render() {
    const { isLoading, fileData, checkList } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <h1 className="h5">Operations Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h1 className="h5">Operations Checklist</h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* MERS Register */}
          <ChecklistCard
            checked={this.state.mersRegistrationDate}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="mersRegister"
                  name="mersRegister"
                  checked={this.state.mersRegistrationDate}
                  disabled={true}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="mersRegister">
                  MERS Register
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify
                    tip={
                      'MERS Registration Date:\n' +
                      (this.state.mersRegistrationDate || '(Not Set In Byte)')
                    }
                  />
                  MERS Registration
                </div>
                <div>
                  <Task />
                  Check for Proof of Registration in Byte
                </div>
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.mersRegistrationDate}
                tip={
                  this.state.mersRegistrationDate
                    ? this.state.mersRegistrationDate
                    : 'MERS Registration Date Not Set In Byte'
                }
              />
            }
          />

          {/* MERS Transfer */}
          <ChecklistCard
            checked={this.state.sendToInvestTr || this.state.mersTransferDate}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="mersTransfer"
                  name="mersTransfer"
                  checked={
                    this.state.sendToInvestTr || this.state.mersTransferDate
                  }
                  disabled={true}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="mersTransfer">
                  MERS Transfer
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify tip="Check If We Will Transfer or Keep In-House" />
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="sendInvestTr1"
                      name="sendInvestTr"
                      className="form-check-input"
                      disabled={
                        this.state.sendToInvestTr || this.state.mersTransferDate
                      }
                      checked={
                        this.state.sendToInvestTr || !this.state.sendToInvestTr
                      }
                      onChange={() => void 0}
                      onClick={() => {
                        if (this.state.sendToInvestTr) {
                          this.setState({ sendToInvestTr: false });
                          this.updateDynamicDataField([
                            ['Send Invest Tr', '', '0', '', ''],
                          ]);
                          checkList.mersTransfer.checked = false;
                          this.setChecklistField('mersTransfer', false);
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="sendInvestTr1">
                      Transfer
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="sendInvestTr2"
                      name="sendInvestTr"
                      className="form-check-input"
                      checked={this.state.sendToInvestTr}
                      disabled={
                        this.state.sendToInvestTr || this.state.mersTransferDate
                      }
                      onChange={() => void 0}
                      onClick={() => {
                        if (!this.state.sendToInvestTr) {
                          this.setState({ sendToInvestTr: true });
                          this.updateDynamicDataField([
                            ['Send Invest Tr', '', '1', '', ''],
                          ]);
                          checkList.mersTransfer.checked = false;
                          this.setChecklistField('mersTransfer', false);
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="sendInvestTr2">
                      In-House
                    </label>
                  </div>
                </div>

                {!this.state.sendToInvestTr && (
                  <React.Fragment>
                    <div>
                      <Verify
                        tip={
                          'MERS Transfer Date:\n' +
                          (this.state.mersTransferDate || '(Not Set In Byte)')
                        }
                      />
                      MERS Transfer
                    </div>
                    <div>
                      <Task />
                      Check for Proof of Transfer in Byte
                    </div>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              this.state.sendToInvestTr ? (
                <AutoCheck passed={true} />
              ) : (
                <AutoCheck
                  passed={this.state.mersTransferDate}
                  tip={
                    this.state.mersTransferDate
                      ? this.state.mersTransferDate
                      : 'MERS Transfer Date Not Set In Byte'
                  }
                />
              )
            }
          />

          {/* Warehouse Note */}
          <ChecklistCard
            checked={checkList.warehouseNote.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="warehouseNote"
                  name="warehouseNote"
                  checked={checkList.warehouseNote.checked}
                  disabled={
                    !this.state.sendToInvestWH && !this.state.originalWhTracking
                  }
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="warehouseNote">
                  Note to WH
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify tip="Check If We Will Send to INVEST or Keep In-House" />
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="sendInvestWH1"
                      name="sendInvestWH"
                      className="form-check-input"
                      disabled={checkList.warehouseNote.checked}
                      checked={!this.state.sendToInvestWH}
                      onChange={() => void 0}
                      onClick={() => {
                        if (this.state.sendToInvestWH) {
                          this.setState({ sendToInvestWH: false });
                          this.updateDynamicDataField([
                            ['Send Invest WH', '', '0', '', ''],
                          ]);
                          checkList.warehouseNote.checked = false;
                          this.setChecklistField('warehouseNote', false);
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="sendInvestWH1">
                      Send to Investor
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="sendInvestWH2"
                      name="sendInvestWH"
                      className="form-check-input"
                      disabled={checkList.warehouseNote.checked}
                      checked={this.state.sendToInvestWH}
                      onChange={() => void 0}
                      onClick={() => {
                        if (!this.state.sendToInvestWH) {
                          this.setState({ sendToInvestWH: true });
                          this.updateDynamicDataField([
                            ['Send Invest WH', '', '1', '', ''],
                          ]);
                          checkList.warehouseNote.checked = false;
                          this.setChecklistField('warehouseNote', false);
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="sendInvestWH2">
                      Keep In-House
                    </label>
                  </div>
                </div>

                {!this.state.sendToInvestWH && (
                  <React.Fragment>
                    {/* input tracking info */}
                    <div className="input-group">
                      <span className="input-group-text">Tracking Info:</span>
                      <input
                        type="text"
                        className="form-control"
                        name="whTracking"
                        value={this.state.whTracking || ''}
                        disabled={checkList.warehouseNote.checked}
                        onChange={this.captureInput}
                      />
                      <button
                        className="btn btn-primary btn-sm"
                        disabled={
                          this.state.originalWhTracking ===
                            this.state.whTracking ||
                          checkList.warehouseNote.checked
                        }
                        onClick={() =>
                          this.updateDynamicDataField([
                            [
                              'whTracking',
                              '',
                              this.state.whTracking,
                              'warehouseNote',
                              'whTracking',
                            ],
                          ])
                        }
                      >
                        {this.state.whTrackingUpdated ? '✓' : 'Update'}
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              checkList.warehouseNote.checked && (
                <SignOff
                  user={checkList.warehouseNote.userName}
                  date={checkList.warehouseNote.timestamp}
                />
              )
            }
          />

          {/* Invest Note */}
          <ChecklistCard
            checked={checkList.investorNote.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="investorNote"
                  name="investorNote"
                  checked={checkList.investorNote.checked}
                  disabled={
                    !this.state.sendToInvestIN && !this.state.originalInTracking
                  }
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="investorNote">
                  Note to Investor
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify tip="Check If We Will Send to INVEST or Keep In-House" />
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="sendInvestIN1"
                      name="sendInvestIN"
                      className="form-check-input"
                      checked={!this.state.sendToInvestIN}
                      disabled={checkList.investorNote.checked}
                      onChange={() => void 0}
                      onClick={() => {
                        if (this.state.sendToInvestIN) {
                          this.setState({ sendToInvestIN: false });
                          this.updateDynamicDataField([
                            ['Send Invest IN', '', '0', '', ''],
                          ]);
                          checkList.investorNote.checked = false;
                          this.setChecklistField('investorNote', false);
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="sendInvestIN1">
                      Send to Investor
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="sendInvestIN2"
                      name="sendInvestIN"
                      className="form-check-input"
                      checked={this.state.sendToInvestIN}
                      onChange={() => void 0}
                      disabled={checkList.investorNote.checked}
                      onClick={() => {
                        if (!this.state.sendToInvestIN) {
                          this.setState({ sendToInvestIN: true });
                          this.updateDynamicDataField([
                            ['Send Invest IN', '', '1', '', ''],
                          ]);
                          checkList.investorNote.checked = false;
                          this.setChecklistField('investorNote', false);
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="sendInvestIN2">
                      Keep In-House
                    </label>
                  </div>
                </div>

                {!this.state.sendToInvestIN && (
                  <React.Fragment>
                    {/* input tracking info */}
                    <div className="input-group">
                      <span className="input-group-text">Tracking Info:</span>
                      <input
                        type="text"
                        className="form-control"
                        name="inTracking"
                        value={this.state.inTracking || ''}
                        disabled={checkList.investorNote.checked}
                        onChange={this.captureInput}
                      />
                      <button
                        className="btn btn-primary btn-sm"
                        disabled={
                          this.state.originalInTracking ===
                            this.state.inTracking ||
                          checkList.investorNote.checked
                        }
                        onClick={() =>
                          this.updateDynamicDataField([
                            [
                              'inTracking',
                              '',
                              this.state.inTracking,
                              'investorNote',
                              'inTracking',
                            ],
                          ])
                        }
                      >
                        {this.state.inTrackingUpdated ? '✓' : 'Update'}
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              checkList.investorNote.checked && (
                <SignOff
                  user={checkList.investorNote.userName}
                  date={checkList.investorNote.timestamp}
                />
              )
            }
          />

          {/* Final SS */}
          <ChecklistCard
            checked={checkList.finalSS.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="finalSS"
                  name="finalSS"
                  checked={checkList.finalSS.checked}
                  disabled={true}
                />
                <label className="form-check-label" htmlFor="finalSS">
                  Final SS
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Handled By Post-Funding" />
                Final Settlement Statement Received
              </div>
            }
            alert={
              <AutoCheck
                passed={checkList.finalSS.checked}
                tip="Post-Funding Did Not Confirm Receipt"
              />
            }
          />

          {/* Title Policy */}
          <ChecklistCard
            checked={checkList.titlePolicy.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="titlePolicy"
                  name="titlePolicy"
                  checked={checkList.titlePolicy.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="titlePolicy">
                  Title Policy
                </label>
              </div>
            }
            body={
              <div>
                <Verify />
                Receipt of Title Policy
              </div>
            }
            alert={
              checkList.titlePolicy.checked && (
                <SignOff
                  user={checkList.titlePolicy.userName}
                  date={checkList.titlePolicy.timestamp}
                />
              )
            }
          />

          {/* Recorded DOT */}
          <ChecklistCard
            checked={checkList.recordedDOT.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="recordedDOT"
                  name="recordedDOT"
                  checked={checkList.recordedDOT.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="recordedDOT">
                  Recorded DOT
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify />
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    [Original Copy Must Be Received]
                  </span>
                </div>
                <div>
                  <Verify />
                  Receipt of Recorded DOT
                </div>
              </div>
            }
            alert={
              checkList.recordedDOT.checked && (
                <SignOff
                  user={checkList.recordedDOT.userName}
                  date={checkList.recordedDOT.timestamp}
                />
              )
            }
          />

          {/* DOT to Invest */}
          {!this.state.sendToInvestDI && (
            <ChecklistCard
              checked={checkList.dotToInvest.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    aria-label="Checkbox for following text input"
                    id="dotToInvest"
                    name="dotToInvest"
                    checked={checkList.dotToInvest.checked}
                    disabled={
                      !this.state.sendToInvestDI &&
                      !this.state.originalDiTracking
                    }
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="dotToInvest">
                    DOT to Investor
                  </label>
                </div>
              }
              body={
                <div className="vstack gap-3">
                  <div>
                    <Verify tip="Check If We Will Send to INVEST or Keep In-House" />
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        id="sendInvestDI1"
                        name="sendInvestDI"
                        className="form-check-input"
                        checked={!this.state.sendToInvestDI}
                        disabled={checkList.dotToInvest.checked}
                        onChange={() => void 0}
                        onClick={() => {
                          if (this.state.sendToInvestDI) {
                            this.setState({ sendToInvestDI: false });
                            this.updateDynamicDataField([
                              ['Send Invest DI', '', '0', '', ''],
                            ]);
                            checkList.dotToInvest.checked = false;
                            this.setChecklistField('dotToInvest', false);
                          }
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="sendInvestDI1"
                      >
                        Send to Investor
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        id="sendInvestDI2"
                        name="sendInvestDI"
                        className="form-check-input"
                        checked={this.state.sendToInvestDI}
                        disabled={checkList.dotToInvest.checked}
                        onChange={() => void 0}
                        onClick={() => {
                          if (!this.state.sendToInvestDI) {
                            this.setState({ sendToInvestDI: true });
                            this.updateDynamicDataField([
                              ['Send Invest DI', '', '1', '', ''],
                            ]);
                            checkList.dotToInvest.checked = false;
                            this.setChecklistField('dotToInvest', false);
                          }
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="sendInvestDI2"
                      >
                        Keep In-House
                      </label>
                    </div>
                  </div>

                  {!this.state.sendToInvestDI && (
                    <React.Fragment>
                      {/* input tracking info */}
                      <div className="input-group">
                        <span className="input-group-text">Tracking Info:</span>
                        <input
                          type="text"
                          className="form-control"
                          name="diTracking"
                          value={this.state.diTracking || ''}
                          disabled={checkList.dotToInvest.checked}
                          onChange={this.captureInput}
                        />
                        <button
                          className="btn btn-primary btn-sm"
                          disabled={
                            this.state.originalDiTracking ===
                              this.state.diTracking ||
                            checkList.dotToInvest.checked
                          }
                          onClick={() =>
                            this.updateDynamicDataField([
                              [
                                'diTracking',
                                '',
                                this.state.diTracking,
                                'dotToInvest',
                                'diTracking',
                              ],
                            ])
                          }
                        >
                          {this.state.diTrackingUpdated ? '✓' : 'Update'}
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              }
              alert={
                checkList.dotToInvest.checked && (
                  <SignOff
                    user={checkList.dotToInvest.userName}
                    date={checkList.dotToInvest.timestamp}
                  />
                )
              }
            />
          )}

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
        </div>
      </React.Fragment>
    );
  }
}
