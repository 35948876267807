import React, { Component } from 'react';
import axios from 'axios';

export default class DailyCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
    };
    this.HandleDailyCheck();
  }

  async HandleDailyCheck() {
    const response = axios.get('/dailycheck');

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if (resultData === 'Done!') this.setState({ done: true });
  }

  render() {
    return (
      <div className="container">
        Running Daily Check...
        <br />
        Not meant to be called by end-users, but from automated call each day.
        <br />
        {this.state.done && <>Done!</>}
      </div>
    );
  }
}
