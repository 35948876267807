import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { STATUS_COLORS } from '../../helpers/constants';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9800;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalCard = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  z-index: 9900;
  width: ${p => (p.width ? p.width : '600px')};
  min-height: ${p => (p.height ? p.height : '300px')};
  max-height: 90vh;
  background-color: ${p => p.theme.colors.white};
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h6`
  margin: 0;
  color: red;
`;

const ModalBody = styled.div`
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
`;

export default class ModalEmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailData: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email) {
      this.getEmailResults(this.props.email);
    }
  }

  async getEmailResults(email) {
    var response = axios.get('/getAssociatedEmail', {
      params: {
        pBorrowerEmail: email,
      },
    });

    try {
      var resultData = (await response).data;
      this.setState({ emailData: resultData });
    } catch {
      this.setState({ emailData: [] });
    }
  }

  render() {
    return this.props.show ? (
      <ModalWrapper>
        <ModalCard width={this.props.width} height={this.props.height}>
          <ModalHeader>
            <ModalTitle>[Email Check]</ModalTitle>
            <button
              type="button"
              className="btn-close"
              onClick={this.props.onDecline}
              aria-label="Close"
            ></button>
          </ModalHeader>
          <ModalBody>
            {this.state.emailData.others ? (
              <div className="col-12 col-md-10 offset-md-1">
                {this.state.emailData.others.length > 0 ? (
                  <div className="col-12">
                    {this.state.emailData.others.map(file => (
                      <div
                        className="border mb-2 rounded bg-white p-3 position-relative"
                        key={file.fileDataID}
                      >
                        <div className="d-flex justify-content-between align-items-start">
                          <h4 className="h5 fw-bold mb-3">
                            {file.borrowers}
                            <br />
                            <Link
                              to={`../pipeline/loan-summary/${file.fileDataID}`}
                              className="stretched-link"
                            >
                              {file.fileDataID}
                            </Link>
                          </h4>
                          <h4>
                            <span
                              className={`badge pill-${
                                STATUS_COLORS[file.loanStatus.toLowerCase()]
                              }`}
                            >
                              {file.loanStatus}
                            </span>
                          </h4>
                        </div>
                        <p className="mb-0">
                          <span className="fw-bold">File Name:</span>{' '}
                          {file.fullName}{' '}
                          <span className="ms-4 fw-bold">Submitted Date:</span>{' '}
                          {file.submittedDate}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="alert alert-danger text-center p-2 border-0 fw-bold text-dark"
                    role="alert"
                  >
                    <i className="bi bi-x-circle me-2 text-danger"></i>
                    No file found using email:{' '}
                    <span className="fw-bold">{this.state.oldq}</span>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </ModalBody>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={this.props.onDecline}
            >
              Close
            </button>
          </div>
        </ModalCard>
      </ModalWrapper>
    ) : (
      <></>
    );
  }
}
