import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { formatPhoneNumber } from '../helpers/utils';
import LoginState from '../components/LoginState';

const roleIndex = {
  lo: 'Loan Officer',
  lp: 'Loan Processor #1',
  lp2: 'Loan Processor #2',
  uw1: 'Underwriter #1',
  uw2: 'Underwriter #2',
  ae: 'AE',
  sc: 'Settlement Company',
  tc: 'Title Company',
  ba: "Buyer's Agent",
  sa: "Seller's Agent",
  ap: 'Appraiser',
  co: 'Compliance',
  cl: 'Closing',
  fu: 'Funding',
};

const selectedList = ['lp', 'lp2', 'lo', 'ae', 'uw1', 'co', 'cl', 'fu'];

const ContactCard = ({ contact, handleTargetChange }) => (
  <div className="col-12 col-md-6 col-xl-3 mb-4">
    <div className="card h-100">
      <div className="card-body">
        <div className="badge text-bg-success mb-3">
          {roleIndex[contact.roleCode]}
        </div>
        <p className="fw-bold mb-0">
          <i className="bi bi-person text-secondary me-2 fs-5"></i>
          {contact.name || '---'}
          {['lp', 'lp2'].includes(contact.roleCode) && (
            <button
              className="btn btn-link btn-sm"
              name={contact.role}
              data-bs-toggle="modal"
              data-bs-target="#contactUpdateModal"
              onClick={() => handleTargetChange(contact)}
            >
              <i className="bi bi-pencil-square fs-6" />
            </button>
          )}
        </p>
        <p className="mb-0">
          <i className="bi bi-at text-secondary me-2 fs-5"></i>
          {contact.email || '---'}
        </p>
        <p className="mb-0">
          <i className="bi bi-telephone text-secondary me-2 fs-5"></i>
          {formatPhoneNumber(contact.phone)}
        </p>
      </div>
    </div>
  </div>
);

const ContactUpdateModal = ({ contacts, target = {}, onSubmit, onCancel }) => {
  const [newContact, setNewContact] = useState();
  const [hasChanged, setChange] = useState(false);

  const handleChange = e => {
    setNewContact(e.target.value);
    setChange(true);
  };

  return (
    <div
      className="modal fade"
      id="contactUpdateModal"
      tabIndex="-1"
      aria-labelledby="editContactModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="editContactModal">
              Update Contact
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {/* CURRENT CONTACT */}
            <p className="text-black-50 mb-1">Current...</p>
            <div className="card mb-2">
              <div className="card-body p-2">
                <h5 className="card-title">{target.name}</h5>
                <div className="hstack gap-4">
                  <p className="mb-0">{target.email}</p>
                  <p className="mb-0">{target.phone}</p>
                </div>
              </div>
            </div>
            {/* NEW CONTACT */}
            <p className="text-black-50 mb-1">Change for...</p>
            <select
              className="form-select"
              aria-label="Default select example"
              defaultValue={-1}
              value={newContact}
              onChange={handleChange}
            >
              <option>Select a contact...</option>
              {contacts.map(option => (
                <option
                  key={option.email + option.roleCode}
                  value={JSON.stringify(option)}
                  disabled={option.email === target.email}
                >
                  {option.name} - {option.email}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onSubmit(newContact)}
              disabled={!hasChanged}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoanMenu = ({ searchLog, getContacts }) => {
  const history = useHistory();
  return (
    <>
      <button
        className="btn btn-text dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="bi bi-list"></i>
      </button>
      <ul className="dropdown-menu">
        <li>
          <h6 className="dropdown-header">Recent Files</h6>
        </li>
        {searchLog.length > 0 ? (
          <>
            {searchLog.map(file => (
              <li key={file.encodedFileID}>
                <span
                  className="dropdown-item"
                  onClick={() => {
                    history.push('/pipeline/contacts/' + file.encodedFileID);
                    getContacts();
                  }}
                >
                  {file.encodedFileID} ({file.borrower})
                </span>
              </li>
            ))}
          </>
        ) : (
          <li>
            <p className="dropdown-item mb-0 disabled">No Recent Files</p>
          </li>
        )}
      </ul>
      {/* {LoginState.userId === 1000009 && (
        <span
          style={{
            fontSize: '14px',
            marginLeft: '20px',
            position: 'relative',
            top: '-3px',
            color: 'red',
          }}
        >
          (ID: {loan.fileName} - Byte: {loan.fileDataID})
        </span>
      )} */}
    </>
  );
};

function LoanContacts() {
  const params = useParams();

  const [contacts, setContacts] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [target, setTarget] = useState();
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState();
  const [searchLog, setSearchLog] = useState([]);

  useEffect(() => {
    if (!contacts[0]) {
      fetchContacts();
    }
  }, []);
  useEffect(() => {
    if (!searchLog[0]) {
      fetchSearchLog();
    }
  }, []);

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/getcontactinfo`, {
        params: { pFileID: params.id },
      });
      const newContactsToRender = selectedList.map(key => ({
        ...response.data[key],
        roleCode: key,
      }));
      const newAllContacts = [];
      Object.keys(response.data).forEach(key => {
        if (response.data[key].hasOwnProperty('0')) return;
        const datum = { ...response.data[key], roleCode: key };
        newAllContacts.push(datum);
      });
      setContacts(newContactsToRender);
      setAllContacts(newAllContacts);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setAlert({ type: 'danger', message: 'Error fetching contacts' });
      setLoading(false);
    }
  };

  const fetchSearchLog = async () => {
    const response = await axios.get('/getUpdatedSearchLog', {
      params: {
        pFileID: params.id,
        pUserID: LoginState.userId,
      },
    });
    setSearchLog(response.data);
  };

  const saveChanges = async newContactData => {
    const newContact = JSON.parse(newContactData);
    const params = {
      pFileID: params.id,
    };
    if (newContact.roleCode === 'lp') {
      params.pProcessor1 = newContact.userName;
      params.pProcessor2 = contacts.find(
        contact => contact.code === 'lp2'
      ).userName;
    } else {
      params.pProcessor1 = contacts.find(
        contact => contact.code === 'lp'
      ).userName;
      params.pProcessor2 = newContact.userName;
    }
    const response = await axios.get('/updateprocessors', {
      params,
    });
    setAlert({
      type: response.data,
      message: response.data,
    });
  };

  const handleTargetChange = newTarget => {
    setTarget(newTarget);
  };

  const renderContacts = () => {
    if (loading) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      );
    }
    return contacts.map(contact => (
      <ContactCard
        contact={contact}
        key={contact.email + contact.roleCode}
        handleTargetChange={handleTargetChange}
      />
    ));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 hstack gap-2 align-items-center">
          <h1 className="h4 mb-0 text-uppercase">contacts</h1>
          <LoanMenu searchLog={searchLog} getContacts={fetchContacts} />
        </div>
      </div>
      {alert && (
        <div className={`alert alert-${alert.type}`} role="alert">
          {alert.message}
        </div>
      )}
      <div className="row">{renderContacts()}</div>
      <ContactUpdateModal
        contacts={allContacts}
        target={target}
        onSubmit={saveChanges}
        onCancel={handleTargetChange}
      />
    </div>
  );
}

export default LoanContacts;
