import { CurrencyInput } from '../components/CurrencyInput';
import React, { Component } from 'react';
import { PRICING_OPTIONS } from '../helpers/constants';
import { numbersNoFormat } from '../helpers/utils';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

class LoanWizard extends Component {
  constructor(props) {
    super(props);
    this.programsRef = React.createRef();
    this.state = {
      currentPage: this.props.match.params.pg,
      lienPosition: '1',
      loanProduct: '1',
      documentType: '1',
      occupancyType: '',
      residenceRecords: [],
      employmentRecords: [],
      propertyRecords: [],
      coborrowers: 0,
      residencePosition: 0,
      employmentPosition: 0,
      propertyPosition: 0,
      declarationPosition: 0,
      demographicPosition: 0,
      propertyInfoReady: false,
    };
  }

  componentDidUpdate() {
    if (this.state.currentPage !== this.props.match.params.pg)
      this.setState({ currentPage: this.props.match.params.pg });
  }

  captureInput = e => {
    if (this.state.errors) {
      let errors = { ...this.state.errors };
      delete errors[e.target.name];
      this.setState({
        [e.target.name]: e.target.value,
        errors,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  toggleCheckBox = e => {
    // get alt-checkbox to toggle as well
    var otherName = e.target.name.includes('Yes')
      ? e.target.name.substr(0, e.target.name.length - 3) + 'No'
      : e.target.name.substr(0, e.target.name.length - 2) + 'Yes';

    if (this.state[e.target.name])
      this.setState({ [e.target.name]: false, [otherName]: true });
    else this.setState({ [e.target.name]: true, [otherName]: false });
  };

  async submitData() {
    var loanWizardBaseParams = [];
    var loanWizardParams = [];
    var index = 0;
    var index2 = 0;

    loanWizardBaseParams.push('Loan Purpose');
    loanWizardBaseParams.push(this.state.loanPurpose || '');

    loanWizardBaseParams.push('Occupancy Type');
    loanWizardBaseParams.push(this.state.occupancyType || '');

    if (
      this.state.loanPurpose === 'Refinance' ||
      this.state.propertyInfoReady
    ) {
      loanWizardBaseParams.push('Property Address');
      loanWizardBaseParams.push(this.state.propertyAddress || '');

      loanWizardBaseParams.push('City');
      loanWizardBaseParams.push(this.state.propertyCity || '');

      loanWizardBaseParams.push('State');
      loanWizardBaseParams.push(this.state.propertyState || '');

      loanWizardBaseParams.push('Zip Code');
      loanWizardBaseParams.push(this.state.propertyZip || '');
    }

    if (this.state.loanPurpose === 'Refinance') {
      loanWizardBaseParams.push('Original Purchase Price');
      loanWizardBaseParams.push(numbersNoFormat(this.state.orgPrice) || '');

      loanWizardBaseParams.push('Amount Existing Liens');
      loanWizardBaseParams.push(numbersNoFormat(this.state.extLiens) || '');
    }

    // Primary Borrower Info
    loanWizardParams.push('Primary Borrower');
    loanWizardParams.push('');

    loanWizardParams.push('Borrower Name');
    loanWizardParams.push(this.state.borrowerName || '');

    loanWizardParams.push('Phone');
    loanWizardParams.push(this.state.phone || '');

    loanWizardParams.push('Email');
    loanWizardParams.push(this.state.email || '');

    loanWizardParams.push('Date of Birth');
    loanWizardParams.push(this.state.dob || '');

    // Residence Records for Primary Borrower
    for (index = 0; index < this.state.residenceRecords[0]; index++) {
      loanWizardParams.push("Primary Borrower's Residence History");
      loanWizardParams.push('(Residence #' + (index + 1) + ')');

      loanWizardParams.push('Address');
      loanWizardParams.push(this.state['0propertyAddress' + index] || '');

      loanWizardParams.push('City');
      loanWizardParams.push(this.state['0propertyCity' + index] || '');

      loanWizardParams.push('State');
      loanWizardParams.push(this.state['0propertyState' + index] || '');

      loanWizardParams.push('Zip');
      loanWizardParams.push(this.state['0propertyZip' + index] || '');

      loanWizardParams.push('Property Ownership');
      loanWizardParams.push(
        this.getPropertyOwnership(this.state['0propertyOwnership' + index])
      );
    }

    // Employment Records for Primary Borrower
    for (index = 0; index < this.state.employmentRecords[0]; index++) {
      loanWizardParams.push("Primary Borrower's Employment History");
      loanWizardParams.push('(Record #' + (index + 1) + ')');

      loanWizardParams.push('Employer');
      loanWizardParams.push(this.state['0employer' + index] || '');

      loanWizardParams.push('Position/Title');
      loanWizardParams.push(this.state['0position' + index] || '');

      loanWizardParams.push('Type of Business');
      loanWizardParams.push(this.state['0type' + index] || '');

      loanWizardParams.push('Contact Person');
      loanWizardParams.push(this.state['0contactPerson' + index] || '');

      loanWizardParams.push('Contact Phone');
      loanWizardParams.push(this.state['0contactPhone' + index] || '');

      loanWizardParams.push('Years on Job');
      loanWizardParams.push(this.state['0yearsJob' + index] || '');

      loanWizardParams.push('Years in Profession');
      loanWizardParams.push(this.state['0yearsProf' + index] || '');
    }

    // Property Records for Primary Borrower
    for (index = 0; index < this.state.propertyRecords[0]; index++) {
      loanWizardParams.push("Primary Borrower's Properties");
      loanWizardParams.push('(Property #' + (index + 1) + ')');

      loanWizardParams.push('Address');
      loanWizardParams.push(this.state['0ownedPropertyAddress' + index] || '');

      loanWizardParams.push('City');
      loanWizardParams.push(this.state['0ownedPropertyCity' + index] || '');

      loanWizardParams.push('State');
      loanWizardParams.push(this.state['0ownedPropertyState' + index] || '');

      loanWizardParams.push('Zip Code');
      loanWizardParams.push(this.state['0ownedPropertyZip' + index] || '');

      loanWizardParams.push('Property Type');
      loanWizardParams.push(
        this.getPropertyType(this.state['0ownedPropertyType' + index])
      );
    }

    // Declarations for Primary Borrower
    loanWizardParams.push("Primary Borrower's Declarations");
    loanWizardParams.push('');

    loanWizardParams.push('(a) Outstanding judgments');
    loanWizardParams.push(
      this.state['0checkboxAYes']
        ? 'Yes'
        : this.state['0checkboxANo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(b) Bankrupt within 7 years');
    loanWizardParams.push(
      this.state['0checkboxBYes']
        ? 'Yes'
        : this.state['0checkboxBNo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(c) Foreclosure within 7 years');
    loanWizardParams.push(
      this.state['0checkboxCYes']
        ? 'Yes'
        : this.state['0checkboxCNo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(d) Party to lawsuit');
    loanWizardParams.push(
      this.state['0checkboxDYes']
        ? 'Yes'
        : this.state['0checkboxDNo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(e) Obligations on defaulted loan');
    loanWizardParams.push(
      this.state['0checkboxEYes']
        ? 'Yes'
        : this.state['0checkboxENo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(f) Delinquent on any loan');
    loanWizardParams.push(
      this.state['0checkboxFYes']
        ? 'Yes'
        : this.state['0checkboxFNo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(g) Obligations for alimony or child support');
    loanWizardParams.push(
      this.state['0checkboxGYes']
        ? 'Yes'
        : this.state['0checkboxGNo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(h) Any part of down payment borrowed');
    loanWizardParams.push(
      this.state['0checkboxHYes']
        ? 'Yes'
        : this.state['0checkboxHNo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(i) Co-maker or endorser on a note');
    loanWizardParams.push(
      this.state['0checkboxIYes']
        ? 'Yes'
        : this.state['0checkboxINo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(*) Notes');
    loanWizardParams.push(this.state['0notes'] || '');

    loanWizardParams.push('(j) US Citizen');
    loanWizardParams.push(
      this.state['0checkboxJYes']
        ? 'Yes'
        : this.state['0checkboxJNo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(k) Permanent resident alien');
    loanWizardParams.push(
      this.state['0checkboxKYes']
        ? 'Yes'
        : this.state['0checkboxKNo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(l) Intend to occupy as primary residence');
    loanWizardParams.push(
      this.state['0checkboxLYes']
        ? 'Yes'
        : this.state['0checkboxLNo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(m) Owned property in past 3 years');
    loanWizardParams.push(
      this.state['0checkboxMYes']
        ? 'Yes'
        : this.state['0checkboxMNo']
        ? 'No'
        : 'Unspecified'
    );

    loanWizardParams.push('(m-1) Property Type');
    loanWizardParams.push(this.state['0propTypeDec'] || '');

    loanWizardParams.push('(m-2) Title Held As');
    loanWizardParams.push(this.state['0propTitleDec'] || '');

    // Demographics for Primary Borrower
    loanWizardParams.push("Primary Borrower's Demographics");
    loanWizardParams.push('');

    loanWizardParams.push('Ethnicity');
    loanWizardParams.push(
      this.state['0checkboxHispanic']
        ? 'Hispanic (' +
            (this.state['0checkboxMexican'] ? ' Mexican' : '') +
            (this.state['0checkboxPuertoRican'] ? ' Puerto Rican' : '') +
            (this.state['0checkboxCuban'] ? ' Cuban' : '') +
            (this.state['0checkboxOtherHispanic']
              ? ' ' + this.state['0otherHispanic']
              : '') +
            ' ) '
        : '' + this.state['0checkboxNotHispanic']
        ? 'Not-Hispanic '
        : '' + this.state['0checkboxDeclineEthnicity']
        ? 'Declined to Specify'
        : ''
    );

    loanWizardParams.push('Sex');
    loanWizardParams.push(
      this.state['0checkboxMale']
        ? 'Male'
        : this.state['0checkboxFemale']
        ? 'Female'
        : 'Declined to Specify'
    );

    loanWizardParams.push('Race');
    loanWizardParams.push(
      (this.state['0checkboxAmericanIndian']
        ? 'American Indian or Alaska Native ( ' +
          this.state['0indianTribe'] +
          ' ) '
        : '') +
        (this.state['0checkboxAsian']
          ? 'Asian ( ' +
            (this.state['0checkboxAsianIndian'] ? ' Asian-Indian' : '') +
            (this.state['0checkboxChinese'] ? ' Chinese' : '') +
            (this.state['0checkboxFilipino'] ? ' Filipino' : '') +
            (this.state['0checkboxJapanese'] ? ' Japanese' : '') +
            (this.state['0checkboxKorean'] ? ' Korean' : '') +
            (this.state['0checkboxVietnamese'] ? ' Vietnamese' : '') +
            (this.state['0checkboxOtherAsian']
              ? ' ' + this.state['0otherAsian']
              : '') +
            ' ) '
          : '') +
        (this.state['0checkboxBlack'] ? 'Black or African American ' : '') +
        (this.state['0checkboxHawaiian']
          ? 'Native Hawaiian or Other Pacific Islander ( ' +
            (this.state['0checkboxNativeHawaiian'] ? ' Native Hawaiian' : '') +
            (this.state['0checkboxGuam'] ? ' Guamanian or Chamorro' : '') +
            (this.state['0checkboxSamoan'] ? ' Samoan' : '') +
            (this.state['0checkboxOtherPacific']
              ? ' ' + this.state['0otherPacific']
              : '') +
            ' ) '
          : '') +
        (this.state['0checkboxWhite'] ? 'White ' : '') +
        (this.state['0checkboxDeclineRace'] ? 'Declined to Specify' : '')
    );

    // List of Co-Borrowers (array)
    for (index = 0; index < this.state.coborrowers; index++) {
      loanWizardParams.push('*$*$*Co-Borrower #' + (index + 1));
      loanWizardParams.push('');

      loanWizardParams.push('Borrower Name');
      loanWizardParams.push(this.state['borrowerName' + index] || '');

      loanWizardParams.push('Phone');
      loanWizardParams.push(this.state['borrowerPhone' + index] || '');

      loanWizardParams.push('Email');
      loanWizardParams.push(this.state['borrowerEmail' + index] || '');

      loanWizardParams.push('Date of Birth');
      loanWizardParams.push(this.state['borrowerDOB' + index] || '');

      // Residence Records for Co-Borrower
      for (index2 = 0; index2 < this.state.residenceRecords[0]; index2++) {
        loanWizardParams.push(
          'Co-Borrower #' + (index + 1) + "'s Residence History"
        );
        loanWizardParams.push('(Residence #' + (index2 + 1) + ')');

        loanWizardParams.push('Address');
        loanWizardParams.push(
          this.state[index + 1 + 'propertyAddress' + index2] || ''
        );

        loanWizardParams.push('City');
        loanWizardParams.push(
          this.state[index + 1 + 'propertyCity' + index2] || ''
        );

        loanWizardParams.push('State');
        loanWizardParams.push(
          this.state[index + 1 + 'propertyState' + index2] || ''
        );

        loanWizardParams.push('Zip');
        loanWizardParams.push(
          this.state[index + 1 + 'propertyZip' + index2] || ''
        );

        loanWizardParams.push('Property Ownership');
        loanWizardParams.push(
          this.getPropertyOwnership(
            this.state[index + 1 + 'propertyOwnership' + index2]
          )
        );
      }

      // Employment Records for Co-Borrower
      for (index2 = 0; index2 < this.state.employmentRecords[0]; index2++) {
        loanWizardParams.push(
          'Co-Borrower #' + (index + 1) + "'s Employment History"
        );
        loanWizardParams.push('(Record #' + (index2 + 1) + ')');

        loanWizardParams.push('Employer');
        loanWizardParams.push(
          this.state[index + 1 + 'employer' + index2] || ''
        );

        loanWizardParams.push('Position/Title');
        loanWizardParams.push(
          this.state[index + 1 + 'position' + index2] || ''
        );

        loanWizardParams.push('Type of Business');
        loanWizardParams.push(this.state[index + 1 + 'type' + index2] || '');

        loanWizardParams.push('Contact Person');
        loanWizardParams.push(
          this.state[index + 1 + 'contactPerson' + index2] || ''
        );

        loanWizardParams.push('Contact Phone');
        loanWizardParams.push(
          this.state[index + 1 + 'contactPhone' + index2] || ''
        );

        loanWizardParams.push('Years on Job');
        loanWizardParams.push(
          this.state[index + 1 + 'yearsJob' + index2] || ''
        );

        loanWizardParams.push('Years in Profession');
        loanWizardParams.push(
          this.state[index + 1 + 'yearsProf' + index2] || ''
        );
      }

      // Property Records for Co-Borrower
      for (index2 = 0; index2 < this.state.propertyRecords[0]; index2++) {
        loanWizardParams.push('Co-Borrower #' + (index + 1) + "'s Properties");
        loanWizardParams.push('(Property #' + (index2 + 1) + ')');

        loanWizardParams.push('Address');
        loanWizardParams.push(
          this.state[index + 1 + 'ownedPropertyAddress' + index2] || ''
        );

        loanWizardParams.push('City');
        loanWizardParams.push(
          this.state[index + 1 + 'ownedPropertyCity' + index2] || ''
        );

        loanWizardParams.push('State');
        loanWizardParams.push(
          this.state[index + 1 + 'ownedPropertyState' + index2] || ''
        );

        loanWizardParams.push('Zip Code');
        loanWizardParams.push(
          this.state[index + 1 + 'ownedPropertyZip' + index2] || ''
        );

        loanWizardParams.push('Property Type');
        loanWizardParams.push(
          this.getPropertyType(
            this.state[index + 1 + 'ownedPropertyType' + index2]
          )
        );
      }

      // Declarations for Co-Borrower
      loanWizardParams.push('Co-Borrower #' + (index + 1) + "'s Declarations");
      loanWizardParams.push('');

      loanWizardParams.push('(a) Outstanding judgments');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxAYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxANo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(b) Bankrupt within 7 years');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxBYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxBNo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(c) Foreclosure within 7 years');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxCYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxCNo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(d) Party to lawsuit');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxDYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxDNo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(e) Obligations on defaulted loan');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxEYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxENo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(f) Delinquent on any loan');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxFYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxFNo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(g) Obligations for alimony or child support');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxGYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxGNo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(h) Any part of down payment borrowed');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxHYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxHNo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(i) Co-maker or endorser on a note');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxIYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxINo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(*) Notes');
      loanWizardParams.push(this.state[index + 1 + 'notes'] || '');

      loanWizardParams.push('(j) US Citizen');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxJYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxJNo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(k) Permanent resident alien');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxKYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxKNo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(l) Intend to occupy as primary residence');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxLYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxLNo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(m) Owned property in past 3 years');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxMYes']
          ? 'Yes'
          : this.state[index + 1 + 'checkboxMNo']
          ? 'No'
          : 'Unspecified'
      );

      loanWizardParams.push('(m-1) Property Type');
      loanWizardParams.push(this.state[index + 1 + 'propTypeDec'] || '');

      loanWizardParams.push('(m-2) Title Held As');
      loanWizardParams.push(this.state[index + 1 + 'propTitleDec'] || '');

      // Demographics for Co-Borrower
      loanWizardParams.push('Co-Borrower #' + (index + 1) + "'s Demographics");
      loanWizardParams.push('');

      loanWizardParams.push('Ethnicity');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxHispanic']
          ? 'Hispanic (' +
              (this.state[index + 1 + 'checkboxMexican'] ? ' Mexican' : '') +
              (this.state[index + 1 + 'checkboxPuertoRican']
                ? ' Puerto Rican'
                : '') +
              (this.state[index + 1 + 'checkboxCuban'] ? ' Cuban' : '') +
              (this.state[index + 1 + 'checkboxOtherHispanic']
                ? ' ' + this.state['0otherHispanic']
                : '') +
              ' ) '
          : '' + this.state[index + 1 + 'checkboxNotHispanic']
          ? 'Not-Hispanic '
          : '' + this.state[index + 1 + 'checkboxDeclineEthnicity']
          ? 'Declined to Specify'
          : ''
      );

      loanWizardParams.push('Sex');
      loanWizardParams.push(
        this.state[index + 1 + 'checkboxMale']
          ? 'Male'
          : this.state[index + 1 + 'checkboxFemale']
          ? 'Female'
          : 'Declined to Specify'
      );

      loanWizardParams.push('Race');
      loanWizardParams.push(
        (this.state[index + 1 + 'checkboxAmericanIndian']
          ? 'American Indian or Alaska Native ( ' +
            (this.state[index + 1 + 'indianTribe'] || '') +
            ' ) '
          : '') +
          (this.state[index + 1 + 'checkboxAsian']
            ? 'Asian ( ' +
              (this.state[index + 1 + 'checkboxAsianIndian']
                ? ' Asian-Indian'
                : '') +
              (this.state[index + 1 + 'checkboxChinese'] ? ' Chinese' : '') +
              (this.state[index + 1 + 'checkboxFilipino'] ? ' Filipino' : '') +
              (this.state[index + 1 + 'checkboxJapanese'] ? ' Japanese' : '') +
              (this.state[index + 1 + 'checkboxKorean'] ? ' Korean' : '') +
              (this.state[index + 1 + 'checkboxVietnamese']
                ? ' Vietnamese'
                : '') +
              (this.state[index + 1 + 'checkboxOtherAsian']
                ? ' ' + this.state['0otherAsian']
                : '') +
              ' ) '
            : '') +
          (this.state[index + 1 + 'checkboxBlack']
            ? 'Black or African American '
            : '') +
          (this.state[index + 1 + 'checkboxHawaiian']
            ? 'Native Hawaiian or Other Pacific Islander ( ' +
              (this.state[index + 1 + 'checkboxNativeHawaiian']
                ? ' Native Hawaiian'
                : '') +
              (this.state[index + 1 + 'checkboxGuam']
                ? ' Guamanian or Chamorro'
                : '') +
              (this.state[index + 1 + 'checkboxSamoan'] ? ' Samoan' : '') +
              (this.state[index + 1 + 'checkboxOtherPacific']
                ? ' ' + this.state['0otherPacific']
                : '') +
              ' ) '
            : '') +
          (this.state[index + 1 + 'checkboxWhite'] ? 'White ' : '') +
          (this.state[index + 1 + 'checkboxDeclineRace']
            ? 'Declined to Specify'
            : '')
      );
    }

    var borrowers = this.state.borrowerName;
    for (var i = 0; i < this.state.coborrowers; i++) {
      borrowers += ', ' + (this.state['borrowerName' + i] || '');
    }

    axios
      .get('/loanwizard', {
        params: {
          pBorrowers: borrowers,
          pLoanData: loanWizardBaseParams.join('$$$$$'),
          pBorrowerData: loanWizardParams.join('$$$$$'),
        },
      })
      .then(res => {
        if (res && res.data) {
          this.setState({ loan: res.data });
        }
      })
      .catch(e => console.log(e, 'Error Submitting To Server'));

    // redirect to show that we are done
    this.props.history.push('/loan-wizard/complete');
  }

  setPurpose(purpose) {
    this.setState({ loanPurpose: purpose });
    this.props.history.push('/loan-wizard/occupancy');
  }

  setOccupancy(occupancy) {
    this.setState({ occupancyType: occupancy });
    this.props.history.push('/loan-wizard/address');
  }

  setAddress(address) {
    this.props.history.push('/loan-wizard/borrower');
  }

  addResidenceRecord(borrowerIndex) {
    var resRecs = this.state.residenceRecords;
    resRecs[borrowerIndex] += 1;
    this.setState({ residenceRecords: resRecs });
  }

  removeResidenceRecord(borrowerIndex) {
    var resRecs = this.state.residenceRecords;
    resRecs[borrowerIndex] -= 1;
    this.setState({ residenceRecords: resRecs });
  }

  nextResidencePage() {
    if (
      this.state.coborrowers > 0 &&
      this.state.residencePosition < this.state.coborrowers
    ) {
      this.setState({ residencePosition: this.state.residencePosition + 1 });
    } else {
      this.props.history.push('/loan-wizard/employment');
    }
  }

  prevResidencePage() {
    if (this.state.coborrowers > 0 && this.state.residencePosition > 0) {
      this.setState({ residencePosition: this.state.residencePosition - 1 });
    } else {
      this.props.history.push('/loan-wizard/borrower');
    }
  }

  addEmploymentRecord(borrowerIndex) {
    var empRecs = this.state.employmentRecords;
    empRecs[borrowerIndex] += 1;
    this.setState({ employmentRecords: empRecs });
  }

  removeEmploymentRecord(borrowerIndex) {
    var empRecs = this.state.employmentRecords;
    empRecs[borrowerIndex] -= 1;
    this.setState({ employmentRecords: empRecs });
  }

  setEmploymentType(borrowerIndex, type) {
    this.setState({ ['employmentType' + borrowerIndex]: type });
  }

  nextEmploymentPage() {
    if (
      this.state.coborrowers > 0 &&
      this.state.employmentPosition < this.state.coborrowers
    ) {
      this.setState({ employmentPosition: this.state.employmentPosition + 1 });
    } else {
      this.props.history.push('/loan-wizard/properties');
    }
  }

  prevEmploymentPage() {
    if (this.state.coborrowers > 0 && this.state.employmentPosition > 0) {
      this.setState({ employmentPosition: this.state.employmentPosition - 1 });
    } else {
      this.props.history.push('/loan-wizard/residence');
    }
  }

  addPropertyRecord(borrowerIndex) {
    var propRecs = this.state.propertyRecords;
    propRecs[borrowerIndex] += 1;
    this.setState({ propertyRecords: propRecs });
  }

  removePropertyRecord(borrowerIndex) {
    var propRecs = this.state.propertyRecords;
    propRecs[borrowerIndex] -= 1;
    this.setState({ propertyRecords: propRecs });
  }

  nextPropertyPage() {
    if (
      this.state.coborrowers > 0 &&
      this.state.propertyPosition < this.state.coborrowers
    ) {
      this.setState({ propertyPosition: this.state.propertyPosition + 1 });
    } else {
      this.props.history.push('/loan-wizard/declarations');
    }
  }

  prevPropertyPage() {
    if (this.state.coborrowers > 0 && this.state.propertyPosition > 0) {
      this.setState({ propertyPosition: this.state.propertyPosition - 1 });
    } else {
      this.props.history.push('/loan-wizard/employment');
    }
  }

  nextDeclarationPage() {
    if (
      this.state.coborrowers > 0 &&
      this.state.declarationPosition < this.state.coborrowers
    ) {
      this.setState({
        declarationPosition: this.state.declarationPosition + 1,
      });
    } else {
      this.props.history.push('/loan-wizard/demographics');
    }
  }

  prevDeclarationPage() {
    if (this.state.coborrowers > 0 && this.state.declarationPosition > 0) {
      this.setState({
        declarationPosition: this.state.declarationPosition - 1,
      });
    } else {
      this.props.history.push('/loan-wizard/properties');
    }
  }

  nextDemographicsPage() {
    if (
      this.state.coborrowers > 0 &&
      this.state.demographicPosition < this.state.coborrowers
    ) {
      this.setState({
        demographicPosition: this.state.demographicPosition + 1,
      });
    } else {
      this.props.history.push('/loan-wizard/review');
    }
  }

  prevDemographicsPage() {
    if (this.state.coborrowers > 0 && this.state.demographicPosition > 0) {
      this.setState({
        demographicPosition: this.state.demographicPosition - 1,
      });
    } else {
      this.props.history.push('/loan-wizard/declarations');
    }
  }

  getPropertyType(value) {
    try {
      return PRICING_OPTIONS.propertyType[parseInt(value) - 1].label;
    } catch {
      return '';
    }
  }

  getPropertyOwnership(value) {
    switch (value) {
      case '0':
        return '';
      case '1':
        return 'Borrower Owns Property';
      case '2':
        return 'Borrower Does Not Own Property';
      default:
        return '';
    }
  }

  pagePurpose() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4>Loan Wizard (Purpose)</h4>
          </div>
          <div className="col-12">
            For prospective borrowers, please fill out as much of the
            information in this wizard as you can reasonably provide. This will
            help us get started with preparing your loan. Once we receive your
            submission, one of our representatives will reach out to you shortly
            to follow up and to go over any additional information we will need.
            Thank you!
            <br />
            <br />
            Let's get started by first determining the purpose of the loan.
            Please let us know what kind of transaction you are looking for.
          </div>

          <div className="col-12">
            <div className="row p-0">
              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.setPurpose('Purchase')}
                  >
                    Purchase
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.setPurpose('Refinance')}
                  >
                    Refinance
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  pageOccupancy() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <h4>Loan Wizard (Occupancy)</h4>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        this.props.history.push('/loan-wizard/purpose')
                      }
                    >
                      ← Back
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            Please let us know the occupancy type of the property you
            {this.state.loanPurpose === 'Purchase' ? (
              <> plan to purchase.</>
            ) : (
              <> are refinancing.</>
            )}
            <br />
            <br />
            <hr />
            <br />
            Principal Residence - Fannie Mae and Freddie Mac define your
            principal residence as a one-to-four unit family home that you (as
            the borrower) occupy as your primary residence. You must occupy the
            property for the majority of each calendar year for it to be
            considered a principal residence for mortgage purposes.
            <br />
            <br />
            Secondary Residence - a residence you intend to occupy in addition
            to a primary residence for part of the year. The property must be
            occupied by the borrower for some portion of the year It must not be
            rental property or a timeshare arrangement
            <br />
            <br />
            Investment Property - If you purchase a property you’re going to use
            to make and profit and you don’t plan to use it as a personal
            residence, it’s an investment property. For example, if you plan to
            use a property for one month and rent it out the rest of the year,
            it’s considered an investment property. <br />
          </div>
          <div className="col-12">
            <div className="row p-0">
              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.setOccupancy('Primary')}
                  >
                    Primary
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.setOccupancy('Secondary')}
                  >
                    Secondary
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.setOccupancy('Investment')}
                  >
                    Investment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  pageAddress() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <h4>Loan Wizard (Property Address)</h4>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        this.props.history.push('/loan-wizard/occupancy')
                      }
                    >
                      ← Back
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            Please provide information on the subject property for the loan.
            {this.state.loanPurpose === 'Purchase' &&
              this.state.propertyInfoReady === false && (
                <>
                  <br />
                  Do you have a specific property that you are looking to
                  purchase? (Do you have the property address)
                </>
              )}
          </div>

          {this.state.loanPurpose === 'Purchase' &&
            this.state.propertyInfoReady === false && (
              <>
                <div className="col-12">
                  <div className="col-12">
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          this.setState({ propertyInfoReady: true })
                        }
                      >
                        Yes
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          this.props.history.push('/loan-wizard/borrower')
                        }
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

          {(this.state.loanPurpose !== 'Purchase' ||
            this.state.propertyInfoReady === true) && (
            <>
              <div className="col-12">
                <br />
                <hr />
                <div className="row p-0">
                  <div className="col-6">
                    <input
                      className="form-control"
                      id="propertyAddress"
                      name="propertyAddress"
                      label="Property Address"
                      type="text"
                      value={this.state.propertyAddress}
                      onChange={this.captureInput}
                      autoComplete="off"
                      tabIndex={1}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      id="propertyCity"
                      name="propertyCity"
                      label="City"
                      type="text"
                      value={this.state.propertyCity}
                      onChange={this.captureInput}
                      autoComplete="off"
                      tabIndex={2}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      id="propertyState"
                      name="propertyState"
                      label="State"
                      type="text"
                      value={this.state.propertyState}
                      onChange={this.captureInput}
                      autoComplete="off"
                      tabIndex={3}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      id="propertyZip"
                      name="propertyZip"
                      label="Zip Code"
                      type="text"
                      value={this.state.propertyZip}
                      onChange={this.captureInput}
                      autoComplete="off"
                      tabIndex={4}
                    />
                  </div>

                  {this.state.loanPurpose !== 'Purchase' && (
                    <>
                      <div className="col-12">
                        <br />
                      </div>

                      <div className="col-6">
                        <CurrencyInput
                          id="orgPrice"
                          name="orgPrice"
                          label="Original Purchase Price"
                          value={this.state.orgPrice}
                          onChange={this.captureInput}
                          autoComplete="off"
                          tabIndex={5}
                        />
                      </div>
                      <div className="col-6">
                        <CurrencyInput
                          id="extLiens"
                          name="extLiens"
                          label="Existing Mortgage Principal"
                          value={this.state.extLiens}
                          onChange={this.captureInput}
                          autoComplete="off"
                          tabIndex={6}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.props.history.push('/loan-wizard/borrower')
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  pageBorrower() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <h4>Loan Wizard (Borrower)</h4>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        this.props.history.push('/loan-wizard/address')
                      }
                    >
                      ← Back
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            Please provide information on the borrower(s) that will be applying
            for the loan.
            <br />
            <br />
            <hr />
          </div>

          <div className="col-12 col-md-4">
            <input
              className="form-control"
              id="borrowerName"
              name="borrowerName"
              label="Borrower's Name"
              type="text"
              value={this.state.borrowerName}
              onChange={this.captureInput}
              tabIndex={1}
            />
          </div>
          <div className="col-12 col-md-4">
            <input
              className="form-control"
              id="phone"
              name="phone"
              label="Phone Number"
              type="text"
              value={this.state.phone}
              onChange={this.captureInput}
              tabIndex={2}
            />
          </div>
          <div className="col-12 col-md-4">
            <input
              className="form-control"
              id="email"
              name="email"
              label="Email Address"
              type="text"
              value={this.state.email}
              onChange={this.captureInput}
              tabIndex={3}
            />
          </div>
          <div className="col-12 col-md-4">
            <input
              className="form-control"
              id="dob"
              name="dob"
              label="Date Of Birth"
              type="text"
              value={this.state.dob}
              onChange={this.captureInput}
              tabIndex={4}
            />
          </div>
          <div className="col-12 col-md-4">
            <select
              className="form-select"
              id="marital"
              name="marital"
              label="Marital Status"
              value={this.state.marital}
              onChange={this.captureInput}
              tabIndex={5}
            >
              {PRICING_OPTIONS.marital.map(el => (
                <option key={el.value} value={el.value}>
                  {el.label}
                </option>
              ))}
            </select>
          </div>

          <div className="col-12 col-md-4">
            <input
              className="form-control"
              id="yearsSchool"
              name="yearsSchool"
              label="Years of School"
              type="number"
              value={this.state.yearsSchool}
              onChange={this.captureInput}
              tabIndex={6}
            />
          </div>

          {this.state.coborrowers > 0 &&
            Array.from(Array(this.state.coborrowers)).map((TableRow, index) => (
              <>
                {index >= 0 && (
                  <div className="col-12">
                    <br />
                  </div>
                )}
                <div className="col-12">
                  <u>Co-Borrower #{index + 1}</u>
                </div>
                <div className="col-12 col-md-4">
                  <input
                    className="form-control"
                    id={'borrowerName' + index}
                    name={'borrowerName' + index}
                    label={'Co-Borrower #' + (index + 1) + ' Name'}
                    type="text"
                    value={this.state['borrowerName' + index] || ''}
                    onChange={this.captureInput}
                    tabIndex={7 + index * 6}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <input
                    className="form-control"
                    id={'borrowerPhone' + index}
                    name={'borrowerPhone' + index}
                    label={'Co-Borrower #' + (index + 1) + ' Phone Number'}
                    type="text"
                    value={this.state['borrowerPhone' + index] || ''}
                    onChange={this.captureInput}
                    tabIndex={8 + index * 6}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <input
                    className="form-control"
                    id={'borrowerEmail' + index}
                    name={'borrowerEmail' + index}
                    label={'Co-Borrower #' + (index + 1) + ' Email Address'}
                    type="text"
                    value={this.state['borrowerEmail' + index] || ''}
                    onChange={this.captureInput}
                    tabIndex={9 + index * 6}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <input
                    className="form-control"
                    id={'borrowerDOB' + index}
                    name={'borrowerDOB' + index}
                    label={'Co-Borrower #' + (index + 1) + ' Date of Birth'}
                    type="text"
                    value={this.state['borrowerDOB' + index] || ''}
                    onChange={this.captureInput}
                    tabIndex={10 + index * 6}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <select
                    className="form-select"
                    id={'borrowerMarital' + index}
                    name={'borrowerMarital' + index}
                    label={'Co-Borrower #' + (index + 1) + ' Martial Status'}
                    value={this.state['borrowerMarital' + index] || ''}
                    onChange={this.captureInput}
                    tabIndex={11 + index * 6}
                  >
                    {PRICING_OPTIONS.marital.map(el => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-4">
                  <input
                    className="form-control"
                    id={'borrowerYearsSchool' + index}
                    name={'borrowerYearsSchool' + index}
                    label={'Co-Borrower #' + (index + 1) + ' Years of School'}
                    type="number"
                    value={this.state['borrowerYearsSchool' + index] || ''}
                    onChange={this.captureInput}
                    tabIndex={12 + index * 6}
                  />
                </div>
              </>
            ))}

          {this.state.coborrowers > 0 && (
            <div className="col-12">
              <br />
              <button
                className="btn btn-primary btn-sm"
                width="200px"
                onClick={() =>
                  this.setState({ coborrowers: this.state.coborrowers - 1 })
                }
              >
                Remove Co-Borrower
              </button>
            </div>
          )}

          <div className="col-12">
            <br />
            <hr />
            <br />
            Are there any other co-borrowers that you want to add for this loan?
            <br />
            <br />
            <hr />
          </div>

          <div className="col-12">
            <div>
              <button
                className="btn btn-primary"
                onClick={() =>
                  this.setState({ coborrowers: this.state.coborrowers + 1 })
                }
              >
                Yes
              </button>
              <button
                className="btn btn-primary"
                onClick={() =>
                  this.props.history.push('/loan-wizard/residence')
                }
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  pageResidence() {
    const borrowerIndex = this.state.residencePosition;
    if (this.state.residenceRecords.length <= borrowerIndex) {
      var resRecs = this.state.residenceRecords;
      resRecs[borrowerIndex] = 1;
      this.setState({ residenceRecords: resRecs });
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <h4>Loan Wizard (Residence)</h4>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.prevResidencePage()}
                    >
                      ← Back
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            Please provide the{' '}
            {this.state.residencePosition === 0 ? (
              <> primary borrower's </>
            ) : (
              <> co-borrower #{this.state.residencePosition}'s </>
            )}{' '}
            current address of residence.
          </div>

          {Array.from(Array(this.state.residenceRecords[borrowerIndex])).map(
            (TableRow, index) => (
              <React.Fragment key={`${TableRow.id}-${index}`}>
                <div className="col-12">
                  <br />
                  <hr />
                  <div className="row p-0">
                    <div className="col-6">
                      <input
                        className="form-control"
                        id={borrowerIndex + 'propertyAddress' + index}
                        name={borrowerIndex + 'propertyAddress' + index}
                        label="Property Address"
                        type="text"
                        value={
                          this.state[
                            borrowerIndex + 'propertyAddress' + index
                          ] || ''
                        }
                        onChange={this.captureInput}
                        autoComplete="off"
                        tabIndex={1 + index * 5}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control"
                        id={borrowerIndex + 'propertyCity' + index}
                        name={borrowerIndex + 'propertyCity' + index}
                        label="City"
                        type="text"
                        value={
                          this.state[borrowerIndex + 'propertyCity' + index] ||
                          ''
                        }
                        onChange={this.captureInput}
                        autoComplete="off"
                        tabIndex={2 + index * 5}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control"
                        id={borrowerIndex + 'propertyState' + index}
                        name={borrowerIndex + 'propertyState' + index}
                        label="State"
                        type="text"
                        value={
                          this.state[borrowerIndex + 'propertyState' + index] ||
                          ''
                        }
                        onChange={this.captureInput}
                        autoComplete="off"
                        tabIndex={3 + index * 5}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control"
                        id={borrowerIndex + 'propertyZip' + index}
                        name={borrowerIndex + 'propertyZip' + index}
                        label="Zip Code"
                        type="text"
                        value={
                          this.state[borrowerIndex + 'propertyZip' + index] ||
                          ''
                        }
                        onChange={this.captureInput}
                        autoComplete="off"
                        tabIndex={4 + index * 5}
                      />
                    </div>
                    <div className="col-6">
                      <select
                        className="form-select"
                        id={borrowerIndex + 'propertyOwnership' + index}
                        name={borrowerIndex + 'propertyOwnership' + index}
                        label="Ownership Status"
                        value={
                          this.state[
                            borrowerIndex + 'propertyOwnership' + index
                          ] || ''
                        }
                        onChange={this.captureInput}
                        tabIndex={5 + index * 5}
                      >
                        {PRICING_OPTIONS.ownership.map(el => (
                          <option key={el.value} value={el.value}>
                            {el.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          )}

          {this.state.residenceRecords[borrowerIndex] > 1 && (
            <div className="col-12">
              <br />
              <button
                className="btn btn-primary btn-sm"
                width="200px"
                onClick={() => this.removeResidenceRecord(borrowerIndex)}
              >
                Remove Residence
              </button>
            </div>
          )}

          <div className="col-12">
            <br />
            <hr />
            <br />
            If the borrower has not lived at the listed address for a total of
            at least 2 years, please enter additional residential history. Are
            there additional addresses to add for the{' '}
            {this.state.residencePosition === 0 ? (
              <> primary borrower</>
            ) : (
              <> co-borrower #{this.state.residencePosition}</>
            )}
            ?
            <br />
            <br />
            <hr />
          </div>

          <div className="col-12">
            <div>
              <button
                className="btn btn-primary"
                onClick={() => this.addResidenceRecord(borrowerIndex)}
              >
                Yes
              </button>
              <button
                className="btn btn-primary"
                onClick={() => this.nextResidencePage()}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  pageEmployment() {
    const borrowerIndex = this.state.employmentPosition;
    if (this.state.employmentRecords.length <= borrowerIndex) {
      var empRecs = this.state.employmentRecords;
      empRecs[borrowerIndex] = 1;
      this.setState({ employmentRecords: empRecs });
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <h4>Loan Wizard (Employment)</h4>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.prevEmploymentPage()}
                    >
                      ← Back
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* First check whether borrower is a wage-earner or self-employed */}
          {!this.state['employmentType' + borrowerIndex] && (
            <>
              <div className="col-12">
                <hr />
                <br />
                Is the{' '}
                {borrowerIndex === 0 ? (
                  <>primary </>
                ) : (
                  <>#{borrowerIndex} co-</>
                )}
                borrower a wage earner or self-employed?
                <br />
                <br />
                <hr />
              </div>
              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.setEmploymentType(borrowerIndex, 'self')
                    }
                  >
                    Self-Employed
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.setEmploymentType(borrowerIndex, 'wage')
                    }
                  >
                    Wage Earner
                  </button>
                </div>
              </div>
            </>
          )}

          {/* If the borrower is self-employed, check if they've had the job at least 2 years */}
          {this.state['employmentType' + borrowerIndex] === 'self' && (
            <>
              <div className="col-12">
                <hr />
                <br />
                Has the{' '}
                {borrowerIndex === 0 ? (
                  <>primary </>
                ) : (
                  <>#{borrowerIndex} co-</>
                )}
                borrower been self-employed for at least 2 years?
                <br />
                <br />
                <hr />
              </div>
              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.setState({
                        ['employmentType' + borrowerIndex]: 'selfNo',
                      })
                    }
                  >
                    No
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.setState({
                        ['employmentType' + borrowerIndex]: 'selfYes',
                      })
                    }
                  >
                    Yes
                  </button>
                </div>
              </div>
            </>
          )}

          {/* If the borrower is self-employed, check if they've had the job at least 2 years */}
          {this.state['employmentType' + borrowerIndex] === 'selfNo' && (
            <>
              <div className="col-12">
                <hr />
                <br />
                For self-employment, the borrower needs a minimum record of 2
                years. We recommend that you contact the Loan Officer, but you
                can still continue with the wizard.
                <br />
                <br />
                <hr />
              </div>
              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.setState({
                        ['employmentType' + borrowerIndex]: 'selfYes',
                      })
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}

          {/* Now obtain employment records */}
          {(this.state['employmentType' + borrowerIndex] === 'selfYes' ||
            this.state['employmentType' + borrowerIndex] === 'wage') && (
            <>
              <div className="col-12">
                <h3 className="border-bottom-2 border-gray text-capitalize my-4">
                  Employment Record (
                  {borrowerIndex === 0 ? (
                    <>Primary Borrower</>
                  ) : (
                    <>Co-Borrower #{borrowerIndex}</>
                  )}
                  )
                </h3>
              </div>

              {Array.from(
                Array(this.state.employmentRecords[borrowerIndex])
              ).map((TableRow, index) => (
                <>
                  {index > 0 && (
                    <div className="col-12">
                      <br />
                    </div>
                  )}
                  <div className="col-12">
                    <u>Record #{index + 1}</u>
                  </div>
                  <div className="col-12 col-md-8">
                    <input
                      className="form-control"
                      id={borrowerIndex + 'employer' + index}
                      name={borrowerIndex + 'employer' + index}
                      label="Name and Address of Employer"
                      type="text"
                      value={
                        this.state[borrowerIndex + 'employer' + index] || ''
                      }
                      onChange={this.captureInput}
                      tabIndex={1 + index * 7}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <input
                      className="form-control"
                      id={borrowerIndex + 'position' + index}
                      name={borrowerIndex + 'position' + index}
                      label="Position/Title"
                      type="text"
                      value={
                        this.state[borrowerIndex + 'position' + index] || ''
                      }
                      onChange={this.captureInput}
                      tabIndex={2 + index * 7}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <input
                      className="form-control"
                      id={borrowerIndex + 'type' + index}
                      name={borrowerIndex + 'type' + index}
                      label="Type of Business"
                      type="text"
                      value={this.state[borrowerIndex + 'type' + index] || ''}
                      onChange={this.captureInput}
                      tabIndex={3 + index * 7}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <input
                      className="form-control"
                      id={borrowerIndex + 'contactPerson' + index}
                      name={borrowerIndex + 'contactPerson' + index}
                      label="Contact Person (Ideally HR)"
                      type="text"
                      value={
                        this.state[borrowerIndex + 'contactPerson' + index] ||
                        ''
                      }
                      onChange={this.captureInput}
                      tabIndex={4 + index * 7}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <input
                      className="form-control"
                      id={borrowerIndex + 'contactPhone' + index}
                      name={borrowerIndex + 'contactPhone' + index}
                      label="Contact Phone Number"
                      type="text"
                      value={
                        this.state[borrowerIndex + 'contactPhone' + index] || ''
                      }
                      onChange={this.captureInput}
                      tabIndex={5 + index * 7}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <input
                      className="form-control"
                      id={borrowerIndex + 'yearsJob' + index}
                      name={borrowerIndex + 'yearsJob' + index}
                      label="Years on the Job"
                      type="text"
                      value={
                        this.state[borrowerIndex + 'yearsJob' + index] || ''
                      }
                      onChange={this.captureInput}
                      tabIndex={6 + index * 7}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <input
                      className="form-control"
                      id={borrowerIndex + 'yearsProf' + index}
                      name={borrowerIndex + 'yearsProf' + index}
                      label="Years in Line of Work/Profession"
                      type="text"
                      value={
                        this.state[borrowerIndex + 'yearsProf' + index] || ''
                      }
                      onChange={this.captureInput}
                      tabIndex={7 + index * 7}
                      autoComplete="off"
                    />
                  </div>
                </>
              ))}

              {this.state.employmentRecords[borrowerIndex] > 1 && (
                <div className="col-12">
                  <br />
                  <button
                    className="btn btn-primary btn-sm"
                    width="200px"
                    onClick={() => this.removeEmploymentRecord(borrowerIndex)}
                  >
                    Remove Employment
                  </button>
                </div>
              )}

              <div className="col-12">
                <br />
                <hr />
                <br />
                Each borrower needs to provide employment history for at least 2
                years. Are there additional employment records to add for the{' '}
                {this.state.employmentPosition === 0 ? (
                  <> primary borrower</>
                ) : (
                  <> co-borrower #{this.state.employmentPosition}</>
                )}
                ?
                <br />
                <br />
                <hr />
              </div>

              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.addEmploymentRecord(borrowerIndex)}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.nextEmploymentPage()}
                  >
                    No
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  pageProperties() {
    const borrowerIndex = this.state.propertyPosition;
    if (this.state.propertyRecords.length <= borrowerIndex) {
      var propRecs = this.state.propertyRecords;
      propRecs[borrowerIndex] = 0;
      this.setState({ propertyRecords: propRecs });
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <h4>Loan Wizard (Properties)</h4>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.prevPropertyPage()}
                    >
                      ← Back
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {this.state.propertyRecords[borrowerIndex] === 0 ? (
            <>
              <div className="col-12">
                <hr />
                <br />
                Does the{' '}
                {borrowerIndex === 0 ? (
                  <>primary </>
                ) : (
                  <>#{borrowerIndex} co-</>
                )}
                borrower currently own any properties?
                <br />
                <br />
                <hr />
              </div>

              <div className="col-12">
                <div className="col-12">
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.addPropertyRecord(borrowerIndex)}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.nextPropertyPage()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <hr />
                <br />
                Properties owned by the{' '}
                {borrowerIndex === 0 ? (
                  <>primary </>
                ) : (
                  <>#{borrowerIndex} co-</>
                )}{' '}
                borrower:
                <br />
                <br />
                <hr />
              </div>

              {Array.from(Array(this.state.propertyRecords[borrowerIndex])).map(
                (TableRow, index) => (
                  <>
                    <div className="col-12">
                      <br />
                      <hr />
                      <div className="row p-0">
                        <div className="col-6">
                          <input
                            className="form-control"
                            id={borrowerIndex + 'ownedPropertyAddress' + index}
                            name={
                              borrowerIndex + 'ownedPropertyAddress' + index
                            }
                            label="Property Address"
                            type="text"
                            value={
                              this.state[
                                borrowerIndex + 'ownedPropertyAddress' + index
                              ] || ''
                            }
                            onChange={this.captureInput}
                            autoComplete="off"
                            tabIndex={1 + index * 5}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            className="form-control"
                            id={borrowerIndex + 'ownedPropertyCity' + index}
                            name={borrowerIndex + 'ownedPropertyCity' + index}
                            label="City"
                            type="text"
                            value={
                              this.state[
                                borrowerIndex + 'ownedPropertyCity' + index
                              ] || ''
                            }
                            onChange={this.captureInput}
                            autoComplete="off"
                            tabIndex={2 + index * 5}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            className="form-control"
                            id={borrowerIndex + 'ownedPropertyState' + index}
                            name={borrowerIndex + 'ownedPropertyState' + index}
                            label="State"
                            type="text"
                            value={
                              this.state[
                                borrowerIndex + 'ownedPropertyState' + index
                              ] || ''
                            }
                            onChange={this.captureInput}
                            autoComplete="off"
                            tabIndex={3 + index * 5}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            className="form-control"
                            id={borrowerIndex + 'ownedPropertyZip' + index}
                            name={borrowerIndex + 'ownedPropertyZip' + index}
                            label="Zip Code"
                            type="text"
                            value={
                              this.state[
                                borrowerIndex + 'ownedPropertyZip' + index
                              ] || ''
                            }
                            onChange={this.captureInput}
                            autoComplete="off"
                            tabIndex={4 + index * 5}
                          />
                        </div>
                        <div className="col-6">
                          <select
                            className="form-select"
                            id={borrowerIndex + 'ownedPropertyType' + index}
                            name={borrowerIndex + 'ownedPropertyType' + index}
                            label="Property Type"
                            value={
                              this.state[
                                borrowerIndex + 'ownedPropertyType' + index
                              ] || ''
                            }
                            onChange={this.captureInput}
                            tabIndex={5 + index * 5}
                          >
                            {PRICING_OPTIONS.propertyType}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}

              {this.state.propertyRecords[borrowerIndex] > 1 && (
                <div className="col-12">
                  <br />
                  <button
                    className="btn btn-primary btn-sm"
                    width="200px"
                    onClick={() => this.removePropertyRecord(borrowerIndex)}
                  >
                    Remove Property
                  </button>
                </div>
              )}

              <div className="col-12">
                <br />
                <hr />
                <br />
                Are there additional properties that the{' '}
                {this.state.propertyPosition === 0 ? (
                  <> primary borrower </>
                ) : (
                  <> co-borrower #{this.state.propertyPosition} </>
                )}{' '}
                owns?
                <br />
                <br />
                <hr />
              </div>

              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.addPropertyRecord(borrowerIndex)}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.nextPropertyPage()}
                  >
                    No
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  pageDeclarations() {
    const borrowerIndex = this.state.declarationPosition;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <h4>Loan Wizard (Declarations)</h4>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.prevDeclarationPage()}
                    >
                      ← Back
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            <hr />
            <br />
            For the{' '}
            {borrowerIndex === 0 ? <>primary </> : <>#{borrowerIndex} co-</>}
            borrower, please answer "Yes" or "No" for the following questions.
            If you answer "Yes" to any questions (a) through (i), please use the
            (notes) section to explain.
            <br />
            <br />
            <hr />
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (a) Are there any outstanding judgments against you?
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxAYes'}
              name={borrowerIndex + 'checkboxAYes'}
              checked={this.state[borrowerIndex + 'checkboxAYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxAYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxANo'}
              name={borrowerIndex + 'checkboxANo'}
              checked={this.state[borrowerIndex + 'checkboxANo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxANo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (b) Have you been declared bankrupt within the past 7 years?
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxBYes'}
              name={borrowerIndex + 'checkboxBYes'}
              checked={this.state[borrowerIndex + 'checkboxBYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxBYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxBNo'}
              name={borrowerIndex + 'checkboxBNo'}
              checked={this.state[borrowerIndex + 'checkboxBNo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxBNo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (c) Have you had property foreclosed upon or given title or deed in
            lieu thereof in the last 7 years?
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxCYes'}
              name={borrowerIndex + 'checkboxCYes'}
              checked={this.state[borrowerIndex + 'checkboxCYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxCYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxCNo'}
              name={borrowerIndex + 'checkboxCNo'}
              checked={this.state[borrowerIndex + 'checkboxCNo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxCNo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (d) Are you a party to a lawsuit?
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxDYes'}
              name={borrowerIndex + 'checkboxDYes'}
              checked={this.state[borrowerIndex + 'checkboxDYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxDYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxDNo'}
              name={borrowerIndex + 'checkboxDNo'}
              checked={this.state[borrowerIndex + 'checkboxDNo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxDNo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (e) Have you directly or indirectly been obligated on any loan which
            resulted in foreclosure, transfer of title in lieu of foreclosure or
            judgement? (This would include such loans as home mortgage loans,
            SBA loans, home improvement loands, educational loadns, manufactured
            (mobile) home loans, any mortgage, financial obligation, bond, or
            loan guarantee. If "Yes," provide details, including date, name, and
            address of Lender, FHA or VA case number, if any, and reasons for
            the action.)
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxEYes'}
              name={borrowerIndex + 'checkboxEYes'}
              checked={this.state[borrowerIndex + 'checkboxEYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxEYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxENo'}
              name={borrowerIndex + 'checkboxENo'}
              checked={this.state[borrowerIndex + 'checkboxENo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxENo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (f) Are you presently delinquent or in default on any Federal debt
            or any other loan, mortgage, financial obligation, bond, or loan
            guarantee?
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxFYes'}
              name={borrowerIndex + 'checkboxFYes'}
              checked={this.state[borrowerIndex + 'checkboxFYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxFYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxFNo'}
              name={borrowerIndex + 'checkboxFNo'}
              checked={this.state[borrowerIndex + 'checkboxFNo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxFNo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (g) Are you obligated to pay alimony, child support, or separate
            maintenance?
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxGYes'}
              name={borrowerIndex + 'checkboxGYes'}
              checked={this.state[borrowerIndex + 'checkboxGYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxGYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxGNo'}
              name={borrowerIndex + 'checkboxGNo'}
              checked={this.state[borrowerIndex + 'checkboxGNo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxGNo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (h) Is any part of the down payment borrowed?
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxHYes'}
              name={borrowerIndex + 'checkboxHYes'}
              checked={this.state[borrowerIndex + 'checkboxHYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxHYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxHNo'}
              name={borrowerIndex + 'checkboxHNo'}
              checked={this.state[borrowerIndex + 'checkboxHNo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxHNo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (i) Are you a co-maker or endorser on a note?
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxIYes'}
              name={borrowerIndex + 'checkboxIYes'}
              checked={this.state[borrowerIndex + 'checkboxIYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxIYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxINo'}
              name={borrowerIndex + 'checkboxINo'}
              checked={this.state[borrowerIndex + 'checkboxINo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxINo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />
        <hr />

        <div className="row">
          <div className="col-12">(notes)</div>
          <br />
          <br />
          <div className="col-12">
            <textarea
              id={borrowerIndex + 'notes'}
              name={borrowerIndex + 'notes'}
              value={this.state[borrowerIndex + 'notes'] || ''}
              rows="5"
              cols="120"
              onChange={this.captureInput}
              style={{ resize: 'none' }}
            />
          </div>
        </div>
        <br />
        <hr />

        <div className="row">
          <div className="col-12 col-md-8">(j) Are you a U.S. Citizen?</div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxJYes'}
              name={borrowerIndex + 'checkboxJYes'}
              checked={this.state[borrowerIndex + 'checkboxJYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxJYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxJNo'}
              name={borrowerIndex + 'checkboxJNo'}
              checked={this.state[borrowerIndex + 'checkboxJNo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxJNo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (k) Are you a permanent resident alien?
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxKYes'}
              name={borrowerIndex + 'checkboxKYes'}
              checked={this.state[borrowerIndex + 'checkboxKYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxKYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxKNo'}
              name={borrowerIndex + 'checkboxKNo'}
              checked={this.state[borrowerIndex + 'checkboxKNo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxKNo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            <b>
              (l) Do you intend to occupy the property as your primary
              residence? If "Yes", complete question (m) below.
            </b>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxLYes'}
              name={borrowerIndex + 'checkboxLYes'}
              checked={this.state[borrowerIndex + 'checkboxLYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxLYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxLNo'}
              name={borrowerIndex + 'checkboxLNo'}
              checked={this.state[borrowerIndex + 'checkboxLNo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxLNo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8">
            (m) Have you had an ownership interest in a property in the last
            three years?
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxMYes'}
              name={borrowerIndex + 'checkboxMYes'}
              checked={this.state[borrowerIndex + 'checkboxMYes']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxMYes'}
              style={{ marginLeft: '10px' }}
            >
              Yes
            </label>
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxMNo'}
              name={borrowerIndex + 'checkboxMNo'}
              checked={this.state[borrowerIndex + 'checkboxMNo']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxMNo'}
              style={{ marginLeft: '10px' }}
            >
              No
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8 ps-4">
            (1) What type of property did you own -- principal residence (PR),
            second home (SH), or investment property (IP)?
          </div>
          <div className="col-12 col-md-4">
            <input
              id={borrowerIndex + 'propTypeDec'}
              name={borrowerIndex + 'propTypeDec'}
              type="text"
              value={this.state[borrowerIndex + 'propTypeDec'] || ''}
              onChange={this.captureInput}
              autoComplete="off"
            />
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-8 ps-4">
            (2) How did you hold title to the home -- solely by yourself (S),
            jointly with your spouse (SP), or jointly with another person (O)?
          </div>
          <div className="col-12 col-md-4">
            <input
              id={borrowerIndex + 'propTitleDec'}
              name={borrowerIndex + 'propTitleDec'}
              type="text"
              value={this.state[borrowerIndex + 'propTitleDec'] || ''}
              onChange={this.captureInput}
              autoComplete="off"
            />
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12">
            <div>
              <button
                className="btn btn-primary"
                onClick={() => this.nextDeclarationPage()}
              >
                Next Page
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  pageDemographics() {
    const borrowerIndex = this.state.demographicPosition;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <h4>Loan Wizard (Demographics)</h4>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.prevDemographicsPage()}
                    >
                      ← Back
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            <hr />
            <br />
            For the{' '}
            {borrowerIndex === 0 ? <>primary </> : <>#{borrowerIndex} co-</>}
            borrower, please provide information their ethnicity, sex, and race.
            <br />
            <br />
            The purpose of collecting this information is to help ensure that
            all applicants are treated fairly and that the housing needs of
            communities and neighborhoods are being fulfilled. For residential
            mortgage lending, Federal law requires that we ask applicants for
            their demographic information (ethnicity, sex, and race) in order to
            monitor our compliance with equal credit opportunity, fair housing,
            and home mortgage disclosure laws. You are not required to provide
            this information, but are encouraged to do so. You may select one or
            more designations for "Ethnicity" and one or more designations for
            "Race." The law provides that we may not discriminate on the basis
            of this information, or on whether you choose to provide it.
            However, if you choose not to provide the information and you have
            made this application in person, Federal regulations require us to
            note your ethnicity, sex, and race on the basis of visual
            observation or surname. The law also provides that we may not
            discriminate on the basis of age or marital status information you
            provide in this application. If you do not wish to provide some or
            all of this information, please check below.
            <br />
            <br />
            <hr />
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12">
            <b>Ethnicity:</b> Check one or more
            <br />
            <br />
          </div>
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxHispanic'}
              name={borrowerIndex + 'checkboxHispanic'}
              checked={this.state[borrowerIndex + 'checkboxHispanic']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxHispanic'}
              style={{ marginLeft: '10px' }}
            >
              Hispanic or Latino
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-3">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxMexican'}
              name={borrowerIndex + 'checkboxMexican'}
              checked={this.state[borrowerIndex + 'checkboxMexican']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxMexican'}
              style={{ marginLeft: '10px' }}
            >
              Mexican
            </label>
          </div>
          <div className="col-3">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxPuertoRican'}
              name={borrowerIndex + 'checkboxPuertoRican'}
              checked={this.state[borrowerIndex + 'checkboxPuertoRican']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxPuertoRican'}
              style={{ marginLeft: '10px' }}
            >
              Puerto Rican
            </label>
          </div>
          <div className="col-3">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxCuban'}
              name={borrowerIndex + 'checkboxCuban'}
              checked={this.state[borrowerIndex + 'checkboxCuban']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxCuban'}
              style={{ marginLeft: '10px' }}
            >
              Cuban
            </label>
          </div>
          <div className="col-2" />
        </div>
        <br />

        <div className="row">
          <div className="col-3">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxOtherHispanic'}
              name={borrowerIndex + 'checkboxOtherHispanic'}
              checked={this.state[borrowerIndex + 'checkboxOtherHispanic']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxOtherHispanic'}
              style={{ marginLeft: '10px' }}
            >
              Other Hispanic or Latino - Print origin:
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-11 offset-1">
            <input
              id={borrowerIndex + 'otherHispanic'}
              name={borrowerIndex + 'otherHispanic'}
              type="text"
              value={this.state[borrowerIndex + 'otherHispanic'] || ''}
              onChange={this.captureInput}
              autoComplete="off"
              style={{ width: '300px' }}
            />
            <br />
            For example: Argentinean, Columbian, Dominican, Nicaraguan,
            Salvadoran, Spaniard, and so on.
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxNotHispanic'}
              name={borrowerIndex + 'checkboxNotHispanic'}
              checked={this.state[borrowerIndex + 'checkboxNotHispanic']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxNotHispanic'}
              style={{ marginLeft: '10px' }}
            >
              Not Hispanic or Latino
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxDeclineEthnicity'}
              name={borrowerIndex + 'checkboxDeclineEthnicity'}
              checked={this.state[borrowerIndex + 'checkboxDeclineEthnicity']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxDeclineEthnicity'}
              style={{ marginLeft: '10px' }}
            >
              I do not wish to provide this information
            </label>
          </div>
        </div>
        <br />
        <hr />
        <br />

        <div className="row">
          <div className="col-12">
            <b>Sex:</b>
            <br />
            <br />
          </div>
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxMale'}
              name={borrowerIndex + 'checkboxMale'}
              checked={this.state[borrowerIndex + 'checkboxMale']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxMale'}
              style={{ marginLeft: '10px' }}
            >
              Male
            </label>
          </div>
          <br />
          <br />
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxFemale'}
              name={borrowerIndex + 'checkboxFemale'}
              checked={this.state[borrowerIndex + 'checkboxFemale']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxFemale'}
              style={{ marginLeft: '10px' }}
            >
              Female
            </label>
          </div>
          <br />
          <br />
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxDeclineSex'}
              name={borrowerIndex + 'checkboxDeclineSex'}
              checked={this.state[borrowerIndex + 'checkboxDeclineSex']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxDeclineSex'}
              style={{ marginLeft: '10px' }}
            >
              I do not wish to provide this information
            </label>
          </div>
        </div>
        <br />
        <hr />
        <br />

        <div className="row">
          <div className="col-12">
            <b>Race:</b> Check one or more
            <br />
            <br />
          </div>
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxAmericanIndian'}
              name={borrowerIndex + 'checkboxAmericanIndian'}
              checked={this.state[borrowerIndex + 'checkboxAmericanIndian']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxAmericanIndian'}
              style={{ marginLeft: '10px' }}
            >
              American Indian or Alaskan Native - Print name of enrolled or
              principal tribe:
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-11 offset-1">
            <input
              id={borrowerIndex + 'indianTribe'}
              name={borrowerIndex + 'indianTribe'}
              type="text"
              value={this.state[borrowerIndex + 'indianTribe'] || ''}
              onChange={this.captureInput}
              autoComplete="off"
              style={{ width: '300px' }}
            />
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxAsian'}
              name={borrowerIndex + 'checkboxAsian'}
              checked={this.state[borrowerIndex + 'checkboxAsian']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxAsian'}
              style={{ marginLeft: '10px' }}
            >
              Asian
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-3 offset-1">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxAsianIndian'}
              name={borrowerIndex + 'checkboxAsianIndian'}
              checked={this.state[borrowerIndex + 'checkboxAsianIndian']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxAsianIndian'}
              style={{ marginLeft: '10px' }}
            >
              Asian Indian
            </label>
          </div>
          <div className="col-3">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxChinese'}
              name={borrowerIndex + 'checkboxChinese'}
              checked={this.state[borrowerIndex + 'checkboxChinese']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxChinese'}
              style={{ marginLeft: '10px' }}
            >
              Chinese
            </label>
          </div>
          <div className="col-3">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxFilipino'}
              name={borrowerIndex + 'checkboxFilipino'}
              checked={this.state[borrowerIndex + 'checkboxFilipino']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxCuban'}
              style={{ marginLeft: '10px' }}
            >
              Filipino
            </label>
          </div>
          <div className="col-2" />
        </div>
        <br />

        <div className="row">
          <div className="col-3 offset-1">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxJapanese'}
              name={borrowerIndex + 'checkboxJapanese'}
              checked={this.state[borrowerIndex + 'checkboxJapanese']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxJapanese'}
              style={{ marginLeft: '10px' }}
            >
              Japanese
            </label>
          </div>
          <div className="col-3">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxKorean'}
              name={borrowerIndex + 'checkboxKorean'}
              checked={this.state[borrowerIndex + 'checkboxKorean']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxKorean'}
              style={{ marginLeft: '10px' }}
            >
              Korean
            </label>
          </div>
          <div className="col-3">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxVietnamese'}
              name={borrowerIndex + 'checkboxVietnamese'}
              checked={this.state[borrowerIndex + 'checkboxVietnamese']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxVietnamese'}
              style={{ marginLeft: '10px' }}
            >
              Vietnamese
            </label>
          </div>
          <div className="col-2" />
        </div>
        <br />

        <div className="row">
          <div className="col-3 offset-1">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxOtherAsian'}
              name={borrowerIndex + 'checkboxOtherAsian'}
              checked={this.state[borrowerIndex + 'checkboxOtherAsian']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxOtherAsian'}
              style={{ marginLeft: '10px' }}
            >
              Other Asian - Print race:{' '}
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-11 offset-1">
            <input
              id={borrowerIndex + 'otherAsian'}
              name={borrowerIndex + 'otherAsian'}
              type="text"
              value={this.state[borrowerIndex + 'otherAsian'] || ''}
              onChange={this.captureInput}
              autoComplete="off"
              style={{ width: '300px' }}
            />
            <br />
            For example: Hmong, Loatian, Thai, Pakistani, Cambodian, and so on.
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxBlack'}
              name={borrowerIndex + 'checkboxBlack'}
              checked={this.state[borrowerIndex + 'checkboxBlack']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxBlack'}
              style={{ marginLeft: '10px' }}
            >
              Black or African American
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxHawaiian'}
              name={borrowerIndex + 'checkboxHawaiian'}
              checked={this.state[borrowerIndex + 'checkboxHawaiian']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxHawaiian'}
              style={{ marginLeft: '10px' }}
            >
              Native Hawaiian or Other Pacific Islander
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-3 offset-1">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxNativeHawaiian'}
              name={borrowerIndex + 'checkboxNativeHawaiian'}
              checked={this.state[borrowerIndex + 'checkboxNativeHawaiian']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxNativeHawaiian'}
              style={{ marginLeft: '10px' }}
            >
              Native Hawaiian
            </label>
          </div>
          <div className="col-3">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxGuam'}
              name={borrowerIndex + 'checkboxGuam'}
              checked={this.state[borrowerIndex + 'checkboxGuam']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxGuam'}
              style={{ marginLeft: '10px' }}
            >
              Guamanian or Chamorro
            </label>
          </div>
          <div className="col-3">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxSamoan'}
              name={borrowerIndex + 'checkboxSamoan'}
              checked={this.state[borrowerIndex + 'checkboxSamoan']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxSamoan'}
              style={{ marginLeft: '10px' }}
            >
              Samoan
            </label>
          </div>
          <div className="col-2" />
        </div>
        <br />

        <div className="row">
          <div className="col-3 offset-1">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxOtherPacific'}
              name={borrowerIndex + 'checkboxOtherPacific'}
              checked={this.state[borrowerIndex + 'checkboxOtherPacific']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxOtherPacific'}
              style={{ marginLeft: '10px' }}
            >
              Other Pacific Islander - Print race:{' '}
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-11 offset-1">
            <input
              id={borrowerIndex + 'otherPacific'}
              name={borrowerIndex + 'otherPacific'}
              type="text"
              value={this.state[borrowerIndex + 'otherPacific'] || ''}
              onChange={this.captureInput}
              autoComplete="off"
              style={{ width: '300px' }}
            />
            <br />
            For example: Fijian, Tongan, and so on.
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxWhite'}
              name={borrowerIndex + 'checkboxWhite'}
              checked={this.state[borrowerIndex + 'checkboxWhite']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxWhite'}
              style={{ marginLeft: '10px' }}
            >
              White
            </label>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12">
            <input
              type="checkbox"
              id={borrowerIndex + 'checkboxDeclineRace'}
              name={borrowerIndex + 'checkboxDeclineRace'}
              checked={this.state[borrowerIndex + 'checkboxDeclineRace']}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label
              htmlFor={borrowerIndex + 'checkboxDeclineRace'}
              style={{ marginLeft: '10px' }}
            >
              I do not wish to provide this information
            </label>
          </div>
        </div>
        <br />
        <hr />
        <br />

        <div className="row">
          <div className="col-12">
            <div>
              <button
                className="btn btn-primary"
                onClick={() => this.nextDemographicsPage()}
              >
                Next Page
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  pageReview() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <h4>Loan Wizard (Review)</h4>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        this.props.history.push('/loan-wizard/demographics')
                      }
                    >
                      ← Back
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            Please review the following information for accuracy before
            submission
          </div>

          <div className="col-12">
            <h3 className="border-bottom-2 border-gray text-capitalize my-4">
              Review and Submit
            </h3>
            <table>
              <tbody>
                <tr>
                  <td width="300px">Loan Purpose: </td>
                  <td>{this.state.loanPurpose}</td>
                </tr>
                <tr>
                  <td>Occupancy Type: </td>
                  <td>{this.state.occupancyType}</td>
                </tr>

                {(this.state.loanPurpose === 'Refinance' ||
                  this.state.propertyInfoReady) && (
                  <>
                    <tr>
                      <td>Property Address: </td>
                      <td>{this.state.propertyAddress}</td>
                    </tr>
                    <tr>
                      <td>City: </td>
                      <td>{this.state.propertyCity}</td>
                    </tr>
                    <tr>
                      <td>State: </td>
                      <td>{this.state.propertyState}</td>
                    </tr>
                    <tr>
                      <td>Zip Code: </td>
                      <td>{this.state.propertyZip}</td>
                    </tr>
                  </>
                )}

                {this.state.loanPurpose === 'Refinance' && (
                  <>
                    <tr>
                      <td>Original Purchase Price: </td>
                      <td>{this.state.orgPrice}</td>
                    </tr>
                    <tr>
                      <td>Amount Existing Liens: </td>
                      <td>{this.state.extLiens}</td>
                    </tr>
                  </>
                )}

                {/* Primary Borrower Info */}
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Borrower Name: </td>
                  <td>{this.state.borrowerName}</td>
                </tr>
                <tr>
                  <td>Phone: </td>
                  <td>{this.state.phone}</td>
                </tr>
                <tr>
                  <td>Email: </td>
                  <td>{this.state.email}</td>
                </tr>
                <tr>
                  <td>Date of Birth: </td>
                  <td>{this.state.dob}</td>
                </tr>

                {/* Residence Records for Primary Borrower */}
                {Array.from(Array(this.state.residenceRecords[0])).map(
                  (TableRow, index) => (
                    <>
                      <tr>
                        <td colSpan="2">
                          <hr />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Primary Borrower's Residence History #{index + 1}
                        </td>
                      </tr>
                      <tr>
                        <td>Address: </td>
                        <td>{this.state['0propertyAddress' + index]}</td>
                      </tr>
                      <tr>
                        <td>City: </td>
                        <td>{this.state['0propertyCity' + index]}</td>
                      </tr>
                      <tr>
                        <td>State: </td>
                        <td>{this.state['0propertyState' + index]}</td>
                      </tr>
                      <tr>
                        <td>Zip: </td>
                        <td>{this.state['0propertyZip' + index]}</td>
                      </tr>
                      <tr>
                        <td>Property Ownership: </td>
                        <td>
                          {this.getPropertyOwnership(
                            this.state['0propertyOwnership' + index]
                          )}
                        </td>
                      </tr>
                    </>
                  )
                )}

                {/* Employment Records for Primary Borrower */}
                {Array.from(Array(this.state.employmentRecords[0])).map(
                  (TableRow, index) => (
                    <>
                      <tr>
                        <td colSpan="2">
                          <hr />
                        </td>
                      </tr>
                      <tr>
                        <td>Primary Borrower's Employer #{index + 1}: </td>
                        <td>{this.state['0employer' + index]}</td>
                      </tr>
                      <tr>
                        <td>Position/Title: </td>
                        <td>{this.state['0position' + index]}</td>
                      </tr>
                      <tr>
                        <td>Type of Business: </td>
                        <td>{this.state['0type' + index]}</td>
                      </tr>
                      <tr>
                        <td>Contact Person: </td>
                        <td>{this.state['0contactPerson' + index]}</td>
                      </tr>
                      <tr>
                        <td>Contact Phone: </td>
                        <td>{this.state['0contactPhone' + index]}</td>
                      </tr>
                      <tr>
                        <td>Years on Job: </td>
                        <td>{this.state['0yearsJob' + index]}</td>
                      </tr>
                      <tr>
                        <td>Years in Profession: </td>
                        <td>{this.state['0yearsProf' + index]}</td>
                      </tr>
                    </>
                  )
                )}

                {/* Property Records for Primary Borrower */}
                {Array.from(Array(this.state.propertyRecords[0])).map(
                  (TableRow, index) => (
                    <>
                      <tr>
                        <td colSpan="2">
                          <hr />
                        </td>
                      </tr>
                      <tr>
                        <td>Primary Borrower's Properties #{index + 1}: </td>
                      </tr>
                      <tr>
                        <td>Address: </td>
                        <td>{this.state['0ownedPropertyAddress' + index]}</td>
                      </tr>
                      <tr>
                        <td>City: </td>
                        <td>{this.state['0ownedPropertyCity' + index]}</td>
                      </tr>
                      <tr>
                        <td>State: </td>
                        <td>{this.state['0ownedPropertyState' + index]}</td>
                      </tr>
                      <tr>
                        <td>Zip Code: </td>
                        <td>{this.state['0ownedPropertyZip' + index]}</td>
                      </tr>
                      <tr>
                        <td>Type: </td>
                        <td>
                          {this.getPropertyType(
                            this.state['0ownedPropertyType' + index]
                          )}
                        </td>
                      </tr>
                    </>
                  )
                )}

                {/* Declarations for Primary Borrower */}
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Primary Borrower's Declarations: </td>
                </tr>
                <tr>
                  <td>(a) Outstanding judgments: </td>
                  <td>
                    {this.state['0checkboxAYes']
                      ? 'Yes'
                      : this.state['0checkboxANo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(b) Bankrupt within 7 years: </td>
                  <td>
                    {this.state['0checkboxBYes']
                      ? 'Yes'
                      : this.state['0checkboxBNo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(c) Foreclosure within 7 years: </td>
                  <td>
                    {this.state['0checkboxCYes']
                      ? 'Yes'
                      : this.state['0checkboxCNo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(d) Party to lawsuit: </td>
                  <td>
                    {this.state['0checkboxDYes']
                      ? 'Yes'
                      : this.state['0checkboxDNo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(e) Obligations on defaulted loan: </td>
                  <td>
                    {this.state['0checkboxEYes']
                      ? 'Yes'
                      : this.state['0checkboxENo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(f) Delinquent on any loan: </td>
                  <td>
                    {this.state['0checkboxFYes']
                      ? 'Yes'
                      : this.state['0checkboxFNo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(g) Obligations for alimony or child support: </td>
                  <td>
                    {this.state['0checkboxGYes']
                      ? 'Yes'
                      : this.state['0checkboxGNo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(h) Any part of down payment borrowed: </td>
                  <td>
                    {this.state['0checkboxHYes']
                      ? 'Yes'
                      : this.state['0checkboxHNo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(i) Co-maker or endorser on a note: </td>
                  <td>
                    {this.state['0checkboxIYes']
                      ? 'Yes'
                      : this.state['0checkboxINo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(notes): </td>
                  <td>{this.state['0notes']}</td>
                </tr>
                <tr>
                  <td>(j) US Citizen: </td>
                  <td>
                    {this.state['0checkboxJYes']
                      ? 'Yes'
                      : this.state['0checkboxJNo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(k) Permanent resident alien: </td>
                  <td>
                    {this.state['0checkboxKYes']
                      ? 'Yes'
                      : this.state['0checkboxKNo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(l) Intend to occupy as primary residence: </td>
                  <td>
                    {this.state['0checkboxLYes']
                      ? 'Yes'
                      : this.state['0checkboxLNo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(m) Owned property in past 3 years: </td>
                  <td>
                    {this.state['0checkboxMYes']
                      ? 'Yes'
                      : this.state['0checkboxMNo']
                      ? 'No'
                      : 'Unspecified'}
                  </td>
                </tr>
                <tr>
                  <td>(m-1) Property Type: </td>
                  <td>{this.state['0propTypeDec']}</td>
                </tr>
                <tr>
                  <td>(m-2) Title Held As: </td>
                  <td>{this.state['0propTitleDec']}</td>
                </tr>

                {/* Demographics for Primary Borrower */}
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Primary Borrower's Demographics: </td>
                </tr>
                <tr>
                  <td>Ethnicity: </td>
                  <td>
                    {this.state['0checkboxHispanic'] &&
                      'Hispanic (' +
                        (this.state['0checkboxMexican'] ? ' Mexican' : '') +
                        (this.state['0checkboxPuertoRican']
                          ? ' Puerto Rican'
                          : '') +
                        (this.state['0checkboxCuban'] ? ' Cuban' : '') +
                        (this.state['0checkboxOtherHispanic']
                          ? ' ' + this.state['0otherHispanic']
                          : '') +
                        ' ) '}
                    {this.state['0checkboxNotHispanic'] && 'Not-Hispanic '}
                    {this.state['0checkboxDeclineEthnicity'] &&
                      'Declined to Specify'}
                  </td>
                </tr>
                <tr>
                  <td>Sex: </td>
                  <td>
                    {this.state['0checkboxMale']
                      ? 'Male'
                      : this.state['0checkboxFemale']
                      ? 'Female'
                      : 'Declined to Specify'}
                  </td>
                </tr>
                <tr>
                  <td>Race: </td>
                  <td>
                    {this.state['0checkboxAmericanIndian'] &&
                      'American Indian or Alaska Native ( ' +
                        this.state['0indianTribe'] +
                        ' ) '}
                    {this.state['0checkboxAsian'] &&
                      'Asian ( ' +
                        (this.state['0checkboxAsianIndian']
                          ? ' Asian-Indian'
                          : '') +
                        (this.state['0checkboxChinese'] ? ' Chinese' : '') +
                        (this.state['0checkboxFilipino'] ? ' Filipino' : '') +
                        (this.state['0checkboxJapanese'] ? ' Japanese' : '') +
                        (this.state['0checkboxKorean'] ? ' Korean' : '') +
                        (this.state['0checkboxVietnamese']
                          ? ' Vietnamese'
                          : '') +
                        (this.state['0checkboxOtherAsian']
                          ? ' ' + this.state['0otherAsian']
                          : '') +
                        ' ) '}
                    {this.state['0checkboxBlack'] &&
                      'Black or African American '}
                    {this.state['0checkboxHawaiian'] &&
                      'Native Hawaiian or Other Pacific Islander ( ' +
                        (this.state['0checkboxNativeHawaiian']
                          ? ' Native Hawaiian'
                          : '') +
                        (this.state['0checkboxGuam']
                          ? ' Guamanian or Chamorro'
                          : '') +
                        (this.state['0checkboxSamoan'] ? ' Samoan' : '') +
                        (this.state['0checkboxOtherPacific']
                          ? ' ' + this.state['0otherPacific']
                          : '') +
                        ' ) '}
                    {this.state['0checkboxWhite'] && 'White '}
                    {this.state['0checkboxDeclineRace'] &&
                      'Declined to Specify'}
                  </td>
                </tr>

                {/* Array of Co-Borrower Info(s) */}
                {this.state.coborrowers > 0 &&
                  Array.from(Array(this.state.coborrowers)).map(
                    (TableRow, index) => (
                      <>
                        <tr>
                          <td colSpan="2">
                            <hr />
                          </td>
                        </tr>
                        <tr>
                          <td>Co-Borrower #{index + 1}: </td>
                          <td>{this.state['borrowerName' + index]}</td>
                        </tr>
                        <tr>
                          <td>Phone: </td>
                          <td>{this.state['borrowerPhone' + index]}</td>
                        </tr>
                        <tr>
                          <td>Email:</td>
                          <td>{this.state['borrowerEmail' + index]}</td>
                        </tr>
                        <tr>
                          <td>Date of Birth:</td>
                          <td>{this.state['borrowerDOB' + index]}</td>
                        </tr>

                        {/* For Each Co-Borrower, Also Have An Array of Residential Info */}
                        {Array.from(
                          Array(this.state.residenceRecords[index + 1])
                        ).map((TableRow, index2) => (
                          <>
                            <tr>
                              <td colSpan="2">
                                <hr />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Co-Borrower #{index + 1}'s Residence #
                                {index2 + 1}
                              </td>
                            </tr>
                            <tr>
                              <td>Address: </td>
                              <td>
                                {
                                  this.state[
                                    index + 1 + 'propertyAddress' + index2
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>City: </td>
                              <td>
                                {
                                  this.state[
                                    index + 1 + 'propertyCity' + index2
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>State: </td>
                              <td>
                                {
                                  this.state[
                                    index + 1 + 'propertyState' + index2
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Zip Code: </td>
                              <td>
                                {this.state[index + 1 + 'propertyZip' + index2]}
                              </td>
                            </tr>
                            <tr>
                              <td>Property Ownership: </td>
                              <td>
                                {this.getPropertyOwnership(
                                  this.state[
                                    index + 1 + 'propertyOwnership' + index2
                                  ]
                                )}
                              </td>
                            </tr>
                          </>
                        ))}

                        {/* For Each Co-Borrower, Also Have An Array of Employment Info */}
                        {Array.from(
                          Array(this.state.employmentRecords[index + 1])
                        ).map((TableRow, index2) => (
                          <>
                            <tr>
                              <td colSpan="2">
                                <hr />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Co-Borrower #{index + 1}'s Employer #
                                {index2 + 1}:{' '}
                              </td>
                              <td>
                                {this.state[index + 1 + 'employer' + index2]}
                              </td>
                            </tr>
                            <tr>
                              <td>Position/Title: </td>
                              <td>
                                {this.state[index + 1 + 'position' + index2]}
                              </td>
                            </tr>
                            <tr>
                              <td>Type of Business: </td>
                              <td>{this.state[index + 1 + 'type' + index2]}</td>
                            </tr>
                            <tr>
                              <td>Contact Person: </td>
                              <td>
                                {
                                  this.state[
                                    index + 1 + 'contactPerson' + index2
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Contact Phone: </td>
                              <td>
                                {
                                  this.state[
                                    index + 1 + 'contactPhone' + index2
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Years on Job: </td>
                              <td>
                                {this.state[index + 1 + 'yearsJob' + index2]}
                              </td>
                            </tr>
                            <tr>
                              <td>Years in Profession: </td>
                              <td>
                                {this.state[index + 1 + 'yearsProf' + index2]}
                              </td>
                            </tr>
                          </>
                        ))}

                        {/* For Each Co-Borrower, Also Have An Array of Owned Properties */}
                        {Array.from(
                          Array(this.state.propertyRecords[index + 1])
                        ).map((TableRow, index2) => (
                          <>
                            <tr>
                              <td colSpan="2">
                                <hr />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Co-Borrower #{index + 1}'s Properties #
                                {index2 + 1}:{' '}
                              </td>
                            </tr>
                            <tr>
                              <td>Address: </td>
                              <td>
                                {
                                  this.state[
                                    index + 1 + 'ownedPropertyAddress' + index2
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>City: </td>
                              <td>
                                {
                                  this.state[
                                    index + 1 + 'ownedPropertyCity' + index2
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>State: </td>
                              <td>
                                {
                                  this.state[
                                    index + 1 + 'ownedPropertyState' + index2
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Zip Code: </td>
                              <td>
                                {
                                  this.state[
                                    index + 1 + 'ownedPropertyZip' + index2
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Type: </td>
                              <td>
                                {this.getPropertyType(
                                  this.state[
                                    index + 1 + 'ownedPropertyType' + index2
                                  ]
                                )}
                              </td>
                            </tr>
                          </>
                        ))}

                        {/* Declarations for Co-Borrower */}
                        <tr>
                          <td colSpan="2">
                            <hr />
                          </td>
                        </tr>
                        <tr>
                          <td>Co-Borrower #{index + 1}'s Declarations: </td>
                        </tr>
                        <tr>
                          <td>(a) Outstanding judgments: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxAYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxANo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(b) Bankrupt within 7 years: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxBYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxBNo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(c) Foreclosure within 7 years: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxCYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxCNo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(d) Party to lawsuit: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxDYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxDNo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(e) Obligations on defaulted loan: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxEYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxENo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(f) Delinquent on any loan: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxFYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxFNo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            (g) Obligations for alimony or child support:{' '}
                          </td>
                          <td>
                            {this.state[index + 1 + 'checkboxGYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxGNo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(h) Any part of down payment borrowed: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxHYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxHNo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(i) Co-maker or endorser on a note: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxIYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxINo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(notes): </td>
                          <td>{this.state[index + 1 + 'notes']}</td>
                        </tr>
                        <tr>
                          <td>(j) US Citizen: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxJYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxJNo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(k) Permanent resident alien: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxKYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxKNo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(l) Intend to occupy as primary residence: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxLYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxLNo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(m) Owned property in past 3 years: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxMYes']
                              ? 'Yes'
                              : this.state[index + 1 + 'checkboxMNo']
                              ? 'No'
                              : 'Unspecified'}
                          </td>
                        </tr>
                        <tr>
                          <td>(m-1) Property Type: </td>
                          <td>{this.state[index + 1 + 'propTypeDec']}</td>
                        </tr>
                        <tr>
                          <td>(m-2) Title Held As: </td>
                          <td>{this.state[index + 1 + 'propTitleDec']}</td>
                        </tr>

                        {/* Demographics for Primary Borrower */}
                        <tr>
                          <td colSpan="2">
                            <hr />
                          </td>
                        </tr>
                        <tr>
                          <td>Primary Borrower's Demographics: </td>
                        </tr>
                        <tr>
                          <td>Ethnicity: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxHispanic'] &&
                              'Hispanic (' +
                                (this.state[index + 1 + 'checkboxMexican']
                                  ? ' Mexican'
                                  : '') +
                                (this.state[index + 1 + 'checkboxPuertoRican']
                                  ? ' Puerto Rican'
                                  : '') +
                                (this.state[index + 1 + 'checkboxCuban']
                                  ? ' Cuban'
                                  : '') +
                                (this.state[index + 1 + 'checkboxOtherHispanic']
                                  ? ' ' +
                                    this.state[index + 1 + 'otherHispanic']
                                  : '') +
                                ' ) '}
                            {this.state[index + 1 + 'checkboxNotHispanic'] &&
                              'Not-Hispanic '}
                            {this.state[
                              index + 1 + 'checkboxDeclineEthnicity'
                            ] && 'Declined to Specify'}
                          </td>
                        </tr>
                        <tr>
                          <td>Sex: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxMale']
                              ? 'Male'
                              : this.state[index + 1 + 'checkboxFemale']
                              ? 'Female'
                              : 'Declined to Specify'}
                          </td>
                        </tr>
                        <tr>
                          <td>Race: </td>
                          <td>
                            {this.state[index + 1 + 'checkboxAmericanIndian'] &&
                              'American Indian or Alaska Native ( ' +
                                this.state[index + 1 + 'indianTribe'] +
                                ' ) '}
                            {this.state[index + 1 + 'checkboxAsian'] &&
                              'Asian ( ' +
                                (this.state[index + 1 + 'checkboxAsianIndian']
                                  ? ' Asian-Indian'
                                  : '') +
                                (this.state[index + 1 + 'checkboxChinese']
                                  ? ' Chinese'
                                  : '') +
                                (this.state[index + 1 + 'checkboxFilipino']
                                  ? ' Filipino'
                                  : '') +
                                (this.state[index + 1 + 'checkboxJapanese']
                                  ? ' Japanese'
                                  : '') +
                                (this.state[index + 1 + 'checkboxKorean']
                                  ? ' Korean'
                                  : '') +
                                (this.state[index + 1 + 'checkboxVietnamese']
                                  ? ' Vietnamese'
                                  : '') +
                                (this.state[index + 1 + 'checkboxOtherAsian']
                                  ? ' ' + this.state[index + 1 + 'otherAsian']
                                  : '') +
                                ' ) '}
                            {this.state[index + 1 + 'checkboxBlack'] &&
                              'Black or African American '}
                            {this.state[index + 1 + 'checkboxHawaiian'] &&
                              'Native Hawaiian or Other Pacific Islander ( ' +
                                (this.state[
                                  index + 1 + 'checkboxNativeHawaiian'
                                ]
                                  ? ' Native Hawaiian'
                                  : '') +
                                (this.state[index + 1 + 'checkboxGuam']
                                  ? ' Guamanian or Chamorro'
                                  : '') +
                                (this.state[index + 1 + 'checkboxSamoan']
                                  ? ' Samoan'
                                  : '') +
                                (this.state[index + 1 + 'checkboxOtherPacific']
                                  ? ' ' + this.state[index + 1 + 'otherPacific']
                                  : '') +
                                ' ) '}
                            {this.state[index + 1 + 'checkboxWhite'] &&
                              'White '}
                            {this.state[index + 1 + 'checkboxDeclineRace'] &&
                              'Declined to Specify'}
                          </td>
                        </tr>
                      </>
                    )
                  )}
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            <input
              type="checkbox"
              id="checkboxTrue"
              name="checkboxTrue"
              checked={this.state.checkboxTrue}
              onChange={this.toggleCheckBox}
              style={{ position: 'relative', top: '1px' }}
            />
            <label htmlFor="checkboxTrue" style={{ marginLeft: '10px' }}>
              I certify that the information provided above is true, to the best
              of my knowledge and belief.
            </label>
          </div>

          <div className="col-12">
            <div>
              <button
                className="btn btn-primary"
                onClick={() => this.props.history.push('/loan-wizard/sign')}
                disabled={!this.state.checkboxTrue}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  pageSign() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <h4>Loan Wizard (Sign and Save)</h4>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        this.props.history.push('/loan-wizard/review')
                      }
                    >
                      ← Back
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-12">
            <hr />
            <br />
            Please type your name as the primary borrower, as a digital
            signature to confirm submission of the provided information.
            <br />
            <br />
            <hr />
          </div>

          <div className="col-12">
            <input
              id="signName"
              name="signName"
              type="text"
              value={this.state.signName}
              onChange={this.captureInput}
              autoComplete="off"
              style={{ width: '300px' }}
            />
            <br />
            {this.state.borrowerName}
            <br />
            (Type Name Above - Must Match Exactly)
          </div>

          <div className="col-12">
            <div className="row p-0">
              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.submitData()}
                    disabled={this.state.signName !== this.state.borrowerName}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  pageComplete() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4>Loan Wizard (Complete)</h4>
          </div>
          <div className="col-12">
            <hr />
            <br />
            Your information has been sucessfully submitted! Once the
            information has been reviewed, a loan officer will contact you soon
            to follow up and go over any questions you may have, or request any
            additional information we will need. Thank you!
            <br />
            <br />
            <hr />
          </div>

          <div className="col-12">
            <div className="row p-0">
              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.props.history.push('/')}
                  >
                    Return Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    switch (this.state.currentPage) {
      case 'purpose':
        return this.pagePurpose();
      case 'occupancy':
        return this.pageOccupancy();
      case 'address':
        return this.pageAddress();
      case 'borrower':
        return this.pageBorrower();
      case 'residence':
        return this.pageResidence();
      case 'employment':
        return this.pageEmployment();
      case 'properties':
        return this.pageProperties();
      case 'declarations':
        return this.pageDeclarations();
      case 'demographics':
        return this.pageDemographics();
      case 'review':
        return this.pageReview();
      case 'sign':
        return this.pageSign();
      case 'complete':
        return this.pageComplete();
      default:
        break;
    }
    return <></>;
  }
}

export default withRouter(LoanWizard);
