import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { SessionProvider } from './helpers/sessionContext';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/js/bootstrap.min.js';

import './scss/custom.scss';
import './scss/animations.scss';
import 'animate.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './scss/layouts.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <SessionProvider>
    <App />
  </SessionProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
