import React from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalCard = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  z-index: 9999;
  width: ${p => (p.width ? p.width : '350px')};
  min-height: ${p => (p.height ? p.height : '100px')};
  max-height: 90vh;
  background-color: ${p => p.theme.colors.white};
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const ModalBody = styled.div`
  margin: 0;
  padding: 1rem;
  margin-top: 1rem;
  flex-grow: 1;
`;

export default function ModalConfirm(props) {
  return props.show ? (
    <ModalWrapper>
      <ModalCard width={props.width} height={props.height}>
        <button
          type="button"
          style={{ position: 'relative', left: '300px', top: '-5px' }}
          className="btn-close"
          onClick={props.onDecline}
          aria-label="Close"
        />
        <ModalBody style={{ position: 'relative', top: '-15px' }}>
          {props.children}
        </ModalBody>
        <div className="modal-footer">
          {!props.hideCancelBtn && (
            <button
              type="button"
              className="btn btn-light"
              onClick={props.onDecline}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </button>
          )}
          <button
            type="button"
            onClick={props.onAccept}
            disabled={props.onAcceptDisabled}
            className="btn btn-primary"
          >
            Confirm
          </button>
        </div>
      </ModalCard>
    </ModalWrapper>
  ) : (
    <></>
  );
}
