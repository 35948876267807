import React from 'react';
import styled from 'styled-components';

export const Body = styled.div`
  padding: 0.15rem;
  width: 100%;
  line-height: 25px;

  & div {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }
  & label {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    &:hover {
      background-color: ${p => p.theme.colors.gray100};
    }
  }
  & input[type='date'] {
    width: 100%;
  }
  & input[type='text'] {
    width: 100%;
  }
  & textarea {
    width: 100%;
  }
`;

export const SmallBubble = styled.div`
  display: none;
  background-color: ${p => p.theme.colors.gray800};
  white-space: pre-wrap;
  text-align: center;
  font-size: 13px;
  color: white;
  padding: 8px;
  border-radius: 5px;
  max-width: 350px;
  min-width: 350px;
  line-height: 16px;
  & p {
    color: ${p => p.theme.colors.gray100};
  }
`;

export const TooltipWrapper = styled.span`
  position: relative;
  pointer-events: auto;
  &:hover div {
    display: block;
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
    white-space: pre-wrap;
    z-index: 9999;
    & p {
      margin: 0;
      overflow-x: hidden;
      white-space: pre-wrap;
    }
  }
`;

export const TableZero = styled.table`
  margin: 0px;
  padding: 0px;
`;

export function Bubble({ children, failed, tip }) {
  return (
    <TooltipWrapper>
      {/* this element needs to be a table and not a div, because the hover component of the
      tooltipwrapper will mess up the display of the outer element in that case */}
      <table>
        <tbody>
          <tr>
            <td
              style={{
                lineHeight: '16px',
                borderRadius: '6px',
                background: failed ? 'red' : 'green',
                color: 'white',
                fontSize: '11px',
                textAlign: 'center',
                marginTop: '0rem',
                marginBottom: '0rem',
                padding: '3px',
                minWidth: '80px',
                maxWidth: '80px',
              }}
            >
              {children}
            </td>
          </tr>
        </tbody>
      </table>
      {tip && <SmallBubble>{tip}</SmallBubble>}
    </TooltipWrapper>
  );
}

export function Verify({ tip }) {
  return (
    <TooltipWrapper>
      <span className="badge bg-success me-2 p-2" style={{ width: '45px' }}>
        Verify
      </span>
      {tip && <i className="bi bi-asterisk" 
        style={{color:'red', fontSize:'9px', position:'relative', 
          top:'-16px', marginTop:'-16px', marginLeft:'-62px', paddingRight:'52px'}} />
      }
      {tip && <SmallBubble style={{position:'absolute', top:'30px', left:'20px'}}>{tip}</SmallBubble>}
    </TooltipWrapper>
  );
}

export function Task({ tip }) {
  return (
    <TooltipWrapper>
      <span className="badge bg-info me-2 p-2" style={{ width: '45px' }}>
        Task
      </span>
      {tip && <i className="bi bi-asterisk" 
        style={{color:'red', fontSize:'9px', position:'relative', 
          top:'-16px', marginTop:'-16px', marginLeft:'-62px', paddingRight:'52px'}} />
      }
      {tip && <SmallBubble style={{position:'absolute', top:'30px', left:'20px'}}>{tip}</SmallBubble>}
    </TooltipWrapper>
  );
}

export function Email({ tip }) {
  return (
    <TooltipWrapper>
      <span
        className="badge bg-warning me-2 p-2"
        style={{ width: '45px', color: 'navy' }}
      >
        Email
      </span>
      {tip && <i className="bi bi-asterisk" 
        style={{color:'red', fontSize:'9px', position:'relative', 
          top:'-16px', marginTop:'-16px', marginLeft:'-62px', paddingRight:'52px'}} />
      }
      {tip && <SmallBubble style={{position:'absolute', top:'30px', left:'20px'}}>{tip}</SmallBubble>}
    </TooltipWrapper>
  );
}

export function AutoCheck({ tip, passed }) {
  return (
    <TooltipWrapper>
      {/* this element needs to be a table and not a div, because the hover component of the
      tooltipwrapper will mess up the display of the outer element in that case */}
      <table className="w-100 h-100">
        <tbody>
          <tr>
            <td
              className={`small alert text-center mb-0 border-0 p-1 p-lg-2 ${
                passed ? 'alert-info' : 'alert-danger'
              }`}
              role="alert"
            >
              Auto-Check {passed ? 'Passed' : 'Failed'}
            </td>
          </tr>
        </tbody>
      </table>
      {!passed && tip && <SmallBubble style={{position:'absolute', top:'65px', left:'-250px'}}>{tip}</SmallBubble>}
    </TooltipWrapper>
  );
}

export function SignOff({ tip, user, date }) {
  return (
    <TooltipWrapper>
      {/* this element needs to be a table and not a div, because the hover component of the
      tooltipwrapper will mess up the display of the outer element in that case */}
      <table className="w-100 h-100">
        <tbody>
          <tr>
            <td
              className="small alert text-center mb-0 alert-success border-0 p-1 p-lg-2"
              role="alert"
            >
              {user}
              <br/>
              {date}
            </td>
          </tr>
        </tbody>
      </table>
      {tip && <SmallBubble style={{position:'absolute', top:'65px', left:'-250px'}}>{tip}</SmallBubble>}
    </TooltipWrapper>
  );
}

export const calculateMultiple = (currencyAmount, multiple) => {
  try {
    var amt =
      parseFloat(currencyAmount.replaceAll(',', '').replace('$', '')) *
      multiple;
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(amt);
  } catch {
    return '$---';
  }
};

export function FindLabelFromName(name) {
  var labels = document.getElementsByTagName('label');
  for (var i = 0; i < labels.length; i++) {
    if (labels[i].htmlFor === name) return labels[i].textContent;
  }
}
