import React from 'react';

export default function FormInput({
  className = '',
  type,
  name,
  value,
  label,
  error,
  onChange,
  tabIndex,
}) {
  return (
    <div className="mb-1 mb-md-3">
      <label htmlFor={name} className="form-label text-muted mb-0 mt-2">
        {label}
      </label>
      <input
        tabIndex={tabIndex}
        type={type}
        onChange={onChange}
        name={name}
        value={value}
        className={`form-control ${className} ${
          !!error && 'border border-danger'
        }`}
        id={name}
        placeholder="Enter.."
      />
      {error && <p className="text-danger text-capitalize">{error}</p>}
    </div>
  );
}
