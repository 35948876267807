import 'react-dropzone-uploader/dist/styles.css';

import React, { useState } from 'react';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';

export default function EscrowSearch() {
  const [fileID, setFileID] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const findFile = async e => {
    e.preventDefault();
    // skip if no file id provided
    if (!fileID) {
      setErrorMessage('Please Provide A File ID To Search');
      return;
    }

    try {
      const response = await axios.get('/getEscrowFile', {
        params: { pFileID: fileID },
      });
      if (response.data === 'Found') history.push('/escrow-file/' + fileID);
      else setErrorMessage('Provided File ID Does Not Match Any Records');
    } catch (e) {
      setErrorMessage(e.response.message);
    }
  };

  return (
    <div className="bg-light vh-100 position-relative">
      <div className="border rounded-3 p-5 bg-white shadow-lg position-absolute top-50 start-50 translate-middle text-center">
        <div className="vstack gap-3">
          <h1 className="fs-2">Escrow</h1>
          <input
            className="form-control form-control-lg"
            id="fileID"
            name="fileID"
            label="Loan File ID#"
            onChange={e => setFileID(e.target.value)}
            value={fileID}
            placeholder="Enter File ID"
          />
          {errorMessage && (
            <div className="text-danger text-center">{errorMessage}</div>
          )}
          <button
            onClick={findFile}
            className="btn btn-primary"
            id="findFileBtn"
          >
            Find File
          </button>
          <Link to="/">Back to Login</Link>
        </div>
      </div>
    </div>
  );
}
