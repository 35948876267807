import React, { Component } from 'react';
import { formatCurrency2 } from '../helpers/utils';
import { TinyTooltip } from './Tooltip';

class ProgramRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRate: '',
    };
  }

  selectRow(rate, price, total, points) {
    this.setState({ selectedRate: rate });
    if (this.props.chooseRate) {
      this.props.chooseRate(rate, price, total, points);
    }
  }

  render() {
    var i;
    if (!this.props.programRates || this.props.programRates.length === 0) {
      return <span>Rates Not Available</span>;
    }
    var totalRateAdjs = 0;
    for (i = 0; i < this.props.rateAdjustments.length; i++) {
      totalRateAdjs += this.props.rateAdjustments[i].amount;
    }
    var totalPriceAdjs = 0;
    for (i = 0; i < this.props.priceAdjustments.length; i++) {
      totalPriceAdjs += this.props.priceAdjustments[i].amount;
    }
    const rateCap = parseFloat(this.props.rateCap);

    const loanAmount = parseInt(
      this.props.firstLoan.toString().replace('$', '').replaceAll(',', '')
    );
    let numPayments = parseInt(this.props.programTerm || 0);
    const rateRows = [];
    if (this.props.programRates) {
      for (let i = 0; i < this.props.programRates.length; i++) {
        const rate = this.props.programRates[i].rate + totalRateAdjs;
        const price = this.props.programRates[i].price;
        const adj = totalPriceAdjs;
        var final = parseFloat(price) - totalPriceAdjs;
        if(final > rateCap) { final = rateCap; }
        const rateSplit = parseFloat(rate) / 100 / 12;
        // Monthly Payment: P = L[c(1 + c)^n]/[(1 + c)^n - 1]
        const paymentAmount = this.props.programCode.endsWith("I") ?
          (loanAmount * rateSplit) :
          loanAmount *
          ((rateSplit * Math.pow(1 + rateSplit, numPayments)) /
            (Math.pow(1 + rateSplit, numPayments) - 1));
        rateRows.push({
          rate: parseFloat(rate).toFixed(3),
          rateAdjustmentExists: totalRateAdjs > 0,
          rateTooltip:
            'Base: ' +
            parseFloat(this.props.programRates[i].rate).toFixed(3) +
            ' % + Adjustment: ' +
            parseFloat(totalRateAdjs).toFixed(3) +
            ' %',
          price: 100 - parseFloat(price).toFixed(3),
          adj: parseFloat(adj).toFixed(3),
          total: 100 - parseFloat(final).toFixed(3),
          creditPoint: formatCurrency2(
            (loanAmount / 100) * (100 - parseFloat(final)) * -1
          ),
          monthly: formatCurrency2(paymentAmount),
        });
      }
    }

    return (
      <table className="table">
        <tbody
          style={{
            display: 'block',
            overflow: 'auto',
            width: '570px',
            maxHeight: '500px',
            borderRadius: '5px',
            border: '1px lightgray solid',
          }}
        >
          <tr style={{ background: 'hsl(0,0%,95%)' }}>
            <th style={{ paddingLeft: '15px' }} width="80px">
              Rate
            </th>
            <th className="text-center" width="80px">
              Price
            </th>
            <th className="text-center" width="80px">
              Adjustment
            </th>
            <th className="text-center" width="80px">
              Total
            </th>
            <th className="text-center" width="110px">
              Credit/Point
            </th>
            <th className="text-center" width="110px">
              Monthly
            </th>
            <th />
          </tr>
          {rateRows.map(row => (
            <tr
              key={row.rate}
              style={{
                cursor: 'pointer',
                background:
                  row.rate === this.state.selectedRate
                    ? 'hsl(358,79%,92%)'
                    : 'white',
              }}
              onClick={() =>
                this.selectRow(row.rate, row.price, row.total, row.creditPoint)
              }
            >
              {row.rateAdjustmentExists ? (
                <td style={{ paddingLeft: '15px' }}>
                  <TinyTooltip tip={row.rateTooltip}>
                    {row.rate}&nbsp;&#37;
                  </TinyTooltip>
                </td>
              ) : (
                <td style={{ paddingLeft: '15px' }}>{row.rate}&nbsp;&#37;</td>
              )}
              <td
                className="text-center"
                style={{ color: Number(row.price) >= 0 ? 'black' : 'red' }}
              >
                  {Number(row.price) < 0 && '('}
                  {Number(row.price) < 0 ? (-1 * row.price).toFixed(3) : row.price.toFixed(3)}
                  {Number(row.price) < 0 && ')'}
              </td>
              <td className="text-center">
                <span style={{ color: Number(row.adj) < 0 ? 'red' : 'black' }}>
                  {Number(row.adj) < 0 && '('}
                  {Number(row.adj) < 0 ? (-1 * row.adj).toFixed(3) : row.adj}
                  {Number(row.adj) < 0 && ')'}
                </span>
              </td>
              <td className="text-center">
                <span
                  style={{ color: Number(row.total) >= 0 ? 'black' : 'red' }}
                >
                  {Number(row.total) < 0 && '('}
                  {Number(row.total) < 0 ? (-1 * row.total).toFixed(3) : row.total.toFixed(3)}
                  {Number(row.total) < 0 && ')'}
                </span>
              </td>
              <td className="text-center">
                <span
                  style={{
                    color:
                      Number(
                        row.creditPoint.replaceAll('$', '').replaceAll(',', '')
                      ) < 0
                        ? 'red'
                        : 'green',
                  }}
                >
                  {row.creditPoint}
                </span>
              </td>
              <td className="text-center">{row.monthly}</td>
              <td />
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
export default ProgramRates;
