import React from 'react';

const Pagination = ({
  pages = [],
  pageSize,
  pageSizeChange,
  pageSelected,
  pageChange,
}) => {
  const _handlePageChange = e => {
    if (typeof e === 'number') {
      pageChange(Number(pageSelected) + e);
    } else {
      pageChange(Number(e.target.value));
    }
  };

  const _handlePageSizeChange = e => {
    pageSizeChange(e.target.value);
  };

  return (
    <div className="d-flex flex-wrap flex-md-nowrap gap-3">
      <div className="d-flex align-items-center gap-2">
        <label htmlFor="pageSize" className="form-label text-nowrap mb-0">
          Page Size
        </label>
        <select
          name="pageSize"
          className="form-select form-select-sm flex-grow-1"
          style={{ width: '75px' }}
          onChange={_handlePageSizeChange}
          value={pageSize}
        >
          <option>10</option>
          <option>25</option>
          <option>50</option>
        </select>
      </div>
      <div className="input-group input-group-sm">
        {/* <input
          style={{ width: '40px' }}
          type="text"
          className="form-control text-end"
          value={pageSelected}
        /> */}
        <select
          name="page"
          className="form-select flex-grow-1"
          style={{ width: '75px' }}
          onChange={_handlePageChange}
          value={pageSelected}
        >
          {pages.map((page, index) => (
            <option key={index} value={index}>
              {index + 1}
            </option>
          ))}
        </select>
        <input
          style={{ width: '75px' }}
          type="text"
          className="form-control"
          value={`of ${pages.length}`}
          disabled
        />
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={() => _handlePageChange(-1)}
          disabled={pageSelected === 0}
        >
          <i className="bi bi-chevron-left fs-6"></i>
        </button>
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={() => _handlePageChange(1)}
          disabled={pageSelected === pages.length - 1}
        >
          <i className="bi bi-chevron-right fs-6"></i>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
