import React, { useState } from 'react';
import { TinyTooltip } from './Tooltip';

export default function ProgramList(props) {
  const [selectedCode, setSelectedCode] = useState(null);
  if (!props.availablePrograms) {
    return (
      <div className="text-center">
        <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
      </div>
    );
  } else if (props.availablePrograms.length === 0) {
    return (
      <div className="text-center">
        <h3 style={{color:'red'}}>No programs available!</h3>

        <table className="table mt-4">
          <tbody
            style={{
              display: 'block',
              overflow: 'auto',
              maxHeight: '350px'
            }}
          >
          {props.allPrograms.map(program => 
            {
              return (
                <tr
                  className={`px-1 align-items-center`}
                  key={`${program.code}-${program.displayRate}`}
                  style={{ cursor: 'pointer', maxHeight:"50px"}}
                >
                  <td style={{width:"450px"}}>
                    <span className="fw-bold text-uppercase">{program.code}</span>
                    <span style={{fontSize:"14px", marginLeft:"10px"}}>({program.name})</span>
                  </td>
                  <td style={{width:"350px", color:"red"}}>
                    {program.issues.map(issue => (
                      <div key={issue} style={{fontSize:"14px", position:"relative", top:"2px"}}>{issue}</div>
                    ))}
                  </td>
                </tr>
              )
            }
          )}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <table className="table">
        <tbody
          style={{
            display: 'block',
            overflow: 'auto',
            maxHeight: '350px'
          }}
        >
          {props.availablePrograms.map(program => 
            {
              const selected = program.code === selectedCode || (props.selectedProgram && program.code === props.selectedProgram.code);

              if(program.issues.length === 0)
                return (
                  <tr
                    className={`border bg-white px-1 rounded align-items-center 
                      ${selected && 'border-primary border-3'}`}
                    key={`${program.code}-${program.displayRate}`}
                    style={{ cursor: 'pointer', maxHeight:"50px"}}
                    onClick={() => {
                      setSelectedCode(program.code);
                      props.onSelect(program);
                    }}
                  >
                    <td style={{width:"140px"}}>
                      <span className="fw-bold text-uppercase">
                        {program.code}
                      </span>
                    </td>
                    <td style={{width:"425px"}}>
                      <span style={{fontSize:"14px"}}>{program.name}</span>
                    </td>
                    <td style={{width:"210px"}}>
                      {program.displayRate && (
                        <React.Fragment>
                          <i className="bi bi-check text-success" style={{margin:"0px", padding:"0px", lineHeight:"100%"}}></i>
                          <span style={{fontSize:"14px", color:"green"}}>
                            ({program.displayRate}&#37; PAR)
                          </span>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                )
              else {
                var issueList = program.issues[0];
                for(var i=1; i<program.issues.length; i++) {
                  issueList += "\n" + program.issues[i];
                }
                return (
                  <tr
                    className={`border bg-white px-1 rounded align-items-center 
                      ${selected && 'border-primary border-3'}`}
                    key={`${program.code}-${program.displayRate}`}
                    style={{maxHeight:"50px"}}
                  >
                    <td style={{width:"140px", color:"lightgray"}}>
                      <TinyTooltip tip={issueList}>
                        <span className="fw-bold text-uppercase">
                          {program.code}
                        </span>
                      </TinyTooltip>
                    </td>
                    <td style={{width:"425px", color:"lightgray"}}>
                      <TinyTooltip tip={issueList}>
                        <span style={{fontSize:"14px"}}>{program.name}</span>
                      </TinyTooltip>
                    </td>
                    <td style={{width:"210px", color:"lightgray"}}>
                      {program.displayRate && (
                        <TinyTooltip tip={issueList}>
                          <i className="bi bi-check" style={{margin:"0px", padding:"0px", lineHeight:"100%", color:"lightgray"}}></i>
                          <span style={{fontSize:"14px"}}>
                            ({program.displayRate}&#37; PAR)
                          </span>
                        </TinyTooltip>
                      )}
                    </td>
                  </tr>
                )
              }
            }
          )}
        </tbody>
      </table>
    );
  }
}
