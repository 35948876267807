import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  color: ${p => p.theme.colors.primary700};
  padding: 1rem;
  text-align: center;
  margin-top: 1rem;
  position: relative;
`;

export default function Footer() {
  return (
    <StyledFooter>
      <span>All Rights Reserved &copy; MTGRL 2024</span>
    </StyledFooter>
  );
}
