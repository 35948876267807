import React, { Component } from 'react';
import { TinyTooltip } from '../components/Tooltip';
import CanvasJSReact from '../libraries/canvasjs.react';
import LoginState from '../components/LoginState';
import { Session } from '../helpers/sessionContext';
import axios from 'axios';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardDisplay: '',
      userList: '',
      chartCount: [0, 0, 0, 0],
      loanRates: null,
      treasuryBondRate: null,
      freddieRate: null,
      timerRefresh: null,
      lastRefresh: null,
    };

    // redirection to saved page
    const lastPage = localStorage.getItem('lastPage');
    localStorage.setItem('lastPage', '');
    if (lastPage) this.props.history.push(lastPage);

    // pull data to setup the charts
    this.setupPieChartDisplay();
  }
  static contextType = Session;

  componentWillUnmount() {
    clearTimeout(this.state.timerRefresh);
  }

  timeNow() {
    const date = new Date();
    return date.toLocaleString('en-US').split(' ')[1];
  }

  async getDashboardStats() {
    try {
      const response = await axios.get('getLoanCounts');
      if (response.data) {
        const sortedRates = {};
        ['mtgdU30', 'mtgP1A7', 'mtgP1A7C', 'mtgP2A7'].forEach(key => {
          sortedRates[key] = response.data[key].sort((a, b) =>
            new Date(a.timestamp) > new Date(b.timestamp) ? 1 : -1
          );
        });
        this.setState({
          loanRates: sortedRates,
          treasuryBondRate: response.data.treasury10Year,
          freddieRate: response.data.freddieMac30Year,
          lastRefresh: this.timeNow(),
          timerRefresh: setTimeout(() => {
            this.getDashboardStats();
          }, 600000), // refresh every 10 minutes
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  componentDidMount() {
    if (!this.state.loanRates) {
      this.getDashboardStats();
    }
  }

  async setupPieChartDisplay() {
    const response = axios.get('getPipelineFiles', {
      params: {
        pUserID: LoginState.userId,
      },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    var ptClose = 0;
    var ctClose = 0;
    var ptFund = 0;
    var ctFund = 0;
    let total = 0;

    resultData.forEach(pFile => {
      if (pFile.loanStatus === 'Clear to Fund') ctFund++;
      else if (
        pFile.loanStatus === 'Doc In' ||
        pFile.loanStatus === 'Doc Out' ||
        pFile.loanStatus === 'In Review' ||
        pFile.loanStatus === 'Reviewed'
      )
        ptFund++;
      else if (pFile.loanStatus === 'Clear to Close') ctClose++;
      else if (
        pFile.loanStatus === 'Registered' ||
        pFile.loanStatus === 'In Submission' ||
        pFile.loanStatus === 'Submitted' ||
        pFile.loanStatus === 'In Underwriting' ||
        pFile.loanStatus === 'Approved'
      )
        ptClose++;
    });
    total = ctFund + ptFund + ctClose + ptClose;

    this.setState({ chartCount: [ptClose, ctClose, ptFund, ctFund], total });
  }

  onClick(context, sub) {
    context.props.history.push('/pipeline/' + sub);
  }

  /*
  / this function will render the primary display for the pricing
  / engine page.
  */
  render() {
    const { total, chartCount, loanRates, treasuryBondRate, freddieRate } =
      this.state;
    // options (parameters) for pie chart to be displayed below
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const donutOptions = {
      animationEnabled: true,
      subtitles: [
        {
          text: `${total} Loans`,
          verticalAlign: 'center',
          fontSize: 24,
          fontFamily: 'Source Sans Pro',
          dockInsidePlotArea: true,
        },
      ],
      legend: {
        horizontalAlign: 'bottom',
        verticalAlign: 'bottom',
        fontFamily: 'Source Sans Pro',
        fontSize: 18,
        fontWeight: 'lighter',
        maxWidth: 200,
      },
      backgroundColor: 'transparent',
      data: [
        {
          type: 'doughnut',
          innerRadius: '60%',
          startAngle: 180,
          dataPoints: [
            {
              y: chartCount[0],
              name: 'Prior to Close',
              color: 'hsl(358,79%,92%)',
              click: () => this.onClick(this, 'ptc'),
            },
            {
              y: chartCount[1],
              name: 'Clear to Close',
              color: 'hsl(358,79%,77%)',
              click: () => this.onClick(this, 'ctc'),
            },
            {
              y: chartCount[2],
              name: 'Prior to Funding',
              color: 'hsl(358,79%,62%)',
              click: () => this.onClick(this, 'ptf'),
            },
            {
              y: chartCount[3],
              name: 'Clear to Funding',
              color: 'hsl(358,79%,47%)',
              click: () => this.onClick(this, 'ctf'),
            },
          ],
          showInLegend: 'true',
          toolTip: '{name} - {y}',
        },
      ],
    };

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 mb-4 text-uppercase">dashboard</h1>
          </div>
          <div className="col-12 col-lg-4 mb-3">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-uppercase">loans</h4>
                {typeof total !== 'undefined' ? (
                  total > 0 ? (
                    <CanvasJSChart options={donutOptions} />
                  ) : (
                    <div className="donut-placeholder">
                      <h5>No Data</h5>
                    </div>
                  )
                ) : (
                  <p className="placeholder-glow d-flex flex-column justify-content-center align-items-center">
                    <span
                      className="placeholder bg-danger"
                      style={{
                        width: '273px',
                        height: '273px',
                        borderRadius: '273px',
                      }}
                    ></span>
                    <span
                      className="placeholder bg-danger mt-4"
                      style={{ width: '150px', height: '20px' }}
                    ></span>
                    <span
                      className="placeholder bg-danger mt-2"
                      style={{ width: '150px', height: '20px' }}
                    ></span>
                    <span
                      className="placeholder bg-danger mt-2"
                      style={{ width: '150px', height: '20px' }}
                    ></span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 mb-2 col-lg-6">
            <div className="row m-0 g-2 mb-3">
              <div className="col-6 m-0 ps-0 pe-2">
                <div className="card h-100">
                  {treasuryBondRate ? (
                    <div className="card-body text-center">
                      <h1 className="card-title">
                        {Number(treasuryBondRate).toFixed(3)}
                        <span className="fs-5">%</span>
                      </h1>
                      <a
                        href="https://finance.yahoo.com/quote/%5ETNX/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none card-subtitle mb-2 text-secondary d-flex justify-content-center align-items-center"
                      >
                        <TinyTooltip
                          tip={'Last Refreshed: ' + this.state.lastRefresh}
                        >
                          <span>10 Year Treasury Bond</span>
                        </TinyTooltip>
                        <i className="bi bi-box-arrow-up-right ms-2 fs-6"></i>
                      </a>
                    </div>
                  ) : (
                    <div className="card-body text-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6 m-0 pe-0 ps-2">
                <div className="card h-100">
                  {freddieRate ? (
                    <div className="card-body text-center">
                      <h1 className="card-title">
                        {Number(freddieRate).toFixed(3)}
                        <span className="fs-5">%</span>
                      </h1>
                      <a
                        href="https://www.freddiemac.com/pmms"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none card-subtitle mb-2 text-secondary d-flex justify-content-center align-items-center"
                      >
                        <TinyTooltip
                          tip={'Last Refreshed: ' + this.state.lastRefresh}
                        >
                          <span>FreddieMac 30 Year Fixed</span>
                        </TinyTooltip>
                        <i className="bi bi-box-arrow-up-right ms-2 fs-6"></i>
                      </a>
                    </div>
                  ) : (
                    <div className="card-body text-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">DAILY RATE SHEETS</h4>
                {loanRates ? (
                  <table className="table table-hover align-middle">
                    <tbody>
                      {Object.keys(loanRates).map((key, index) => {
                        const { rate, change } =
                          loanRates[key][loanRates[key].length - 1];
                        const formattedRate =
                          parseFloat(rate).toFixed(3) + ' %';
                        const formattedDiff =
                          change < 0
                            ? parseFloat(change * -1).toFixed(3)
                            : change > 0
                            ? `(${parseFloat(change).toFixed(3)})`
                            : '-';
                        const fromattedIcon =
                          change === 0 ? (
                            <i className="bi bi-dash-square-fill text-black-50"></i>
                          ) : change > 0 ? (
                            <i className="bi bi-arrow-up-square-fill text-success"></i>
                          ) : (
                            <i className="bi bi-arrow-down-square-fill text-danger"></i>
                          );
                        // get object from array with smallest rate
                        const minVal = loanRates[key].reduce((prev, current) =>
                          prev.rate < current.rate ? prev : current
                        );
                        const maxVal = loanRates[key].reduce((prev, current) =>
                          prev.rate > current.rate ? prev : current
                        );
                        return (
                          <React.Fragment key={key}>
                            <tr
                              data-bs-toggle="collapse"
                              data-bs-target={`#${key}`}
                              aria-expanded="false"
                              aria-controls={key}
                            >
                              <th scope="row">{key.toUpperCase()}</th>
                              <td className="text-center">{formattedRate}</td>
                              <td className="text-center">{fromattedIcon}</td>
                              <td
                                className={`${
                                  change > 0 && 'text-danger'
                                } text-center`}
                              >
                                {formattedDiff}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4" className="collapse" id={key}>
                                <CanvasJSChart
                                  options={{
                                    height: 200,
                                    backgroundColor: 'transparent',
                                    axisY: {
                                      labelFontSize: 12,
                                      labelFormatter: e =>
                                        Number(e.value).toFixed(3),
                                      tickColor: 'transparent',
                                      tickLength: 10,
                                      gridColor: 'transparent',
                                      lineColor: '#adb5bd',
                                      minimum: minVal.rate - 0.1,
                                      maximum: maxVal.rate + 0.1,
                                      interval: 0.25,
                                    },
                                    axisX: {
                                      labelFontSize: 12,
                                      tickColor: '#adb5bd',
                                      tickLength: 10,
                                      gridColor: 'transparent',
                                      lineColor: '#adb5bd',
                                      labelAngle: 25,
                                    },
                                    toolTip: {
                                      shared: true,
                                      borderColor: 'transparent',
                                      content: 'Rate: {y}%, Date: {label}',
                                    },
                                    data: [
                                      {
                                        type: 'line',
                                        showInLegend: false,
                                        lineColor: '#f42128',
                                        markerSize: 0,
                                        dataPoints: loanRates[key].map(
                                          rate => ({
                                            ...rate,
                                            color: '#f42128',
                                            label: moment(
                                              rate.timestamp
                                            ).format('MM/DD, h:mm a'),
                                            y: rate.rate,
                                          })
                                        ),
                                      },
                                    ],
                                  }}
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center p-4">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
