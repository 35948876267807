import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import axios from 'axios';

class Wizard1003 extends Component {
  constructor(props) {
    super(props);
    this.programsRef = React.createRef();
    this.state = {
      wizardData: [],
    };

    // get options before page loads
    this.getWizardData();
  }

  async getWizardData() {
    var response = axios.get('/getwizarddata');
    try {
      var resultData = (await response).data;
      this.setState({ wizardData: resultData });
    } catch {
      this.setState({ wizardData: [] });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="h4 mb-4 text-uppercase">
              loan wizard 1003 submissions
            </h1>
          </div>
          <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <div className="border rounded">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Submission Date</th>
                    <th>Borrowers</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.wizardData.map(wd => (
                    <tr key={wd.wizardID}>
                      <td>
                        <Link to={`../wizardsubmission/${wd.wizardID}`}>
                          {wd.wizardID}
                        </Link>
                      </td>
                      <td>{wd.submissionTime}</td>
                      <td>{wd.borrowers}</td>
                      <td>{wd.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Wizard1003;
