import React from 'react';

export default function ViewElement({
  label = 'placeholder',
  value,
  textArea,
}) {
  return (
    <div className="mb-1 mb-md-3">
      <label
        htmlFor={label}
        className={`form-label text-muted mb-0 mt-2 ${
          label === 'placeholder' ? 'opacity-0' : ''
        }`}
      >
        {label}
      </label>
      {textArea ? (
        <textarea
          className="form-control"
          disabled={true}
          value={value}
          rows="2"
          style={{ resize: 'none' }}
        />
      ) : (
        <input
          type="text"
          disabled={true}
          className="form-control"
          id={label}
          value={value}
        />
      )}
    </div>
  );
}
