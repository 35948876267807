import React from 'react';

export default function WizardIndex(props) {
  const { resetForm, visited, activeStep, changeStep } = props;
  /* const progress = ['0%', '25%', '50%', '75%', '100%']; */
  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="mx-md-4 my-5 d-flex align-items-start">
          {/* <div className="progress" style={{ height: "2px", marginLeft: '20%' }} >
                        <div className="progress-bar" role="progressbar" aria-label="Progress" style={{ width: progress[visited.length - 1] }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div> */}
          <div className="d-flex flex-column align-items-center">
            <button
              type="button"
              onClick={() => resetForm()}
              className="btn btn-sm btn-secondary text-white rounded-pill"
              style={{ width: '3rem', height: '3rem' }}
            >
              <i className="bi bi-x-lg" style={{ fontSize: '1.5rem' }}></i>
            </button>
            <span className="mt-2">
              <small>Cancel</small>
            </span>
          </div>
          <hr className="border border-2 opacity-0 flex-grow-1 mx-1 rounded" />
          <div className="d-flex flex-column align-items-center">
            <button
              type="button"
              onClick={() => changeStep('import')}
              className={`btn btn-sm rounded-pill ${
                activeStep === 'import'
                  ? 'btn-primary'
                  : visited.includes('import')
                  ? 'btn-outline-primary'
                  : 'btn-outline-secondary'
              }`}
              style={{ width: '3rem', height: '3rem', fontSize: '1.5rem' }}
            >
              1
            </button>
            <span className="mt-2">
              <small>Import</small>
            </span>
          </div>
          <hr
            className={`border border-2 opacity-50 flex-grow-1 mt-4 mx-2 rounded ${
              visited.includes('select') && 'border-danger'
            }`}
          />
          <div className="d-flex flex-column align-items-center">
            <button
              disabled={!visited.includes('select')}
              type="button"
              onClick={() => changeStep('select')}
              className={`btn btn-sm rounded-pill ${
                activeStep === 'select'
                  ? 'btn-primary'
                  : visited.includes('select')
                  ? 'btn-outline-primary'
                  : 'btn-outline-secondary'
              }`}
              style={{ width: '3rem', height: '3rem', fontSize: '1.5rem' }}
            >
              2
            </button>
            <span className="mt-2">
              <small>Select</small>
            </span>
          </div>
          <hr
            className={`border border-2 opacity-50 flex-grow-1 mt-4 mx-2 rounded ${
              visited.includes('contact') && 'border-danger'
            }`}
          />
          <div className="d-flex flex-column align-items-center">
            <button
              disabled={!visited.includes('contact')}
              type="button"
              onClick={() => changeStep('contact')}
              className={`btn btn-sm rounded-pill ${
                activeStep === 'contact'
                  ? 'btn-primary'
                  : visited.includes('contact')
                  ? 'btn-outline-primary'
                  : 'btn-outline-secondary'
              }`}
              style={{ width: '3rem', height: '3rem', fontSize: '1.5rem' }}
            >
              3
            </button>
            <span className="mt-2">
              <small>Contact</small>
            </span>
          </div>
          <hr
            className={`border border-2 opacity-50 flex-grow-1 mt-4 mx-2 rounded ${
              visited.includes('review') && 'border-danger'
            }`}
          />
          <div className="d-flex flex-column align-items-center">
            <button
              disabled={!visited.includes('review')}
              type="button"
              onClick={() => changeStep('review')}
              className={`btn btn-sm rounded-pill ${
                activeStep === 'review'
                  ? 'btn-primary'
                  : visited.includes('review')
                  ? 'btn-outline-primary'
                  : 'btn-outline-secondary'
              }`}
              style={{ width: '3rem', height: '3rem', fontSize: '1.5rem' }}
            >
              4
            </button>
            <span className="mt-2">
              <small>Review</small>
            </span>
          </div>
          <hr
            className={`border border-2 opacity-50 flex-grow-1 mt-4 mx-2 rounded ${
              visited.includes('confirm') && 'border-danger'
            }`}
          />
          <div className="d-flex flex-column align-items-center">
            <button
              disabled={!visited.includes('confirm')}
              type="button"
              className={`btn btn-sm rounded-pill ${
                activeStep === 'confirm'
                  ? 'btn-success text-white'
                  : visited.includes('confirm')
                  ? 'btn-outline-success'
                  : 'btn-outline-secondary'
              }`}
              style={{ width: '3rem', height: '3rem' }}
            >
              <i
                className="bi bi-check-lg text-success"
                style={{ fontSize: '1.5rem' }}
              ></i>
            </button>
            <span className="mt-2">
              <small>Done</small>!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
