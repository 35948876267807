import React, { Component } from 'react';

class Restricted extends Component {
  
  render() {
    
    return (
      <div className="container-xxl">
        <div className="row">
          <div style={{ width: '100%', textAlign: 'center', paddingTop:'50px' }}>
            <h2 style={{ fontWeight:'bold' }}>Restricted Access</h2>
            <br/>
            <img
              src="/images/Stop.png"
              alt=""
              height="300px"
              width="300px"
            />            
            <h5 style={{color:'red', paddingTop:'20px', fontWeight:'bold'}}>(File Not In Organization or Assigned To User)</h5>
          </div>
        </div>
      </div>
    );
  }
}

export default Restricted;
