import React, { Component } from 'react';
import { PRICING_OPTIONS } from '../helpers/constants';
export default class AppraisalFinder extends Component {
  state = {
    errors: {},
    showFees: false,
    salesEstValue: '',
    zipCode: '',
    loanProgram: 1,
    feeAmount: 0,
  };

  resetFields = e => {
    e.preventDefault();
    this.setState({
      showFees: false,
      errors: {},
      loanProgram: 1,
      occupancyType: null,
      salesEstValue: '',
      zipCode: '',
      propertyType: null,
      requestType: null,
    });
  };

  validateFields = () => {
    // keep track of any existing issues
    const errors = {};

    [
      'loanProgram',
      'occupancyType',
      'salesEstValue',
      'zipCode',
      'propertyType',
      'requestType',
    ].forEach(field => {
      if (
        !this.state[field] ||
        this.state[field] === '' ||
        this.state[field] === -1
      ) {
        errors[field] = 'Required';
      }
    });

    if (Object.values(errors).length > 0) {
      this.setState({ errors });
      return false;
    } else {
      return true;
    }
  };

  findFee = e => {
    e.preventDefault();
    // first run validation
    const isValid = this.validateFields();
    if (!isValid) {
      return;
    }

    const price = this.state.salesEstValue;
    var feeAmount = 0;
    // next find fees
    switch (Number(this.state.requestType)) {
      case 1: // New Order
        if (this.state.occupancyType < 3) {
          // first or second home
          if (this.state.propertyType < 5) {
            // SFR, Condo (A), Condo (D), PUD
            if (price <= 1000000) feeAmount += 550;
            else if (price <= 2000000) feeAmount += 700;
            else if (price <= 3000000) feeAmount += 1100;
            else if (price <= 4000000) feeAmount += 1300;
            else feeAmount = 'Contact AMC';
          } else if (this.state.propertyType === 6) {
            // 2 Unit
            if (price <= 1000000) feeAmount += 750;
            else if (price <= 2000000) feeAmount += 900;
            else if (price <= 3000000) feeAmount += 1050;
            else if (price <= 4000000) feeAmount += 1400;
            else feeAmount = 'Contact AMC';
          } else if (this.state.propertyType > 6) {
            // 3-4 Unit
            if (price <= 1000000) feeAmount += 800;
            else if (price <= 2000000) feeAmount += 950;
            else if (price <= 3000000) feeAmount += 1100;
            else if (price <= 4000000) feeAmount += 1400;
            else feeAmount = 'Contact AMC';
          }
        } else {
          // investment property
          if (this.state.propertyType < 5) {
            // SFR, Condo (A), Condo(D), PUD
            if (price <= 1000000) feeAmount += 650;
            else if (price <= 2000000) feeAmount += 850;
            else if (price <= 3000000) feeAmount += 1200;
            else if (price <= 4000000) feeAmount += 1400;
            else feeAmount = 'Contact AMC';
          } else if (this.state.propertyType === 6) {
            // 2 Unit
            if (price <= 1000000) feeAmount += 750;
            else if (price <= 2000000) feeAmount += 900;
            else if (price <= 3000000) feeAmount += 1300;
            else if (price <= 4000000) feeAmount += 1500;
            else feeAmount = 'Contact AMC';
          } else if (this.state.propertyType > 6) {
            // 3-4 Unit
            if (price <= 1000000) feeAmount += 800;
            else if (price <= 2000000) feeAmount += 950;
            else if (price <= 3000000) feeAmount += 1300;
            else if (price <= 4000000) feeAmount += 1500;
            else feeAmount = 'Contact AMC';
          }
        }
        break;
      case 2: // Form 1004D/442 Re-Inspection
        feeAmount += 150;
        break;
      case 3: // Form 1004D/442 Re-Certification
        feeAmount += 150;
        break;
      case 4: // Form 2055 Exterior Only
        feeAmount += 350;
        break;
      case 5: // Desk Review
        this.state.propertyType < 5 ? (feeAmount += 275) : (feeAmount += 425);
        break;
      case 6: // Field Review
        this.state.propertyType < 5 ? (feeAmount += 350) : (feeAmount += 450);
        break;
      default:
        break;
    }
    this.setState({ feeAmount, showFees: true });
  };

  /**
   * Capture any inputs value, save it in state
   */
  captureInput = e => {
    if (this.state.errors) {
      let errors = { ...this.state.errors };
      delete errors[e.target.name];
      this.setState({
        [e.target.name]: e.target.value,
        errors,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  render() {
    const { errors } = this.state;
    const feeValue =
      this.state.feeAmount.toString() === 'Contact AMC'
        ? this.state.feeAmount
        : '$' + parseFloat(this.state.feeAmount.toString()).toFixed(2);
    return (
      <div className="container">
        <h1 className="h4 text-uppercase">appraisal fee finder</h1>
        <div className="card p-4 border-secondary-light">
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3 col-xl-4 offset-xl-4">
              <div className="mb-2">
                <label className="form-label">Request Type</label>
                <select
                  className={`form-select ${
                    errors.requestType ? 'is-invalid' : ''
                  }`}
                  name="requestType"
                  value={this.state.requestType}
                  onChange={this.captureInput}
                  tabIndex={1}
                >
                  <option value={0}>Select...</option>
                  {PRICING_OPTIONS.requestType.map(el => (
                    <option key={el.value} value={el.value}>
                      {el.label}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">{errors.requestType}</div>
              </div>
              <div className="mb-2">
                <label className="form-label">Occupancy Type</label>
                <select
                  className={`form-select ${
                    errors.occupancyType ? 'is-invalid' : ''
                  }`}
                  name="occupancyType"
                  value={this.state.occupancyType}
                  onChange={this.captureInput}
                  tabIndex={3}
                >
                  <option value={0}>Select...</option>
                  {PRICING_OPTIONS.occupancyType.map(el => (
                    <option key={el.value} value={el.value}>
                      {el.label}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">{errors.occupancyType}</div>
              </div>
              <div className="mb-2">
                <label className="form-label">Sales/Est.Value</label>
                <input
                  className={`form-control ${
                    errors.salesEstValue ? 'is-invalid' : ''
                  }`}
                  type="number"
                  name="salesEstValue"
                  value={this.state.salesEstValue}
                  onChange={this.captureInput}
                  tabIndex={5}
                />
                <div className="invalid-feedback">{errors.salesEstValue}</div>
              </div>
              <div className="mb-2">
                <label className="form-label">Loan Program</label>
                <select
                  className={`form-select ${
                    errors.loanProgram ? 'is-invalid' : ''
                  }`}
                  name="loanProgram"
                  value={this.state.loanProgram}
                  onChange={this.captureInput}
                  tabIndex={2}
                >
                  <option value="1">Conventional</option>
                </select>
                <div className="invalid-feedback">{errors.loanProgram}</div>
              </div>
              <div className="mb-2">
                <label className="form-label">Property Type</label>
                <select
                  className={`form-select ${
                    errors.propertyType ? 'is-invalid' : ''
                  }`}
                  name="propertyType"
                  value={this.state.propertyType}
                  onChange={this.captureInput}
                  tabIndex={4}
                >
                  <option value={0}>Select...</option>
                  {PRICING_OPTIONS.propertyType.map(el => (
                    <option key={el.value} value={el.value}>
                      {el.label}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">{errors.propertyType}</div>
              </div>
              <div className="mb-3">
                <label className="form-label">Zip Code</label>
                <input
                  className={`form-control ${
                    errors.zipCode ? 'is-invalid' : ''
                  }`}
                  type="number"
                  name="zipCode"
                  value={this.state.zipCode}
                  onChange={this.captureInput}
                  error={this.state.errors.zipCode}
                  tabIndex={6}
                />
                <div className="invalid-feedback">{errors.zipCode}</div>
              </div>
              <div className="d-flex gap-2 justify-content-end">
                <button
                  className="btn px-4 btn-light"
                  onClick={this.resetFields}
                >
                  Reset
                </button>
                <button className="btn px-4 btn-primary" onClick={this.findFee}>
                  Find Fee
                </button>
              </div>
            </div>
          </div>
          {this.state.showFees && (
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2">
                <div
                  className={`mt-4 alert alert-${
                    !feeValue || feeValue === '0' ? 'danger' : 'success'
                  } text-center`}
                  role="alert"
                >
                  <h4 className="alert-heading">Appraisal Fee:</h4>
                  <h1>
                    {!feeValue || feeValue === '0' ? 'No fees found' : feeValue}
                  </h1>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
