import React, { Component } from 'react';
import LoginState from './LoginState';
import axios from 'axios';
import { formatCurrency } from '../helpers/utils';
import Modal from './Modal';
import { withRouter } from 'react-router-dom';
class Closing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      retrievedData: false,
      feeData: [],
      requestData: [],
      showErrorsModal: false,
      showConfirmModal: false,
      errors: [],
      searchLog: [],
      rateLocked: false,
      confirmCB: false,
    };
  }

  async GetSearchLog(fileID) {
    // now update server with this data
    const response = axios.get('/getUpdatedSearchLog', {
      params: {
        pFileID: fileID,
        pUserID: LoginState.userId,
      },
    });
    var resultData = (await response).data;
    this.setState({ searchLog: resultData });
  }

  PageLoad(fileID) {
    this.setState({ feeData: [], requestData: [], retrievedData: false });
    this.GetSearchLog(fileID);
    this.FetchDocInfoFromServer(fileID);
  }

  componentDidMount() {
    this.PageLoad(this.props.match.params.id);
  }

  async FetchDocInfoFromServer(fileID) {
    axios
      .get('/getdocinfo', {
        params: {
          pFileID: fileID,
        },
      })
      .then(res => {
        if (res && res.data) {
          this.setState({
            retrievedData: true,
            settlementCompany: res.data.company,
            contactPerson: res.data.contactPerson,
            workPhone: res.data.workPhone,
            email: res.data.email,
            feeData: res.data.feeData,
            submittedDate: res.data.submittedDate,
            submittedBy: res.data.submittedBy,
            requestData: res.data.requestData,
            rateLocked:
              res.data.rateLockHistoryItems &&
              res.data.rateLockHistoryItems !== '0',
            isSubmitting: false,
          });
        }
      })
      .catch(e => console.log(e, 'Error fetching Doc Information'));
  }

  /**
   * Capture any inputs value, save it in state
   */
  captureInput = e => {
    if (this.state.errors) {
      let errors = { ...this.state.errors };
      delete errors[e.target.name];
      this.setState({
        [e.target.name]: e.target.value,
        errors,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  checkBoxClicked() {
    this.setState(prevState => ({ confirmCB: !prevState.confirmCB }));
  }

  async validateDocRequest() {
    if (
      !this.state.confirmCB ||
      !this.state.email ||
      !this.state.signingDate ||
      !this.state.vesting
    )
      this.setState({ showErrorsModal: true });
    else {
      var notifyFields = [];

      // Settlement Company
      notifyFields.push('Settlement Company');
      notifyFields.push(this.state.settlementCompany);

      // Contact Person
      notifyFields.push('Contact Person');
      notifyFields.push(this.state.contactPerson);

      // Work Phone
      notifyFields.push('Work Phone');
      notifyFields.push(this.state.workPhone);

      // Email
      notifyFields.push('Email');
      notifyFields.push(this.state.email);

      // Vesting
      notifyFields.push('Vesting');
      notifyFields.push(this.state.vesting);

      // Est Signing Date
      notifyFields.push('Estimated Signing Date');
      notifyFields.push(this.state.signingDate);

      // Fees
      for (var i = 0; i < this.state.feeData.length; i++) {
        notifyFields.push(this.state.feeData[i].description);
        notifyFields.push(
          '$' + parseFloat(this.state.feeData[i].amount).toFixed(2)
        );
      }

      this.setState({ isSubmitting: true });

      axios
        .get('/senddocrequest', {
          params: {
            pFileID: this.props.match.params.id,
            pUserName: LoginState.userName,
            pChanges: notifyFields.join('$$$$$'),
          },
        })
        .then(res => {
          this.FetchDocInfoFromServer(this.props.match.params.id);
        })
        .catch(e => console.log(e, 'Error fetching documents'));
    }
  }

  ShowConfirmModal = () => {
    return (
      <div className="row">
        <br />
        The Doc Request Has Been Successfully Submitted
      </div>
    );
  };

  ShowErrorModal = () => {
    let errorMsgList = [];
    if (!this.state.confirmCB)
      errorMsgList.push(
        'Please confirm submission by clicking the checkbox stating that the information ' +
          'being submitted is accurate and correct, or an official COC has been requested.'
      );
    if (!this.state.email)
      errorMsgList.push(
        'Please provide an email address for the settlement company or agent.'
      );
    if (!this.state.signingDate)
      errorMsgList.push('Please provide an an estimated signing date.');
    if (!this.state.vesting)
      errorMsgList.push('Please provide information for vesting.');

    return (
      <div className="row">
        <br />
        {errorMsgList.map(errorItem => (
          <div key={errorItem}>
            - {errorItem}
            <br />
            <br />
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { errors } = this.state;

    if (!this.state.retrievedData || this.state.isSubmitting) {
      return (
        <div>
          <div className="container">
            <div className="col-12">
              <h1 className="h4 text-uppercase">Request Closing Package</h1>
            </div>
            <div className="col-12">
              <div style={{ width: '100%', textAlign: 'center' }}>
                <img
                  src="/images/spinner.gif"
                  alt=""
                  height="64px"
                  width="64px"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.submittedDate && this.state.requestData) {
      return (
        <div className="container">
          <div className="col-12">
            <h4>
              Request Closing Package
              <button
                className="btn btn-text dropdown-toggle"
                style={{ marginLeft: '5px' }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-list"></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <p className="dropdown-item mb-0 disabled">Recent Files</p>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {this.state.searchLog.length > 0 ? (
                  <>
                    {this.state.searchLog.map(file => (
                      <li key={file.encodedFileID}>
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            this.props.history.push(
                              '/pipeline/closing/' + file.encodedFileID
                            );
                            this.PageLoad(file.encodedFileID);
                          }}
                        >
                          {file.encodedFileID} ({file.borrower})
                        </span>
                      </li>
                    ))}
                  </>
                ) : (
                  <li>
                    <p className="dropdown-item mb-0 disabled">
                      No Recent Files
                    </p>
                  </li>
                )}
              </ul>
            </h4>
          </div>

          <div className="col-12 text-center bg-white border rounded p-5">
            <img
              src="/images/coc_submitted.png"
              height="150"
              alt="document request submitted"
              className="mb-3"
            />
            <h1 className="h4">Documents Request Submitted!</h1>
            <h2 className="h5 text-secondary mb-3">
              Submitted on: {this.state.submittedDate}
              {this.state.submittedBy && ` by: ${this.state.submittedBy}`}
            </h2>
            {this.state.reviewedDate && (
              <h5 className="text-secondary">
                Reviewed on: {this.state.reviewedDate} ({this.state.reviewedBy})
              </h5>
            )}
            <div className="w-auto">
              {this.state.requestData.map(requestField => (
                <div
                  className="row border-bottom border-opacity-25 py-1"
                  key={requestField.fieldName}
                >
                  <div className="col text-end">{requestField.fieldName}</div>
                  <div className="col-1">
                    <i className="bi bi-arrow-right fs-5 text-secondary"></i>
                  </div>
                  <div className="col text-start">
                    {requestField.fieldValue}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="h4 text-uppercase">
                Request Closing Package
                <button
                  className="btn btn-text dropdown-toggle"
                  style={{ marginLeft: '5px' }}
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-list"></i>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <p className="dropdown-item mb-0 disabled">Recent Files</p>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  {this.state.searchLog.length > 0 ? (
                    <>
                      {this.state.searchLog.map(file => (
                        <li key={file.encodedFileID}>
                          <span
                            className="dropdown-item"
                            onClick={() => {
                              this.props.history.push(
                                '/pipeline/closing/' + file.encodedFileID
                              );
                              this.PageLoad(file.encodedFileID);
                            }}
                          >
                            {file.encodedFileID} ({file.borrower})
                          </span>
                        </li>
                      ))}
                    </>
                  ) : (
                    <li>
                      <p className="dropdown-item mb-0 disabled">
                        No Recent Files
                      </p>
                    </li>
                  )}
                </ul>
              </h1>
            </div>

            {!this.state.rateLocked ? (
              <div className="col-12">
                <table
                  style={{
                    marginTop: '35px',
                    width: '420px',
                    margin: '0 auto',
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'center' }}>
                        <img
                          src="/images/Lock.png"
                          height="100px"
                          alt="Rates Suspended"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: '700',
                          fontFamily: 'Montserrat',
                          color: 'red',
                          fontSize: '20px',
                          textAlign: 'center',
                        }}
                      >
                        Closing Cannot Be Initiated Before <br />
                        Rate is Locked
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <React.Fragment>
                <div className="col-12 col-lg-8 order-1 order-lg-2">
                  {/* FORM */}
                  <div className="border rounded p-3  bg-white mb-3">
                    <p className="fw-bold ">Request Form</p>
                    <div className="col-12 col-lg-8 col-xl-6 offset-lg-2 offset-xl-3">
                      <small className="text-muted">Settlement Company</small>
                      <p className="mb-2">{this.state.settlementCompany}</p>
                      <small className="text-muted">Contact Person</small>
                      <p className="mb-2">{this.state.contactPerson}</p>
                      <small className="text-muted">Work Phone</small>
                      <p className="mb-2">{this.state.workPhone}</p>
                      <label>Email</label>
                      <input
                        className="form-control mb-2"
                        error={errors && errors.email}
                        id="email"
                        name="email"
                        placeholder="Enter..."
                        type="text"
                        value={this.state.email}
                        onChange={this.captureInput}
                      />
                      <label>Vesting</label>
                      <input
                        className="form-control mb-2"
                        error={errors && errors.vesting}
                        id="vesting"
                        name="vesting"
                        placeholder="Enter..."
                        type="text"
                        value={this.state.vesting}
                        onChange={this.captureInput}
                      />
                      <label>Est. Signing Date</label>
                      <input
                        className="form-control mb-2"
                        error={errors && errors.signingDate}
                        id="signingDate"
                        name="signingDate"
                        type="date"
                        value={this.state.signingDate}
                        onChange={this.captureInput}
                      />
                    </div>
                  </div>
                </div>

                {/* FEES */}
                <div className="col-12 col-lg-4 order-2 order-lg-1">
                  {this.state.feeData.length > 0 && (
                    <ul className="list-group">
                      <li className="fw-bold list-group-item">Fees</li>
                      {this.state.feeData.map(fee => (
                        <li
                          className="list-group-item d-flex justify-content-between"
                          key={fee.description}
                        >
                          {fee.description}
                          <span className="fw-bold">
                            {formatCurrency(fee.amount)}
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {/* SUBMIT */}
                  <div className=" border rounded p-3 bg-white mt-3">
                    <p className="fw-bold">Submission</p>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        checked={this.state.confirmCB}
                        onChange={() => this.checkBoxClicked()}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        I hereby confirm that the information provided above is
                        accurate and correct or that the official CIC has been
                        requested
                      </label>
                    </div>
                    <button
                      className="btn btn-primary w-100"
                      disabled={!this.state.retrievedData}
                      onClick={() => this.validateDocRequest()}
                    >
                      {!this.state.retrievedData ? 'Retrieving' : 'Submit'}
                    </button>
                  </div>
                </div>
                {this.state.feeData.length === 0 && (
                  <div className="col-12 mt-2">
                    <div className=" border rounded p-2">
                      <button
                        className="btn btn-primary w-100 d-lg-none"
                        d
                        disabled={!this.state.retrievedData}
                        onClick={() =>
                          this.props.history.push(
                            '/pipeline/change-in-circumstance/' +
                              this.props.match.params.id
                          )
                        }
                      >
                        Request Changes
                      </button>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}

            {/* MODAL */}
            <Modal
              title="Submit Doc Request"
              width="400px"
              height="200px"
              show={this.state.showConfirmModal}
              onAccept={() => this.setState({ showConfirmModal: false })}
              onDecline={() => this.setState({ showConfirmModal: false })}
              buttonMessage="Continue"
              hideCancelBtn
            >
              {this.ShowConfirmModal()}
            </Modal>
            {/* MODAL 2 */}
            <Modal
              title="Validation Error"
              show={this.state.showErrorsModal}
              onAccept={() => this.setState({ showErrorsModal: false })}
              onDecline={() => this.setState({ showErrorsModal: false })}
              buttonMessage="Continue"
              hideCancelBtn
            >
              {this.ShowErrorModal()}
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Closing);
