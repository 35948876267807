import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9800;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalCard = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  z-index: 9900;
  width: ${p => (p.width ? p.width : '500px')};
  min-height: ${p => (p.height ? p.height : '300px')};
  max-height: 90vh;
  background-color: ${p => p.theme.colors.white};
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h6`
  margin: 0;
  color: red;
`;

const ModalBody = styled.div`
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
`;

export default class ModalEmailLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailData: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email) {
      this.getEmailResults(this.props.email);
    }
  }

  async getEmailResults(email) {
    var response = axios.get('/getAssociatedEmail', {
      params: {
        pBorrowerEmail: email,
      },
    });

    try {
      var resultData = (await response).data;
      this.setState({ emailData: resultData });
    } catch {
      this.setState({ emailData: [] });
    }
  }

  render() {
    return this.props.show ? (
      <ModalWrapper>
        <ModalCard width={this.props.width} height={this.props.height}>
          <ModalHeader>
            <ModalTitle>[Sent Email Log]</ModalTitle>
            <button
              type="button"
              className="btn-close"
              onClick={this.props.onDecline}
              aria-label="Close"
            ></button>
          </ModalHeader>
          <ModalBody>
            {this.props.emailLog.map(logEntry => (
              <div key={logEntry.timestamp} className="mb-2" style={{ fontSize: '13px' }}>
                <b>{logEntry.timestamp}</b>
                <br />
                {logEntry.subject}
                <br />
                Sent By: <span style={{ color: 'green' }}>{logEntry.from}</span>
                <br />
                To:{' '}
                <span style={{ color: 'green' }}>{logEntry.to || '---'}</span>
                <br />
                Cc:{' '}
                <span style={{ color: 'green' }}>{logEntry.cc || '---'}</span>
                <br />
                <hr />
              </div>
            ))}
          </ModalBody>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={this.props.onDecline}
            >
              Close
            </button>
          </div>
        </ModalCard>
      </ModalWrapper>
    ) : (
      <></>
    );
  }
}
