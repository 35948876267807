import React, { Component } from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9800;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalCard = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  z-index: 9900;
  width: ${p => (p.width ? p.width : '450px')};
  min-height: ${p => (p.height ? p.height : '140px')};
  max-height: 90vh;
  background-color: ${p => p.theme.colors.white};
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalBody = styled.div`
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
`;

export default class Toast extends Component {
  render() {
    const colorType = {
      info: '#0577e3',
      success: '#00b339',
      warning: '#fcba03',
      danger: '#ff201c',
    };

    return (
      <ModalWrapper>
        <ModalCard width={this.props.width} height={this.props.height}>
          <ModalHeader>
            <button
              type="button"
              className="btn-close"
              onClick={this.props.onClose}
              aria-label="Close"
            ></button>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontSize: '16px',
                textAlign: 'center',
                color: colorType[this.props.type],
              }}
            >
              {this.props.title}
            </div>
          </ModalBody>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={this.props.onClose}
            >
              Close
            </button>
          </div>
        </ModalCard>
      </ModalWrapper>
    );
  }
}
