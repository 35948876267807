import React from 'react';

export default function Adjustments(props) {
  // rate adjustments
  var displayRateAdj, displayPriceAdj;
  let totalRate = 0;
  for (let i = 0; i < props.rateAdjustments.length; i++)
    totalRate += props.rateAdjustments[i].amount;
  const adjustmentsToRate = props.rateAdjustments.concat({
    reason: 'Total Rate Adjustments',
    amount: totalRate,
  });
  if (props.rateAdjustments.length > 0) {
    displayRateAdj = (
      <table className="table">
        <tbody
          style={{
            display: 'block',
            overflow: 'auto',
            minWidth: '570px',
            maxHeight: '300px',
            borderRadius: '5px',
          }}
        >
        {adjustmentsToRate.map(adjustment => (
          <tr>
            <td>
              {adjustment.reason === 'Total Rate Adjustments' ? (
                <b>{adjustment.reason}</b>
              ) : (
                adjustment.reason
              )}
            </td>
            <td>
              <div style={{ width: '80px', textAlign: 'center', color: Number(adjustment.amount) < 0 ? 'red' : 'black' }}
                className="fw-bold"
              >
                {adjustment.amount < 0 && '('}
                {adjustment.amount < 0
                  ? (adjustment.amount * -1).toFixed(3)
                  : adjustment.amount.toFixed(3)}
                {adjustment.amount < 0 && ')'}
                &#37;
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    );
  }

  // price adjustments
  let totalPrice = 0;
  for (let i = 0; i < props.priceAdjustments.length; i++)
    totalPrice += props.priceAdjustments[i].amount;
  const adjustmentsWithTotal = props.priceAdjustments.concat({
    reason: 'Total Price Adjustments',
    amount: totalPrice,
  });
  if (props.priceAdjustments.length > 0) {
    displayPriceAdj = (
      <table className="table">
        <tbody
          style={{
            display: 'block',
            overflow: 'auto',
            width: '570px',
            maxHeight: '300px',
            borderRadius: '5px',
          }}
        >
        {adjustmentsWithTotal.map(adjustment => (
          <tr>
            <td>
              {adjustment.reason === 'Total Price Adjustments' ? (
                <b>{adjustment.reason}</b>
              ) : (
                adjustment.reason
              )}
            </td>
            <td>
              <div
                style={{ width: '80px', textAlign: 'center', color: Number(adjustment.amount) < 0 ? 'red' : 'black' }}
                className="fw-bold"
              >
                {adjustment.amount < 0 && '('}
                {adjustment.amount < 0
                  ? (adjustment.amount * -1).toFixed(3)
                  : adjustment.amount.toFixed(3)}
                {adjustment.amount < 0 && ')'}
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    );
  }
  if (
    props.rateAdjustments.length === 0 &&
    props.priceAdjustments.length === 0
  ) {
    return <div style={{ marginLeft: '10px' }}>No Adjustments</div>;
  } else {
    return (
      <>
        {displayRateAdj}
        {displayPriceAdj}
      </>
    );
  }
}
