import React from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalCard = styled.div`
  border-radius: 1rem;
  z-index: 9999;
  width: ${p => (p.width ? p.width : '700px')};
  min-height: ${p => (p.height ? p.height : '500px')};
  max-height: 90vh;
  background-color: ${p => p.theme.colors.white};
  margin: 0 auto;
  padding: 1rem;
  align-self: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export default function ModalWait(props) {
  return props.show ? (
    <ModalWrapper>
      <ModalCard width="350px" height="200px">
        <div style={{ width: '100%', textAlign: 'center' }}>
          <br />
          <br />
          <b>{props.title}</b>
          <br />
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      </ModalCard>
    </ModalWrapper>
  ) : (
    <></>
  );
}
