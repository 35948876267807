import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('time', {
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('smallDate', {
    cell: info => <small className="text-muted ms-4">{info.getValue()}</small>,
  }),
  columnHelper.accessor('tag', {
    cell: info => (
      <span className="badge text-bg-danger ms-4">{info.getValue()}</span>
    ),
  }),
];

export default function DailyRates(props) {
  const [data, _setData] = useState(() => []);
  const [alert, setAlert] = useState();
  const [expanded, setExpanded] = useState(true);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    if (!data[0]) getRates();
  }, []);

  const getRates = async () => {
    const response = axios.get('/GetDailyRates');
    try {
      var resultData = (await response).data;
      _setData(resultData);
    } catch {
      console.log('Error fetching daily rates');
    }
  };

  const openFile = async ({ original }) => {
    var response = axios.get('/GetRatesheetFile', {
      params: { pFileName: original.fileTime },
    });
    try {
      var resultData = (await response).data;
      var binary = atob(resultData);
      var array = [];
      for (var j = 0; j < binary.length; j++) {
        array.push(binary.charCodeAt(j));
      }
      var file = new Blob([new Uint8Array(array)]);

      // this code below will download the file we selected
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download =
        'Daily Rate Sheet (' +
        original.fileTime.replaceAll('/', '-').replaceAll(':', '.') +
        ' PST)' +
        (original.tag ? ' [' + original.tag + ']' : '') +
        '.pdf';
      a.click();
      URL.revokeObjectURL(url);
    } catch {
      setAlert({
        type: 'danger',
        message: 'Failed to retrieve file from server.',
      });
    }
  };

  const toggleListSize = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="container">
      <div className="row">
        {/* HEADER */}
        <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-2">
          <h1 className="h4 text-uppercase mb-sm-0">daily rate sheets</h1>
          {/* <p className="d-sm-none mb-0">
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </p> */}
          <div className="hstack gap-1">
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={toggleListSize}
            >
              <i
                className={`fs-6 bi bi-arrows-${
                  expanded ? 'collapse' : 'expand'
                }`}
              ></i>
            </button>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => table.firstPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <i className="fs-6 bi bi-chevron-double-left"></i>
            </button>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <i className="fs-6 bi bi-chevron-left"></i>
            </button>
            <p className="mb-0 mx-md-2">
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </p>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <i className="fs-6 bi bi-chevron-right"></i>
            </button>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => table.lastPage()}
              disabled={!table.getCanNextPage()}
            >
              <i className="fs-6 bi bi-chevron-double-right"></i>
            </button>
            <select
              className="form-select w-auto"
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* TABLE */}
        <div className="col-12">
          {/* <div className="table-responsive bg-white rounded border"> */}
          <div className="list-group">
            {table.getRowModel().rows.map(row => (
              <button
                key={row.id}
                onClick={() => openFile(row)}
                className={`list-group-item list-group-item-action d-flex align-items-center ${
                  expanded ? 'p-2 px-3' : 'p-1 px-2'
                }`}
              >
                <i
                  className={`bi bi-download me-3 ${
                    expanded ? 'fs-5' : 'fs-6'
                  }`}
                ></i>
                {row.getVisibleCells().map(cell => (
                  <strong key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    {/* Daily Rate Sheet ({row.time} PST) */}
                  </strong>
                ))}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
