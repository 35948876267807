import {
  Verify,
  Email,
  AutoCheck,
  SignOff,
  FindLabelFromName,
  TableZero,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS } from '../../helpers/constants';
import { getPropertyType, isAdmin } from '../../helpers/utils';
import Toast from '../Toast';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class ComplianceChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      ltvFico: [],
      disclosureDates: [],
      isLoading: true,
      errorMessage: '',
      checkList: {
        complete: { checked: false },
        importantDates: { checked: false },
        initialDisclosure: { checked: false },
        borrowerAuthDate: { checked: false },
        vestingConfirm: { checked: false },
        idEmails: { checked: false },
        prepaidInterest: { checked: false },
        feeTolerance: { checked: false },
        appraisal: { checked: false },
        titleSignatures: { checked: false },
        titleNames: { checked: false },
        sellerCredit: { checked: false },
        leEmails: { checked: false },
        serviceFees: { checked: false },
        borrowerToSeller: { checked: false },
        cashToClose: { checked: false },
        escrowZeroed: { checked: false },
        sellerCreditsZeroed: { checked: false },
        emdPayoff: { checked: false },
        autoFill: { checked: false },
        conditionsCleared: { checked: false },
        disclosureHistory: { checked: false },
        icdEmails: { checked: false },
      },
      showEmailTemplate: false,
      emailTemplate: [],

      idBorrowerTemplate: [],
      idProcessorTemplate: [],
      lleBorrowerTemplate: [],
      lleProcessorTemplate: [],
      rleBorrowerTemplate: [],
      rleProcessorTemplate: [],
      icdBorrowerTemplate: [],
      icdProcessorTemplate: [],
      icdEscrowTemplate: [],
      idBorrowerEmailLog: [],
      idProcessorEmailLog: [],
      lleBorrowerEmailLog: [],
      lleProcessorEmailLog: [],
      rleBorrowerEmailLog: [],
      rleProcessorEmailLog: [],
      icdBorrowerEmailLog: [],
      icdProcessorEmailLog: [],
      icdEscrowEmailLog: [],
    };
    this.getComplianceData();
  }

  async getComplianceData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'CMP' },
    });
    // the response.data will contain the values returned
    var checkData = (await response).data;
    var checklistData = this.state.checkList;
    for (var i = 0; i < checkData.length; i++) {
      checklistData[checkData[i].fieldName] = {
        checked: checkData[i].isChecked === 'True',
        timestamp: checkData[i].timestamp,
        userName: checkData[i].userName,
      };
    }

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    response = axios.get('/getcompliancefields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var complianceFields = (await response).data;
    this.setState({
      fileData: resultData,
      checkList: checklistData,
      disclosureDates: complianceFields.disclosures,
      sellCredit: complianceFields.sellCredit === '1',
      originalSellCredit: complianceFields.sellCredit === '1',
      creditReportFee: complianceFields.creditReportFee,
      floodCertFee: complianceFields.floodCertFee,
      titleBorrowerReponsible: complianceFields.titleBorrowerReponsible === '1',
      useAltCashToClose: complianceFields.useAltCashToClose === 'True',
      autoFilled: complianceFields.autoFilled === '1',
      escrowZeroed: complianceFields.escrowZeroed || '',
      sellerCreditsZeroed: complianceFields.sellerCreditsZeroed || '',
      creditReportDates: complianceFields.creditReportDates,
      idBorrowerTemplate: complianceFields.idBorrowerTemplate,
      idBorrowerEmailLog: complianceFields.idBorrowerEmailLog,
      idProcessorTemplate: complianceFields.idProcessorTemplate,
      idProcessorEmailLog: complianceFields.idProcessorEmailLog,
      lleBorrowerTemplate: complianceFields.lleBorrowerTemplate,
      lleBorrowerEmailLog: complianceFields.lleBorrowerEmailLog,
      lleProcessorTemplate: complianceFields.lleProcessorTemplate,
      lleProcessorEmailLog: complianceFields.lleProcessorEmailLog,
      rleBorrowerTemplate: complianceFields.rleBorrowerTemplate,
      rleBorrowerEmailLog: complianceFields.rleBorrowerEmailLog,
      rleProcessorTemplate: complianceFields.rleProcessorTemplate,
      rleProcessorEmailLog: complianceFields.rleProcessorEmailLog,
      icdBorrowerTemplate: complianceFields.icdBorrowerTemplate,
      icdBorrowerEmailLog: complianceFields.icdBorrowerEmailLog,
      icdProcessorTemplate: complianceFields.icdProcessorTemplate,
      icdProcessorEmailLog: complianceFields.icdProcessorEmailLog,
      icdEscrowTemplate: complianceFields.icdEscrowTemplate,
      icdEscrowEmailLog: complianceFields.icdEscrowEmailLog,
      isLoading: false,
    });
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'CMP',
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2].toString().trim(), // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][4].substring(1)]: this.state[fieldsToUpdate[i][4]]
            .toString()
            .trim(),
          [fieldsToUpdate[i][4] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  setFieldFalse(fieldName) {
    const { checkList } = this.state;
    if (checkList[fieldName] && checkList[fieldName].checked === true) {
      checkList[fieldName].checked = false;
      this.setChecklistField(fieldName, false);
    }
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'CMP-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if (!checked) {
      checkList.complete.checked = false;
      this.setChecklistField('complete', false);
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    const { fileData, titleBorrowerReponsible, autoFilled } = this.state;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (prop === 'complete') continue;
      if (
        prop === 'titleSignatures' &&
        !(
          fileData.occupancyType.includes('Primary') &&
          fileData.loanPurpose.includes('Refinance')
        )
      )
        continue;
      if (
        prop === 'borrowerToSeller' &&
        !fileData.loanPurpose.includes('Purchase')
      )
        continue;
      if (prop === 'cashToClose') {
        if (!fileData.loanPurpose.includes('Refinance')) continue;
        else if (this.state.useAltCashToClose) checkedCount++;
      }
      visibleCount++;
      if (prop === 'rateLocked') {
        if (fileData.lockStartDate !== '---') checkedCount++;
        continue;
      }
      if (prop === 'autoFill') {
        if (autoFilled) checkedCount++;
        continue;
      }
      if (
        Object.prototype.hasOwnProperty.call(checklistData, prop) &&
        checklistData[prop].checked
      )
        checkedCount++;
    }
    var completePercent = this.state.fileData.completePercent
      ?.split('CMP')[1]
      .split('(')[1]
      .split(')')[0];
    if (
      completePercent.toString() !==
      Math.round((checkedCount / visibleCount) * 100).toString()
    ) {
      this.state.fileData.completePercent =
        this.state.fileData.completePercent?.split('CMP')[0] +
        'CMP (' +
        Math.round((checkedCount / visibleCount) * 100).toString() +
        ')' +
        this.state.fileData.completePercent
          ?.split('CMP')[1]
          .substring(
            this.state.fileData.completePercent?.split('CMP')[1].indexOf(')') +
              1
          );
      this.props.updatePercent(
        'CMP',
        Math.round((checkedCount / visibleCount) * 100).toString()
      );
    }
    if (checkedCount < visibleCount) return false;
    return true;
  }

  markComplete() {
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('CMP', 'complete');
  }

  async setToSellerCost() {
    var response = axios.get('/setFeeToSellerCost', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if (resultData === 'Success') {
      this.setState({ titleBorrowerReponsible: false });
    }
  }

  async setUseAltCashToClose() {
    var response = axios.get('/setUseAltCashToClose', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if (resultData === 'Success') {
      this.setState({ useAltCashToClose: true });
    }
  }

  async autoFillCdPage4() {
    var response = axios.get('/autoFillCdPage4', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    if (resultData === 'Success') {
      this.setState({ autoFilled: true });
    }
  }

  render() {
    const {
      isLoading,
      fileData,
      checkList,
      disclosureDates,
      creditReportFee,
      floodCertFee,
      titleBorrowerReponsible,
      useAltCashToClose,
      autoFilled,
    } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <h1 className="h5">Compliance Checklist</h1>
          <div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h1 className="h5">Compliance Checklist</h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* Show Disclosures Here */}
          {disclosureDates.length > 0 && (
            <div className="table-responsive mb-4">
              <table className="table small">
                <thead>
                  <tr>
                    <th className="text-start">Disclosure Type</th>
                    <th className="text-center">Sent Date</th>
                    <th className="text-center">Received Date</th>
                    <th className="text-center">Rate Locked</th>
                    <th className="text-center">Delivery Method</th>
                  </tr>
                </thead>
                <tbody>
                  {disclosureDates.map(disclosure => (
                    <tr key={disclosure.index}>
                      <td>{disclosure.documentType}</td>
                      <td className="text-center">{disclosure.sentDate}</td>
                      <td className="text-center">{disclosure.receivedDate}</td>
                      <td className="text-center">{disclosure.rateLocked}</td>
                      <td className="text-center">
                        {disclosure.deliveryMethod}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Important Dates */}
          <ChecklistCard
            checked={checkList.importantDates.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="importantDates"
                  className="form-check-input"
                  name="importantDates"
                  checked={checkList.importantDates.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="importantDates">
                  Important Dates
                </label>
              </div>
            }
            body={
              <div>
                <Verify
                  tip={
                    'Check Disclosure Dates Above\nAnd Important Dates on Right Side'
                  }
                />
                Dates In Compliance
              </div>
            }
            alert={
              checkList.importantDates.checked && (
                <SignOff
                  user={checkList.importantDates.userName}
                  date={checkList.importantDates.timestamp}
                />
              )
            }
          />

          {/* Initial Disclosure */}
          <ChecklistCard
            checked={checkList.initialDisclosure.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="initialDisclosure"
                  className="form-check-input"
                  name="initialDisclosure"
                  checked={checkList.initialDisclosure.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="initialDisclosure">
                  Initial Disclosure
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify
                    tip={
                      fileData.programCode.includes('A') &&
                      !fileData.programCode.includes('AF')
                        ? 'For ARM, Once Rate Locked\nSet Index Date to (Lock Date)'
                        : 'Set Index Date to Application Date'
                    }
                  />{' '}
                  Set Index Date
                </div>
                <div>
                  <Verify tip={'Yes / No'} /> Rate Lock
                </div>
                <div>
                  <Verify /> Lender Credits
                </div>
                <div>
                  <Verify /> Signed ID Uploaded
                </div>
                <div>
                  <Verify /> Update Disclosure History
                </div>
              </div>
            }
            alert={
              checkList.initialDisclosure.checked && (
                <SignOff
                  user={checkList.initialDisclosure.userName}
                  date={checkList.initialDisclosure.timestamp}
                />
              )
            }
          />

          {/* Borrower Authorization Date */}
          <ChecklistCard
            checked={checkList.borrowerAuthDate.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="borrowerAuthDate"
                  className="form-check-input"
                  name="borrowerAuthDate"
                  checked={checkList.borrowerAuthDate.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="borrowerAuthDate">
                  Borrower Auth Date
                </label>
              </div>
            }
            body={
              <div>
                <TableZero>
                  <tbody>
                    <tr>
                      <td>
                        <Verify
                          tip={
                            'Get Borrower Auth Date From Document With Wet Signature'
                          }
                        />
                      </td>
                      <td>
                        Before Or On Credit Report Date(s):
                        <br />
                        <b>{this.state.creditReportDates}</b>
                      </td>
                    </tr>
                  </tbody>
                </TableZero>
              </div>
            }
            alert={
              checkList.borrowerAuthDate.checked && (
                <SignOff
                  user={checkList.borrowerAuthDate.userName}
                  date={checkList.borrowerAuthDate.timestamp}
                />
              )
            }
          />

          {/* Vesting Confim */}
          <ChecklistCard
            checked={checkList.vestingConfirm.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="vestingConfirm"
                  className="form-check-input"
                  name="vestingConfirm"
                  checked={checkList.vestingConfirm.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="vestingConfirm">
                  Vesting Confirm
                </label>
              </div>
            }
            body={
              <div>
                <TableZero>
                  <tbody>
                    <tr>
                      <td>
                        <Verify />
                      </td>
                      <td>
                        Title Vesting (Escrow Instruction, <br />
                        Insurance Policy)
                      </td>
                    </tr>
                  </tbody>
                </TableZero>
              </div>
            }
            alert={
              checkList.vestingConfirm.checked && (
                <SignOff
                  user={checkList.vestingConfirm.userName}
                  date={checkList.vestingConfirm.timestamp}
                />
              )
            }
          />

          {/* ID Emails */}
          <ChecklistCard
            checked={checkList.idEmails.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="idEmails"
                  className="form-check-input"
                  name="idEmails"
                  checked={checkList.idEmails.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="idEmails">
                  Send Emails
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Email />
                  ID Package (Borrower)
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.idBorrowerTemplate,
                        emailLog: this.state.idBorrowerEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.idBorrowerEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.idBorrowerEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
                <div>
                  <Email />
                  ID Package (Processor/LO)
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.idProcessorTemplate,
                        emailLog: this.state.idProcessorEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.idProcessorEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.idProcessorEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
            }
            alert={
              checkList.idEmails.checked && (
                <SignOff
                  user={checkList.idEmails.userName}
                  date={checkList.idEmails.timestamp}
                />
              )
            }
          />

          {/* Prepaid Interest */}
          <ChecklistCard
            checked={checkList.prepaidInterest.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="prepaidInterest"
                  className="form-check-input"
                  name="prepaidInterest"
                  checked={checkList.prepaidInterest.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="prepaidInterest">
                  Prepaid Daily Interest
                </label>
              </div>
            }
            body={
              <div>
                <Verify
                  tip={
                    'Check to See That The Amount Is Updated\nBased On the Funding Date'
                  }
                />
                Amount Updated
              </div>
            }
            alert={
              checkList.prepaidInterest.checked && (
                <SignOff
                  user={checkList.prepaidInterest.userName}
                  date={checkList.prepaidInterest.timestamp}
                />
              )
            }
          />

          {/* Fee Tolerance */}
          <ChecklistCard
            checked={checkList.feeTolerance.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="feeTolerance"
                  className="form-check-input"
                  name="feeTolerance"
                  checked={checkList.feeTolerance.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="feeTolerance">
                  Fee Tolerance
                </label>
              </div>
            }
            body={
              <div>
                <Verify
                  tip={
                    'Ensure That Any Changed Fees Are\nWithin The Allowable Tolerance'
                  }
                />
                Any Changed Fees
              </div>
            }
            alert={
              checkList.feeTolerance.checked && (
                <SignOff
                  user={checkList.feeTolerance.userName}
                  date={checkList.feeTolerance.timestamp}
                />
              )
            }
          />

          {/* Appraisal */}
          <ChecklistCard
            checked={checkList.appraisal.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="appraisal"
                  className="form-check-input"
                  name="appraisal"
                  checked={checkList.appraisal.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="appraisal">
                  Appraisal
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify />
                  Effective Date
                </div>
                <div>
                  <Verify tip={'Check That Invoice Amounts Match'} />
                  Invoice Amounts
                </div>
                <div>
                  <Verify />
                  Check POC
                </div>
              </div>
            }
            alert={
              checkList.appraisal.checked && (
                <SignOff
                  user={checkList.appraisal.userName}
                  date={checkList.appraisal.timestamp}
                />
              )
            }
          />

          {/* Title Signatures */}
          {/* Only For Primary Residence + Refinance */}
          {fileData.occupancyType.includes('Primary') &&
            fileData.loanPurpose.includes('Refinance') && (
              <ChecklistCard
                checked={checkList.titleSignatures.checked}
                label={
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="titleSignatures"
                      className="form-check-input"
                      name="titleSignatures"
                      checked={checkList.titleSignatures.checked}
                      onChange={this._toggleCheckbox}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="titleSignatures"
                    >
                      Title Signatures
                    </label>
                  </div>
                }
                body={
                  <div>
                    <Verify />
                    Signatures for Each Non-Borrower On Title
                  </div>
                }
                alert={
                  checkList.titleSignatures.checked && (
                    <SignOff
                      user={checkList.titleSignatures.userName}
                      date={checkList.titleSignatures.timestamp}
                    />
                  )
                }
              />
            )}

          {/* Title Names */}
          <ChecklistCard
            checked={checkList.titleNames.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="titleNames"
                  className="form-check-input"
                  name="titleNames"
                  checked={checkList.titleNames.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="titleNames">
                  Title Holders
                </label>
              </div>
            }
            body={
              <div>
                <Verify />
                All Title Holder Names
              </div>
            }
            alert={
              checkList.titleNames.checked && (
                <SignOff
                  user={checkList.titleNames.userName}
                  date={checkList.titleNames.timestamp}
                />
              )
            }
          />

          {/* Credit From Seller/Agent */}
          <ChecklistCard
            checked={checkList.sellerCredit.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="sellerCredit"
                  className="form-check-input"
                  name="sellerCredit"
                  checked={checkList.sellerCredit.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="sellerCredit">
                  Credit From Seller
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify tip="Credit From Seller/Agent" />
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="yes"
                      name="sellCredit"
                      checked={this.state.sellCredit}
                      onChange={() => void 0}
                      disabled={checkList.sellerCredit.checked}
                      onClick={() => {
                        if (!this.state.sellCredit) {
                          this.setState({ sellCredit: true });
                          this.updateDynamicDataField([
                            ['SellCredit', '', '1', '', 'sellCredit'],
                          ]);
                          checkList.sellerCredit.checked = false;
                          this.setChecklistField('sellerCredit', false);
                        }
                      }}
                    />
                    <label htmlFor="yes" className="form-check-label">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="no"
                      className="form-check-input"
                      name="sellCredit"
                      checked={!this.state.sellCredit}
                      onChange={() => void 0}
                      disabled={checkList.sellerCredit.checked}
                      onClick={() => {
                        if (this.state.sellCredit) {
                          this.setState({ sellCredit: false });
                          this.updateDynamicDataField([
                            ['SellCredit', '', '0', '', 'sellCredit'],
                          ]);
                          checkList.sellerCredit.checked = false;
                          this.setChecklistField('sellerCredit', false);
                        }
                      }}
                    />
                    <label htmlFor="no" className="form-check-label">
                      No
                    </label>
                  </div>
                </div>
                {this.state.sellCredit && (
                  <div>
                    <Verify tip="If Seller/Agent Credit Then Verify" />
                    PTF Condition Added
                  </div>
                )}
              </div>
            }
            alert={
              checkList.sellerCredit.checked && (
                <SignOff
                  user={checkList.sellerCredit.userName}
                  date={checkList.sellerCredit.timestamp}
                />
              )
            }
          />

          {/* Rate Locked */}
          <ChecklistCard
            checked={fileData.lockStartDate !== '---'}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="rateLocked"
                  className="form-check-input"
                  name="rateLocked"
                  checked={fileData.lockStartDate !== '---'}
                  disabled={true}
                />
                <label className="form-check-label" htmlFor="rateLocked">
                  Rate Locked
                </label>
              </div>
            }
            body={
              <div>
                {fileData.lockStartDate !== '---'
                  ? fileData.intRate + ' @ ' + fileData.lockStartDate
                  : 'Not Locked Yet!'}
              </div>
            }
            alert={<AutoCheck passed={fileData.lockStartDate !== '---'} />}
          />

          {/* LE Emails */}
          <ChecklistCard
            checked={checkList.leEmails.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="leEmails"
                  className="form-check-input"
                  name="leEmails"
                  checked={checkList.leEmails.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="leEmails">
                  Send Emails
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Email />
                  Locked LE (Borrower)
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.lleBorrowerTemplate,
                        emailLog: this.state.lleBorrowerEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.lleBorrowerEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.lleBorrowerEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
                <div>
                  <Email />
                  Locked LE (Processor/LO)
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.lleProcessorTemplate,
                        emailLog: this.state.lleProcessorEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.lleProcessorEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.lleProcessorEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
                <div>
                  <Email />
                  Revised LE (Borrower)
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.rleBorrowerTemplate,
                        emailLog: this.state.rleBorrowerEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.rleBorrowerEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.rleBorrowerEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
                <div>
                  <Email />
                  Revised LE (Processor/LO)
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.rleProcessorTemplate,
                        emailLog: this.state.rleProcessorEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.rleProcessorEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.rleProcessorEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
            }
            alert={
              checkList.leEmails.checked && (
                <SignOff
                  user={checkList.leEmails.userName}
                  date={checkList.leEmails.timestamp}
                />
              )
            }
          />

          {/* Service Fees */}
          <ChecklistCard
            checked={checkList.serviceFees.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="serviceFees"
                  className="form-check-input"
                  name="serviceFees"
                  disabled={floodCertFee === ' (Not Found)'}
                  checked={checkList.serviceFees.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="serviceFees">
                  Service Fees
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify tip={'Correct Fee Amounts Charged'} />
                  Services You Cannot Shop For
                </div>
                <div>
                  <Verify
                    tip={
                      'Individual ($27.45)\nCouple [Co-Borrowers] ($42.90)\nOthers ($27.45 x # of Borrowers)'
                    }
                  />
                  Credit Report Fee {creditReportFee}
                </div>
                <div>
                  <Verify tip={'Fee: $13'} />
                  Flood Certification {floodCertFee}
                </div>
              </div>
            }
            alert={
              floodCertFee === ' (Not Found)' ? (
                <AutoCheck passed={false} tip={'Flood Cert Fee - Not Found'} />
              ) : (
                <React.Fragment>
                  {checkList.serviceFees.checked && (
                    <SignOff
                      user={checkList.serviceFees.userName}
                      date={checkList.serviceFees.timestamp}
                    />
                  )}
                </React.Fragment>
              )
            }
          />

          {/* Borrower Fee To Seller Cost */}
          {fileData.loanPurpose.includes('Purchase') && (
            <ChecklistCard
              checked={checkList.borrowerToSeller.checked}
              label={
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="borrowerToSeller"
                    className="form-check-input"
                    name="borrowerToSeller"
                    checked={checkList.borrowerToSeller.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label
                    className="form-check-label"
                    style={{ paddingLeft: '8px' }}
                    htmlFor="borrowerToSeller"
                  >
                    Fee Classification
                  </label>
                </div>
              }
              body={
                <div>
                  <Verify
                    tip={
                      'Should Be Changed to Seller Cost\nAfter Initial CD Sent Out'
                    }
                  />
                  Title Owner Policy
                  {titleBorrowerReponsible && (
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      disabled={
                        !titleBorrowerReponsible ||
                        checkList.borrowerToSeller.checked
                      }
                      onClick={() => this.setToSellerCost()}
                    >
                      Change
                    </button>
                  )}
                </div>
              }
              alert={
                checkList.borrowerToSeller.checked && (
                  <SignOff
                    user={checkList.borrowerToSeller.userName}
                    date={checkList.borrowerToSeller.timestamp}
                  />
                )
              }
            />
          )}

          {/* Escrow Zeroed */}
          <ChecklistCard
            checked={checkList.escrowZeroed.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="escrowZeroed"
                  className="form-check-input"
                  name="escrowZeroed"
                  checked={checkList.escrowZeroed.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="escrowZeroed">
                  Escrow Account
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Values Set to Zero" />
                Impound (Tax, Insurance)
              </div>
            }
            alert={
              checkList.escrowZeroed.checked && (
                <SignOff
                  user={checkList.escrowZeroed.userName}
                  date={checkList.escrowZeroed.timestamp}
                />
              )
            }
          />

          {/* Seller Credits Zeroed */}
          <ChecklistCard
            checked={checkList.sellerCreditsZeroed.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="sellerCreditsZeroed"
                  className="form-check-input"
                  name="sellerCreditsZeroed"
                  checked={checkList.sellerCreditsZeroed.checked}
                  onChange={this._toggleCheckbox}
                />
                <label
                  className="form-check-label"
                  htmlFor="sellerCreditsZeroed"
                >
                  Seller/Agent Credits
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Values Set to Zero" />
                Zero Balance
              </div>
            }
            alert={
              checkList.sellerCreditsZeroed.checked && (
                <SignOff
                  user={checkList.sellerCreditsZeroed.userName}
                  date={checkList.sellerCreditsZeroed.timestamp}
                />
              )
            }
          />

          {/* EMD, Payoff Amount */}
          <ChecklistCard
            checked={checkList.emdPayoff.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="emdPayoff"
                  className="form-check-input"
                  name="emdPayoff"
                  checked={checkList.emdPayoff.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="emdPayoff">
                  EMD
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip={'Earnest Money Deposit'} /> Payoff Amount
              </div>
            }
            alert={
              checkList.emdPayoff.checked && (
                <SignOff
                  user={checkList.emdPayoff.userName}
                  date={checkList.emdPayoff.timestamp}
                />
              )
            }
          />

          {/* Cash To Close */}
          {fileData.loanPurpose.includes('Refinance') && (
            <ChecklistCard
              checked={useAltCashToClose}
              label={
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="cashToClose"
                    className="form-check-input"
                    name="cashToClose"
                    disabled={true}
                    checked={useAltCashToClose}
                  />
                  <label className="form-check-label" htmlFor="cashToClose">
                    Alt Cash To Close
                  </label>
                </div>
              }
              body={
                <div>
                  <Verify
                    tip={'For Refinances This Setting\nShould Be Checked (Yes)'}
                  />
                  Check Yes
                  {!useAltCashToClose && (
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      disabled={useAltCashToClose}
                      onClick={() => this.setUseAltCashToClose()}
                    >
                      Change
                    </button>
                  )}
                </div>
              }
              alert={
                <AutoCheck
                  passed={useAltCashToClose}
                  tip="Use Alt Cash to Close\nNot Selected!"
                />
              }
            />
          )}

          {/* Auto Fill */}
          <ChecklistCard
            checked={autoFilled}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="autoFilled"
                  className="form-check-input"
                  name="autoFilled"
                  disabled={true}
                  checked={autoFilled}
                />
                <label className="form-check-label" htmlFor="autoFilled">
                  Auto Fill
                </label>
              </div>
            }
            body={
              <div>
                <Verify
                  tip={
                    "Set Fields\n[Assumption] = 'Not Allowed'\n[Late Payment] = '15 Days'\n" +
                    "[Late Fee] = '5%'\n[Partial Payment] = 'Not Accepted'"
                  }
                />
                CD Page 4 Fields
                {!autoFilled && (
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    disabled={autoFilled}
                    onClick={() => this.autoFillCdPage4()}
                  >
                    Change
                  </button>
                )}
              </div>
            }
            alert={
              <AutoCheck passed={autoFilled} tip={'Fields Not Yet Updated!'} />
            }
          />

          {/* Conditions Cleared */}
          <ChecklistCard
            checked={checkList.conditionsCleared.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="conditionsCleared"
                  className="form-check-input"
                  name="conditionsCleared"
                  checked={checkList.conditionsCleared.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="conditionsCleared">
                  Conditions Cleared
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Received and Cleared" />
                ID and TRID Conditions
              </div>
            }
            alert={
              checkList.conditionsCleared.checked && (
                <SignOff
                  user={checkList.conditionsCleared.userName}
                  date={checkList.conditionsCleared.timestamp}
                />
              )
            }
          />

          {/* Disclosure History */}
          <ChecklistCard
            checked={checkList.disclosureHistory.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="disclosureHistory"
                  className="form-check-input"
                  name="disclosureHistory"
                  checked={checkList.disclosureHistory.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="disclosureHistory">
                  Disclosure History
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Dates and Amounts Correct" />
                Disclosure History and TRID Documents
              </div>
            }
            alert={
              checkList.disclosureHistory.checked && (
                <SignOff
                  user={checkList.disclosureHistory.userName}
                  date={checkList.disclosureHistory.timestamp}
                />
              )
            }
          />

          {/* ICD Emails */}
          <ChecklistCard
            checked={checkList.icdEmails.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  id="icdEmails"
                  className="form-check-input"
                  name="icdEmails"
                  checked={checkList.icdEmails.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="icdEmails">
                  Send Emails
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Email />
                  Initial CD (Borrower)
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.icdBorrowerTemplate,
                        emailLog: this.state.icdBorrowerEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.icdBorrowerEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.icdBorrowerEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
                <div>
                  <Email />
                  Initial CD (Processor/LO)
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.icdProcessorTemplate,
                        emailLog: this.state.icdProcessorEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.icdProcessorEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.icdProcessorEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
                <div>
                  <Email />
                  Initial CD (Escrow)
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.icdEscrowTemplate,
                        emailLog: this.state.icdEscrowEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.icdEscrowEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.icdEscrowEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
            }
            alert={
              checkList.icdEmails.checked && (
                <SignOff
                  user={checkList.icdEmails.userName}
                  date={checkList.icdEmails.timestamp}
                />
              )
            }
          />

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}

          <ModalEmail
            show={this.state.showEmailTemplate}
            template={this.state.emailTemplate}
            fileID={this.state.activeFile}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
            onAccept={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
          />
          <ModalEmailLog
            show={this.state.showEmailLog}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailLog: false, emailLog: [] })
            }
          />
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
        </div>
      </React.Fragment>
    );
  }
}
