import React, { Component } from 'react';
import axios from 'axios';

class DownloadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      folderName: '',
      searching: false,
      searchChanged: false,
      files: [],
      fileSize: 0,
      fileChunks: 0,
      fileProcessed: false,
      fileRead: false,
      currentFile: '',
      statusMsg: '',
      chunkArray: [],
    };
  }

  async getFilesInFoler() {
    this.setState({
      searching: true,
      searchChanged: false,
      fileRead: false,
      currentFile: '',
    });
    var response = axios.get('/getFilesInFolder', {
      params: { pFolderName: this.state.folderName },
    });
    try {
      var resultData = (await response).data;
      this.setState({
        files: resultData,
        searching: false,
        message: !resultData.lentgh && 'No files found',
      });
    } catch {
      this.setState({
        files: [],
        searching: false,
        message: 'Error getting files',
      });
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      searchChanged: true,
      fileRead: false,
    });
  };

  fileNameOnly(fileWithFolder) {
    var fileName = fileWithFolder;
    while (fileName.indexOf('\\') >= 0) {
      fileName = fileName.substr(fileName.indexOf('\\') + 1);
    }
    return fileName;
  }

  async showFile(filePath) {
    this.setState({ searching: true, searchChanged: false });
    var response = axios.get('/getFileData', {
      params: { pFileName: filePath },
    });
    try {
      var resultData = (await response).data;
      var cArray = [];
      for (var i = 0; i < parseInt(resultData.chunks); i++) {
        cArray.push({ index: i, downloaded: false });
      }
      console.log('processed: ' + resultData.processed);
      this.setState({
        searching: false,
        fileSize: resultData.fileSize,
        fileChunks: resultData.fileChunks,
        fileRead: true,
        chunkArray: cArray,
        currentFile: filePath,
        fileProcessed: resultData.processed === true,
      });
    } catch {
      this.setState({
        searching: false,
        fileSize: 0,
        fileChunks: 0,
        fileRead: false,
        chunkArray: [],
        currentFile: '',
      });
    }
  }

  async processFile() {
    this.setState({ statusMsg: 'Processing File - Please Wait!' });
    var response = axios.get('/processFile', {
      params: { pFileName: this.state.currentFile },
    });
    var resultData = (await response).data;
    if (resultData)
      this.setState({
        statusMsg: 'File Processing Complete!',
        fileProcessed: true,
      });
  }

  async downloadChunk(chunkIndex) {
    // skip if already downloaded
    if (this.state.chunkArray[chunkIndex].downloaded === true) return;

    var cArray = this.state.chunkArray;
    cArray[chunkIndex].downloaded = true;
    this.setState({
      chunkArray: cArray,
      statusMsg: 'Download Chunk #' + (chunkIndex + 1) + ' - Function Called',
    });

    // else download and mark
    var response = axios.get('/getFileChunk', {
      params: { pFileName: this.state.currentFile, pChunkIndex: chunkIndex },
    });

    try {
      var resultData = (await response).data;
      this.setState({
        statusMsg: 'Download Chunk #' + (chunkIndex + 1) + ' - Server Response',
      });
      var binary = atob(resultData.fileData);
      var array = [];
      for (var j = 0; j < binary.length; j++) {
        array.push(binary.charCodeAt(j));
      }
      var file = new Blob([new Uint8Array(array)]);

      // this code below will download the file we selected
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = resultData.fileName;
      a.click();
      URL.revokeObjectURL(url);
      this.setState({
        statusMsg:
          'Download Chunk #' + (chunkIndex + 1) + ' - Download Initiated',
      });
    } catch {
      console.log('Failed to retrieve file data from server.');
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4>File download utility</h4>
          </div>
          <div className="col-12 d-flex gap-2">
            <input
              className="form-control"
              autoFocus
              name="folderName"
              onChange={this.captureInput}
              value={this.state.folderName}
              label="Folder Path"
              style={{ maxWidth: '400px' }}
            />
            <button
              className="btn btn-small btn-primary"
              disabled={this.state.searching}
              onClick={() => this.getFilesInFoler()}
            >
              Search
            </button>
          </div>
          {this.state.message && (
            <div className="col-12 py-3">
              <div className="alert alert-info" role="alert">
                <i className="bi bi-info-circle me-3" />
                {this.state.message}
              </div>
            </div>
          )}
        </div>
        <div className="row">
          {this.state.searching ? (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="/images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          ) : (
            <>
              {this.state.fileRead ? (
                <div>
                  <div className="ml-2 px-2 text-red">
                    {this.fileNameOnly(this.state.currentFile)}
                  </div>
                  <button
                    className="btn btn-primary btn-small"
                    disabled={this.state.fileProcessed}
                    style={{ marginLeft: '10px' }}
                    onClick={() => this.processFile()}
                  >
                    Process
                  </button>
                  <br />
                  <div
                    style={{
                      minWidth: '350px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      marginLeft: '10px',
                      backgroundColor: 'lightgray',
                      color: 'blue',
                    }}
                  >
                    {this.state.statusMsg}
                  </div>
                  <br />
                  {this.state.fileProcessed && (
                    <>
                      {this.state.chunkArray.map(chunk => (
                        <div
                          key={chunk.index}
                          onClick={() => this.downloadChunk(chunk.index)}
                          style={{
                            minWidth: '350px',
                            height: '40px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            marginLeft: '10px',
                            backgroundColor: chunk.downloaded
                              ? 'gray'
                              : 'white',
                          }}
                        >
                          {chunk.downloaded ? (
                            <i className="bi bi-check-circle mr-3" />
                          ) : (
                            <i className="bi bi-exclamation-triangle mr-3" />
                          )}{' '}
                          File Chunk #{chunk.index + 1}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ) : (
                <div>
                  {this.state.files.length === 0 || this.state.searchChanged ? (
                    <></>
                  ) : (
                    <>
                      {this.state.files.map(file => (
                        <div
                          key={file}
                          onClick={() => this.showFile(file)}
                          style={{
                            minWidth: '350px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            marginLeft: '10px',
                          }}
                        >
                          {this.fileNameOnly(file)}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default DownloadFile;
