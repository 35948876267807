import React from 'react';
import { isAdmin } from '../../helpers/utils';

function ChecklistCard({ label, body, alert, checked }) {
  return (
    <div className="row mb-3 g-lg-1">
      <div className="col-12 col-lg-10 d-flex">
        <div
          className="d-flex align-items-center bg-light p-3 border rounded-start"
          style={{ width: '180px' }}
        >
          {label}
        </div>
        <div className="d-flex align-items-center flex-grow-1 p-3 border border-start-0 rounded-end" 
          style={{pointerEvents: (checked && !isAdmin()) ? 'none' : 'auto', background: checked ? '#f7fffa' : 'white'}}>
          {body}
        </div>
      </div>
      <div className="col-12 col-lg-2">{alert}</div>
    </div>
  );
}

export default ChecklistCard;
