import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 0;
  background: linear-gradient(
    -30deg,
    ${p => (p.bg ? p.theme.colors.primary100 : 'transparent')},
    white,
    white
  );
  padding: 10vh 0;
`;

class TimedOut extends Component {
  render() {
    return (
      <Wrapper>
        <div
          style={{ marginTop: '100px', textAlign: 'center', fontSize: '36px' }}
        >
          Logged Out Due to Inactivity
          <br />
          <span style={{ fontSize: '24px' }}>(Timed Out After 6 Hours)</span>
          <br />
          <button
            className="btn btn-primary"
            onClick={() => this.props.history.push('/')}
          >
            Home/Login
          </button>
          <br />
          <img src={'/images/sleep.gif'} width="140px" height="140px" alt="" />
        </div>
      </Wrapper>
    );
  }
}

export default withRouter(TimedOut);
