import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function ForgotPassword() {
  const [processing, setProcessing] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const emailValidate =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const sendPasswordEmail = async values => {
    // first validate to make sure the email is valid
    if (!values.email) {
      setErrorMessage('Email Not Provided!');
      return;
    } else if (!emailValidate.test(String(values.email).toLowerCase())) {
      setErrorMessage('Invalid Email Format!');
      return;
    }

    // disable buttons while processing
    setProcessing(true);

    // then check with the server
    const response = axios.get('password', {
      params: {
        email: document.getElementById('emailTxt').value,
      },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    setProcessing(false);

    // if result shows it was sent, then display
    if (resultData !== '') {
      setErrorMessage('');
      setSuccessMsg('Password Sent To Email!');
      return;
    }
    // otherwise, show an error message that the email
    // was not found in our account records
    else {
      setErrorMessage(
        'A user account with the specified email address was not found'
      );
    }
  };

  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ height: '100vh' }}
    >
      <Form
        onSubmit={sendPasswordEmail}
        initialValues={{ email: '' }}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            style={{ maxWidth: '400px' }}
            className="mx-auto"
          >
            <div className="vstack gap-3 text-center">
              <h4>Reset Password</h4>
              <p>
                Please enter your email address and we'll send your password
                there.
              </p>
              <Field
                id="emailTxt"
                name="email"
                component="input"
                type="email"
                placeholder="Email Address"
                className="form-control form-control-lg"
              />
              <button
                className="btn btn-primary"
                id="SendBtn"
                onClick={handleSubmit}
                disabled={processing}
              >
                {processing ? 'Please Wait...' : 'Send'}
              </button>
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              {successMsg && (
                <div className="alert alert-success" role="alert">
                  {successMsg}
                </div>
              )}
              <Link to="/">
                <button type="button" className="btn btn-link">
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        )}
      />
    </div>
  );
}
