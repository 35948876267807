import {
  AutoCheck,
  Body,
  Verify,
  SignOff,
  TableZero,
  FindLabelFromName,
} from './FileDetailsHelpers';
import { unformatCurrency } from '../../helpers/utils';
import React, { Component } from 'react';
import axios from 'axios';
import { formatCurrency, numbersNoFormat, isAdmin } from '../../helpers/utils';
import Modal from '../Modal';
import ModalWait from '../ModalWait';
import LoginState from '../LoginState';
import AppraisalReceipt from './AppraisalReceipt';
import ModalEmailLog from './ModalEmailLog';
import Toast from '../Toast';
import { TinyTooltip } from '../Tooltip';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class AppraisalReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      retrievedData: false,
      appData: [],
      history: [],
      propertyValue: '',
      reviewText: '',
      isSubmitting: false,
      isUploading: false,
      showConfirmModal: false,
      showApproveModal: false,
      showResendModal: false,
      errorMsg: '',
      checkList: {
        appraisalCompany: { checked: false },
        propertyValue: { checked: false },
        adu: { checked: false },
        uwReviewed: { checked: false },
        complete: { checked: false },
      },
      apiError: null,
      disclosureEmailLog: [],
      showEmailLog: false,
      emailLog: [],
    };
  }

  componentDidMount() {
    this.PageLoad(this.props.id);
  }

  PageLoad(fileID) {
    this.setState({ appData: [], history: [], retrievedData: false });
    this.getAppraisalData(fileID);
  }

  async getAppraisalData(fileID) {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    var orderID = '';

    response = await axios.get('/getappraisaldata', {
      params: { pFileID: fileID },
    });
    try {
      const all = response.data.comments.concat(response.data.files);
      const sorted = all.sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );
      this.setState({
        propertyValue: response.data.appraisalValue,
        originalPropertyValue: response.data.appraisalValue,
        bytePropertyValue: resultData.propertyValue,
        appComp: response.data.appraisalCompany,
        aduExists: response.data.aduExists === '1' || false,
        disclosureEmailLog: response.data.disclosureEmailLog,
        piw: resultData.piw === '1',
        appData: response.data,
        history: sorted,
        retrievedData: true,
      });
      orderID = response.data.orderID;
    } catch {
      this.setState({
        propertyValue: response.data.appraisalValue,
        originalPropertyValue: response.data.appraisalValue,
        bytePropertyValue: resultData.propertyValue,
        appComp: response.data.appraisalCompany,
        aduExists: response.data.aduExists === '1' || false,
        disclosureEmailLog: [],
        piw: resultData.piw === '1',
        appData: [],
        history: [],
        retrievedData: true,
      });
    }

    var paymentDetails = {};
    try {
      const response2 = await axios.get('/getpaymentdetails', {
        params: {
          pOrderID: orderID,
          pUserName: LoginState.userName,
        },
      });
      if (response2.data) {
        paymentDetails = response2.data;
      }
    } catch (e) {
      this.setState({ apiError: e.message });
    }

    // get checklist data
    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'APR' },
    });
    var checklistData = this.state.checkList;
    var checkData = (await response).data;
    for (var k = 0; k < checkData.length; k++) {
      checklistData[checkData[k].fieldName] = {
        checked: checkData[k].isChecked === 'True',
        timestamp: checkData[k].timestamp,
        userName: checkData[k].userName,
      };
    }
    this.setState({ checkList: checklistData, paymentDetails: paymentDetails });
  }

  async downloadFile(fileName) {
    var response = axios.get('/getamcfiledata', {
      params: { pOrderID: this.state.appData.orderID, pFileName: fileName },
    });
    try {
      var resultData = (await response).data;
      var dlFN = fileName;
      if (fileName[fileName.length - 1] === ')') {
        dlFN = '';
        var splitFN = fileName.split(' ');
        for (var i = 0; i < splitFN.length - 1; i++) {
          dlFN = dlFN + splitFN[i];
        }
      }
      var binary = atob(resultData);
      var array = [];
      for (var j = 0; j < binary.length; j++) {
        array.push(binary.charCodeAt(j));
      }
      var file = new Blob([new Uint8Array(array)]);

      // this code below will download the file we selected
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = dlFN;
      a.click();
      URL.revokeObjectURL(url);
    } catch {}
  }

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if (!checked) {
      checkList.complete.checked = false;
      this.setChecklistField('complete', false);
    }
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value, errorMsg: '' });
  };

  allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (prop === 'complete') continue;
      if (prop === 'uwReviewed' && (!this.state.appComp || this.state.appComp === "1")) continue;
      visibleCount++;
      if (prop === 'propertyValue' && (numbersNoFormat(this.state.bytePropertyValue) === numbersNoFormat(this.state.originalPropertyValue))) {
        checkedCount++;
        continue;
      }
      if (
        Object.prototype.hasOwnProperty.call(checklistData, prop) &&
        checklistData[prop].checked
      ) {
        checkedCount++;
      }
    }
    if (checkedCount < visibleCount) return false;
    return true;
  }

  markComplete() {
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('APR', 'complete');
  }

  async requestChanges() {
    if (!this.state.reviewText) {
      this.setState({
        errorMsg: 'A comment is required when changes are requested',
      });
      return;
    }

    this.setState({ isSubmitting: true });

    let response = await axios.get('/appraisalstatuschange', {
      params: {
        pOrderID: this.state.appData.orderID,
        pFileID: this.state.activeFile,
        pStatus: 'AMC Review',
        pUserName: LoginState.userName,
        pComment: this.state.reviewText,
      },
    });
    if (response.data) {
      this.getAppraisalData(this.props.id);
      this.setState({ isSubmitting: false, reviewText: '' });
    }
  }

  async approveReport() {
    this.setState({ isSubmitting: true, showApproveModal: false });

    let response = await axios.get('/appraisalstatuschange', {
      params: {
        pOrderID: this.state.appData.orderID,
        pFileID: this.state.activeFile,
        pStatus: 'Reviewed',
        pUserName: LoginState.userName,
        pComment: this.state.reviewText,
      },
    });
    if (response.data) {
      this.getAppraisalData(this.props.id);
      this.setState({
        isSubmitting: false,
        reviewText: '',
        isUploading: false,
      });
    }
  }

  ShowApproveModal() {
    return (
      <div>
        Do you want to approve the <b>Appraisal Report</b>?
      </div>
    );
  }

  ShowResendModal() {
    return (
      <div>
        Do you want to re-send an Email Disclosure to the borrower(s) with the
        latest Appraisal Report attached?
      </div>
    );
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'APR-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'APR', // we only use this method in the Appraisals to update the PIW field which we share with the CTC Checklist
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2].toString().trim(), // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success' && fieldsToUpdate[i][4]) {
        this.setState({
          ['original' +
          fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][4].substring(1)]: this.state[fieldsToUpdate[i][4]]
            .toString()
            .trim(),
          [fieldsToUpdate[i][4] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataFieldCtc(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'CTC', // we only use this method in the Appraisals to update the PIW field which we share with the CTC Checklist
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2].toString().trim(), // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][4].substring(1)]: this.state[fieldsToUpdate[i][4]]
            .toString()
            .trim(),
          [fieldsToUpdate[i][4] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  async resendDisclosure() {
    var response = axios.get('/resendAppraisalDisclosure', {
      params: {
        pOrderID: this.state.appData.orderID,
        pFileID: this.state.activeFile,
        pUserName: LoginState.userName,
      },
    });
    var resultData = (await response).data;
    if (resultData === 'Success') {
      // refresh
      this.getAppraisalData(this.state.activeFile);
      // show some kind of confirmation
      this.setState({ showSentModal: true });
    } else {
      this.setState({ errorMessage: 'Error: Failed to Re-Send Disclosure!' });
    }
  }

  DisplayAppraisalValue() {
    const { checkList } = this.state;

    return (
      <React.Fragment>
        <ChecklistCard
          checked={checkList.appraisalCompany.checked}
          label={
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="appraisalCompany"
                id="appraisalCompany"
                disabled={
                  this.state.appraisalCompany !==
                  this.state.appraisalCompany
                }
                checked={checkList.appraisalCompany.checked}
                onChange={this._toggleCheckbox}
              />
              <label className="form-check-label" htmlFor="appraisalCompany">
                AMC Company
              </label>
            </div>
          }
          body={
            <div>
              <div className="input-group vstack">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="appComp1"
                    name="appComp"
                    className="form-check-input"
                    checked={this.state.appComp === "1" || !this.state.appComp}
                    onChange={() => void 0}
                    disabled={checkList.appraisalCompany.checked}
                    onClick={() => {
                      if (this.state.appComp !== "1") {
                        this.setState({ appComp: "1" });
                        this.updateDynamicDataField([
                          ['AppraisalCompany', '', '1', '', ''],
                        ]);
                        checkList.appraisalCompany.checked = false;
                        this.setChecklistField('appraisalCompany', false);
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor="appComp1">
                    OneStop AMC
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="appComp2"
                    name="appComp"
                    className="form-check-input"
                    checked={this.state.appComp === "2"}
                    onChange={() => void 0}
                    disabled={checkList.appraisalCompany.checked}
                    onClick={() => {
                      if (this.state.appComp !== "2") {
                        this.setState({ appComp: "2" });
                        this.updateDynamicDataField([
                          ['AppraisalCompany', '', '2', '', ''],
                        ]);
                        checkList.appraisalCompany.checked = false;
                        this.setChecklistField('appraisalCompany', false);
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor="appComp2">
                    Other AMC
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="appComp3"
                    name="appComp"
                    className="form-check-input"
                    checked={this.state.appComp === "3"}
                    onChange={() => void 0}
                    disabled={checkList.appraisalCompany.checked}
                    onClick={() => {
                      if (this.state.appComp !== "3") {
                        this.setState({ appComp: "3" });
                        this.updateDynamicDataField([
                          ['AppraisalCompany', '', '3', '', ''],
                        ]);
                        checkList.appraisalCompany.checked = false;
                        this.setChecklistField('appraisalCompany', false);
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor="appComp3">
                    Transfer
                  </label>
                </div>
                
              </div>
            </div>
          }
          alert={
            checkList.appraisalCompany.checked && (
              <SignOff
                user={checkList.appraisalCompany.userName}
                date={checkList.appraisalCompany.timestamp}
              />
            )
          }
        />

        <ChecklistCard
          checked={numbersNoFormat(this.state.bytePropertyValue) === numbersNoFormat(this.state.originalPropertyValue)}
          label={
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="propertyValue"
                id="propertyValue"
                checked={numbersNoFormat(this.state.bytePropertyValue) === numbersNoFormat(this.state.originalPropertyValue)}
                disabled={true}
              />
              <label className="form-check-label" htmlFor="propertyValue">
                Property Value
              </label>
            </div>
          }
          body={
            <div>
              <div className="input-group">
                <span className="input-group-text">Appraised At:</span>
                <input
                  type="text"
                  name="propertyValue"
                  className="form-control"
                  value={this.state.propertyValue || ''}
                  onChange={this.captureInput}
                />
                <button
                  className="btn btn-primary btn-sm"
                  disabled={
                    this.state.originalPropertyValue ===
                    this.state.propertyValue
                  }
                  onClick={() => {
                    this.updateDynamicDataField([
                      ['AppraisalValue', '', formatCurrency(numbersNoFormat(this.state.propertyValue)), '', ''],
                    ]);
                    this.setState({ 
                      originalPropertyValue: formatCurrency(numbersNoFormat(this.state.propertyValue)),
                      propertyValue: formatCurrency(numbersNoFormat(this.state.propertyValue)),
                    });
                  }}
                >
                  {this.state.propertyValueUpdated ? '✓' : 'Update'}
                </button>
              </div>
              <small>(Get Value From Completed Appraisal Report)</small>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="piw"
                  checked={this.state.piw}
                  disabled={checkList.propertyValue.checked}
                  onChange={e => {
                    this.updateDynamicDataFieldCtc([
                      ['PIW', '', e.target.checked ? '1' : '0', '', 'piw'],
                    ]);
                    this.setState({ piw: e.target.checked });
                  }}
                />
                <label className="form-check-label" htmlFor="piw">
                  Property Inspection Waiver (PIW)
                </label>
              </div>
            </div>
          }
          alert={
            <AutoCheck
              passed={
                numbersNoFormat(this.state.bytePropertyValue) ===
                numbersNoFormat(this.state.originalPropertyValue)
              }
              tip={
                'Appraised Value Input ( ' +
                (this.state.originalPropertyValue || '---') +
                ' )\nDoes Not Match\nByte Property Value ( ' +
                (this.state.bytePropertyValue || '---') +
                ' )'
              }
            />
          }
        />
        <ChecklistCard
          checked={checkList.adu.checked}
          label={
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="adu"
                id="adu"
                checked={checkList.adu.checked}
                onChange={this._toggleCheckbox}
              />
              <label className="form-check-label" htmlFor="adu">
                ADU
              </label>
            </div>
          }
          body={
            <TableZero>
              <tbody>
                <tr>
                  <td>Does the Subject Property Have ADUs?</td>
                </tr>
                <tr style={{ height: '10px' }} />
                <tr>
                  <td>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="aduExists1"
                        name="aduExists1"
                        checked={this.state.aduExists}
                        disabled={checkList.adu.checked}
                        onChange={() => void 0}
                        onClick={() => {
                          this.setState({ aduExists: true });
                          this.updateDynamicDataField([
                            ['ADU Exists', '', '1', '', ''],
                          ]);
                        }}
                      />
                      <label
                        htmlFor="aduExists1"
                        className="form-check-label"
                        onClick={() => {
                          this.setState({ aduExists: true });
                          this.updateDynamicDataField([
                            ['ADU Exists', '', '1', '', ''],
                          ]);
                        }}
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="aduExists2"
                        disabled={checkList.adu.checked}
                        name="aduExists2"
                        checked={!this.state.aduExists}
                        onChange={() => void 0}
                        onClick={() => {
                          this.setState({ aduExists: false });
                          this.updateDynamicDataField([
                            ['ADU Exists', '', '0', '', ''],
                          ]);
                        }}
                      />
                      <label
                        htmlFor="aduExists2"
                        className="form-check-label"
                        onClick={() => {
                          this.setState({ aduExists: false });
                          this.updateDynamicDataField([
                            ['ADU Exists', '', '0', '', ''],
                          ]);
                        }}
                      >
                        No
                      </label>
                    </div>
                  </td>
                </tr>
                {this.state.aduExists && (
                  <tr>
                    <td>
                      <hr />
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ paddingTop: '10px' }}>
                    {this.state.aduExists && (
                      <div>
                        <Verify />
                        Check If Home Insurance Policy Includes ADUs
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </TableZero>
          }
          alert={
            checkList.adu.checked && (
              <SignOff
                user={checkList.adu.userName}
                date={checkList.adu.timestamp}
              />
            )
          }
        />

        {/* UW Reviewed */}
        {(this.state.appComp === "2" || this.state.appComp === "3") && (
          <ChecklistCard
            checked={checkList.uwReviewed.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="uwReviewed"
                  id="uwReviewed"
                  checked={checkList.uwReviewed.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="uwReviewed">
                  Underwriting
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="For Companies Other Than 1StopAMC" />
                UW Review Complete
              </div>
            }
            alert={
              checkList.uwReviewed.checked && (
                <SignOff
                  user={checkList.uwReviewed.userName}
                  date={checkList.uwReviewed.timestamp}
                />
              )
            }
          />
        )}

      </React.Fragment>
    );
  }

  render() {
    const { retrievedData, appData, history, apiError } = this.state;

    if (!retrievedData) {
      return (
        <React.Fragment>
          <h1 className="h5">Appraisal Order</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </React.Fragment>
      );
    } else if (!appData.orderID) {
      return (
        <React.Fragment>
          <h1 className="h5">Appraisal Order</h1>
          {this.DisplayAppraisalValue()}
          <Body>
            {!(this.state.appComp === "2" || this.state.appComp === "3") && (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <span style={{ color: 'red' }}>
                  - No Appraisal Orders Found -
                </span>
              </div>
            )}
            <div>
              {this.state.checkList.complete.checked && this.allChecked() ? (
                <div className="text-center mt-4">
                  <button
                    className="btn btn-primary text-white"
                    style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                    disabled={true}
                  >
                    Completed ✓
                  </button>
                </div>
              ) : (
                <div className="text-center mt-4">
                  <button
                    className="btn btn-primary text-white"
                    onClick={() => this.markComplete()}
                    disabled={
                      !this.allChecked() ||
                      this.state.checkList.complete.checked
                    }
                  >
                    {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
                  </button>
                </div>
              )}
            </div>
            <ModalConfirm
              show={this.state.showConfirmModal || this.state.showClearModal}
              onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
              onDecline={() =>
                this.setState({
                  showConfirmModal: false,
                  showClearModal: false,
                })
              }
            >
              <div style={{ fontSize: '16px' }}>
                <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
                <span style={{ marginLeft: '10px' }}>
                  "{FindLabelFromName(this.state.confirmTitle)}"
                </span>
              </div>
            </ModalConfirm>
          </Body>
        </React.Fragment>
      );
    }

    const paid = appData
      ? appData.paymentStatus !== 'Pending' &&
        appData.paymentStatus !== 'Not Paid'
      : '';
    let splitID = this.props.id.split('-');
    let index = parseInt(
      splitID.length > 1 ? splitID[1] : appData.allOrders.length
    );

    return (
      <React.Fragment>
        <h1 className="h5">Appraisal Order</h1>

        {appData.allOrders.length > 1 && (
          <table style={{ marginBottom: '20px', fontSize: '13px' }}>
            <tbody>
              <tr>
                <td colSpan={3}>
                  <hr />
                </td>
              </tr>
              {appData.allOrders.map(order => (
                <tr
                  key={order.index}
                  style={{ color: order.index === index ? 'blue' : 'black' }}
                  onClick={() => {
                    if (order.index !== index)
                      this.props.appNav(
                        '/details/apr-' + splitID[0] + '-' + order.index
                      );
                  }}
                >
                  <td style={{ width: '20px' }}>
                    {order.index === index && (
                      <i
                        className="bi bi-caret-right me-2"
                        style={{ fontSize: '12px' }}
                      />
                    )}
                  </td>
                  <td>
                    <div style={{ cursor: 'pointer' }}>
                      <table>
                        <tbody>
                          <tr>
                            <td width="80px">{order.orderID}</td>
                            <td width="200px">({order.orderType})</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}>
                  <hr />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="row">
          <div className="col-12 mb-4">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-2">
                <small className="text-muted">Order ID</small>
                <p className="fw-bold mb-1">{appData.orderID}</p>
                <small className="text-muted">Appraisal Type</small>
                <p className="fw-bold mb-1">{appData.orderType}</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <small className="text-muted">Payment Status</small>
                <p className="fw-bold mb-1">{appData.paymentStatus}</p>
                <small className="text-muted">Estimated Fee</small>
                <p className="fw-bold mb-1">
                  {formatCurrency(appData.orderFee)}
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <small className="text-muted">Ordered By</small>
                <p className="fw-bold mb-1">{appData.orderedBy}</p>
                <small className="text-muted">Status</small>
                <p className="fw-bold mb-1">{appData.status}</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <small className="text-muted">Order Date</small>
                <p className="fw-bold mb-1">{appData.orderDate}</p>
                <small className="text-muted">Last Updated</small>
                <p className="fw-bold mb-1">{appData.lastUpdated}</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <small className="text-muted">AMC Approved</small>
                <p className="fw-bold mb-1" style={{ marginLeft: '35px' }}>
                  {appData.adminApproved === 'Approved' ? (
                    <i className="bi fs-6 bi-check-circle text-success" />
                  ) : (
                    <i className="bi fs-6 bi-exclamation-triangle text-warning" />
                  )}
                </p>
                <small className="text-muted">UW Approved</small>
                <p className="fw-bold mb-1" style={{ marginLeft: '35px' }}>
                  {appData.uwApproved === 'Approved' ? (
                    <i className="bi fs-6 bi-check-circle text-success" />
                  ) : (
                    <i className="bi fs-6 bi-exclamation-triangle text-warning" />
                  )}
                </p>
              </div>
            </div>
          </div>

          {paid && (
            <table style={{ position: 'relative', left: '10px' }}>
              <tbody>
                <tr>
                  <td width="250px">
                    <AppraisalReceipt
                      orderID={appData.orderID}
                      fee={appData.paymentLinkFee}
                      style={{ maxWidth: '200px' }}
                    />
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>
          )}

          {this.DisplayAppraisalValue()}

          {/* email disclosure */}
          <div className="col-12">
            <h5 className="mt-4">Email Disclosure {this.state.disclosureEmailLog.length > 0 ? '' : 'Not'} Sent</h5>
            {this.state.disclosureEmailLog.length > 0 && (
            <React.Fragment>
              <button
                className="btn"
                type="button"
                style={{
                  height: '24px',
                  maxWidth: '24px',
                  marginLeft: '10px',
                  pointerEvents: 'auto',
                }}
                onClick={() =>
                  this.setState({
                    showEmailLog: true,
                    emailLog: this.state.disclosureEmailLog,
                  })
                }
              >
                <i
                  className="bi bi-envelope-check"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    position: 'relative',
                    top: '-10px',
                    left: '-10px',
                  }}
                />
              </button>{' '}
              (Email Log)
            </React.Fragment>
            )}
            <button
              type="button"
              className="btn btn-sm btn-light ms-4 me-auto border"
              onClick={() => this.setState({ showResendModal: true })}
            >
              {this.state.disclosureEmailLog.length > 0 ? 'Resend' : 'Send'} Disclosure
            </button>
          </div>

          <div className="col-12">
            <h5 className="mt-4">Report-In Files and Comments</h5>

            {history.length === 0 && (
              <span
                style={{ color: 'red', fontSize: '13px', marginLeft: '5px' }}
              >
                No Files or Comments Have Been Submitted Yet
              </span>
            )}

            <table>
              <tbody>
                {history.map(item => {
                  if (item.name) {
                    return (
                      <React.Fragment>
                        <tr key={item.timestamp}>
                          <td className="text-center" style={{ width: '90px' }}>
                            {item.timestamp.substring(0, 8)}
                            <br />
                            <small>{item.timestamp.substring(8)}</small>
                          </td>
                          <td
                            className="text-center px-4"
                            style={{ width: '120px' }}
                          >
                            <TinyTooltip tip="File Available in BytePro">
                              <i
                                className="bi bi-file-earmark-text"
                                style={{ fontSize: '1.25em' }}
                              />
                            </TinyTooltip>
                          </td>
                          <td colSpan="2" className="text-primary">
                            {item.name}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <hr />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment>
                        <tr key={item.timestamp}>
                          <td className="text-center" style={{ width: '70px' }}>
                            {item.timestamp.substring(0, 8)}
                            <br />
                            <small>{item.timestamp.substring(8)}</small>
                          </td>
                          <td
                            className="text-center px-4"
                            style={{ width: '120px' }}
                          >
                            <i
                              className="bi bi-chat-right-dots"
                              style={{
                                fontSize: '1.25rem',
                                marginRight: '7px',
                              }}
                            ></i>
                            <span className="fw-normal badge border rounded-pill pill-info">
                              {item.userName}
                            </span>
                          </td>
                          <td colSpan="2">
                            {item.commentText.endsWith('[Approved By AMC]') && (
                              <span style={{ color: 'green' }}>
                                AMC{' '}
                                <i className="bi bi-check-circle text-success" />
                                <br />
                              </span>
                            )}
                            {item.commentText.endsWith(
                              '[Approved By Lender]'
                            ) && (
                              <span style={{ color: 'green' }}>
                                UW{' '}
                                <i className="bi bi-check-circle text-success" />
                                <br />
                              </span>
                            )}
                            {
                              item.commentText
                                .split('[Approved By AMC]')[0]
                                .split('[Approved By Lender]')[0]
                            }
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <hr />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  }
                })}
                {appData.status === 'UW Review' &&
                appData.adminApproved === 'Approved' ? (
                  <React.Fragment>
                    <tr>
                      <td colSpan="4" style={{ minWidth: '600px' }}>
                        <input
                          type="text"
                          className="form-control mb-2 me-md-2"
                          onChange={this.captureInput}
                          id="reviewText"
                          name="reviewText"
                          disabled={this.state.isSubmitting}
                          placeholder="Review Comments"
                          value={this.state.reviewText}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" style={{ textAlign: 'right' }}>
                        <button
                          type="button"
                          className="btn btn-sm btn-light ml-4 me-auto border"
                          disabled={this.state.isSubmitting}
                          onClick={() => this.requestChanges()}
                        >
                          Request Changes
                        </button>
                        <button
                          type="button"
                          className="ms-3 btn btn-sm btn-primary ml-2 text-white text-white"
                          disabled={this.state.isSubmitting}
                          onClick={() =>
                            this.setState({ showApproveModal: true })
                          }
                        >
                          Approve Report
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="4"
                        style={{
                          color: 'red',
                          textAlign: 'center',
                          paddingTop: '10px',
                        }}
                      >
                        {this.state.errorMsg}
                      </td>
                    </tr>
                  </React.Fragment>
                ) : (
                  <tr>
                    <td colSpan="4" style={{ minWidth: '600px', color: 'red' }}>
                      {appData.status === 'AMC Review' && (
                        <React.Fragment>
                          (Appraisal Files Currently Under AMC Review)
                        </React.Fragment>
                      )}

                      {this.state.checkList.complete.checked &&
                      this.allChecked() ? (
                        <div className="text-center mt-4">
                          <button
                            className="btn btn-primary text-white"
                            style={{
                              backgroundColor: '#84c488',
                              borderColor: 'gray',
                            }}
                            disabled={true}
                          >
                            Completed ✓
                          </button>
                        </div>
                      ) : (
                        <div className="text-center mt-4">
                          <button
                            className="btn btn-primary text-white"
                            onClick={() => this.markComplete()}
                            disabled={
                              !this.allChecked() ||
                              this.state.checkList.complete.checked
                            }
                          >
                            {this.allChecked()
                              ? 'Mark Complete'
                              : 'Not Completed'}
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {appData.status === 'AMC Review' && (
              <React.Fragment>
                <span className="small">
                  (Waiting on 1StopAMC Admin Review Before UW Comments Enabled)
                </span>
              </React.Fragment>
            )}
          </div>
          <Modal
            title="Confirm Report Approval"
            height="200px"
            width="400px"
            show={this.state.showApproveModal}
            onAccept={() => this.approveReport()}
            onDecline={() => this.setState({ showApproveModal: false })}
            buttonMessage="Approve"
          >
            {this.ShowApproveModal()}
          </Modal>
          <Modal
            title="Re-send Email Disclosure"
            height="200px"
            width="400px"
            show={this.state.showResendModal}
            onAccept={() => {
              this.setState({ showResendModal: false });
              this.resendDisclosure();
            }}
            onDecline={() => this.setState({ showResendModal: false })}
            buttonMessage="Send"
          >
            {this.ShowResendModal()}
          </Modal>
          <Modal
            width="300px"
            height="150px"
            show={this.state.showSentModal}
            onAccept={() => this.setState({ showSentModal: false })}
            onDecline={() => this.setState({ showSentModal: false })}
            buttonMessage="Close"
            hideCancelBtn
          >
            <div
              style={{
                textAlign: 'center',
                position: 'relative',
                top: '-10px',
              }}
            >
              Disclosure Email Sent
            </div>
          </Modal>

          <ModalWait
            title="Updating File Data - Please Wait!"
            show={this.state.isUploading}
          />
          <ModalEmailLog
            show={this.state.showEmailLog}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailLog: false, emailLog: [] })
            }
          />
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
        </div>
        {apiError && (
          <Toast
            type="danger"
            title={apiError}
            onClose={() => this.setState({ apiError: null })}
          />
        )}
      </React.Fragment>
    );
  }
}
