import React, { Component } from 'react';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import Modal from '../components/Modal';
export default class GoodbyeLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageReady: false,
      showInputModal: false,
      errorMsg: '',
      effectiveDate: new Date('1/1/2000'),
    };
  }

  componentDidMount() {
    if (!this.props.fileID) {
      this.setState({ pageReady: true, errorMsg: 'No File ID Provided' });
      return;
    }
    // fetch borrower and loan info
    this.fetchLetterData();
  }

  async fetchLetterData() {
    var response = axios.get('/getGoodbyeLetterData', {
      params: { pFileID: this.props.fileID },
    });
    try {
      var resultData = (await response).data;
      this.setState({
        pageReady: true,
        errorMsg: '',
        borrowerName: resultData.borrower,
        mailingAddress: resultData.mailingAddress,
        propertyAddress: resultData.propertyAddress,
        accountNumber: this.props.fileID,
        todayDate: resultData.letterDate,
        pi: resultData.pi,
        escrow: resultData.escrow,
        tax: resultData.tax,
        hazard: resultData.hazard,
        flood: resultData.flood,
        pmi: resultData.pmi,
        total: resultData.total,
      });
    } catch {
      this.setState({
        pageReady: true,
        errorMsg: 'Failed to retrieve file data.',
      });
    }

    this.setState({ pageReady: true });
  }

  async createPDF() {
    this.setState({ showInputModal: false });
    var fileData;
    var arialNarrowFont;
    var response = axios.get('/getPdfTemplate', {
      params: { pTemplateName: 'GoodbyeLetter.pdf' },
    });
    var resultData;
    try {
      resultData = (await response).data;
      fileData = resultData;
    } catch {
      this.setState({
        errorMsg: 'Failed to retrieve PDF template from server.',
      });
    }
    const pdfDoc = await PDFDocument.load(fileData);
    const pages = pdfDoc.getPages();

    // setup custom font
    response = axios.get('/getFont', { params: { pFontName: 'ARIALN.TTF' } });
    try {
      resultData = (await response).data;
      arialNarrowFont = resultData;
    } catch {
      this.setState({ errorMsg: 'Failed to retrieve font from server.' });
    }
    pdfDoc.registerFontkit(fontkit);
    const ArialNarrow = await pdfDoc.embedFont(arialNarrowFont);

    //---------------------------------------------------------------------------------
    // fill out custom text and content here
    //---------------------------------------------------------------------------------
    var i;
    // const timesRoman = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    // const timesRomanBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

    // Borrower Name
    pages[0].drawText(this.state.borrowerName, {
      x: 40,
      y: 650,
      size: 12,
      font: ArialNarrow,
    });

    // Borrower Address
    var addr = this.state.mailingAddress.split('\n');
    for (i = 0; i < addr.length; i++) {
      pages[0].drawText(addr[i], {
        x: 40,
        y: 635 - i * 15,
        size: 12,
        font: ArialNarrow,
      });
    }

    // Today's Date
    pages[0].drawText(this.state.todayDate, {
      x: 420,
      y: 655,
      size: 10,
      font: ArialNarrow,
    });

    // Account Number
    pages[0].drawText(this.state.accountNumber, {
      x: 420,
      y: 638,
      size: 10,
      font: ArialNarrow,
    });

    // Property Address
    addr = this.state.propertyAddress.split('\n');
    for (i = 0; i < addr.length; i++) {
      pages[0].drawText(addr[i], {
        x: 420,
        y: 621 - i * 15,
        size: 10,
        font: ArialNarrow,
      });
    }

    // Principal and Interest
    pages[0].drawText(this.state.pi, {
      x: 450,
      y: 569,
      size: 11,
      font: ArialNarrow,
    });

    // Escrow
    pages[0].drawText(this.state.escrow, {
      x: 450,
      y: 557,
      size: 11,
      font: ArialNarrow,
    });

    // Tax
    pages[0].drawText(this.state.tax, {
      x: 460,
      y: 543,
      size: 11,
      font: ArialNarrow,
    });

    // Hazard
    pages[0].drawText(this.state.hazard, {
      x: 460,
      y: 531,
      size: 11,
      font: ArialNarrow,
    });

    // Flood
    pages[0].drawText(this.state.flood, {
      x: 460,
      y: 517,
      size: 11,
      font: ArialNarrow,
    });

    // Pmi
    pages[0].drawText(this.state.pmi, {
      x: 460,
      y: 505,
      size: 11,
      font: ArialNarrow,
    });

    // Total
    pages[0].drawText(this.state.total, {
      x: 450,
      y: 491,
      size: 11,
      font: ArialNarrow,
    });

    // Effective Date
    let effDate = new Date(this.state.effectiveDate);
    let formattedDate =
      this.pad(effDate.getMonth() + 1) +
      '/' +
      this.pad(effDate.getDate() + 1) +
      '/' +
      effDate.getFullYear();

    pages[0].drawText(formattedDate, {
      x: 493,
      y: 335,
      size: 11,
      font: ArialNarrow,
    });

    const pdfBytes = await pdfDoc.save();
    var file = new Blob([pdfBytes]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'GoodbyeLetter.pdf';
    a.click();
    URL.revokeObjectURL(url);
  }

  pad(n) {
    return n < 10 ? '0' + n : n;
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <>
        {this.state.errorMsg ? (
          <span style={{ color: 'red' }}>{this.state.errorMsg}</span>
        ) : (
          <div>
            Goodbye Letter: &nbsp;
            <button
              className="btn btn-primary btn-sm"
              onClick={() => this.setState({ showInputModal: true })}
              disabled={!this.state.pageReady || this.state.showInputModal}
            >
              Create
            </button>
          </div>
        )}
        <Modal
          title="Goodbye Lettter"
          show={this.state.showInputModal}
          onAccept={() => this.createPDF()}
          onDecline={() => this.setState({ showInputModal: false })}
          buttonMessage="Delete"
          onAcceptDisabled={
            new Date(this.state.effectiveDate) <
            Date.now() - 24 * 60 * 60 * 1000
          }
        >
          <div>
            Please send all future payments to <br />
            Quicken Loans starting on: <br />
            <br />
            <input
              type="date"
              onChange={this.captureInput}
              name="effectiveDate"
              value={this.state.effectiveDate}
            />
          </div>
        </Modal>
      </>
    );
  }
}
