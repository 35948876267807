import React, { Component } from 'react';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { formatCurrency } from '../../helpers/utils';
import { TinyTooltip } from '../Tooltip';

export default class AppraisalReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageReady: false,
      errorMsg: '',
      borrowerName: '',
      borrowerAddress: '',
      invoiceNumber: this.props.orderID,
      invoiceDate: '',
      paymentDate: '',
      inspectionType: '',
      propertyAddress: '',
      appraisalFee: '',
      payAtClosing: false,
    };
    this.fetchReceiptData();
  }

  async fetchReceiptData() {
    var response = axios.get('/getReceiptData', {
      params: { pFileID: this.props.orderID },
    });
    try {
      var resultData = (await response).data;
      this.setState({
        pageReady: true,
        errorMsg: '',
        borrowerName: resultData.borrowerName,
        borrowerAddress: resultData.borrowerAddress,
        invoiceDate: resultData.invoiceDate,
        paymentDate: resultData.paymentDate,
        appraisalFee: resultData.appraisalFee,
        inspectionType: resultData.inspectionType,
        propertyAddress: resultData.propertyAddress,
        payAtClosing: resultData.payAtClosing === 'True',
      });
    } catch {
      this.setState({
        pageReady: true,
        errorMsg: 'Failed to retrieve receipt data.',
      });
    }
    this.setState({ pageReady: true });
  }

  async createPDF(getArray) {
    this.setState({ showInputModal: false });
    var fileData;
    var arialNarrowFont;
    var response = axios.get('/getPdfTemplate', {
      params: {
        pTemplateName:
          this.state.paymentDate === 'At Closing'
            ? 'Invoice.pdf'
            : 'Receipt.pdf',
      },
    });
    var resultData;
    try {
      resultData = (await response).data;
      fileData = resultData;
    } catch {
      this.setState({
        errorMsg: 'Failed to retrieve PDF template from server.',
      });
    }
    const pdfDoc = await PDFDocument.load(fileData);
    const pages = pdfDoc.getPages();

    // setup custom font
    response = axios.get('/getFont', { params: { pFontName: 'ARIALN.TTF' } });
    try {
      resultData = (await response).data;
      arialNarrowFont = resultData;
    } catch {
      this.setState({ errorMsg: 'Failed to retrieve font from server.' });
    }
    pdfDoc.registerFontkit(fontkit);
    const ArialNarrow = await pdfDoc.embedFont(arialNarrowFont);

    const fee =
      this.props.fee && this.props.fee.trim() !== ''
        ? formatCurrency(this.props.fee)
        : this.state.appraisalFee;

    //---------------------------------------------------------------------------------
    // fill out custom text and content here
    //---------------------------------------------------------------------------------
    var i;
    // const timesRoman = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    // const timesRomanBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

    // Borrower Name
    pages[0].drawText(this.state.borrowerName, {
      x: 110,
      y: 644,
      size: 12,
      font: ArialNarrow,
    });

    // Borrower Address
    var addr = this.state.borrowerAddress.split('\n');
    for (i = 0; i < addr.length; i++) {
      pages[0].drawText(addr[i], {
        x: 110,
        y: 629 - i * 15,
        size: 12,
        font: ArialNarrow,
      });
    }

    // Invoice #
    pages[0].drawText(this.state.invoiceNumber, {
      x: 490,
      y: 643,
      size: 12,
      font: ArialNarrow,
    });

    // Invoice Date
    pages[0].drawText(this.state.invoiceDate, {
      x: 490,
      y: 625,
      size: 12,
      font: ArialNarrow,
    });

    // Payment Date
    pages[0].drawText(this.state.paymentDate, {
      x: 490,
      y: 607,
      size: 12,
      font: ArialNarrow,
    });

    // Inspection Type
    pages[0].drawText(this.state.inspectionType, {
      x: 80,
      y: 545,
      size: 12,
      font: ArialNarrow,
    });

    // Property Address
    addr = this.state.propertyAddress.split('\n');
    for (i = 0; i < addr.length; i++) {
      pages[0].drawText(addr[i], {
        x: 80,
        y: 526 - i * 15,
        size: 11,
        font: ArialNarrow,
      });
    }

    // Appraisal Price
    pages[0].drawText(fee, {
      x: 430,
      y: 545,
      size: 12,
      font: ArialNarrow,
    });

    // Total
    pages[0].drawText(fee, {
      x: 430,
      y: 458,
      size: 16,
      font: ArialNarrow,
    });

    const pdfBytes = await pdfDoc.save();
    var file = new Blob([pdfBytes]);

    if (getArray) {
      var buffer = await file.arrayBuffer();
      return buffer;
    } else {
      // this code below will download the file we selected
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download =
        this.state.paymentDate === 'At Closing' ? 'Invoice.pdf' : 'Receipt.pdf';
      a.click();
      URL.revokeObjectURL(url);
    }
  }

  pad(n) {
    return n < 10 ? '0' + n : n;
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    let displayText =
      this.state.paymentDate === 'At Closing'
        ? 'Appraisal Invoice'
        : 'Payment Receipt';
    return (
      <button
        className="btn btn-sm btn-link"
        onClick={() => this.createPDF(false)}
        disabled={!this.state.pageReady}
      >
        {this.props.smallDisplay ? (
          <TinyTooltip tip={displayText}>
            <i className="bi fs-5 bi-file-earmark-arrow-down text-success" />
          </TinyTooltip>
        ) : (
          <span>{displayText}</span>
        )}
      </button>
    );
  }
}
