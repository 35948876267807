import {
  Body,
  Verify,
  Task,
  Email,
  SignOff,
  AutoCheck,
  FindLabelFromName,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS, STATUS_COLORS } from '../../helpers/constants';
import Toast from '../Toast';
import { todaysDateYYYY, getPropertyType, isAdmin } from '../../helpers/utils';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalEmailVerification from './ModalEmailVerification';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';
export default class AusChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      ltvFico: [],
      borrowers: [],
      isLoading: true,
      errorMessage: '',
      checkList: {
        ausCompleted: { checked: false },
        complete: { checked: false },
      },
      showEmailTemplate: false,
      emailTemplate: [],
      submittedTemplate: [],
      submittedEmailLog: [],
      showEmailVerification: false,
      verificationEmail: '',
    };
    this.getAusData();
  }

  async getAusData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'AUS' },
    });
    // the response.data will contain the values returned
    var checkData = (await response).data;
    var checklistData = this.state.checkList;
    for (var i = 0; i < checkData.length; i++) {
      checklistData[checkData[i].fieldName] = {
        checked: checkData[i].isChecked === 'True',
        timestamp: checkData[i].timestamp,
        userName: checkData[i].userName,
      };
    }

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    /* response = axios.get('/getausfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var ausFields = (await response).data; */
    this.setState({
      fileData: resultData,
      checkList: checklistData,
      isLoading: false,
    });
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'AUS-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if(!checked) { 
      checkList.complete.checked = false;
      this.setChecklistField('complete', false); 
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  async setLoanStatus(status) {
    this.setState({ changingState: true });
    var response = axios.get('/updateLoanStatus', {
      params: {
        pFileID: this.state.activeFile,
        pLoanStatus: status,
        pLoanPurpose: this.state.fileData.loanPurpose,
      },
    });
    var resultData = (await response).data;
    if (resultData === 'Success') {
      var file = this.state.fileData;
      switch (status) {
        case 'AUS Completed':
          file.ausCompletedDate = todaysDateYYYY();
          break;
        default:
          break;
      }
      this.setState({ fileData: file, changingState: false });
    } else {
      this.setState({ errorMessage: resultData, changingState: false });
    }
  }

  allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (prop === 'complete')
        continue;
      visibleCount++;
      if(prop === 'ausCompleted') {
        if(this.state.fileData.ausCompletedDate !== '---') { checkedCount++; }
        continue;
      }
      if (Object.prototype.hasOwnProperty.call(checklistData, prop) && checklistData[prop].checked)
        checkedCount++;
    }
    /* var completePercent = this.state.fileData.completePercent?.split("AUS")[1].split('(')[1].split(')')[0];
    if(completePercent.toString() !== Math.round(checkedCount / visibleCount * 100).toString()) {
      this.state.fileData.completePercent = this.state.fileData.completePercent?.split("AUS")[0] + "AUS (" +
        Math.round(checkedCount / visibleCount * 100).toString() + ")" + 
        this.state.fileData.completePercent?.split("AUS")[1].substring(this.state.fileData.completePercent?.split("AUS")[1].indexOf(")")+1);
      this.props.updatePercent('AUS', Math.round(checkedCount / visibleCount * 100).toString());
    } */
    if(checkedCount < visibleCount) return false;
    return true;
  }

  markComplete() {
    axios.get('/AusChecklistComplete', {
      params: { pFileID: this.state.activeFile },
    });
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
  }

  render() {
    const { isLoading, fileData, checkList } = this.state;
    if (isLoading) {
      return (
        <>
          <h1 className="h5">AUS Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </>
      );
    }
    return (
      <>
        <h1 className="h5">AUS Checklist</h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* Change Status (AUS Completed) */}
          <ChecklistCard
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeAusCompleted"
                  name="changeAusCompleted"
                  disabled={true}
                  checked={this.state.fileData.ausCompletedDate !== '---'}
                />
                <label
                  className="form-check-label"
                  htmlFor="changeAusCompleted"
                >
                  AUS Completed
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'AUS Completed'" />
                {this.state.fileData.ausCompletedDate !== '---' ? (
                  <>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['aus completed'],
                      }}
                    >
                      AUS Completed
                    </span>
                    {this.state.fileData.ausCompletedDate}
                  </>
                ) : (
                  <>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={this.state.changingState}
                      onClick={() => this.setLoanStatus('AUS Completed')}
                    >
                      {this.state.changingState ? '...' : 'Change'}
                    </button>
                  </>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.ausCompletedDate !== '---'}
                tip="AUS Completed Date Not Found"
              />
            }
          />
          
          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}

          <ModalEmail
            show={this.state.showEmailTemplate}
            template={this.state.emailTemplate}
            fileID={this.state.activeFile}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
            onAccept={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
          />
          <ModalEmailVerification
            show={this.state.showEmailVerification}
            email={this.state.verificationEmail}
            onDecline={() =>
              this.setState({
                showEmailVerification: false,
                verificationEmail: '',
              })
            }
          />
          <ModalEmailLog
            show={this.state.showEmailLog}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailLog: false, emailLog: [] })
            }
          />
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
        </div>
      </>
    );
  }
}
