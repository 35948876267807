import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ACTIONS, Session } from '../helpers/sessionContext';
import { JWT_SECRET_KEY } from '../helpers/constants';
import jwt from 'jsonwebtoken';

import GlobalNavBar from '../components/GlobalNavBar';
import GlobalMessage from '../components/GlobalMessage';
import Sidebar from '../components/SideBar';
import Footer from '../components/Footer';

function PublicLayout({ children }) {
  const {
    state: { user },
    dispatch,
  } = useContext(Session);

  return (
    <div className="public-grid-container">
      {user && (
        <nav
          className={`${
            window.location.href.toLowerCase().includes('staging')
              ? 'bg-warning'
              : 'bg-white'
          } navbar fixed-top bg-white p-0`}
        >
          <GlobalMessage />
          <Link to="/dashboard" className="mx-auto py-2">
            Go to Dashboard <i class="bi bi-box-arrow-in-right"></i>
          </Link>
        </nav>
      )}
      <div className="public-grid-main">{children}</div>
      {/* <div className="public-grid-footer">
      <Footer />
    </div> */}
    </div>
  );
}

export default PublicLayout;
