import React, { Component } from 'react';
import LoginState from '../LoginState';
import axios from 'axios';
import Toast from '../Toast';
import { debounce } from 'lodash';
import { TinyTooltip } from '../Tooltip';
import GoodbyeLetter from '../../templates/GoodbyeLetter';
import SubmissionChecklist from './SubmissionChecklist';
import UnderwritingChecklist from './UnderwritingChecklist';
import CtcChecklist from './CtcChecklist';
import ClosingChecklist from './ClosingChecklist';
import FundingChecklist from './FundingChecklist';
import PostFundingChecklist from './PostFundingChecklist';
import ComplianceChecklist from './ComplianceChecklist';
import QcChecklist from './QcChecklist';
import LockChecklist from './LockChecklist';
import OperationsChecklist from './OperationsChecklist';
import ServicingChecklist from './ServicingChecklist';
import CommunicationsChecklist from './CommunicationsChecklist';
import AppraisalReview from './AppraisalReview';
import AusChecklist from './AusChecklist';
import { FILE_DETAILS_TABS as tabs } from '../../helpers/constants';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalOutlook from './ModalOutlook';
import ModalEmailVerification from './ModalEmailVerification';
import { Link as RouterLink, withRouter } from 'react-router-dom';

class FileDetails extends Component {
  constructor(props) {
    super(props);
    const searching = this.props.match.params.id === 'search';
    this.state = {
      activeFile: this.props.match.params.id,
      loanFiles: [],
      listFiles: [],
      filteredList: [],
      searchLog: [],
      searchQuery: searching ? '' : this.props.match.params.id.substr(4, 10),
      loanFileSelect: searching ? '' : this.props.match.params.id.substr(4, 10),
      activeTab: '',
      emailGroupSelect: 'All Emails',
      fileData: [],
      fileArr: [],
      showResolved: false,
      isProcessing: false,
      hideSearch: false,
      fetching: searching ? false : true,
      fetchingData: false,
      copied: '',

      ptcTemplate: [],
      ptcEmailLog: [],
      showEmailTemplate: false,
      emailTemplate: [],
      emailLog: [],
      showEmailVerification: false,
      verificationEmail: '',
    };
  }

  componentDidMount() {
    // get options before page loads
    const searching = this.props.match.params.id === 'search';
    if (searching) this.getLoanFileList();
    else this.displayFileData(this.props.match.params.id);
  }

  componentDidUpdate() {
    const { activeTab, retrievedTab } = this.state;
    if (activeTab !== retrievedTab) {
      this.setState({ retrievedTab: activeTab, fetchingData: true });
    }

    let paramString = this.props.match.params.id;
    if (this.state.activeFile !== paramString) {
      this.setState({ activeFile: paramString });

      if (paramString === 'search') {
        this.clearSearch();
        if (this.state.loanFiles.length === 0) this.getLoanFileList();
      } else {
        this.displayFileData(paramString);
      }
    }
  }

  async getLoanFileList() {
    var response = axios.get('/getPipelineFiles', {
      params: { pUserID: LoginState.userId },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    var filteredFiles = [];
    if (resultData) {
      resultData.forEach(pFile => {
        filteredFiles.push(pFile);
      });
    }

    // also get search log
    response = axios.get('/getSearchLog', {
      params: { pUserID: LoginState.userId },
    });
    var log = (await response).data;
    this.setState({
      loanFiles: filteredFiles,
      filteredList: [],
      searchLog: log,
    });
    if (this.state.searchQuery && this.state.searchQuery.length > 2)
      this.searchContains(this.state.searchQuery);
  }

  async displayFileData(paramString) {
    // only re-fetch file data if we selected a new file
    if (
      paramString &&
      (!this.state.fileData.fileDataID ||
        this.state.fileData.fileDataID !== paramString.substr(4, 10))
    ) {
      if (this.state.fileData.fileDataID !== paramString.substr(4, 10)) {
        this.setState({ fileData: [] });
        this.getFileData(paramString);
      }
    }
    this.setState({
      activeTab: paramString.substr(0, 3),
      loanFileSelect: paramString.substr(4, 10),
      searchQuery: paramString.substr(4, 10),
      hideSearch: true,
      fetching: false,
    });
  }

  async getFileData(inputStr) {
    var fileID = inputStr.substr(4, 10);
    var response = axios.get('/getLoanSummary', {
      params: { pFileID: fileID },
    });
    var resultData = (await response).data;

    response = axios.get('/getemailfields', {
      params: { pFileID: fileID },
    });

    var emailFields = (await response).data;

    this.setState({
      fileData: resultData,
      ptcTemplate: emailFields.ptcTemplate,
      ptcEmailLog: emailFields.ptcEmailLog,
      fetching: false,
      fetchingData: false,
    });
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  clearSearch() {
    if (this.props.match.params.id !== 'search') {
      this.props.history.push('/details/search');
      return;
    }
    this.setState({
      searchQuery: '',
      fileData: [],
      filteredList: [],
      hideSearch: false,
      loanFileSelect: '',
    });
  }

  searchLoanNum(e) {
    if (this.props.match.params.id !== 'search') {
      this.props.history.push('/details/search');
    }
    this.searchContains(e.target.value);
  }

  searchContains(searchQuery) {
    this.setState({ searchQuery, hideSearch: false, loanFileSelect: '' });
    const removeDashes = str => str.replace(/-/g, '').toLowerCase();
    const noDashesSearchQuery = removeDashes(searchQuery);
    var searchResults = [];

    for (var i = 0; i < this.state.loanFiles.length; i++) {
      const noDashesId = removeDashes(
        this.state.loanFiles[i].encodedFileID +
          ' ' +
          this.state.loanFiles[i].borrower
      );
      if (noDashesId.includes(noDashesSearchQuery)) {
        searchResults.push(this.state.loanFiles[i]);
      }
    }

    if (searchQuery.length < 3) {
      debounce(() => {
        this.setState({
          filteredList: [],
        });
      }, 500)();
    } else {
      debounce(() => {
        this.setState({
          filteredList: searchResults,
        });
      }, 500)();
    }
  }

  renderTabContent() {
    const { activeTab, retrievedTab } = this.state;
    if (activeTab !== retrievedTab) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      );
    }

    if (activeTab === 'cnd') return this.ShowConditions();
    if (activeTab === 'chk') return this.ShowChecklists();

    if (activeTab === 'uwc') return this.ShowUWConditions();
    if (activeTab === 'trc') return this.ShowTridConditions();
    if (activeTab === 'rlk') return this.ShowRateLock();
    if (activeTab === 'cic') return this.ShowCIC();
    if (activeTab === 'drq') return this.ShowDocRequest();

    if (activeTab === 'sub') return this.ShowSubmissionChecklist();
    if (activeTab === 'uwr') return this.ShowUwChecklist();
    if (activeTab === 'lck') return this.ShowLockChecklist();
    if (activeTab === 'ctc') return this.ShowCtcChecklist();
    if (activeTab === 'clo') return this.ShowClosingChecklist();
    if (activeTab === 'fnd') return this.ShowFundingChecklist();
    if (activeTab === 'pfd') return this.ShowPostFundingChecklist();
    if (activeTab === 'cmp') return this.ShowComplianceChecklist();
    if (activeTab === 'qct') return this.ShowQcChecklist();
    if (activeTab === 'opr') return this.ShowOperationsChecklist();
    if (activeTab === 'srv') return this.ShowServicingChecklist();
    if (activeTab === 'com') return this.ShowCommunicationsChecklist();
    if (activeTab === 'apr') return this.ShowAppraisalOrder();

    if (activeTab === 'aus') return this.ShowAusChecklist();
  }

  async GetContactInfo(fileID) {
    const response = axios.get('/getContactInfo', {
      params: {
        pFileID: fileID,
      },
    });
    // the response.data will contain the values returned
    var resultData = (await response).data;
    var formattedContacts = [];
    for (var i = 0; i < resultData.lpContacts.length; i++) {
      formattedContacts.push({
        label:
          resultData.lpContacts[i].email +
          ' (' +
          resultData.lpContacts[i].name +
          ')',
        value: resultData.lpContacts[i].email,
      });
    }

    this.setState({
      searchQuery: fileID,
      borrowers: resultData.borrowers,
      loanOfficer: resultData.lo,
      loanProcessor: resultData.lp,
      loanProcessor2: resultData.lp2,
      loanProcessorCB: resultData.lp.email !== '---',
      loanProcessor2CB: resultData.lp2.email !== '---',
      underwriter1: resultData.uw1,
      underwriter2: resultData.uw2,
      settlementCompany: resultData.sc,
      titleCompany: resultData.tc,
      buyersAgent: resultData.ba,
      sellersAgent: resultData.sa,
      appraiser: resultData.ap,
      lpContacts: formattedContacts,
      fetchingData: false,
    });
  }

  async updatePercent(tab, percent) {
    var fd = JSON.parse(JSON.stringify(this.state.fileData));
    const response = axios.get('/UpdatePercent', {
      params: {
        pFileID: this.state.activeFile.substr(4, 10),
        pTab: tab,
        pPercent: percent,
      },
    });
    if (!fd || !fd.checklistsCompleted) return;
    var resultData = (await response).data;
    if (resultData !== 'Failed') {
      fd.completePercent = resultData;
      if (percent === 'complete')
        fd.checklistsCompleted = fd.checklistsCompleted + tab + ',';
      else if (percent !== '100' && fd.checklistsCompleted.includes(tab)) {
        let splitStr = fd.checklistsCompleted.split(tab);
        fd.checklistsCompleted =
          splitStr[0] + splitStr[1].substr(splitStr[1].indexOf(','));
      }
      this.setState({ fileData: fd });
    }
  }

  updateNumber(type, value) {
    var strVal = value || '---';
    var fd = this.state.fileData;
    switch (type) {
      case 'Escrow':
        fd.escrowNo = strVal;
        break;
      case 'Title':
        fd.titleNo = strVal;
        break;
      default:
        break;
    }
    this.setState({ fileData: fd });
  }

  ShowConditions() {
    return <>Conditions</>;
  }

  ShowChecklists() {
    return (
      <div className="row">
        <div className="col-12">
          <p>Checklists</p>
        </div>
        <div className="col-12">
          <GoodbyeLetter fileID={this.state.loanFileSelect} />
        </div>
      </div>
    );
  }

  ShowSubmissionChecklist() {
    return (
      <SubmissionChecklist
        id={this.state.loanFileSelect}
        updateNumber={this.updateNumber.bind(this)}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowUwChecklist() {
    return (
      <UnderwritingChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowLockChecklist() {
    return (
      <LockChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowCtcChecklist() {
    return (
      <CtcChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowClosingChecklist() {
    return (
      <ClosingChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowFundingChecklist() {
    return (
      <FundingChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowPostFundingChecklist() {
    return (
      <PostFundingChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowComplianceChecklist() {
    return (
      <ComplianceChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowQcChecklist() {
    return (
      <QcChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowOperationsChecklist() {
    return (
      <OperationsChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowServicingChecklist() {
    return (
      <ServicingChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  ShowCommunicationsChecklist() {
    return <CommunicationsChecklist id={this.state.loanFileSelect} />;
  }

  ShowAppraisalOrder() {
    return (
      <AppraisalReview
        id={this.props.match.params.id.substr(4)}
        updatePercent={this.updatePercent.bind(this)}
        appNav={this.AppraisalNavigation.bind(this)}
      />
    );
  }

  ShowAusChecklist() {
    return (
      <AusChecklist
        id={this.state.loanFileSelect}
        updatePercent={this.updatePercent.bind(this)}
      />
    );
  }

  async NavigateToFile(fileID, encodedID, borrower) {
    // update search log
    var entry = {
      fileDataID: fileID,
      encodedFileID: encodedID,
      borrower: borrower,
    };
    var list = this.state.searchLog;

    for (var i = 0; i < list.length; i++) {
      if (list[i].fileDataID === fileID) {
        list.splice(i, 1);
        i--;
      }
    }
    list.splice(0, 0, entry);
    if (list.length > 5) list.splice(5, 1);

    this.setState({ searchLog: list });

    // now update server with this data
    axios.get('/updateSearchLog', {
      params: {
        fid1: list[0].fileDataID,
        eid1: list[0].encodedFileID,
        bor1: list[0].borrower,
        fid2: list.length > 1 ? list[1].fileDataID : '',
        eid2: list.length > 1 ? list[1].encodedFileID : '',
        bor2: list.length > 1 ? list[1].borrower : '',
        fid3: list.length > 2 ? list[2].fileDataID : '',
        eid3: list.length > 2 ? list[2].encodedFileID : '',
        bor3: list.length > 2 ? list[2].borrower : '',
        fid4: list.length > 3 ? list[3].fileDataID : '',
        eid4: list.length > 3 ? list[3].encodedFileID : '',
        bor4: list.length > 3 ? list[3].borrower : '',
        fid5: list.length > 4 ? list[4].fileDataID : '',
        eid5: list.length > 4 ? list[4].encodedFileID : '',
        bor5: list.length > 4 ? list[4].borrower : '',
        pUserID: LoginState.userId,
      },
    });

    // check loan status
    var ls = await this.GetLoanStatus(encodedID);

    // now navigate to new page
    if (ls && ls.toString().includes('AUS'))
      this.props.history.push('/details/aus-' + encodedID);
    else this.props.history.push('/details/sub-' + encodedID);
  }

  async GetLoanStatus(fileID) {
    var response = axios.get('/getloanstatus', {
      params: { pFileID: fileID },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    return resultData;
  }

  AppraisalNavigation(location) {
    this.setState({ retrievedTab: '' });
    this.props.history.push(location);
  }

  copyToClipboard = (value, target) => {
    navigator.clipboard.writeText(value);
    this.setState({ copied: target });
    setTimeout(() => {
      this.setState({ copied: '' });
    }, 1000);
  };

  render() {
    const { activeTab, fileData, copied } = this.state;
    const today = new Date(Date.now()).setHours(0, 0, 0, 0);

    return (
      <div className="container">
        <div className="row mb-3">
          {/* HEADER */}
          <div className="col-12 d-flex gap-2 align-items-center">
            <h1 className="h4 text-uppercase mb-0">file details</h1>
            {LoginState.userId === 1000009 && fileData.fileName && (
              <span className="text-primary">({fileData.fileName})</span>
            )}
          </div>
          {/* SEARCHBOX */}
          <div className="col-12 col-md-6 offset-md-3 d-flex gap-2">
            <input
              className="form-control"
              style={{ maxWidth: '300px' }}
              onChange={e => this.searchLoanNum(e)}
              value={this.state.searchQuery}
              placeholder="Search Loan"
            />
            <button className="btn btn-primary" disabled={this.state.fetching}>
              Search
            </button>
            <button
              className="btn btn-outline-secondary"
              disabled={this.state.fetching}
              onClick={() => this.clearSearch()}
            >
              Clear
            </button>
          </div>
          {/* SEARCH RESULTS */}
          <div className="col-12 col-md-6 offset-md-3">
            {!this.state.hideSearch && (
              <>
                {this.state.filteredList.length > 0 ? (
                  <ul className="dropdown-menu position-static d-grid gap-1 p-2 mt-2 rounded-3 mx-0 shadow w-220px">
                    {this.state.filteredList.map(file => (
                      <li key={file.encodedFileID}>
                        <div
                          className="border-bottom dropdown-item rounded-2"
                          onClick={() =>
                            this.NavigateToFile(
                              file.fileDataID,
                              file.encodedFileID,
                              file.borrower
                            )
                          }
                        >
                          {file.encodedFileID} ({file.borrower})
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                    {this.state.searchLog.length > 0 && (
                      <>
                        <div className="mt-3">
                          <h1 className="h6">Recent Files</h1>
                        </div>
                        <ul className="list-group position-static mt-2">
                          {this.state.searchLog.map(file => (
                            <button
                              type="button"
                              className="list-group-item list-group-item-action"
                              aria-current="true"
                              key={file.encodedFileID}
                              onClick={() =>
                                this.NavigateToFile(
                                  file.fileDataID,
                                  file.encodedFileID,
                                  file.borrower
                                )
                              }
                            >
                              {file.encodedFileID} ({file.borrower})
                            </button>
                          ))}
                        </ul>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className="row">
          {this.state.loanFileSelect && (
            <>
              {/* TABS */}
              {!fileData.loanStatus ? (
                /* placeholder before we know loan status */
                <div className="col-12 col-lg-9 col-xxl-2" />
              ) : (
                <>
                  {fileData.loanStatus.includes('AUS') ? (
                    <div className="col-12 col-lg-9 col-xxl-2">
                      <nav className="nav flex-nowrap flex-xxl-column overflow-auto">
                        <button
                          key="AUS"
                          className={`nav-link rounded mb-2 border text-nowrap ${
                            fileData.checklistsCompleted?.includes('AUS')
                              ? 'fw-bold border-success'
                              : 'fw-bold border-primary'
                          }`}
                          style={{
                            fontSize: '13px',
                            backgroundColor:
                              fileData.checklistsCompleted?.includes('AUS')
                                ? '#f2fff2'
                                : 'white',
                          }}
                        >
                          AUS
                          {fileData.checklistsCompleted?.includes('AUS') ? (
                            <i className="bi bi-check fs-6 text-success"></i>
                          ) : (
                            <>
                              {fileData.completePercent &&
                                fileData.completePercent.includes('AUS') &&
                                fileData.completePercent
                                  ?.split('AUS')[1]
                                  .split('(')[1]
                                  .split(')')[0] && (
                                  <span
                                    style={{
                                      fontSize: '10px',
                                      color: 'red',
                                      marginLeft: '3px',
                                    }}
                                  >
                                    &nbsp;(
                                    {
                                      fileData.completePercent
                                        ?.split('AUS')[1]
                                        .split('(')[1]
                                        .split(')')[0]
                                    }
                                    %)
                                  </span>
                                )}
                            </>
                          )}
                        </button>
                      </nav>
                    </div>
                  ) : (
                    <div className="col-12 col-lg-9 col-xxl-2">
                      <nav className="nav flex-nowrap flex-xxl-column overflow-auto">
                        {tabs.map(tab => (
                          <button
                            key={tab.prefix}
                            className={`nav-link rounded mb-2 border text-nowrap ${
                              activeTab === tab.prefix
                                ? fileData.checklistsCompleted?.includes(
                                    tab.prefixUpper
                                  )
                                  ? 'fw-bold border-success'
                                  : 'fw-bold border-primary'
                                : null
                            }`}
                            onClick={() =>
                              this.props.history.push(
                                `/details/${tab.prefix}-${this.state.loanFileSelect}`
                              )
                            }
                            style={{
                              fontSize: '13px',
                              backgroundColor:
                                fileData.checklistsCompleted?.includes(
                                  tab.prefixUpper
                                )
                                  ? '#f2fff2'
                                  : 'white',
                            }}
                          >
                            <TinyTooltip
                              tip={
                                tab.prefixUpper !== 'APR' &&
                                fileData.checklistsCompleted?.includes(
                                  tab.prefixUpper
                                ) &&
                                fileData.checklistsCompleted.split(
                                  tab.prefixUpper
                                )[1].length > 2
                                  ? fileData.checklistsCompleted
                                      .split(tab.prefixUpper)[1]
                                      .split(' ')[2]
                                      .split(',')[0] +
                                    '\n' +
                                    fileData.checklistsCompleted
                                      .split(tab.prefixUpper)[1]
                                      .split(' ')[1]
                                  : ''
                              }
                            >
                              {tab.label}
                              {fileData.checklistsCompleted?.includes(
                                tab.prefixUpper
                              ) ? (
                                <i className="bi bi-check fs-6 text-success"></i>
                              ) : (
                                <>
                                  {fileData.completePercent &&
                                    fileData.completePercent.includes(
                                      tab.prefixUpper
                                    ) &&
                                    fileData.completePercent
                                      ?.split(tab.prefixUpper)[1]
                                      .split('(')[1]
                                      .split(')')[0] && (
                                      <span
                                        style={{
                                          fontSize: '10px',
                                          color: 'red',
                                          marginLeft: '3px',
                                        }}
                                      >
                                        &nbsp;(
                                        {
                                          fileData.completePercent
                                            ?.split(tab.prefixUpper)[1]
                                            .split('(')[1]
                                            .split(')')[0]
                                        }
                                        %)
                                      </span>
                                    )}
                                </>
                              )}
                            </TinyTooltip>
                          </button>
                        ))}
                      </nav>
                    </div>
                  )}
                </>
              )}
              {/* MAIN CONTENT */}
              <div
                className="col-12 col-lg-9 col-xxl-7"
                style={{ maxHeight: '80vh', overflowY: 'scroll' }}
              >
                <div className="bg-white border rounded p-3">
                  {this.renderTabContent()}
                </div>
              </div>
              <div
                className="col-12 col-lg-3 col-xxl-3"
                style={{ maxHeight: '80vh', overflowY: 'scroll' }}
              >
                {/* LOAN DETAILS CARD */}
                <div className="bg-white border rounded p-3 mt-3 mt-lg-0">
                  <h1 className="h5">Loan Details</h1>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span
                        style={{ paddingRight: '5px' }}
                        onClick={() =>
                          this.copyToClipboard(
                            fileData.allBorrowers
                              ? fileData.allBorrowers
                                  .toString()
                                  .toUpperCase()
                                  .replace(', ', '\n')
                                  .replace(' AND ', '\n')
                              : '---',
                            'borrower'
                          )
                        }
                      >
                        <TinyTooltip tip="Copy Text">
                          {copied === 'borrower' ? (
                            <i className="bi bi-check-lg text-success fs-6 me-3"></i>
                          ) : (
                            <i className="bi bi-clipboard fs-6 me-3"></i>
                          )}
                        </TinyTooltip>
                        <small
                          className="text-muted"
                          style={{ position: 'relative', left: '-8px' }}
                        >
                          Borrower(s)
                        </small>
                        <br />
                        {fileData.allBorrowers
                          ? fileData.allBorrowers
                              .toString()
                              .toUpperCase()
                              .replace(', ', '\n')
                              .replace(' AND ', '\n')
                          : '---'}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <span
                        style={{ paddingRight: '5px' }}
                        onClick={() =>
                          this.copyToClipboard(
                            this.state.loanFileSelect,
                            'loan'
                          )
                        }
                      >
                        <TinyTooltip tip="Copy Text">
                          {copied === 'loan' ? (
                            <i className="bi bi-check-lg text-success fs-6 me-3"></i>
                          ) : (
                            <i className="bi bi-clipboard fs-6 me-3"></i>
                          )}
                        </TinyTooltip>
                        <small
                          className="text-muted"
                          style={{ position: 'relative', left: '-8px' }}
                        >
                          Loan Number
                        </small>
                        <br />
                        {this.state.loanFileSelect || '---'}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <span
                        style={{ paddingRight: '5px' }}
                        onClick={() =>
                          this.copyToClipboard(fileData.escrowNo, 'escrow')
                        }
                      >
                        <TinyTooltip tip="Copy Text">
                          {copied === 'escrow' ? (
                            <i className="bi bi-check-lg text-success fs-6 me-3"></i>
                          ) : (
                            <i className="bi bi-clipboard fs-6 me-3"></i>
                          )}
                        </TinyTooltip>
                        <small
                          className="text-muted"
                          style={{ position: 'relative', left: '-8px' }}
                        >
                          Escrow No
                        </small>
                        <br />
                        {fileData.escrowNo || '---'}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <span
                        style={{ paddingRight: '5px' }}
                        onClick={() =>
                          this.copyToClipboard(fileData.titleNo, 'title')
                        }
                      >
                        <TinyTooltip tip="Copy Text">
                          {copied === 'title' ? (
                            <i className="bi bi-check-lg text-success fs-6 me-3"></i>
                          ) : (
                            <i className="bi bi-clipboard fs-6 me-3"></i>
                          )}
                        </TinyTooltip>
                        <small
                          className="text-muted"
                          style={{ position: 'relative', left: '-8px' }}
                        >
                          Title No
                        </small>
                        <br />
                        {fileData.titleNo || '---'}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <span
                        style={{ paddingRight: '5px' }}
                        onClick={() =>
                          this.copyToClipboard(
                            `${fileData.street}\n${fileData.city}, ${fileData.state} ${fileData.zip}`,
                            'address'
                          )
                        }
                      >
                        <TinyTooltip tip="Copy Text">
                          {copied === 'address' ? (
                            <i className="bi bi-check-lg text-success fs-6 me-3"></i>
                          ) : (
                            <i className="bi bi-clipboard fs-6 me-3"></i>
                          )}
                        </TinyTooltip>
                        <small
                          className="text-muted"
                          style={{ position: 'relative', left: '-8px' }}
                        >
                          Address
                        </small>
                        <br />
                        {fileData.street || '---'}
                        {fileData.city && (
                          <>
                            <br />
                            {fileData.city}, {fileData.state} {fileData.zip}
                          </>
                        )}
                      </span>
                    </li>
                    <li className="list-group-item text-center">
                      <small
                        className="text-muted"
                        style={{ position: 'relative', left: '-8px' }}
                      >
                        Conditions Completed:
                        <span style={{ color: 'green', paddingLeft: '10px' }}>
                          ( {fileData.conditionsPercent || '---'} )
                        </span>
                        <br />
                        {fileData.unreviewedDocuments &&
                        parseInt(fileData.unreviewedDocuments) > 0 ? (
                          <span>
                            Documents Not Reviewed:
                            <span style={{ color: 'red', paddingLeft: '10px' }}>
                              ( {fileData.unreviewedDocuments} )
                            </span>
                          </span>
                        ) : (
                          <span style={{ color: 'green' }}>
                            {fileData.unreviewedDocuments
                              ? 'All Documents Reviewed'
                              : ''}
                          </span>
                        )}
                      </small>
                    </li>
                    <li className="list-group-item text-center">
                      <RouterLink
                        to={`/details/com-${this.state.loanFileSelect}`}
                      >
                        Communications &#8250;
                      </RouterLink>
                    </li>
                    <li className="list-group-item text-center d-flex gap-2">
                      <button
                        className="btn btn-sm btn-outline-primary col"
                        // style={{ borderColor: '#006bbe', color: '#006bbe' }}
                        onClick={() => {
                          this.setState({ showOutlookTemplate: true });
                        }}
                      >
                        Outlook
                      </button>

                      <button
                        className="btn btn-sm btn-outline-primary col"
                        onClick={() => {
                          this.setState({
                            showEmailTemplate: true,
                            emailTemplate: this.state.ptcTemplate,
                            emailLog: this.state.ptcEmailLog,
                          });
                        }}
                      >
                        PTC Updates
                      </button>
                    </li>
                    <li className="list-group-item text-center">
                      <RouterLink
                        to={
                          '/pipeline/loan-summary/' +
                          this.props.match.params.id.substr(4, 10)
                        }
                      >
                        Go to Loan Summary &#8250;
                      </RouterLink>
                    </li>
                  </ul>
                </div>
                {/* IMPORTANT DATES CARD */}
                <div className="bg-white border rounded p-3 mt-3">
                  <h1 className="h5">Important Dates</h1>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <small className="text-muted">Application Date:</small>
                      {fileData.applicationDate || '---'}
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <small className="text-muted">
                        Required to Send ID By:
                      </small>
                      {fileData.ileSentDate ? (
                        <span
                          style={{
                            color:
                              fileData.ileSentDate !== '---' ? 'green' : 'red',
                          }}
                        >
                          <TinyTooltip
                            tip={
                              'Due 3 Business Days [Mon-Fri]\nFrom Application Date (' +
                              fileData.applicationDate +
                              ')\n' +
                              (fileData.ileSentDate === '---'
                                ? 'Initial Disclosure Not Sent Yet!'
                                : 'Initial Disclosure Sent: ' +
                                  fileData.ileSentDate)
                            }
                          >
                            {fileData.idSendByDate || '---'}
                          </TinyTooltip>
                        </span>
                      ) : (
                        <>{fileData.idSendByDate || '---'}</>
                      )}
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <small className="text-muted">Anticipated Closing:</small>
                      {fileData.signingDate || '---'}
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <small className="text-muted">Scheduled Funding:</small>
                      {fileData.scheduledFundingDate || '---'}
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <small className="text-muted">
                        Close Of Escrow Date:
                      </small>
                      {fileData.escrowDate || '---'}
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <small className="text-muted">
                        Lock Expiration Date:
                      </small>
                      {fileData.lockExpDate || '---'}
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <small className="text-muted">
                        Appraisal Report Due:
                      </small>
                      {/* if there's no scheduled closing date, can't calculate an appraisal due date */}
                      {!fileData.signingDate ||
                      fileData.signingDate === '---' ? (
                        <>{'---'}</>
                      ) : (
                        <>
                          {/* if an appraisal order was not received yet */}
                          {fileData.appraisalReceived === '---' ? (
                            <>
                              {/* if the report is past due */}
                              {Date.parse(fileData.appraisalDueDate) <=
                                today && (
                                <span style={{ color: 'red' }}>
                                  <TinyTooltip
                                    tip={
                                      'Due 3 Business Days [Mon-Fri]\nPrior to Closing (' +
                                      fileData.signingDate +
                                      ')!\nAppraisal Report Past Due!'
                                    }
                                  >
                                    {fileData.appraisalDueDate}
                                  </TinyTooltip>
                                </span>
                              )}
                              {/* otherwise, just show warning */}
                              {Date.parse(fileData.appraisalDueDate) >
                                today && (
                                <span style={{ color: 'orange' }}>
                                  <TinyTooltip
                                    tip={
                                      'Due 3 Business Days [Mon-Fri]\nPrior to Closing (' +
                                      fileData.signingDate +
                                      ')!\nWaiting on Appraisal Report!'
                                    }
                                  >
                                    {fileData.appraisalDueDate}
                                  </TinyTooltip>
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              {/* appraisal order received */}
                              {/* if received past due date */}
                              {Date.parse(fileData.appraisalDueDate) <
                              Date.parse(fileData.appraisalReceived) ? (
                                <span style={{ color: 'red' }}>
                                  <TinyTooltip
                                    tip={
                                      'Due 3 Business Days [Mon-Fri]\nPrior to Closing (' +
                                      fileData.signingDate +
                                      ')!\nAppraisal Report Late: ' +
                                      fileData.appraisalReceived
                                    }
                                  >
                                    {fileData.appraisalDueDate}
                                  </TinyTooltip>
                                </span>
                              ) : (
                                /* sent on time */
                                <span style={{ color: 'green' }}>
                                  <TinyTooltip
                                    tip={
                                      'Due 3 Business Days [Mon-Fri]\nPrior to Closing (' +
                                      fileData.signingDate +
                                      ')!\nAppraisal Report Received: ' +
                                      fileData.appraisalReceived
                                    }
                                  >
                                    {fileData.appraisalDueDate}
                                  </TinyTooltip>
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <small className="text-muted">ICD Due Date:</small>
                      {/* if there's no scheduled closing date, can't calculate an ICD due date */}
                      {!fileData.signingDate ||
                      fileData.signingDate === '---' ? (
                        <>{'---'}</>
                      ) : (
                        <>
                          {/* if an ICD was not sent yet */}
                          {fileData.icdSentDate === '---' ? (
                            <>
                              {/* if the ICD is past due */}
                              {Date.parse(fileData.icdDueDate) <= today && (
                                <span style={{ color: 'red' }}>
                                  <TinyTooltip
                                    tip={
                                      'Due 3 Business Days [Mon-Sat]\nPrior to Closing (' +
                                      fileData.signingDate +
                                      ')!\nICD Not Sent In Time!'
                                    }
                                  >
                                    {fileData.icdDueDate}
                                  </TinyTooltip>
                                </span>
                              )}
                              {/* otherwise, just show warning */}
                              {Date.parse(fileData.icdDueDate) > today && (
                                <span style={{ color: 'orange' }}>
                                  <TinyTooltip
                                    tip={
                                      'Due 3 Business Days [Mon-Sat]\nPrior to Closing (' +
                                      fileData.signingDate +
                                      ')!\nICD Not Sent Yet!'
                                    }
                                  >
                                    {fileData.icdDueDate}
                                  </TinyTooltip>
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              {/* ICD was sent */}
                              {/* if sent past due date */}
                              {Date.parse(fileData.icdDueDate) <
                              Date.parse(fileData.icdSentDate) ? (
                                <span style={{ color: 'red' }}>
                                  <TinyTooltip
                                    tip={
                                      'Due 3 Business Days [Mon-Sat]\nPrior to Closing (' +
                                      fileData.signingDate +
                                      ')!\nICD Sent Late: ' +
                                      fileData.icdSentDate
                                    }
                                  >
                                    {fileData.icdDueDate}
                                  </TinyTooltip>
                                </span>
                              ) : (
                                /* sent on time */
                                <span style={{ color: 'green' }}>
                                  <TinyTooltip
                                    tip={
                                      'Due 3 Business Days [Mon-Sat]\nPrior to Closing (' +
                                      fileData.signingDate +
                                      ')!\nICD Sent: ' +
                                      fileData.icdSentDate
                                    }
                                  >
                                    {fileData.icdDueDate}
                                  </TinyTooltip>
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <small className="text-muted">
                        Earliest Closing Date:
                      </small>
                      {/* if we haven't sent the initial disclosure, can't calculate the earliest closing date */}
                      {!fileData.ileSentDate ||
                      fileData.ileSentDate === '---' ? (
                        <>{'---'}</>
                      ) : (
                        <>
                          {/* if no anticipated closing date, can't compare */}
                          {!fileData.signingDate ||
                          fileData.signingDate === '---' ? (
                            <span style={{ color: 'orange' }}>
                              <TinyTooltip
                                tip={
                                  'Initial Disclosure Sent: ' +
                                  fileData.ileSentDate +
                                  '\n(+7 Business Days [Mon-Sat])'
                                }
                              >
                                {fileData.earliestClosingDate || '---'}
                              </TinyTooltip>
                            </span>
                          ) : (
                            /* if earliest closing date is after the anticipated closing date - show in red */
                            <span
                              style={{
                                color:
                                  Date.parse(fileData.earliestClosingDate) >
                                  Date.parse(fileData.signingDate)
                                    ? 'red'
                                    : 'green',
                              }}
                            >
                              <TinyTooltip
                                tip={
                                  'Initial Disclosure Sent: ' +
                                  fileData.ileSentDate +
                                  '\n(+7 Business Days [Mon-Sat])'
                                }
                              >
                                {fileData.earliestClosingDate || '---'}
                              </TinyTooltip>
                            </span>
                          )}
                        </>
                      )}
                    </li>
                    {fileData.lockStartDate && !fileData.revisedLeSent && (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <small className="text-muted">Revised LE Due:</small>
                        {Date.parse(fileData.revisedLeDue) < today ? (
                          /* the revised LE is past due */
                          <span style={{ color: 'red' }}>
                            <TinyTooltip
                              tip={
                                'Rate Locked: ' +
                                fileData.lockStartDate +
                                '\n(+3 Business Days [Mon-Sat])\nPast Due Date!'
                              }
                            >
                              {fileData.revisedLeDue || '---'}
                            </TinyTooltip>
                          </span>
                        ) : (
                          <span style={{ color: 'orange' }}>
                            <TinyTooltip
                              tip={
                                'Rate Locked: ' +
                                fileData.lockStartDate +
                                '\n(+3 Business Days [Mon-Sat])'
                              }
                            >
                              {fileData.revisedLeDue || '---'}
                            </TinyTooltip>
                          </span>
                        )}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
        {this.state.successMessage && (
          <Toast
            type="success"
            title={this.state.successMessage}
            onClose={() => this.setState({ successMessage: '' })}
          />
        )}
        {this.state.errorMessage && (
          <Toast
            type="danger"
            title={this.state.errorMessage}
            onClose={() => this.setState({ errorMessage: '' })}
          />
        )}

        <ModalOutlook
          show={this.state.showOutlookTemplate}
          fileID={this.state.activeFile.substr(4, 10)}
          onDecline={() => this.setState({ showOutlookTemplate: false })}
          onAccept={() => this.setState({ showOutlookTemplate: false })}
        />
        <ModalEmail
          show={this.state.showEmailTemplate}
          template={this.state.emailTemplate}
          fileID={this.state.activeFile.substr(4, 10)}
          emailLog={this.state.emailLog}
          onDecline={() =>
            this.setState({ showEmailTemplate: false, emailTemplate: [] })
          }
          onAccept={() =>
            this.setState({ showEmailTemplate: false, emailTemplate: [] })
          }
        />
        <ModalEmailVerification
          show={this.state.showEmailVerification}
          email={this.state.verificationEmail}
          onDecline={() =>
            this.setState({
              showEmailVerification: false,
              verificationEmail: '',
            })
          }
        />
        <ModalEmailLog
          show={this.state.showEmailLog}
          emailLog={this.state.emailLog}
          onDecline={() => this.setState({ showEmailLog: false, emailLog: [] })}
        />
      </div>
    );
  }
}

export default withRouter(FileDetails);
