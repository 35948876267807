import { useEffect, useState } from 'react';
import LoginState from '../components/LoginState';

export const useFormFields = initialState => {
  const [fields, setValues] = useState(initialState);
  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
};

// RESOURCES METHODS:

export const saveRates = sheet => {
  const MORTGAGE_TYPE_OBJECT = {
    ML30DU: {},
    ML20DU: {},
    ML15DU: {},
    ML10DU: {},
    ML10ADU: {},
    ML7ADU: {},
    ML5ADU: {},
    ML3ADU: {},
    ML30HDU: {},
    ML15HDU: {},
    ML10HADU: {},
    ML7HADU: {},
    ML5HADU: {},
  };
  // 263 relates to the number of rows on the
  // RSDF sheet dated 10/22/2019
  for (let i = 2; i < 263; i++) {
    const type = sheet['G' + i].v;
    const key = sheet['H' + i].v;
    const value = sheet['I' + i].v;
    if (type === 'ML30DU') {
      MORTGAGE_TYPE_OBJECT.ML30DU[key] = value;
    }
    if (type === 'ML20DU') {
      MORTGAGE_TYPE_OBJECT.ML20DU[key] = value;
    }
    if (type === 'ML15DU') {
      MORTGAGE_TYPE_OBJECT.ML15DU[key] = value;
    }
    if (type === 'ML10DU') {
      MORTGAGE_TYPE_OBJECT.ML10DU[key] = value;
    }
    if (type === 'ML10ADU') {
      MORTGAGE_TYPE_OBJECT.ML10ADU[key] = value;
    }
    if (type === 'ML7ADU') {
      MORTGAGE_TYPE_OBJECT.ML7ADU[key] = value;
    }
    if (type === 'ML5ADU') {
      MORTGAGE_TYPE_OBJECT.ML5ADU[key] = value;
    }
    if (type === 'ML3ADU') {
      MORTGAGE_TYPE_OBJECT.ML3ADU[key] = value;
    }
    if (type === 'ML30HDU') {
      MORTGAGE_TYPE_OBJECT.ML30HDU[key] = value;
    }
    if (type === 'ML15HDU') {
      MORTGAGE_TYPE_OBJECT.ML15HDU[key] = value;
    }
    if (type === 'ML10HADU') {
      MORTGAGE_TYPE_OBJECT.ML10HADU[key] = value;
    }
    if (type === 'ML7HADU') {
      MORTGAGE_TYPE_OBJECT.ML7HADU[key] = value;
    }
    if (type === 'ML5HADU') {
      MORTGAGE_TYPE_OBJECT.ML5HADU[key] = value;
    }
  }
};

//
export const capitalize = str => {
  if (!str) return;
  const regex = /(\b[a-z](?!\s))/g;
  return str.toLowerCase().replace(regex, x => x.toUpperCase());
};

export const numbersNoFormat = numStr => {
  if (!numStr) return 0;
  else return numStr.toString().replaceAll(",", "").replaceAll("$", "").replaceAll("%", "");
};

export const formatPercent = num => {
  if(isNaN(num))
    return "---";
  else if(Number(num) === 0)
    return "";
  return parseFloat(num).toFixed(2) + "%";
}

export const getLTV = ({ purpose, amount, appraisalValue, purchasePrice }) => {
  var loanPurpose = purpose;
  if (!isNaN(purpose)) {
    switch (purpose) {
      case 1:
        loanPurpose = 'PUR';
        break;
      case 4:
        loanPurpose = 'R/T';
        break;
      case 5:
        loanPurpose = 'C/O';
        break;
      default:
        loanPurpose = 'PUR';
        break;
    }
  }

  if (!loanPurpose || !amount) {
    return '---';
  }
  if (!(appraisalValue || purchasePrice)) {
    return '---';
  }

  let appVal = appraisalValue ? numbersNoFormat(appraisalValue) : Number.MAX_SAFE_INTEGER;
  let purPri = purchasePrice ? numbersNoFormat(purchasePrice) : Number.MAX_SAFE_INTEGER;
  let total = numbersNoFormat(amount) / Math.min(appVal, purPri);

  if (total >= 1)
    return `${(total * 100).toPrecision(
      2 + total.toString().split('.')[0].length
    )}%`;
  else return `${(total * 100).toPrecision(2)}%`;
};

export const getCLTV = ({
  purpose,
  amount,
  secondLoanAmount,
  appraisalValue,
  purchasePrice,
}) => {
  var loanPurpose = purpose;
  if (!isNaN(purpose)) {
    switch (purpose) {
      case 1:
        loanPurpose = 'PUR';
        break;
      case 4:
        loanPurpose = 'R/T';
        break;
      case 5:
        loanPurpose = 'C/O';
        break;
      default:
        loanPurpose = 'PUR';
        break;
    }
  }

  if (!loanPurpose || !amount) {
    return '---';
  }
  if (!(appraisalValue || purchasePrice)) {
    return '---';
  }

  let total = numbersNoFormat(amount);
  if (secondLoanAmount) {
    total += numbersNoFormat(secondLoanAmount);
  }

  let appVal = appraisalValue ? numbersNoFormat(appraisalValue) : Number.MAX_SAFE_INTEGER;
  let purPri = purchasePrice ? numbersNoFormat(purchasePrice) : Number.MAX_SAFE_INTEGER;

  total = total / Math.min(appVal, purPri);

  if (total >= 1)
    return `${(total * 100).toPrecision(
      2 + total.toString().split('.')[0].length
    )}%`;
  else return `${(total * 100).toPrecision(2)}%`;
};

// FORMAT MONEY
/**
 * Format number to currency
 */
export const formatCurrency = (amount, edit) => {
  // if blank, leave blank
  if(amount === '') { return ''; }

  // check to see that it's a valid number
  if (isNaN(amount) || isNaN(parseFloat(amount))) {
    return edit ? '' : '---';
  }
  const number = typeof amount === 'number' ? amount : Number(amount);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(number === 0 ? 0 : number); // to prevent -$0.00 issue
};

export const formatCurrency2 = (amount, edit) => {
  // check to see that it's a valid number
  if (Number(amount) === Number.Nan) {
    return edit ? '' : '---';
  }
  const number = typeof amount === 'number' ? amount : Number(amount);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(number === 0 ? 0 : number); // to prevent -$0.00 issue
};

const formatNumber = n => {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPrice = n => {
  return ((n<0) ? '(' : '') + ((n<0) ? (n*-1) : n).toFixed(3) + ((n<0) ? ')' : '');
}

const noCommas = n => {
  // format number 1000000 to 1,234,567
  return n
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .replace(',', '');
};

export const unformatCurrency = text => {
  if (text === '') {
    return;
  }
  var returnVal = '';
  // check for decimal
  if (text.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = text.indexOf('.');

    // split number by decimal point
    var left_side = text.substring(0, decimal_pos);
    var right_side = text.substring(decimal_pos + 1);

    // remove comma from left side of number
    left_side = noCommas(left_side);

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    returnVal = left_side + (right_side.length > 0 ? '.' + right_side : '');
  } else {
    // no decimal entered, remove commas
    returnVal = noCommas(text);
  }
  // send updated string to input
  return returnVal;
};

export const autoFormatCurrency = text => {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.
  if (text === '') {
    return;
  }
  var returnVal = '';

  // check for decimal
  if (text.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = text.indexOf('.');

    // split number by decimal point
    var left_side = text.substring(0, decimal_pos);
    var right_side = text.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    returnVal = '$' + left_side + '.' + right_side;
  } else {
    // no decimal entered, add commas to number and remove all non-digits
    returnVal = formatNumber(text);
    returnVal = returnVal.length > 0 ? '$' + returnVal : '';
  }
  // send updated string to input
  return returnVal;
};

// removes middle name from full name
export const getFormattedName = name => {
  if (!name) return 'loading...';
  const temp = name.split(' ').filter(el => el !== '');
  for (var i = 0; i < temp.length; i++) {
    temp[i] = toTitleCase(temp[i]);
  }
  return temp.join(' ');
};

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const parseFICOadj = (condition, creditScore) => {
  var conditionPassed = true;
  var condBefore = condition.indexOf('FICO') > 0;
  var condAfter = condition.indexOf('FICO') < condition.length - 4;
  var splitStr = condition.split('FICO');

  if (condBefore) {
    // handle before condition
    let containsEquals = splitStr[0].includes('=');
    let containsGreater = splitStr[0].includes('>');
    let containsLesser = splitStr[0].includes('<');

    if (containsEquals) {
      if (containsGreater)
        conditionPassed = creditScore <= parseFloat(splitStr[0].split('>=')[0]);
      else if (containsLesser)
        conditionPassed = creditScore >= parseFloat(splitStr[0].split('<=')[0]);
    } else {
      if (containsGreater)
        conditionPassed = creditScore < parseFloat(splitStr[0].split('>')[0]);
      else if (containsLesser)
        conditionPassed = creditScore > parseFloat(splitStr[0].split('<')[0]);
    }

    if (!conditionPassed) return false;
  }

  if (condAfter) {
    // handle after condition
    let containsEquals = splitStr[1].includes('=');
    let containsGreater = splitStr[1].includes('>');
    let containsLesser = splitStr[1].includes('<');

    if (containsEquals) {
      if (containsGreater)
        conditionPassed = creditScore >= parseFloat(splitStr[1].split('>=')[1]);
      else if (containsLesser)
        conditionPassed = creditScore <= parseFloat(splitStr[1].split('<=')[1]);
    } else {
      if (containsGreater)
        conditionPassed = creditScore > parseFloat(splitStr[1].split('>')[1]);
      else if (containsLesser)
        conditionPassed = creditScore < parseFloat(splitStr[1].split('<')[1]);
    }

    if (!conditionPassed) return false;
  }

  return true;
};

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '---';
};

export const checkRouteForString = (term, pathname) => pathname.includes(term);

export const base64ArrayBuffer = arrayBuffer => {
  var base64 = '';
  var encodings =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder === 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }

  return base64;
};

export const todaysDate = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yy = today.getFullYear().toString().slice(2);
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  return mm + '/' + dd + '/' + yy;
};

export const todaysDateYYYY = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yy = today.getFullYear().toString().slice(2);
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  return mm + '/' + dd + '/20' + yy;
};

export const useCountdown = targetDate => {
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 60000); // check once every minute

    return () => clearInterval(interval);
  }, [countDownDate]);

  var countDownComplete = countDown < 0;
  return [countDownComplete];
};

export const getPropertyType = typeNum => {
  switch (typeNum.toString()) {
    case '1':
    case '2':
      return 'SFR';
    case '3':
    case '4':
      return 'Condo (Attached)';
    case '5':
      return 'Condo (Detached)';
    case '6':
      return 'PUD';
    case '7':
      return 'Cooperative';
    case '8':
    case '9':
    case '10':
    case '11':
    case '12':
      return 'Manufactured';
    case '13':
      return 'Vacant Land';
    default:
      return '---';
  }
};

export const isAdmin = () => {
  return LoginState.userRole && (LoginState.userRole.toLowerCase().includes('admin') || LoginState.userName === 'jlee');
}