import { Body, Email } from './FileDetailsHelpers';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS } from '../../helpers/constants';
import { getPropertyType } from '../../helpers/utils';
import Toast from '../Toast';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalEmailVerification from './ModalEmailVerification';
import { TinyTooltip } from '../Tooltip';

export default class CommunicationsChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      borrowers: [],
      isLoading: true,
      errorMessage: '',
      showEmailTemplate: false,
      emailTemplate: [],
      onHoldTemplate: [],
      onHoldEmailLog: [],
      canceledTemplate: [],
      canceledEmailLog: [],
      ptcTemplate: [],
      ptcEmailLog: [],
      blankSubject: '',
      showEmailVerification: false,
      verificationEmail: '',
    };
    this.getFileData();
  }

  async getFileData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    response = axios.get('/getemailfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var emailFields = (await response).data;
    this.setState({
      fileData: resultData,
      onHoldTemplate: emailFields.onHoldTemplate,
      onHoldEmailLog: emailFields.onHoldEmailLog,
      canceledTemplate: emailFields.canceledTemplate,
      canceledEmailLog: emailFields.canceledEmailLog,
      ptcTemplate: emailFields.ptcTemplate,
      ptcEmailLog: emailFields.ptcEmailLog,
      blankSubject: emailFields.blankSubject,
      isLoading: false,
    });
  }

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  openOutlookTemplate() {
    var mail = 'mailto:?subject=' + this.state.blankSubject + '&body=';
    var a = document.createElement('a');
    a.href = mail;
    a.click();
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <>
          <h1 className="h5">
            Communications <span className="small">(Email Templates)</span>
          </h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </>
      );
    }
    return (
      <>
        <h1 className="h5">
          Communications <span className="small">(Email Templates)</span>
        </h1>
        <div>
          {/* Blank */}
          <div className="mt-4 mb-3 row">
            <div className="col-12 d-flex">
              <div className="flex-grow-1 p-3 bg-white border rounded-start rounded-end">
                <div>
                  <Email />
                  Blank Template
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => this.openOutlookTemplate()}
                  >
                    Open Outlook
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* PTC Updates */}
          <div className="mb-3 row">
            <div className="col-12 d-flex">
              <div className="flex-grow-1 p-3 bg-white border rounded-start rounded-end">
                <div>
                  <Email />
                  PTC Updates
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.ptcTemplate,
                        emailLog: this.state.ptcEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.ptcEmailLog.length > 0 && (
                    <button
                      className="btn btn-sm btn-light ms-2 px-3 border-0"
                      type="button"
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.ptcEmailLog,
                        })
                      }
                    >
                      <TinyTooltip tip="View email log">
                        <i className="bi bi-envelope-check text-success fs-6" />
                      </TinyTooltip>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* On Hold */}
          <div className="mb-3 row">
            <div className="col-12 d-flex">
              <div className="flex-grow-1 p-3 bg-white border rounded-start rounded-end">
                <div>
                  <Email />
                  On Hold
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.onHoldTemplate,
                        emailLog: this.state.onHoldEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.onHoldEmailLog.length > 0 && (
                    <button
                      className="btn btn-sm btn-light ms-2 px-3 border-0"
                      type="button"
                      id="email-log"
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.onHoldEmailLog,
                        })
                      }
                    >
                      <TinyTooltip tip="View email log">
                        <i className="bi bi-envelope-check text-success fs-6" />
                      </TinyTooltip>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Canceled File */}
          <div className="mb-3 row">
            <div className="col-12 d-flex">
              <div className="flex-grow-1 p-3 bg-white border rounded-start rounded-end">
                <div>
                  <Email />
                  Canceled File
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.canceledTemplate,
                        emailLog: this.state.canceledEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.canceledEmailLog.length > 0 && (
                    <button
                      className="btn btn-sm btn-light ms-2 px-3 border-0"
                      type="button"
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.canceledEmailLog,
                        })
                      }
                    >
                      <TinyTooltip tip="View email log">
                        <i className="bi bi-envelope-check text-success fs-6" />
                      </TinyTooltip>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}

          <ModalEmail
            show={this.state.showEmailTemplate}
            template={this.state.emailTemplate}
            fileID={this.state.activeFile}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
            onAccept={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
          />
          <ModalEmailVerification
            show={this.state.showEmailVerification}
            email={this.state.verificationEmail}
            onDecline={() =>
              this.setState({
                showEmailVerification: false,
                verificationEmail: '',
              })
            }
          />
          <ModalEmailLog
            show={this.state.showEmailLog}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailLog: false, emailLog: [] })
            }
          />
        </div>
      </>
    );
  }
}
