import React from 'react';
import ProgramCard from './ProgramCard';
import FAQ from './FAQ';

const ProgramComparison = () => {
  // Responsive table
  // Logo link
  // downloadble bg image

  return (
    <>
      <main>
        <div className="pattern-square"></div>
        {/* INTRO start */}
        <section className="pt-5 pb-0 pb-lg-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
                <div className="text-center">
                  <a href="https://www.mtgrl.com/">
                    <img
                      src="/images/MTG_Logo.png"
                      alt="logo"
                      width="200"
                      className="mb-4"
                    />
                  </a>
                  <h1 className="fw-bold mb-1 mb-lg-3">
                    Our No-BS-For-Real Non-QM Programs
                  </h1>
                  <p className="mb-0 fs-5 text-muted"></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* PROGRAMS OVERVIEW */}
        {/* <ProgramCard /> */}
        {/* PROGRAM DETAILS */}
        <section className="mb-xl-9 py-4 mb-4">
          <div className="container">
            <div className="row">
              {/* MOBILE TABLE 1 */}
              <div className="d-lg-none col-12">
                <div className="table-responsive">
                  <table className="table table-striped text-nowrap table-lg table-borderless">
                    <thead className="border-bottom border-2">
                      <tr>
                        <th className="w-50" scope="col"></th>
                        <th className="w-50" scope="col">
                          <div className="fs-5 text-primary fw-semibold text-center">
                            MTGP1
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Purpose
                        </td>
                        <td>
                          <span>PUR, RT, CO</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Occupancy
                        </td>
                        <td>
                          <span>PR, 2ND, INVEST</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Products
                        </td>
                        <td>
                          <span>
                            30Y Fixed <br />
                            7/6M ARM
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. Loan Amount
                        </td>
                        <td>
                          <span className="d-block animate__animated animate__heartBeat animate__infinite animate__slower">
                            $10M
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. LTV
                        </td>
                        <td>
                          <span>70%</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. FICO
                        </td>
                        <td>
                          <span>680</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by CPA
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>
                            No Bank Stmt. Req.
                            <br />
                            No Exp. Ratio
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by Borrower
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>
                            No Bank Stmt. Req.
                            <br />
                            No Exp. Ratio
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Written VOE
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>No Bank Stmt. Req.</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Bank Statements
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          IRS Form 1099
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset Based Qual.
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Foreign National
                        </td>
                        <td>
                          <span>
                            <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. Borrower Contrib.
                        </td>
                        <td>
                          <span>None</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset
                        </td>
                        <td>
                          <span>2mo or VOD</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Gift Funds
                        </td>
                        <td>
                          <span>
                            100%
                            <br />
                            Except Reserves
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Reserves
                        </td>
                        <td>
                          <span>2 to 6 mth P&amp;I</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Rental Income from REOs
                        </td>
                        <td>
                          <span>
                            Lease or FNMA 1007
                            <br />
                            No 2 mo Proof Req'd
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Non-Arm's Length
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Sale-By-Owner
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* MOBILE TABLE 2 */}
              <div className="d-lg-none col-12">
                <div className="table-responsive">
                  <table className="table table-striped text-nowrap table-lg table-borderless">
                    <thead className="border-bottom border-2">
                      <tr>
                        <th className="w-50" scope="col"></th>
                        <th className="w-50" scope="col">
                          <div className="fs-5 text-primary fw-semibold text-center">
                            MTGP1 CRA
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Purpose
                        </td>
                        <td>PUR, RT</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Occupancy
                        </td>
                        <td>PR, 2ND, INVEST</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Products
                        </td>
                        <td>
                          30Y Fixed
                          <br />
                          7/6M ARM
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. Loan Amount
                        </td>
                        <td>
                          <span className="d-block animate__animated animate__heartBeat animate__infinite animate__slower">
                            $10M
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. LTV
                        </td>
                        <td>80%</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. FICO
                        </td>
                        <td>680</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by CPA
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>{' '}
                          No Bank Stmt. Req.
                          <br />
                          No Exp. Ratio
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by Borrower
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>{' '}
                          No Bank Stmt. Req.
                          <br />
                          No Exp. Ratio
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Written VOE
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>{' '}
                          No Bank Stmt. Req.
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Bank Statements
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          IRS Form 1099
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset Based Qual.
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Foreign National
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. Borrower Contrib.
                        </td>
                        <td>None</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset
                        </td>
                        <td>2mo or VOD</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Gift Funds
                        </td>
                        <td>100% Except Reserves</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Reserves
                        </td>
                        <td>2 to 6mo P&I</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Rental Income from REOs
                        </td>
                        <td>
                          Lease or FNMA 1007
                          <br />
                          No 2mo Proof Req'd
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Non-Arm's Length
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Sale-By-Owner
                        </td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* MOBILE TABLE 3 */}
              <div className="d-lg-none col-12">
                <div className="table-responsive">
                  <table className="table table-striped text-nowrap table-lg table-borderless">
                    <thead className="border-bottom border-2">
                      <tr>
                        <th className="w-50" scope="col"></th>
                        <th className="w-50" scope="col">
                          <div className="fs-5 text-primary fw-semibold text-center">
                            MTGP2
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Purpose
                        </td>
                        <td>PUR, RT, CO</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Occupancy
                        </td>
                        <td>PR, 2ND, INVEST</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Products
                        </td>
                        <td>
                          30Y Fixed
                          <br />
                          7/6M, 5/6M ARM
                          <br />
                          <span className="animateHighlight">40Y Amort.</span>
                          <br />
                          <span className="animateHighlight">Int-Only</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. Loan Amount
                        </td>
                        <td>$4M</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. LTV
                        </td>
                        <td>
                          <span className="d-block animate__animated animate__heartBeat animate__infinite animate__slower">
                            90%
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. FICO
                        </td>
                        <td className="text-primary fw-bold">660</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by CPA
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>{' '}
                          No Bank Stmt.
                          <br />
                          Req. No Exp. Ratio
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by Borrower
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Written VOE
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>{' '}
                          No Bank Stmt. Req.
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Bank Statements
                        </td>
                        <td>12 mo Flexable Exp. Ratio</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          IRS Form 1099
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>{' '}
                          10% Exp. Ratio
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset Based Qual.
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Foreign National
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. Borrower Contrib.
                        </td>
                        <td>None</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset
                        </td>
                        <td>
                          <span className="text-primary fw-bold">1mo</span> or
                          VOD
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Gift Funds
                        </td>
                        <td>
                          100%
                          <br />
                          <span className="animateHighlight">
                            Including Reserves
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Reserves
                        </td>
                        <td>
                          <span className="text-primary fw-bold">None</span> to
                          12mo P&I
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Rental Income from REOs
                        </td>
                        <td>
                          Lease or FNMA 1007
                          <br />
                          No 2mo Proof Req'd
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Non-Arm's Length
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Sale-By-Owner
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* MOBILE TABLE 4 */}
              <div className="d-lg-none col-12">
                <div className="table-responsive">
                  <table className="table table-striped text-nowrap table-lg table-borderless">
                    <thead className="border-bottom border-2">
                      <tr>
                        <th className="w-50" scope="col"></th>
                        <th className="w-50" scope="col">
                          <div className="fs-5 text-primary fw-semibold text-center">
                            MTGPA
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Purpose
                        </td>
                        <td>PUR, RT</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Occupancy
                        </td>
                        <td>PR, 2ND, INVEST</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Products
                        </td>
                        <td>
                          30Y Fixed
                          <br />
                          7/6M ARM
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. Loan Amount
                        </td>
                        <td>$2.5M</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. LTV
                        </td>
                        <td>70%</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. FICO
                        </td>
                        <td>700</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by CPA
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by Borrower
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Written VOE
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Bank Statements
                        </td>
                        <td>
                          <span className="text-primary fw-bold">
                            Only 3 mo
                          </span>
                          <br />
                          No Exp. Ratio
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          IRS Form 1099
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset Based Qual.
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Foreign National
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. Borrower Contrib.
                        </td>
                        <td>None</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset
                        </td>
                        <td>2mo or VOD</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Gift Funds
                        </td>
                        <td>100% Except Reserves</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Reserves
                        </td>
                        <td>2mo P&I</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Rental Income from REOs
                        </td>
                        <td>
                          Lease or FNMA 1007
                          <br />
                          No 2mo Proof Req'd
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Non-Arm's Length
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Sale-By-Owner
                        </td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* MOBILE TABLE 5 */}
              <div className="d-lg-none col-12">
                <div className="table-responsive">
                  <table className="table table-striped text-nowrap table-lg table-borderless">
                    <thead className="border-bottom border-2">
                      <tr>
                        <th className="w-50" scope="col"></th>
                        <th className="w-50" scope="col">
                          <div className="fs-5 text-primary fw-semibold text-center">
                            MTGQUASH
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Purpose
                        </td>
                        <td>PUR, RT</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Occupancy
                        </td>
                        <td>PR, 2ND, INVEST</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Products
                        </td>
                        <td>
                          30Y Fixed
                          <br />
                          7/6M ARM
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. Loan Amount
                        </td>
                        <td>$2.5M</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. LTV
                        </td>
                        <td>70%</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. FICO
                        </td>
                        <td>700</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by CPA
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by Borrower
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Written VOE
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Bank Statements
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          IRS Form 1099
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset Based Qual.
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>{' '}
                          Qualif. Assets / 6 mo
                          <br />
                          =Max. Income
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Foreign National
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. Borrower Contrib.
                        </td>
                        <td>None</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset
                        </td>
                        <td>2mo or VOD</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Gift Funds
                        </td>
                        <td>100% Except Reserves</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Reserves
                        </td>
                        <td>2mo P&I</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Rental Income from REOs
                        </td>
                        <td>
                          Lease or FNMA 1007
                          <br />
                          No 2mo Proof Req'd
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Non-Arm's Length
                        </td>
                        <td>-</td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Sale-By-Owner
                        </td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* DESKTOP TABLE */}
              <div className="d-none d-lg-block col-12">
                <div className="table-responsive">
                  <table className="table table-striped text-nowrap table-lg table-borderless">
                    <thead className="border-bottom border-2">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">
                          <div className="fs-5 text-primary fw-semibold text-center">
                            MTGP1
                          </div>
                        </th>
                        <th scope="col">
                          <div className="fs-5 text-primary fw-semibold text-center">
                            MTGP1CRA
                          </div>
                        </th>
                        <th scope="col">
                          <div className="fs-5 text-primary fw-semibold text-center">
                            MTGP2
                          </div>
                        </th>
                        <th scope="col">
                          <div className="fs-5 text-primary fw-semibold text-center">
                            MTGPA
                          </div>
                        </th>
                        <th scope="col">
                          <div className="fs-5 text-primary fw-semibold text-center">
                            MTGQUASH
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Purpose
                        </td>
                        <td>
                          <span>PUR, RT, CO</span>
                        </td>
                        <td>
                          <span>PUR, RT</span>
                        </td>
                        <td>
                          <span>PUR, RT, CO</span>
                        </td>
                        <td>
                          <span>PUR, RT</span>
                        </td>
                        <td>
                          <span>PUR, RT</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Occupancy
                        </td>
                        <td>
                          <span>PR, 2ND, INVEST</span>
                        </td>
                        <td>
                          <span>PR, 2ND, INVEST</span>
                        </td>
                        <td>
                          <span>PR, 2ND, INVEST</span>
                        </td>
                        <td>
                          <span>PR, 2ND, INVEST</span>
                        </td>
                        <td>
                          <span>PR, 2ND, INVEST</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Loan Products
                        </td>
                        <td>
                          <span>
                            30Y Fixed <br />
                            7/6M ARM
                          </span>
                        </td>
                        <td>
                          <span>
                            30Y Fixed <br />
                            7/6M ARM
                          </span>
                        </td>
                        <td>
                          <span>
                            30Y Fixed <br />
                            7/6M, 5/6M ARM
                            <br />
                            <span className="animateHighlight">40Y Amort.</span>
                            <br />
                            <span className="animateHighlight">Int-Only</span>
                          </span>
                        </td>
                        <td>
                          <span>
                            30Y Fixed <br />
                            7/6M ARM
                          </span>
                        </td>
                        <td>
                          <span>
                            30Y Fixed <br />
                            7/6M ARM
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. Loan Amount
                        </td>
                        <td>
                          <span className="d-block animate__animated animate__heartBeat animate__infinite animate__slower">
                            $10M
                          </span>
                        </td>
                        <td>
                          <span className="d-block animate__animated animate__delay-2s animate__heartBeat animate__infinite animate__slower">
                            $10M
                          </span>
                        </td>
                        <td>
                          <span>$4M</span>
                        </td>
                        <td>
                          <span>$2.5M</span>
                        </td>
                        <td>
                          <span>$2.5M</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Max. LTV
                        </td>
                        <td>
                          <span>70%</span>
                        </td>
                        <td>
                          <span>80%</span>
                        </td>
                        <td>
                          <span className="d-block animate__animated animate__delay-3s animate__heartBeat animate__infinite animate__slower">
                            90%
                          </span>
                        </td>
                        <td>
                          <span>70%</span>
                        </td>
                        <td>
                          <span>70%</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. FICO
                        </td>
                        <td>
                          <span>680</span>
                        </td>
                        <td>
                          <span>680</span>
                        </td>
                        <td>
                          <span className="text-primary fw-bold">660</span>
                        </td>
                        <td>
                          <span>700</span>
                        </td>
                        <td>
                          <span>700</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by CPA
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>
                            No Bank Stmt. Req.
                            <br />
                            No Exp. Ratio
                          </span>
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>
                            No Bank Stmt. Req.
                            <br />
                            No Exp. Ratio
                          </span>
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>
                            No Bank Stmt. Req.
                            <br />
                            No Exp. Ratio
                          </span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          P&L by Borrower
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>
                            No Bank Stmt. Req.
                            <br />
                            No Exp. Ratio
                          </span>
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>
                            No Bank Stmt. Req.
                            <br />
                            No Exp. Ratio
                          </span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Written VOE
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>No Bank Stmt. Req.</span>
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>No Bank Stmt. Req.</span>
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>No Bank Stmt. Req.</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Bank Statements
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>12 mth Flexible Exp. Ratio</span>
                        </td>
                        <td>
                          <span>
                            <span className="text-primary fw-bold">
                              Only 3 mo
                            </span>
                            <br />
                            No Exp. Ratio
                          </span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          IRS Form 1099
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>10% Exp. Ratio</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset Based Qual.
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          <span>
                            Qualif. Assets / 6 mth
                            <br />= Max. Income
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Foreign National
                        </td>
                        <td>
                          <span>
                            <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                          </span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Min. Borrower Contrib.
                        </td>
                        <td>
                          <span>None</span>
                        </td>
                        <td>
                          <span>None</span>
                        </td>
                        <td>
                          <span>None</span>
                        </td>
                        <td>
                          <span>None</span>
                        </td>
                        <td>
                          <span>None</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Asset
                        </td>
                        <td>
                          <span>2mo or VOD</span>
                        </td>
                        <td>
                          <span>2mo or VOD</span>
                        </td>
                        <td>
                          <span>
                            <span className="text-primary fw-bold">1mo</span> or
                            VOD
                          </span>
                        </td>
                        <td>
                          <span>2mo or VOD</span>
                        </td>
                        <td>
                          <span>2mo or VOD</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Gift Funds
                        </td>
                        <td>
                          <span>
                            100%
                            <br />
                            Except Reserves
                          </span>
                        </td>
                        <td>
                          <span>
                            100%
                            <br />
                            Exc. Reserves
                          </span>
                        </td>
                        <td>
                          <span>100%</span>
                          <br />
                          <span className="animateHighlight">
                            Including Reserves
                          </span>
                        </td>
                        <td>
                          <span>
                            100%
                            <br />
                            Exc. Reserves
                          </span>
                        </td>
                        <td>
                          <span>
                            100%
                            <br />
                            Exc. Reserves
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Reserves
                        </td>
                        <td>
                          <span>2 to 6 mth PI</span>
                        </td>
                        <td>
                          <span>2 to 6 mth PI</span>
                        </td>
                        <td>
                          <span>
                            <span className="text-primary fw-bold">None</span>{' '}
                            to 12 mth PI
                          </span>
                        </td>
                        <td>
                          <span>2 mth PI</span>
                        </td>
                        <td>
                          <span>2 mth PI</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Rental Income from REOs
                        </td>
                        <td>
                          <span>
                            Lease or FNMA 1007
                            <br />
                            No 2 mo Proof Req'd
                          </span>
                        </td>
                        <td>
                          <span>
                            Lease or FNMA 1007
                            <br />
                            No 2 mo Proof Req'd
                          </span>
                        </td>
                        <td>
                          <span>
                            Lease or FNMA 1007
                            <br />
                            No 2 mo Proof Req'd
                          </span>
                        </td>
                        <td>
                          <span>
                            Lease or FNMA 1007
                            <br />
                            No 2 mo Proof Req'd
                          </span>
                        </td>
                        <td>
                          <span>
                            Lease or FNMA 1007
                            <br />
                            No 2 mo Proof Req'd
                          </span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Non-Arm's Length
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                      {/* ROW */}
                      <tr className="align-middle text-center">
                        <td className="border-end border-2 text-end fw-bold text-wrap">
                          Sale-By-Owner
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <i class="bi bi-check-circle-fill text-success fs-5 d-block"></i>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                        <td>
                          <span>-</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* CTA */}
        <section className="my-5">
          <div className="container">
            <div className="row line-pattern bg-primary-gradient rounded-3 p-5 g-0">
              <div className="col-lg-8 offset-lg-2 " style={{ zIndex: 1 }}>
                <div className="text-center py-md-5 vstack gap-3 align-items-center">
                  <h2 className="h1 text-white">Do you like the programs?</h2>
                  <p className="text-white-50 fs-5">
                    We’d love to hear about your brand and business challenges,
                    even if you’re not sure what your next step is. No pitch, no
                    strings attached.
                  </p>
                  <a href="/" className="btn btn-dark btn-lg px-5">
                    Get started!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQ */}
        {/* <FAQ /> */}
      </main>
      <footer id="footer">
        <div className="container-fluid py-5">
          <div className="container my-5">
            <div className="vstack gap-3">
              <div className="d-flex flex-column flex-lg-row gap-4 mb-3">
                <div>
                  <i className="bi bi-envelope text-white" />
                  <a
                    className="ms-2 text-white"
                    href="mailto:info@mtgrl.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@mtgrl.com
                  </a>
                </div>
                <div>
                  <i className="bi bi-telephone text-white" />
                  <a className="ms-2 text-white" href="tel:1-800-562-3220">
                    +1 (800) 562 3220
                  </a>
                </div>
                <div>
                  <i className="bi geo-alt text-white" />
                  <a
                    className="ms-2 text-white"
                    href="https://goo.gl/maps/jWTvCK1bpuuKAfRR9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    3435 Wilshire Blvd Ste 2880 Los Angeles, CA 90010
                  </a>
                </div>
              </div>
              <p className="text-white">
                &copy; 2024 Mortgage Research Laboratory Inc. All Right
                Reserved. Mortgage Research Laboratory Inc. is a direct lender.
                <br />
                Loans Available In:
                <span className="ps-3">AZ, CA, CO, GA, TX, WA</span>
                <br />
                Equal Housing Lender.
              </p>

              <span className="text-white">
                NMLS ID #1944774. For licensing information, go to:&nbsp;
                <a
                  className="text-light"
                  href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1944774"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.nmlsconsumeraccess.org
                </a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ProgramComparison;
