import React, { useEffect, useState } from 'react';
import ProgramList from '../ProgramList';
import { PRICING_OPTIONS } from '../../helpers/constants';
import axios from 'axios';

const ProgramSelect = ({ loan, fnmFile, changeStep }) => {
  const [allPrograms, setAllPrograms] = useState(null);
  const [availablePrograms, setAvailablePrograms] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState('');
  const [programErrors, setProgramErrors] = useState('');

  useEffect(() => {
    if (!availablePrograms) {
      calculatePrograms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePrograms]);

  const calculatePrograms = async () => {
    const res = await axios.get('/program', {
      params: {
        pTimestamp: '', // latest rates
        pProgCode: '', // specific program code
        pProgFamily: '',
        pDocumentType: loan.documentType, // full doc, portfolio
        pLoanType: loan.product.includes('Fixed') ? 1 : 2, // fixed, adjustable
        pLoanTerm: loan.product.split(' ')[0],
        pCounty: loan.property.county,
        pState: loan.property.state,

        pFirstLoanAmt: loan.amount, // loan amount
        pSecondLoanAmt: 0, // loan amount
        pLoanPurpose: parseInt(loan.purpose) > 3 ? '3' : loan.purpose, // purchase, r/t refi, c/o refi
        pPropertyType:
          PRICING_OPTIONS.propertyType[loan.propertyType - 1].label, // SFR, Condo (A), Condo (D), PUD, 2-4 Unit
        pOccupancyType: loan.property.occupancyType, // primary, second home, investment

        pLtv: loan.ltv,
        pCltv: loan.cltv,
        pFico: loan.borrowers[0].fico,
        pDti: loan.borrowers[0].dti,

        pLockPeriod: 1, // 15 Day, 30 Day
        pPmi: loan.pmi, // No PMI, Borrower Paid, Lender Paid

        pIV: '0',
        pEscrow: '1', // 1 - No Impound, 2 - Tax, 3 - Insurance, 4 - Tax and Insurance
        pForeignNational: 2, // 1 - Yes, 2 - No
        pMonthsReserves: 1, // 1 - 2 months, 2 - 6 months, 3 - 12 months
        pPrepayPenalty: 1 // 1 - No Prepay Penalty  - todo: add option to choose this
      },
    });

    if (!res || res.error) {
      setProgramErrors('Server Error While Fetching Programs!');
    } else {
      const programs = res.data;
      var availablePrograms = [];
      var issues = [];
      for (var i = 0; i < programs.length; i++) {
        if (programs[i].issues.length === 0)
          availablePrograms.push(programs[i]);
        else {
          for (var j = 0; j < programs[i].issues.length; j++) {
            var dupIssue = false;
            for (var k = 0; k < issues.length; k++) {
              if (
                programs[i].issues[j].split('(')[0] === issues[k].split('(')[0]
              ) {
                dupIssue = true;
                break;
              }
            }
            if (!dupIssue) issues.push(programs[i].issues[j]);
          }
        }
      }
      setAllPrograms(programs);

      if (availablePrograms.length === 0) {
        var issueStr = '';
        for (var l = 0; l < issues.length; l++) {
          issueStr = issueStr + issues[l] + '\n';
        }
        setProgramErrors(issueStr);
        setAvailablePrograms(availablePrograms);
      } else setAvailablePrograms(availablePrograms);
    }
  };

  const NextStep = () => {
    loan.selectedProgram = selectedProgram.selectedProgram.code;
    changeStep('contact', { ...loan }, fnmFile);
  };

  return (
    <div className="col-12">
      <div className="row">
        <div className="text-center">
          <h5>
            {availablePrograms &&
              availablePrograms.length > 0 &&
              `Please select a program.`}
          </h5>
        </div>
        <div className="col-12 col-md-6 offset-md-3">
          <ProgramList
            allPrograms={allPrograms}
            availablePrograms={availablePrograms}
            progErr={programErrors}
            onSelect={program =>
              setSelectedProgram({ selectedProgram: program })
            }
            selectedProgram={selectedProgram}
          />
        </div>
        <div className="col-12 text-end mt-4">
          <button
            className="btn btn-light"
            onClick={() => changeStep('import', loan, fnmFile, null)}
          >
            Go Back
          </button>
          <button
            className="btn btn-primary text-white ms-2"
            disabled={!selectedProgram}
            onClick={() => NextStep()}
          >
            CONTINUE TO STEP 3
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgramSelect;
