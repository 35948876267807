import React, { Component } from 'react';
import axios from 'axios';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import LoginState from '../LoginState';

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9800;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalCard = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  z-index: 9900;
  width: ${p => (p.width ? p.width : '700px')};
  min-height: ${p => (p.height ? p.height : '400px')};
  max-height: 90vh;
  background-color: ${p => p.theme.colors.white};
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h6`
  margin: 0;
  color: red;
`;

const ModalBody = styled.div`
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-grow: 1;
`;

const ExtraModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9990;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ExtraModalCard = styled.div`
  padding: 1rem;
  z-index: 9999;
  width: ${p => (p.width ? p.width : '700px')};
  min-height: ${p => (p.height ? p.height : '200px')};
  max-height: 90vh;
  background-color: ${p => p.theme.colors.white};
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const EmailTextField = styled.input`
  width: 200px;
  text-align: left;
  padding-left: 3px;
  border: 1px solid lightgray;
`;

const HiddenSelect = ({
  name,
  label,
  options = [],
  error,
  value,
  onChange,
  ...rest
}) => (
  <select
    id={name}
    name={name}
    value={value || -1}
    {...rest}
    invalid={error}
    onChange={e => onChange(e)}
    style={{border: 'none', maxHeight:'20px', maxWidth: '18px', margin: '0px', padding: '0px', backgroundColor: 'transparent'}}
  >
    <option key="placeholder" disabled="disabled" value="-1"></option>
    {options.map((option, index) => (
      <option
        key={`${option.label}-${option.value}-${index}`}
        value={option.value}
      >
        {option.label}
      </option>
    ))}
  </select>
);

export default class ModalOutlook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectType: 1,
      showExtraWrapper: false,
      extraContentType: '',
      toList: [],
      ccList: [],
      emailFrom: '',
      emailSubject: '',
      emailContent: '',
      emailExtraText: '',
      errorMsg: '',
      errorMessage: '',

      borrowers: [],
      loanOfficer: { name: '---', email: '---' },
      loanProcessor: { name: '---', email: '---' },
      loanProcessor2: { name: '---', email: '---' },
      underwriter1: { name: '---', email: '---' },
      underwriter2: { name: '---', email: '---' },
      accountExecutive: { name: '---', email: '---' },
      settlementCompany: { name: '---', email: '---' },
      titleCompany: { name: '---', email: '---' },
      buyersAgent: { name: '---', email: '---' },
      sellersAgent: { name: '---', email: '---' },
      amc: { name: 'PARK, INSUN', email: 'ipark@1stopamc.com'},
      appraiser: { name: '---', email: '---' },
      lpContacts: [],

      loanOfficerCB: false,
      loanOfficerCCCB: false,
      loanProcessorCB: false,
      loanProcessorCCCB: false,
      loanProcessor2CB: false,
      loanProcessor2CCCB: false,
      underwriter1CB: false,
      underwriter1CCCB: false,
      underwriter2CB: false,
      underwriter2CCCB: false,
      accountExecutiveCB: false,
      accountExecutiveCCCB: false,
      settlementCB: false,
      settlementCCCB: false,
      titleCB: false,
      titleCCCB: false,
      buyerCB: false,
      buyerCCCB: false,
      sellerCB: false,
      sellerCCCB: false,
      amcCB: false,
      amcCCCB: false,
      appraiserCB: false,
      appraiserCCCB: false,
      borrower0CB: false,
      borrower0CCCB: false,
      borrower1CB: false,
      borrower1CCCB: false,
      borrower2CB: false,
      borrower2CCCB: false,
      custom1CB: false,
      custom1CCCB: false,
      custom2CB: false,
      custom2CCCB: false,
      custom3CB: false,
      custom3CCCB: false,
      custom1: '',
      custom2: '',
      custom3: '',
      custom1error: '',
      custom2error: '',
      custom3error: '',
      optionalText: '',
      fileArr: []
    };

    this.getContactInfo(this.props.fileID);
  }

  async getContactInfo(fileID) {
    const response = axios.get('/getContactInfo', {
      params: {
        pFileID: fileID,
      },
    });
    // the response.data will contain the values returned
    var resultData = (await response).data;
    try {
      var formattedContacts = [];
      for (var i = 0; i < resultData.lpContacts.length; i++) {
        formattedContacts.push({
          label:
            resultData.lpContacts[i].email +
            ' (' +
            resultData.lpContacts[i].name +
            ')',
          value: resultData.lpContacts[i].email,
        });
      }

      this.setState({
        resultData: resultData,
        searchQuery: fileID + ' (' + resultData.borrowers[0].name + ')',
        borrowers: resultData.borrowers,
        loanOfficer: resultData.lo,
        loanProcessor: resultData.lp,
        loanProcessor2: resultData.lp2,
        loanProcessorCB: resultData.lp.email !== '---',
        loanProcessor2CB: resultData.lp2.email !== '---',
        underwriter1: resultData.uw1,
        underwriter2: resultData.uw2,
        accountExecutive: resultData.ae,
        accountExecutiveCCCB: resultData.ae.email !== '---',
        settlementCompany: resultData.sc,
        titleCompany: resultData.tc,
        buyersAgent: resultData.ba,
        sellersAgent: resultData.sa,
        appraiser: resultData.ap,
        lpContacts: formattedContacts,
        escrowNo: resultData.escrowNo,
        titleNo: resultData.titleNo,
        investorNo: resultData.investorNo,
        fetching: false,
        fileArr: [],
        basicSubject: 'MTGLABS | ' + fileID + " | " + resultData.borrowers[0].name,
        emailSubject: 'MTGLABS | ' + fileID + " | " + resultData.borrowers[0].name
      });
    }
    catch {}
  }

  componentDidUpdate(prevProps) {
    if(prevProps.fileID !== this.props.fileID) 
      this.getContactInfo(this.props.fileID);
  }

  clearEmails() {
    this.setState({
      loanOfficerCB: false,
      loanOfficerCCCB: false,
      loanProcessorCB: false,
      loanProcessorCCCB: false,
      loanProcessor2CB: false,
      loanProcessor2CCCB: false,
      underwriter1CB: false,
      underwriter1CCCB: false,
      underwriter2CB: false,
      underwriter2CCCB: false,
      accountExecutiveCB: false,
      accountExecutiveCCCB: false,
      settlementCB: false,
      settlementCCCB: false,
      titleCB: false,
      titleCCCB: false,
      buyerCB: false,
      buyerCCCB: false,
      sellerCB: false,
      sellerCCCB: false,
      amcCB: false,
      amcCCCB: false,
      appraiserCB: false,
      appraiserCCCB: false,
      borrower0CB: false,
      borrower0CCCB: false,
      borrower1CB: false,
      borrower1CCCB: false,
      borrower2CB: false,
      borrower2CCCB: false,
      custom1CB: false,
      custom1CCCB: false,
      custom2CB: false,
      custom2CCCB: false,
      custom3CB: false,
      custom3CCCB: false,
      custom1: '',
      custom2: '',
      custom3: '',
      custom1error: '',
      custom2error: '',
      custom3error: ''
    });
  }

  getExtraText() {
    return (
      <span>
        {this.state.emailExtraText && (
          <span style={{ whiteSpace: 'pre-line' }}>
            {this.state.emailExtraText}
          </span>
        )}
        <button
          className="btn"
          type="button"
          style={{ height: '16px', maxWidth: '16px' }}
          onClick={() =>
            this.setState({
              showExtraWrapper: true,
              extraContentType: 'EditExtraText',
              tempExtraText: this.state.emailExtraText,
            })
          }
        >
          <i
            className="bi bi-pencil-square"
            style={{
              color: 'red',
              fontSize: '14px',
              position: 'relative',
              top: '-14px',
              left: '-7px',
            }}
          />
        </button>
      </span>
    );
  }

  getErrorIcon() {
    return (
      <i
        className="bi bi-exclamation-triangle text-red ml-2"
        style={{
          position: 'relative',
          top: '-3px',
        }}
      ></i>
    );
  }

  captureInput = e => {
    if (
      e.target.name === 'custom1' ||
      e.target.name === 'custom2' ||
      e.target.name === 'custom3'
    ) {
      // validate email here
      const emailValidate =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (e.target.name === 'custom1')
        this.setState({
          custom1error:
            e.target.value.length < 5 ||
            emailValidate.test(String(e.target.value).toLowerCase())
              ? ''
              : this.getErrorIcon(),
          custom1CB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom1CB,
          custom1CCCB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom1CCCB,
        });
      else if (e.target.name === 'custom2')
        this.setState({
          custom2error:
            e.target.value.length < 5 ||
            emailValidate.test(String(e.target.value).toLowerCase())
              ? ''
              : this.getErrorIcon(),
          custom2CB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom2CB,
          custom2CCCB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom2CCCB,
        });
      else if (e.target.name === 'custom3')
        this.setState({
          custom3error:
            e.target.value.length < 5 ||
            emailValidate.test(String(e.target.value).toLowerCase())
              ? ''
              : this.getErrorIcon(),
          custom3CB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom3CB,
          custom3CCCB:
            e.target.value.length < 5 ||
            !emailValidate.test(String(e.target.value).toLowerCase())
              ? false
              : this.state.custom3CCCB,
        });
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  checkBoxClicked(targetName) {
    var checkedTest = this.state[targetName] ? this.state[targetName] : false;
    this.setState({ [targetName]: !checkedTest });

    // if we are checking (true), then uncheck the corresponding to/cc field
    if (checkedTest === false) {
      var otherTarget = targetName.endsWith('CCCB')
        ? targetName.substr(0, targetName.length - 4) + 'CB'
        : targetName.substr(0, targetName.length - 2) + 'CCCB';
      this.setState({ [otherTarget]: false });
    }
  }

  updateEmailField = e => {
    var fieldNum = e.target.name.substr(e.target.name.length - 1);
    this.setState({
      ['custom' + fieldNum]: e.target.value,
      ['custom' + fieldNum + 'error']: '',
      ['custom' + fieldNum + 'CB']: false,
      ['custom' + fieldNum + 'CCCB']: false,
    });
  };

  getSendAddresses() {
    var sendTo = '';
    for (var i = 0; i < this.state.borrowers.length; i++) {
      if (
        this.state['borrower' + i + 'CB'] &&
        sendTo.indexOf(this.state.borrowers[i].email) === -1
      )
        sendTo += this.state.borrowers[i].email + '; ';
    }

    if (
      this.state.loanOfficerCB &&
      sendTo.indexOf(this.state.loanOfficer.email) === -1
    )
      sendTo += this.state.loanOfficer.email + '; ';
    if (
      this.state.loanProcessorCB &&
      sendTo.indexOf(this.state.loanProcessor.email) === -1
    )
      sendTo += this.state.loanProcessor.email + '; ';
    if (
      this.state.loanProcessor2CB &&
      sendTo.indexOf(this.state.loanProcessor2.email) === -1
    )
      sendTo += this.state.loanProcessor2.email + '; ';
    if (
      this.state.underwriter1CB &&
      sendTo.indexOf(this.state.underwriter1.email) === -1
    )
      sendTo += this.state.underwriter1.email + '; ';
    if (
      this.state.underwriter2CB &&
      sendTo.indexOf(this.state.underwriter2.email) === -1
    )
      sendTo += this.state.underwriter2.email + '; ';
    if (
      this.state.accountExecutiveCB &&
      sendTo.indexOf(this.state.accountExecutive.email) === -1
    )
      sendTo += this.state.accountExecutive.email + '; ';
    if (
      this.state.settlementCB &&
      sendTo.indexOf(this.state.settlementCompany.email) === -1
    )
      sendTo += this.state.settlementCompany.email + '; ';
    if (
      this.state.titleCB &&
      sendTo.indexOf(this.state.titleCompany.email) === -1
    )
      sendTo += this.state.titleCompany.email + '; ';
    if (
      this.state.buyerCB &&
      sendTo.indexOf(this.state.buyersAgent.email) === -1
    )
      sendTo += this.state.buyersAgent.email + '; ';
    if (
      this.state.sellerCB &&
      sendTo.indexOf(this.state.sellersAgent.email) === -1
    )
      sendTo += this.state.sellersAgent.email + '; ';
    if (
      this.state.amcCB &&
      sendTo.indexOf(this.state.amc.email) === -1
    )
      sendTo += this.state.amc.email + '; ';
    if (
      this.state.appraiserCB &&
      sendTo.indexOf(this.state.appraiser.email) === -1
    )
      sendTo += this.state.appraiser.email + '; ';
    if (this.state.custom1CB && sendTo.indexOf(this.state.custom1.email) === -1)
      sendTo += this.state.custom1 + '; ';
    if (this.state.custom2CB && sendTo.indexOf(this.state.custom2.email) === -1)
      sendTo += this.state.custom2 + '; ';
    if (this.state.custom3CB && sendTo.indexOf(this.state.custom3.email) === -1)
      sendTo += this.state.custom3 + '; ';

    return sendTo.substr(0, sendTo.length - 2);
  }

  getCCAddresses() {
    var sendTo = '';
    for (var i = 0; i < this.state.borrowers.length; i++) {
      if (
        this.state['borrower' + i + 'CCCB'] &&
        sendTo.indexOf(this.state.borrowers[i].email) === -1
      )
        sendTo += this.state.borrowers[i].email + '; ';
    }

    if (
      this.state.loanOfficerCCCB &&
      sendTo.indexOf(this.state.loanOfficer.email) === -1
    )
      sendTo += this.state.loanOfficer.email + '; ';
    if (
      this.state.loanProcessorCCCB &&
      sendTo.indexOf(this.state.loanProcessor.email) === -1
    )
      sendTo += this.state.loanProcessor.email + '; ';
    if (
      this.state.loanProcessor2CCCB &&
      sendTo.indexOf(this.state.loanProcessor2.email) === -1
    )
      sendTo += this.state.loanProcessor2.email + '; ';
    if (
      this.state.underwriter1CCCB &&
      sendTo.indexOf(this.state.underwriter1.email) === -1
    )
      sendTo += this.state.underwriter1.email + '; ';
    if (
      this.state.underwriter2CCCB &&
      sendTo.indexOf(this.state.underwriter2.email) === -1
    )
      sendTo += this.state.underwriter2.email + '; ';
    if (
      this.state.accountExecutiveCCCB &&
      sendTo.indexOf(this.state.accountExecutive.email) === -1
    )
      sendTo += this.state.accountExecutive.email + '; ';
    if (
      this.state.settlementCCCB &&
      sendTo.indexOf(this.state.settlementCompany.email) === -1
    )
      sendTo += this.state.settlementCompany.email + '; ';
    if (
      this.state.titleCCCB &&
      sendTo.indexOf(this.state.titleCompany.email) === -1
    )
      sendTo += this.state.titleCompany.email + '; ';
    if (
      this.state.buyerCCCB &&
      sendTo.indexOf(this.state.buyersAgent.email) === -1
    )
      sendTo += this.state.buyersAgent.email + '; ';
    if (
      this.state.sellerCCCB &&
      sendTo.indexOf(this.state.sellersAgent.email) === -1
    )
      sendTo += this.state.sellersAgent.email + '; ';
    if (
      this.state.amcCCCB &&
      sendTo.indexOf(this.state.amc.email) === -1
    )
      sendTo += this.state.amc.email + '; ';
    if (
      this.state.appraiserCCCB &&
      sendTo.indexOf(this.state.appraiser.email) === -1
    )
      sendTo += this.state.appraiser.email + '; ';
    if (
      this.state.custom1CCCB &&
      sendTo.indexOf(this.state.custom1.email) === -1
    )
      sendTo += this.state.custom1 + '; ';
    if (
      this.state.custom2CCCB &&
      sendTo.indexOf(this.state.custom2.email) === -1
    )
      sendTo += this.state.custom2 + '; ';
    if (
      this.state.custom3CCCB &&
      sendTo.indexOf(this.state.custom3.email) === -1
    )
      sendTo += this.state.custom3 + '; ';

    return sendTo.substr(0, sendTo.length - 2);
  }

  getEmailToList() {
    var emails = this.getSendAddresses();
    return emails.split(';');
  }

  getEmailCcList() {
    var emails = this.getCCAddresses();
    return emails.split(';');
  }

  editExtraText() {
    this.setState({
      showExtraWrapper: false,
      emailExtraText: this.state.tempExtraText,
      tempExtraText: '',
    });
  }

  getExtraContent() {
    switch (this.state.extraContentType) {
      case 'SelectEmailReceipients':
        return (
          <ExtraModalCard>
            <ModalBody>
              <h1 className="h5">Select Email Receipients</h1>
              <hr />
              {this.state.borrowers.map((borrower, index) => (
                <div className="row" key={borrower.name}>
                  <div className="col-3">Borrower #{index + 1}</div>
                  <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state['borrower' + index + 'CB']}
                      onChange={() => this.checkBoxClicked('borrower' + index + 'CB')}
                      id={'borrower' + index + 'CB'}
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state['borrower' + index + 'CCCB']}
                      onChange={() => this.checkBoxClicked('borrower' + index + 'CCCB')}
                      id={'borrower' + index + 'CCCB'}
                    />
                  </div>
                  <div className="col-6">
                    <span className={`text-${(this.state['borrower' + index + 'CB'] || this.state['borrower' + index + 'CCCB']) ? "primary" : 'muted'}`}>
                      {borrower.email + ' (' + borrower.name + ')'}
                    </span>
                  </div>
                </div>
              ))}
              <div className="row mb-1">
                <div className="col-3">Loan Officer</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanOfficerCB}
                      disabled={this.state.loanOfficer.email === '---'}
                      id="loanOfficerCB"
                      onChange={() => this.checkBoxClicked('loanOfficerCB')}
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanOfficerCCCB}
                      disabled={this.state.loanOfficer.email === '---'}
                      onChange={() => this.checkBoxClicked('loanOfficerCCCB')}
                      id="loanOfficerCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.loanOfficerCB || this.state.loanOfficerCCCB) ? "primary" : 'muted'}`}>
                    {this.state.loanOfficer.email + ' (' + this.state.loanOfficer.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Loan Processor 1</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanProcessorCB}
                      disabled={this.state.loanProcessor.email === '---'}
                      onChange={() => this.checkBoxClicked('loanProcessorCB')}
                      id="loanProcessorCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanProcessorCCCB}
                      disabled={this.state.loanProcessor.email === '---'}
                      onChange={() => this.checkBoxClicked('loanProcessorCCCB')}
                      id="loanProcessorCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.loanProcessorCB || this.state.loanProcessorCCCB) ? "primary" : 'muted'}`}>
                    {this.state.loanProcessor.email + ' (' + this.state.loanProcessor.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Loan Processor 2</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanProcessor2CB}
                      disabled={this.state.loanProcessor2.email === '---'}
                      onChange={() => this.checkBoxClicked('loanProcessor2CB')}
                      id="loanProcessor2CB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.loanProcessor2CCCB}
                      disabled={this.state.loanProcessor2.email === '---'}
                      onChange={() => this.checkBoxClicked('loanProcessor2CCCB')}
                      id="loanProcessor2CCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.loanProcessor2CB || this.state.loanProcessor2CCCB) ? "primary" : 'muted'}`}>
                    {this.state.loanProcessor2.email + ' (' + this.state.loanProcessor2.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Underwriter 1</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.underwriter1CB}
                      disabled={this.state.underwriter1.email === '---'}
                      onChange={() => this.checkBoxClicked('underwriter1CB')}
                      id="underwriter1CB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.underwriter1CCCB}
                      disabled={this.state.underwriter1.email === '---'}
                      onChange={() => this.checkBoxClicked('underwriter1CCCB')}
                      id="underwriter1CCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.underwriter1CB || this.state.underwriter1CCCB) ? "primary" : 'muted'}`}>
                    {this.state.underwriter1.email + ' (' + this.state.underwriter1.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Underwriter 2</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.underwriter2CB}
                      disabled={this.state.underwriter2.email === '---'}
                      onChange={() => this.checkBoxClicked('underwriter2CB')}
                      id="underwriter2CB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.underwriter2CCCB}
                      disabled={this.state.underwriter2.email === '---'}
                      onChange={() => this.checkBoxClicked('underwriter2CCCB')}
                      id="underwriter2CCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.underwriter2CB || this.state.underwriter2CCCB) ? "primary" : 'muted'}`}>
                    {this.state.underwriter2.email + ' (' + this.state.underwriter2.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Account Executive</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.accountExecutiveCB}
                      disabled={this.state.accountExecutive.email === '---'}
                      onChange={() => this.checkBoxClicked('accountExecutiveCB')}
                      id="accountExecutiveCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.accountExecutiveCCCB}
                      disabled={this.state.accountExecutive.email === '---'}
                      onChange={() => this.checkBoxClicked('accountExecutiveCCCB')}
                      id="accountExecutiveCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.accountExecutiveCB || this.state.accountExecutiveCCCB) ? "primary" : 'muted'}`}>
                    {this.state.accountExecutive.email + ' (' + this.state.accountExecutive.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Settlement Company</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.settlementCB}
                      disabled={this.state.settlementCompany.email === '---'}
                      onChange={() => this.checkBoxClicked('settlementCB')}
                      id="settlementCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.settlementCCCB}
                      disabled={this.state.settlementCompany.email === '---'}
                      onChange={() => this.checkBoxClicked('settlementCCCB')}
                      id="settlementCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.settlementCB || this.state.settlementCCCB) ? "primary" : 'muted'}`}>
                    {this.state.settlementCompany.email + ' (' + this.state.settlementCompany.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Title Company</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.titleCB}
                      disabled={this.state.titleCompany.email === '---'}
                      onChange={() => this.checkBoxClicked('titleCB')}
                      id="titleCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.titleCCCB}
                      disabled={this.state.titleCompany.email === '---'}
                      onChange={() => this.checkBoxClicked('titleCCCB')}
                      id="titleCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.titleCB || this.state.titleCCCB) ? "primary" : 'muted'}`}>
                    {this.state.titleCompany.email + ' (' + this.state.titleCompany.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Buyer's Agent</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.buyerCB}
                      disabled={this.state.buyersAgent.email === '---'}
                      onChange={() => this.checkBoxClicked('buyerCB')}
                      id="buyerCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.buyerCCCB}
                      disabled={this.state.buyersAgent.email === '---'}
                      onChange={() => this.checkBoxClicked('buyerCCCB')}
                      id="buyerCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.buyerCB || this.state.buyerCCCB) ? "primary" : 'muted'}`}>
                    {this.state.buyersAgent.email + ' (' + this.state.buyersAgent.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Seller's Agent</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.sellerCB}
                      disabled={this.state.sellersAgent.email === '---'}
                      onChange={() => this.checkBoxClicked('sellerCB')}
                      id="sellerCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.sellerCCCB}
                      disabled={this.state.sellersAgent.email === '---'}
                      onChange={() => this.checkBoxClicked('sellerCCCB')}
                      id="sellerCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.sellerCB || this.state.sellerCCCB) ? "primary" : 'muted'}`}>
                    {this.state.sellersAgent.email + ' (' + this.state.sellersAgent.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">AMC</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.amcCB}
                      onChange={() => this.checkBoxClicked('amcCB')}
                      id="amcCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.amcCCCB}
                      onChange={() => this.checkBoxClicked('amcCCCB')}
                      id="amcCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.amcCB || this.state.amcCCCB) ? "primary" : 'muted'}`}>
                    {this.state.amc.email + ' (' + this.state.amc.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Appraiser</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.appraiserCB}
                      disabled={this.state.appraiser.email === '---'}
                      onChange={() => this.checkBoxClicked('appraiserCB')}
                      id="appraiserCB"
                    />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox"
                      checked={this.state.appraiserCCCB}
                      disabled={this.state.appraiser.email === '---'}
                      onChange={() => this.checkBoxClicked('appraiserCCCB')}
                      id="appraiserCCCB"
                    />
                </div>
                <div className="col-6">
                  <span className={`text-${(this.state.appraiserCB || this.state.appraiserCCCB) ? "primary" : 'muted'}`}>
                    {this.state.appraiser.email + ' (' + this.state.appraiser.name + ')'}
                  </span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-12"><hr /></div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Custom Email #1</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom1CB}
                      disabled={this.state.custom1.length < 5 || this.state.custom1error}
                      onChange={() => this.checkBoxClicked('custom1CB')}
                      id="custom1CB"
                      />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom1CCCB}
                      disabled={this.state.custom1.length < 5 || this.state.custom1error}
                      onChange={() => this.checkBoxClicked('custom1CCCB')}
                    />
                </div>
                <div className="col-6">
                  <EmailTextField type="text" name="custom1" value={this.state.custom1}
                    onChange={this.captureInput}
                  />
                  &nbsp;
                  <HiddenSelect
                    name="lpContactSelect1"
                    value={this.state.lpContactSelect1}
                    onChange={this.updateEmailField}
                    options={this.state.lpContacts}
                  />
                  &nbsp;
                  <span style={{color:'red'}}>{this.state.custom1error}</span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Custom Email #2</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom2CB}
                      disabled={this.state.custom2.length < 5 || this.state.custom2error}
                      onChange={() => this.checkBoxClicked('custom2CB')}
                      />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom2CCCB}
                      disabled={this.state.custom2.length < 5 || this.state.custom2error}
                      onChange={() => this.checkBoxClicked('custom2CCCB')}
                    />
                </div>
                <div className="col-6">
                  <EmailTextField type="text" name="custom2" value={this.state.custom2}
                    onChange={this.captureInput}
                  />
                  &nbsp;
                  <HiddenSelect
                    name="lpContactSelect2"
                    value={this.state.lpContactSelect2}
                    onChange={this.updateEmailField}
                    options={this.state.lpContacts}
                  />
                  &nbsp;
                  <span style={{color:'red'}}>{this.state.custom2error}</span>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">Custom Email #3</div>
                <div className="col-3">
                    TO&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom3CB}
                      disabled={this.state.custom3.length < 5 || this.state.custom3error}
                      onChange={() => this.checkBoxClicked('custom3CB')}
                      id="Checked checkbox"
                      />
                    &nbsp;CC&nbsp;
                    <input className="form-check-input" type="checkbox" checked={this.state.custom3CCCB}
                      disabled={this.state.custom3.length < 5 || this.state.custom3error}
                      onChange={() => this.checkBoxClicked('custom3CCCB')}
                    />
                </div>
                <div className="col-6">
                  <EmailTextField type="text" name="custom3" value={this.state.custom3}
                    onChange={this.captureInput}
                  />
                  &nbsp;
                  <HiddenSelect
                    name="lpContactSelect3"
                    value={this.state.lpContactSelect3}
                    onChange={this.updateEmailField}
                    options={this.state.lpContacts}
                  />
                  &nbsp;
                  <span style={{color:'red'}}>{this.state.custom3error}</span>
                </div>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => this.clearEmails()}
                style={{ marginRight: '10px' }}
              >
                Clear
              </button>
              <button
                type="button"
                onClick={() => this.setState({ showExtraWrapper: false })}
                className="btn btn-primary"
              >
                Close
              </button>
            </div>
          </ExtraModalCard>
        );
      default:
        return <></>;
    }
  }

  openOutlook() {
    var sendToAddr = this.getSendAddresses();
    var ccAddr = this.getCCAddresses();

    var mail =
      'mailto:' + sendToAddr + '?subject=' + this.state.emailSubject +
      (ccAddr.length >= 0 ? '&cc=' + ccAddr : '');
    var a = document.createElement('a');
    a.href = mail;
    a.click();
  }

  changeSubject(subject) {
    this.clearEmails();
    var statelist = {};
    var borrower = this.state.basicSubject.split('MTGLABS | ' + this.props.fileID)[1];
    var subjectString = 'MTGLABS | ' + this.props.fileID;
    switch(subject) {
      case 1:
        statelist["loanProcessorCB"] = this.state.resultData.lp.email !== '---';
        statelist["loanProcessor2CB"] = this.state.resultData.lp2.email !== '---';
        statelist["accountExecutiveCCCB"] = this.state.resultData.ae.email !== '---';
        subjectString += borrower;
        break;
      case 2:
        statelist["settlementCB"] = this.state.resultData.sc.email !== '---';
        statelist["loanProcessorCCCB"] = this.state.resultData.lp.email !== '---';
        statelist["loanProcessor2CCCB"] = this.state.resultData.lp2.email !== '---';
        statelist["accountExecutiveCCCB"] = this.state.resultData.ae.email !== '---';
        subjectString += " | " + this.state.escrowNo + borrower;
        break;  
      case 3:
        statelist["titleCB"] = this.state.resultData.tc.email !== '---';
        statelist["loanProcessorCCCB"] = this.state.resultData.lp.email !== '---';
        statelist["loanProcessor2CCCB"] = this.state.resultData.lp2.email !== '---';
        statelist["accountExecutiveCCCB"] = this.state.resultData.ae.email !== '---';
        subjectString += " | " + this.state.titleNo + borrower;
        break;
      case 4:
        subjectString += " | " + this.state.investorNo + borrower;
        break;
    }

    statelist["emailSubject"] = subjectString;
    statelist["subjectType"] = subject;
    
    this.setState(statelist);
  }

  render() {
    return this.props.show ? (
      <ModalWrapper>
        <ModalCard width={this.props.width} height={this.props.height}>
          <ModalHeader>
            <ModalTitle>[Outlook Template Options]</ModalTitle>
            <button
              type="button"
              className="btn-close"
              onClick={this.props.onDecline}
              aria-label="Close"
            ></button>
          </ModalHeader>
          <ModalBody>
            <div className="w-100 text-start border border-1 p-2">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'top' }}>
                        <b>To:</b>
                      </td>
                      <td>
                        {this.getEmailToList().map((email, index) => (
                          <span
                            key={email + index}
                            className={`fw-normal badge border rounded-pill pill-info`}
                            style={{ marginLeft: '8px' }}
                          >
                            {email.trim()}
                          </span>
                        ))}
                        <button
                          className="btn"
                          type="button"
                          style={{
                            height: '16px',
                            maxWidth: '16px',
                            marginLeft: '10px',
                          }}
                          onClick={() =>
                            this.setState({
                              showExtraWrapper: true,
                              extraContentType: 'SelectEmailReceipients',
                            })
                          }
                        >
                          <i
                            className="bi bi-pencil-square"
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              position: 'relative',
                              top: '-14px',
                              left: '-7px',
                            }}
                          />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mb-2">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'top' }}>
                        <b>Cc:</b>
                      </td>
                      <td>
                        {this.getEmailCcList().map((email, index) => (
                          <span
                            key={email + index}
                            className={`fw-normal badge border rounded-pill pill-info`}
                            style={{ marginLeft: '8px' }}
                          >
                            {email.trim()}
                          </span>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mb-2">
                <span style={{ marginRight: '8px' }}>
                  <b>Subject:</b>
                </span>
                {this.state.emailSubject}
              </div>
              <hr />
              <div
                style={{
                  minHeight: '100px',
                  verticalAlign: 'top',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}
              >
                <div className="vstack gap-1">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="subject1"
                      name="subject1"
                      checked={this.state.subjectType === 1}
                      onChange={() => void 0}
                      onClick={() => this.changeSubject(1)}
                    />
                    <label
                      htmlFor="subject1"
                      className="form-check-label"
                      onClick={() => this.changeSubject(1)}
                    >
                      Standard
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="subject2"
                      name="subject2"
                      checked={this.state.subjectType === 2}
                      onChange={() => void 0}
                      onClick={() => this.changeSubject(2)}
                    />
                    <label
                      htmlFor="subject2"
                      className="form-check-label"
                      onClick={() => this.changeSubject(2)}
                    >
                      Escrow
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="subject3"
                      name="subject3"
                      checked={this.state.subjectType === 3}
                      onChange={() => void 0}
                      onClick={() => this.changeSubject(3)}
                    />
                    <label
                      htmlFor="subject3"
                      className="form-check-label"
                      onClick={() => this.changeSubject(3)}
                    >
                      Title
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="subject4"
                      name="subject4"
                      checked={this.state.subjectType === 4}
                      onChange={() => void 0}
                      onClick={() => this.changeSubject(4)}
                    />
                    <label
                      htmlFor="subject4"
                      className="form-check-label"
                      onClick={() => this.changeSubject(4)}
                    >
                      Investor
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {this.state.showExtraWrapper && (
              <ExtraModalWrapper>{this.getExtraContent()}</ExtraModalWrapper>
            )}
          </ModalBody>

          {/* Error Messages */}
          {this.state.errorMessage && (
            <div style={{ color: 'red', fontSize: '12px' }}>
              {this.state.errorMessage}
            </div>
          )}

          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-6" />
              <div className="col-6 float-right" style={{textAlign:'right'}}>
                {!this.props.hideCancelBtn && (
                  <button
                    type="button"
                    className="btn btn-light float-right"
                    disabled={this.state.isProcessing}
                    onClick={this.props.onDecline}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => this.openOutlook()}
                  disabled={
                    this.props.onAcceptDisabled || this.state.isProcessing
                  }
                  className="btn btn-primary float-right"
                >
                  Open Outlook
                </button>
              </div>
            </div>
          </div>
        </ModalCard>
      </ModalWrapper>
    ) : (
      <></>
    );
  }
}
