import React from 'react';

export default function FormSelect({
  label,
  name,
  value = -1,
  options,
  onChange,
  error,
  tabIndex,
}) {
  return (
    <div className="mb-1 mb-md-3">
      <label htmlFor={name} className="form-label text-muted mb-0 mt-2">
        {label}
      </label>
      <select
        tabIndex={tabIndex}
        onChange={onChange}
        name={name}
        value={value}
        className={`form-select ${!!error && 'border border-danger'}`}
        id={name}
      >
        <option value={0}>Select..</option>
        {options && (<>
        {options.map(el => (
          <option key={el.value} value={el.value}>
            {el.label}
          </option>
        ))}
        </>)}
      </select>
      {error && <p className="text-danger text-capitalize">{error}</p>}
    </div>
  );
}
