import {
  Body,
  Verify,
  Email,
  Task,
  AutoCheck,
  SignOff,
  FindLabelFromName,
  TableZero,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS, STATUS_COLORS } from '../../helpers/constants';
import { todaysDateYYYY, getPropertyType, isAdmin } from '../../helpers/utils';
import Toast from '../Toast';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class ClosingChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      ltvFico: [],
      borrowers: [],
      isLoading: true,
      errorMessage: '',
      checkList: {
        changeDocOut: { checked: false },
        pppAddendum: { checked: false },
        powerOfAttorney: { checked: false },
        rightToCancel: { checked: false },
        piwCheck: { checked: false },
        cpldEmail: { checked: false },
        complete: { checked: false },
      },
      prepayPenaltyTerm: '0',
      prepayPenaltyAmount: '0',
      showEmailTemplate: false,
      emailTemplate: [],
      cpldEmailLog: [],
      showEmailVerification: false,
      verificationEmail: '',
    };
    this.getFundingData();
  }

  async getFundingData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'CLO' },
    });
    // the response.data will contain the values returned
    var checkData = (await response).data;
    var checklistData = this.state.checkList;
    for (var i = 0; i < checkData.length; i++) {
      checklistData[checkData[i].fieldName] = {
        checked: checkData[i].isChecked === 'True',
        timestamp: checkData[i].timestamp,
        userName: checkData[i].userName,
      };
    }

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    response = axios.get('/getclosingfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var closingFields = (await response).data;
    this.setState({
      fileData: resultData,
      markedPiw: closingFields.markedPiw === '1',      
      ocMtg: closingFields.originalCreditorMtg,
      prepayPenaltyTerm: closingFields.prepayPenaltyTerm,
      prepayPenaltyAmount: closingFields.prepayPenaltyAmount,
      cpldTemplate: closingFields.cpldTemplate,
      cpldEmailLog: closingFields.cpldEmailLog,
      isLoading: false,
    });
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'CLO-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if(!checked) { 
      checkList.complete.checked = false;
      this.setChecklistField('complete', false); 
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  async setLoanStatus(status) {
    this.setState({ changingState: status });
    var response = axios.get('/updateLoanStatus', {
      params: {
        pFileID: this.state.activeFile,
        pLoanStatus: status,
        pLoanPurpose: this.state.fileData.loanPurpose,
      },
    });
    var resultData = (await response).data;
    if (resultData === 'Success') {
      var file = this.state.fileData;
      file.loanStatus = status;
      switch (status) {
        case 'Doc Out':
          file.docOutDate = todaysDateYYYY();
          break;
        default:
          break;
      }
      this.setState({ fileData: file, changingState: false });
    } else {
      this.setState({ errorMessage: resultData, changingState: false });
    }
  }

  allChecked() {
    const { fileData, checkList } = this.state;
    var visibleCount = 0;
    var checkedCount = 0;
    for (var prop in checkList) {
      if (prop === 'complete') 
        continue;
      if (prop === 'pppAddendum' && (!fileData.programCode.includes('MTGP2') || fileData.occupancyType !== 'Investment'))
        continue;
      if (prop === 'piwCheck' && !this.state.markedPiw)
        continue;
      if (prop === 'rightToCancel' && (fileData.loanPurpose.includes('Purchase') || (this.state.ocMtg !== '1')))
        continue;
      visibleCount++;
      if(prop === 'changeDocOut') {
        if(this.state.fileData.docOutDate !== '---') { checkedCount++; }
        continue;
      }
      if (Object.prototype.hasOwnProperty.call(checkList, prop) && checkList[prop].checked) {
        checkedCount++;
      }
    }
    var completePercent = this.state.fileData.completePercent?.split("CLO")[1].split('(')[1].split(')')[0];
    if(completePercent.toString() !== Math.round(checkedCount / visibleCount * 100).toString()) {
      this.state.fileData.completePercent = this.state.fileData.completePercent?.split("CLO")[0] + "CLO (" +
        Math.round(checkedCount / visibleCount * 100).toString() + ")" + 
        this.state.fileData.completePercent?.split("CLO")[1].substring(this.state.fileData.completePercent?.split("CLO")[1].indexOf(")")+1);
      this.props.updatePercent('CLO', Math.round(checkedCount / visibleCount * 100).toString());
    }
    if(checkedCount < visibleCount) return false;
    return true;
  }

  markComplete() {
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('CLO', 'complete');
  }

  render() {
    const { isLoading, fileData, checkList } = this.state;
    if (isLoading) {
      return (
        <>
          <h1 className="h5">Closing Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </>
      );
    }
    return (
      <>
        <h1 className="h5">Closing Checklist</h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* POA */}
          <ChecklistCard checked={checkList.powerOfAttorney.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="powerOfAttorney"
                  name="powerOfAttorney"
                  checked={checkList.powerOfAttorney.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="powerOfAttorney">
                  Power of Attorney
                </label>
              </div>
            }
            body={
              <div>
                <Task /> 
                Check If The File Has An Associated POA
              </div>
            }
            alert={
              checkList.powerOfAttorney.checked && (
                <SignOff
                  user={checkList.powerOfAttorney.userName}
                  date={checkList.powerOfAttorney.timestamp}
                />
              )
            }
          />

          {/* Right to Cancel Form */}
          {(!fileData.loanPurpose.includes('Purchase') && (this.state.ocMtg === '1')) && (
            <ChecklistCard checked={checkList.rightToCancel.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    aria-label="Checkbox for following text input"
                    id="rightToCancel"
                    name="rightToCancel"
                    checked={checkList.rightToCancel.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="rightToCancel">
                    Right to Cancel
                  </label>
                </div>
              }
              body={
                <div>
                  <Task tip={'This File Is a Refinance With\n The Original Creditor (MTG)'} /> 
                    Right to Cancel Form (H-9) is Needed
                </div>
              }
              alert={
                checkList.rightToCancel.checked && (
                  <SignOff
                    user={checkList.rightToCancel.userName}
                    date={checkList.rightToCancel.timestamp}
                  />
                )
              }
            />
          )}

          {/* PPP Addendum */}
          {(fileData.programCode.includes('MTGP2') && fileData.occupancyType === 'Investment') && (
            <ChecklistCard checked={checkList.pppAddendum.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    aria-label="Checkbox for following text input"
                    id="pppAddendum"
                    name="pppAddendum"
                    checked={checkList.pppAddendum.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="pppAddendum">
                    PPP Addendum
                  </label>
                </div>
              }
              body={
                <div>
                  <TableZero>
                    <tbody>
                      <tr>
                        <td>
                          <Task tip="Prepay Penalty (MTGP2 / Investment Files)" /> 
                        </td>
                        <td>
                          {this.state.prepayPenaltyTerm === "0" ? ('No PPP Addendum Needed') : 
                            (
                              <div>
                                {'PPP Addendum Needed For ' + (Number(this.state.prepayPenaltyTerm) / 12) + 
                                  ' Year' + (Number(this.state.prepayPenaltyTerm) > 12 ? 's' : '')}
                                <br/>
                                {'Amount: (' + this.state.prepayPenaltyAmount + ')'}
                              </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </TableZero>
                </div>
              }
              alert={
                checkList.pppAddendum.checked && (
                  <SignOff
                    user={checkList.pppAddendum.userName}
                    date={checkList.pppAddendum.timestamp}
                  />
                )
              }
            />
          )}

          {/* Change Status (Doc Out) */}
          <ChecklistCard checked={this.state.fileData.docOutDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeDocOut"
                  name="changeDocOut"
                  disabled={true}
                  checked={this.state.fileData.docOutDate !== '---'}
                />
                <label
                  className="form-check-label"
                  htmlFor="changeDocOut"
                >
                  Doc Out
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'Doc Out'" />
                {this.state.fileData.docOutDate !== '---' ? (
                  <>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['doc out'],
                      }}
                    >
                      Doc Out
                    </span>
                    {this.state.fileData.docOutDate}
                  </>
                ) : (
                  <>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={this.state.changingState}
                      onClick={() => this.setLoanStatus('Doc Out')}
                    >
                      {this.state.changingState === 'Doc Out' ? '...' : 'Change'}
                    </button>
                  </>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.docOutDate !== '---'}
                tip="Doc Out Date Not Found"
              />
            }
          />

          {/* PIW */}
          {this.state.markedPiw && (
            <ChecklistCard checked={checkList.piwCheck.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    aria-label="Checkbox for following text input"
                    id="piwCheck"
                    name="piwCheck"
                    checked={checkList.piwCheck.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="piwCheck">
                    PIW
                  </label>
                </div>
              }
              body={
                <div>
                  <Verify /> 
                  This File Is With a Property Inspection Waiver
                </div>
              }
              alert={
                checkList.piwCheck.checked && (
                  <SignOff
                    user={checkList.piwCheck.userName}
                    date={checkList.piwCheck.timestamp}
                  />
                )
              }
            />
          )}

          {/* Closing Package Loan/Doc Email */}
          <ChecklistCard checked={checkList.cpldEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="cpldEmail"
                  name="cpldEmail"
                  checked={checkList.cpldEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="cpldEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <Email />
                Closing Package Loan/Doc
                <button
                  className="btn btn-sm btn-outline-primary ms-2"
                  onClick={() => {
                    this.setState({
                      showEmailTemplate: true,
                      emailTemplate: this.state.cpldTemplate,
                      emailLog: this.state.cpldEmailLog,
                    });
                  }}
                >
                  Preview
                </button>
                {this.state.cpldEmailLog.length > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      height: '24px',
                      maxWidth: '24px',
                      marginLeft: '10px',
                      pointerEvents: 'auto'
                    }}
                    onClick={() =>
                      this.setState({
                        showEmailLog: true,
                        emailLog: this.state.cpldEmailLog,
                      })
                    }
                  >
                    <i
                      className="bi bi-envelope-check"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                      }}
                    />
                  </button>
                )}
              </div>
            }
            alert={
              (checkList.cpldEmail.checked && (
                <SignOff
                  user={checkList.cpldEmail.userName}
                  date={checkList.cpldEmail.timestamp}
                />
              ))
            }
          />

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}

          <ModalEmail
            show={this.state.showEmailTemplate}
            template={this.state.emailTemplate}
            fileID={this.state.activeFile}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
            onAccept={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
          />
          <ModalEmailLog
            show={this.state.showEmailLog}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailLog: false, emailLog: [] })
            }
          />
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
        </div>
      </>
    );
  }
}
