import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import axios from 'axios';
import { STATUS_COLORS } from '../helpers/constants';

class EmailSearch extends Component {
  constructor(props) {
    super(props);
    this.programsRef = React.createRef();
    this.state = {
      email: '',
      emailData: [],
    };
  }

  getEmailResults = async e => {
    e.preventDefault();
    this.setState({ oldq: this.state.query });
    var response = axios.get('/getAssociatedEmail', {
      params: {
        pBorrowerEmail: this.state.query,
      },
    });

    try {
      var resultData = (await response).data;
      this.setState({ emailData: resultData });
    } catch {
      this.setState({ emailData: [] });
    }
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.getEmailResults(e);
    }
  };

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  clearSearch = () => {
    this.setState({ query: '', emailData: [] });
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="h4 text-uppercase">email search</h1>
          </div>
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 d-flex gap-3">
            {/* <div className="col-12 col-md-6 offset-md-3 d-flex gap-3"> */}
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Search email"
              aria-label="search box"
              aria-describedby="button-addon2"
              name="query"
              value={this.state.query}
              onChange={this.captureInput}
              onKeyPress={this.handleKeyPress}
            ></input>
            <button
              className="btn btn-primary btn-lg"
              type="submit"
              id="button-addon2"
              onClick={this.getEmailResults}
            >
              Search
            </button>
            <button
              className="btn btn-outline-secondary btn-lg"
              disabled={this.state.fetching || !this.state.emailData}
              onClick={this.clearSearch}
            >
              Clear
            </button>
          </div>

          {this.state.emailData.others ? (
            <div className="col-12 col-md-8 offset-md-2">
              <h2 className="h5 border-bottom mb-md-3">Results</h2>
              {this.state.emailData.others.length > 0 ? (
                <div className="col-12">
                  {this.state.emailData.others.map(file => (
                    <div
                      className="border mb-2 rounded bg-white p-3 position-relative"
                      key={file.fileDataID}
                    >
                      <div className="d-flex justify-content-between align-items-start">
                        <h2 className="h4 fw-bold mb-3">
                          {file.borrowers}{' '}
                          <Link
                            to={`../pipeline/loan-summary/${file.fileDataID}`}
                            className="stretched-link"
                          >
                            {file.fileDataID}
                          </Link>
                        </h2>
                        <h2>
                          <span
                            className={`badge pill-${
                              STATUS_COLORS[file.loanStatus.toLowerCase()]
                            }`}
                          >
                            {file.loanStatus}
                          </span>
                        </h2>
                      </div>
                      <p className="mb-0">
                        <span className="fw-bold">File Name:</span>{' '}
                        {file.fullName}{' '}
                        <span className="ms-4 fw-bold">Submitted Date:</span>{' '}
                        {file.submittedDate}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className="alert alert-danger text-center p-2 border-0 fw-bold text-dark"
                  role="alert"
                >
                  <i className="bi bi-x-circle me-2 text-danger"></i>
                  No file found using email:{' '}
                  <span className="fw-bold">{this.state.oldq}</span>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default EmailSearch;
