import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import axios from 'axios';
import LoginState from './LoginState';
import Toast from '../components/Toast';
import styled from 'styled-components';

const ButtonLink = styled.span`
  color: ${p => p.theme.colors[p.color] || p.theme.colors.gray500};
  cursor: pointer;
  margin-left: 3px;
  margin-right: 3px;
  text-decoration: none;

  &:hover,
  &:active {
    color: ${p => p.theme.colors[p.color] || p.theme.colors.primary500};
    text-decoration: underline;
  }
`;

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      retrievedData: false,
      successMessage: '',
    };

    // get options before page loads
    this.getMessages();
  }

  async getMessages() {
    axios
      .get('/getmessages', {
        params: {
          pUserID: LoginState.userId,
        },
      })
      .then(res => {
        if (res) {
          this.setState({ messages: res.data, retrievedData: true });
        }
      });
  }

  async ClearComments(timestamp) {
    axios
      .get('/getmessages', {
        params: {
          pUserID: LoginState.userId,
          pTimestamp: timestamp,
        },
      })
      .then(res => {
        if (res) {
          this.setState({
            messages: res.data,
            retrievedData: true,
            successMessage: 'Cleared Message',
          });
        }
      });
  }

  render() {
    if (!this.state.retrievedData) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>Messages</h4>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <img
                  src="/images/spinner.gif"
                  alt=""
                  height="64px"
                  width="64px"
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.messages.length === 0) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>Messages</h4>
              <div>No Messages Found</div>
            </div>
          </div>
          {this.state.successMessage && (
            <Toast
              type="success"
              title={this.state.successMessage}
              onClose={() => this.setState({ successMessage: null })}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>Messages</h4>
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Condition</th>
                    <th>Message</th>
                    <th>Mark Read</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.messages.map(message => (
                    <tr key={message.fileDataID}>
                      <td className="text-center">
                        <Link
                          to={`../pipeline/conditions/ac-${message.encodedFileID}`}
                        >
                          {message.encodedFileID}
                        </Link>
                      </td>
                      <td>{message.code}</td>
                      <td>
                        [{message.submitter}] responded to your comment on [
                        {message.timestampDisplay}]
                      </td>
                      <td>
                        <ButtonLink
                          onClick={() => this.ClearComments(message.timestamp)}
                        >
                          [Clear]
                        </ButtonLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {this.state.successMessage && (
            <Toast
              type="success"
              title={this.state.successMessage}
              onClose={() => this.setState({ successMessage: null })}
            />
          )}
        </div>
      );
    }
  }
}

export default Messages;
