import React from 'react';
import { numbersNoFormat } from '../helpers/utils';

export default function FormInputPercentage({
  className = '',
  type,
  name,
  value,
  label,
  error,
  onChange,
  tabIndex,
}) {

  const formatValue = e => {
    let removeFormat = numbersNoFormat(value);
    onChange({ target: { id: name, value: removeFormat ? removeFormat + "%" : '' } });
  }

  return (
    <div className="mb-1 mb-md-3">
      <label htmlFor={name} className="form-label text-muted mb-0 mt-2">
        {label}
      </label>
      <input
        tabIndex={tabIndex}
        type='text'
        onChange={onChange}
        name={name}
        value={value}
        className={`form-control ${className} ${
          !!error && 'border border-danger'
        }`}
        id={name}
        placeholder="Enter %"
        onBlur={formatValue}
      />
      {error && <p className="text-danger text-capitalize">{error}</p>}
    </div>
  );
}
