import React, { useContext, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { ACTIONS, Session } from '../helpers/sessionContext';
import { JWT_SECRET_KEY } from '../helpers/constants';
import jwt from 'jsonwebtoken';

import GlobalNavBar from '../components/GlobalNavBar';
import GlobalMessage from '../components/GlobalMessage';
import Sidebar from '../components/SideBar';
import Footer from '../components/Footer';
import { useLocation, useHistory } from 'react-router-dom';

function PrivateLayout({ children }) {
  const { state, dispatch } = useContext(Session);
  const location = useLocation();
  const history = useHistory();

  const Ref = useRef(null);

  const startTimer = e => {
    const latestStored = localStorage.getItem('lastTimeStamp');
    if (
      Date.parse(e === latestStored ? e : latestStored) -
        Date.parse(new Date()) <=
      0
    ) {
      // timed out - logout
      dispatch({ type: ACTIONS.REMOVE_USER });
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('lastTimeStamp');
      history.push('/timedout');
    }
  };

  const clearTimer = e => {
    if (Ref.current) clearInterval(Ref.current);
    localStorage.setItem('lastTimeStamp', e);
    const id = setInterval(() => {
      startTimer(e);
    }, 10000);
    Ref.current = id;
  };

  const getTimeoutSeconds = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60 * 60 * 6); // 6 Hours
    return deadline;
  };

  useEffect(() => {
    console.log('private layout first render');
    clearTimer(getTimeoutSeconds());
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        jwt.verify(token, JWT_SECRET_KEY);
      } catch (error) {
        dispatch({ type: ACTIONS.REMOVE_USER });
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('lastTimeStamp');
      }
    }
  }, []);

  if (!state.user) {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  return (
    <div className="private-grid-container">
      <div className="private-grid-header">
        <GlobalMessage />
        <GlobalNavBar user={state.user} />
      </div>
      <div className="private-grid-sidebar">
        <Sidebar />
      </div>
      <div className="private-grid-main">{children}</div>
      {/* <div className="private-grid-footer">
      <Footer />
    </div> */}
    </div>
  );
}

export default PrivateLayout;
